import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { ReactElement, useEffect } from 'react';
import { logViewHelp } from '../../../analytics/helpers/log-view-help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    element: {
      padding: theme.spacing(2),
    },
    helpElement: {
      padding: theme.spacing(1),
    },
    helpText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  })
);

export default function ContactUs(): ReactElement {
  const classes = useStyles();

  useEffect(() => {
    logViewHelp();
  }, []);

  return (
    <Box className={classes.element} display='flex' justifyContent='center' alignContent='center'>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <PhoneIcon className={classes.helpElement} color='primary' />
        <EmailIcon className={classes.helpElement} color='primary' />
      </Box>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <a
          href='https://wa.me/551140201739'
          target='_blank'
          rel='noopener noreferrer'
          className={classes.helpText}
        >
          <Typography>4020-1739</Typography>
        </a>
        <a href='mailto: falaai@pedepronto.com.br' className={classes.helpText}>
          <Typography>falaai@pedepronto.com.br</Typography>
        </a>
      </Box>
    </Box>
  );
}
