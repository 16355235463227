import {
  MutationsFlipChosenProductQuantityArgs,
  Product,
  ProductChosenQuantityFragment,
  ProductChosenQuantityFragmentDoc,
  ProductFragment,
  ProductFragmentDoc,
  ProductPriceFragment,
  ProductPriceFragmentDoc,
  ProductType,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

/**
 * Changes the chosen product quantity from 0 to 1 and from 1 to 0,
 * based on the current value.
 */
export function flipChosenProductQuantity(
  root: any,
  variables: MutationsFlipChosenProductQuantityArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  // Get the simple product
  const product = context.cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
  });
  if (!product) {
    console.error(`Product: ${variables.input.productCacheId} not found`);
    return false;
  }

  let updatedProduct: ProductFragment;

  if (product.chosenQuantity === 0) {
    updatedProduct = { ...product, chosenQuantity: 1, scrollToProduct: true };
  } else if (product.chosenQuantity === 1) {
    updatedProduct = { ...product, chosenQuantity: 0, scrollToProduct: true };
  } else {
    console.warn('Invalid change. Chosen quantity is different than 0 or 1.');
    return false;
  }

  // Ignore change if it breaks the rules.
  if (
    product.productType !== ProductType.Choosable &&
    (updatedProduct.chosenQuantity > updatedProduct.maximumChoices! ||
      updatedProduct.chosenQuantity < updatedProduct.minimumChoices!)
  ) {
    console.warn('Invalid change. Chosen quantity is invalid', updatedProduct.chosenQuantity);
    return false;
  } else if (
    updatedProduct.chosenQuantity > updatedProduct.maximumChoices! ||
    updatedProduct.chosenQuantity < 0
  ) {
    console.warn(
      'Invalid change for a choosable. Chosen quantity is invalid',
      updatedProduct.chosenQuantity
    );
    return false;
  }

  if (variables.input.parentCacheId) {
    const parentUpdateResult = flipParent(
      product.chosenQuantity,
      variables.input.parentCacheId,
      context.cache,
      context.getCacheKey
    );
    if (!parentUpdateResult) {
      return false;
    }
  }

  // Write changes to cache
  context.cache.writeFragment<ProductChosenQuantityFragment>({
    fragment: ProductChosenQuantityFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
    data: updatedProduct,
  });
  // Handle menu item.
  if (variables.input.menuItemCacheId && product.productType !== ProductType.Choosable) {
    updateMenuItemPrice(
      product as Product,
      variables.input.menuItemCacheId,
      context.cache,
      context.getCacheKey
    );
  }

  return true;
}

function flipParent(
  chosenQuantity: number,
  parentCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
): boolean {
  const parent = cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
  });

  let updatedParent;
  if (parent && parent.productType === ProductType.Choosable) {
    if (chosenQuantity === 0) {
      updatedParent = { ...parent, chosenQuantity: parent.chosenQuantity + 1 };
      if (updatedParent.chosenQuantity > updatedParent.maximumChoices!) {
        console.warn('Invalid change, quantity greater than parent maximum');
        return false;
      }
    } else if (chosenQuantity === 1) {
      updatedParent = { ...parent, chosenQuantity: parent.chosenQuantity - 1 };
      if (updatedParent.chosenQuantity < 0) {
        console.warn('Invalid change, quantity less than 0');
        return false;
      }
    } else {
      console.warn('Invalid change. Chosen quantity is different than 0 or 1 for parent.');
      return false;
    }

    // Write changes to cache
    cache.writeFragment<ProductChosenQuantityFragment>({
      fragment: ProductChosenQuantityFragmentDoc,
      id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
      data: updatedParent,
    });
  } else {
    console.warn(`Parent not found or is not a choosable`, parentCacheId);
  }

  return true;
}

function updateMenuItemPrice(
  product: Product,
  menuItemCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const menuItem = cache.readFragment<ProductPriceFragment>({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
  });
  if (!menuItem) {
    console.error(`Menu item: ${menuItemCacheId} not found`);
    return;
  }

  // Increase or decrease the menu item total price
  let price = 0;
  if (product.productCompanyByCompanyId && product.productCompanyByCompanyId.price) {
    price = product.productCompanyByCompanyId.price;
  }

  let updatedMenuItem;
  if (product.chosenQuantity === 0) {
    updatedMenuItem = { ...menuItem, totalPrice: menuItem.totalPrice! + price };
  } else if (product.chosenQuantity === 1) {
    updatedMenuItem = { ...menuItem, totalPrice: menuItem.totalPrice! - price };
  } else {
    console.warn('Invalid change. Chosen quantity is different than 0 or 1 for parent.');
    return false;
  }

  cache.writeFragment<ProductPriceFragment>({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
    data: updatedMenuItem,
  });
}
