import { hotjar } from 'react-hotjar';

export function initHotjar() {
  if (process.env.NODE_ENV === 'production') {
    const hjid = Number(process.env.REACT_APP_HOTJAR_ID);
    const hjsv = Number(process.env.REACT_APP_HOTJAR_SV);

    hotjar.initialize(hjid, hjsv);
  }
}
