import { Maybe, Product } from '../generated/graphql';

export function isProductAvailable(product: Maybe<Product>): boolean {
  let result = false;
  if (product && product.productCompanyByCompanyId) {
    result =
      !product.hidden &&
      !product.productCompanyByCompanyId.hidden &&
      !product.productCompanyByCompanyId.unavailable;
  }

  return result;
}
