import { Box, Grid, Container } from '@material-ui/core';
import React from 'react';
import ProfileMenu from '../../../common/components/profile-menu/profile-menu';
import { Image, Maybe } from '../../../common/generated/graphql';
import AddressPicker from '../address-picker/address-picker';
import clsx from 'clsx';
import { getAppConfig } from '../../../common/helpers/configs';
import { useStyles } from './aggregator-header.style';
import { useHistory, useParams } from 'react-router-dom';
import { AggregatorLogo } from '../../../common/components/aggregator-logo/aggregator-logo';

interface Props {
  aggregatorName?: Maybe<string>;
  aggregatorImages?: Maybe<Array<Maybe<Image>>>;
  withTabs?: Maybe<boolean>;
  hiddenAddressPicker?: boolean;
}

export default function AggregatorHeader(props: Props) {
  const classes = useStyles();
  const appConfig = getAppConfig();
  const showAddressPicker = appConfig.featureFlags.gmapsAddress;
  const { aggregatorId } = useParams<{ aggregatorId: string }>();
  const { replace } = useHistory();

  return (
    <Grid
      container
      className={`${classes.root} ${
        appConfig.featureFlags.removeLogoFromTheApp && classes._withoutBrand
      } ${props.withTabs && classes._withTabs}`}
    >
      <Container className={classes.container}>
        <Box className={classes.logoWrap}>
          <Box
            id='aggregator-header-logo'
            onClick={() => replace(`/${aggregatorId}/aggregator`)}
            className={clsx(classes.logo, {
              [classes.growImageContainer]: !showAddressPicker,
            })}
          >
            <AggregatorLogo
              reduced
              aggregatorImages={props.aggregatorImages}
              aggregatorName={props.aggregatorName}
            />
          </Box>
        </Box>
        <Box className={classes.addressWrap}>
          {!props.hiddenAddressPicker && showAddressPicker && (
            <Box className={classes.addressPicker}>
              <AddressPicker />
            </Box>
          )}
        </Box>
        <Box className={classes.userWrap}>
          <Box id='agg-menu'>
            <ProfileMenu />
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
