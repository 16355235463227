import {
  Result,
  PartnerLoginMutationVariables,
  IsEmbeddedQuery,
  IsEmbeddedDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { getEndpoints } from '../../helpers/configs';
import { logError } from '../../helpers/log-error';
import { logLoginError } from '../../../analytics/helpers/log-login';

export async function partnerLogin(
  root: any,
  variables: PartnerLoginMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    setEmbeddedFlag(context.cache);

    const endpoints = getEndpoints();
    const uri = 'v1/partners/login/refresh';
    const url = `${endpoints.api}${uri}`;

    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${variables.token}`,
      },
    });

    return {
      __typename: 'Result',
      success: true,
      errorMsg: '',
    };
  } catch (error) {
    const errorMsg = 'Error executing third party login';
    logError(error, errorMsg);
    logLoginError({ errorCode: 78 });
    return {
      __typename: 'Result',
      success: false,
      errorMsg,
    };
  }
}

function setEmbeddedFlag(cache: InMemoryCache) {
  cache.writeQuery<IsEmbeddedQuery>({
    query: IsEmbeddedDocument,
    data: { isEmbedded: true },
  });
}
