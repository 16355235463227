import { PaymentMethod } from '../generated/graphql';
import PaymentMethodArgs from '../model/payment-method';
import { Maybe } from 'graphql/jsutils/Maybe';

export default function formatPaymentMethod(payment: PaymentMethod): Maybe<PaymentMethodArgs> {
  let cardBrand: string = payment.cardBrand;
  let id = payment.id;

  if (payment?.__typename === 'RemoteCard') {
    return {
      id: payment.numericalId,
      cardBrand,
      cardNumber: payment.maskedNumber,
      cardPrintedName: payment.printedName,
      cardExpiration: payment.expiration,
      cardFullMasked: payment.maskedNumberFull,
    };
  }

  if (payment?.__typename === 'LocalCard') {
    return {
      id,
      cardBrand,
      cardNumber: payment.cardNumber,
      cardPrintedName: payment.cardName,
      cardExpiration: payment.cardExpiration,
    };
  }

  if (payment.__typename === 'OfflinePayment') {
    return {
      id,
      cardPrintedName: payment.cardName,
      cardBrand,
    };
  }
}
