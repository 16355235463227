export const STATUS_CANCELED = 'onyo.order.canceled';
export const STATUS_BACKEND_RECEIVED = 'onyo.order.backend-received';
export const STATUS_PAYMENT_AUTHORIZED = 'onyo.order.payment-authorized';
export const STATUS_POS_RECEIVED = 'onyo.order.pos-received';
export const STATUS_POS_ACCEPTED = 'onyo.order.pos-accepted';
export const STATUS_POS_DENIED = 'onyo.order.pos-denied';
export const STATUS_READY = 'onyo.order.ready';
export const STATUS_DISPATCHED = 'onyo.order.dispatched';
export const STATUS_DELIVERED = 'onyo.order.delivered';
export const STATUS_CONSUMED = 'onyo.order.consumed';
export const STATUS_RECEIVED = 'onyo.order.received';
export const STATUS_POS_IMPORTED = 'onyo.order.pos-imported';
export const STATUS_POS_IMPORT_ERROR = 'onyo.order.pos-import-error';
export const STATUS_PREPARING = 'onyo.order.preparing';
export const STATUS_POS_ANALYSING = 'onyo.order.pos-analysing';
export const STATUS_PRODUCT_UNAVAILABLE = 'onyo.order.product-unavailable';
export const STATUS_CUSTOMER_ACTION_NEEDED = 'onyo.order.customer-action-needed';

export const STATUS_CANCELED_ID = 0;
export const STATUS_BACKEND_RECEIVED_ID = 1;
export const STATUS_PAYMENT_AUTHORIZED_ID = 2;
export const STATUS_POS_RECEIVED_ID = 3;
export const STATUS_POS_ACCEPTED_ID = 4;
export const STATUS_POS_DENIED_ID = 5;
export const STATUS_READY_ID = 6;
export const STATUS_READY_DELIVERY_ID = 65; //pedido pronto/delivery
export const STATUS_READY_EVENT_ID = 66; //custom event ready order
export const STATUS_DISPATCHED_ID = 7;
export const STATUS_DELIVERED_ID = 8;
export const STATUS_CONSUMED_ID = 9;
export const STATUS_RECEIVED_ID = 10;
export const STATUS_POS_IMPORTED_ID = 11;
export const STATUS_POS_IMPORT_ERROR_ID = 12;
export const STATUS_PREPARING_ID = 13;
export const STATUS_POS_ANALYSING_ID = 14;
export const STATUS_PRODUCT_UNAVAILABLE_ID = 15;
export const STATUS_CUSTOMER_ACTION_NEEDED_ID = 16;
