import { InMemoryCache, defaultDataIdFromObject, gql } from '@apollo/client';
import { createNewCacheKey } from '../../common/helpers/create-new-cache-key';
import { getAppConfig } from '../../common/helpers/configs';
import { CartPaymentStatus } from '../../common/generated/graphql';

export const localCache = new InMemoryCache({
  dataIdFromObject: customIds,
});

function customIds(object: any) {
  const appConfig = getAppConfig();

  // Set a unique Id for the company group, so that we fetch new data whenever user address changes.
  if (object.__typename === 'CompanyGroup' && appConfig.featureFlags.gmapsAddress) {
    return createNewCacheKey('CompanyGroup');
  }

  return defaultDataIdFromObject(object);
}

/**
 * Initializes local cache with default values
 */
export const LocalCacheInitQuery = gql`
  query LocalCacheInit {
    isAuthenticated
    authExecuted
    isEmbedded
    isGmapsReady
    isExecutingPaymentCall
    orderCheck
    shoppingCarts
    selectedPaymentMethod
    selectedOrderType
    userAddress
    deviceMetadata
    selectedTab
    cartPaymentFlow
    productFilter
    notify
    coupons
  }
`;

export function initLocalCache() {
  const localCacheInitialValues = {
    isAuthenticated: false,
    authExecuted: false,
    isEmbedded: false,
    isGmapsReady: false,
    isExecutingPaymentCall: false,
    orderCheck: null,
    shoppingCarts: [],
    selectedPaymentMethod: null,
    selectedOrderType: null,
    userAddress: null,
    deviceMetadata: null,
    selectedTab: 0,
    cartPaymentFlow: {
      __typename: 'AccountsCachedData',
      status: CartPaymentStatus.CartPayment,
      cardBrand: '',
    },
    productFilter: {
      __typename: 'ProductFilter',
      query: '',
      active: false,
    },
    notify: {
      __typename: 'Notify',
      status: '',
      message: '',
      active: false,
    },
    productDetailsDialog: {
      __typename: 'ProductDetailsDialog',
      active: false,
      product: 0,
    },
    coupons: {
      __typename: 'Coupons',
      telephoneValidated: false,
      loading: false,
      nodes: [],
    },
  };

  localCache.writeQuery({
    query: LocalCacheInitQuery,
    data: localCacheInitialValues,
  });
}

export function resetLocalCache() {
  localCache.reset();
}
