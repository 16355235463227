import React, { useCallback } from 'react';
import { useLocation, useHistory, useParams } from 'react-router';
import { Box, Grid, Container } from '@material-ui/core';

import {
  CompanyGroup,
  useGetAggregatorCompaniesQuery,
  Address,
  DeliveryAddressInput,
} from '../../../common/generated/graphql';
import AggregatorsGroupItem from '../aggregators-group-item/aggregators-group-item';
import { useStyles } from './aggregator-details.style';
import AggregatorContainerHeader from '../aggregator-container-header/aggregator-container-header';
import { logError } from '../../../common/helpers/log-error';
import Progress from '../../../common/components/progress/progress';
import NetworkError from '../../../common/components/network-error/network-error';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import useUserCoords from '../../hooks/use-user-coords';

interface stateType {
  companiesGroup: CompanyGroup;
}

export default function AggregatorDetails() {
  const headerSpace = useMainHeaderSpace();
  const params = useParams<{ numericalId: string }>();
  const { state, pathname } = useLocation<stateType>();
  const companiesGroup = state?.companiesGroup;
  const classes = useStyles();
  const history = useHistory();
  const userLocation = useUserCoords();

  const deliveryAddressInput: DeliveryAddressInput = {
    street: userLocation?.street ?? '',
    number: userLocation?.addressNumber ?? '',
    complement: userLocation?.complement ?? '',
    district: userLocation?.district ?? '',
    city: userLocation?.city ?? '',
    state: userLocation?.stateUf ?? '',
    zipCode: userLocation?.zipCode ?? '',
    country: 'BR',
    geoLat: userLocation?.latitude ?? 0,
    geoLon: userLocation?.longitude ?? 0,
  };

  const { data, loading, error, refetch } = useGetAggregatorCompaniesQuery({
    variables: { aggregatorId: Number(params.numericalId), deliveryAddress: deliveryAddressInput },

    onError: (err) => logError(err, 'Error executing Get Aggregator Companies Query'),
    skip: !!companiesGroup,
  });

  const aggregatorData = companiesGroup ? companiesGroup : data?.aggregator;

  const handleBack = useCallback(() => {
    history.push('/');
  }, [history]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados.'
        retry={refetch}
        error={error}
        path={pathname}
      />
    );
  }

  return (
    <Box className={`${classes.root} ${headerSpace.measures}`} maxWidth='lg'>
      <Box className={classes.pane}>
        <Grid container>
          <MainHeader withoutBackButton />
        </Grid>
        <Container className={classes.container}>
          <Grid item container zeroMinWidth className={classes.content}>
            <AggregatorContainerHeader
              title={aggregatorData?.name}
              icon='ArrowBack'
              onClickIcon={handleBack}
              showAction
              address={aggregatorData?.address as Address}
            />
            <AggregatorsGroupItem
              companiesCard={aggregatorData?.companies?.edges}
              isEvent={false}
            />
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
