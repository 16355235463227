import {
  GetShoppingCartQueryVariables,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function shoppingCart(
  root: any,
  variables: GetShoppingCartQueryVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const cart = context.cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: context.getCacheKey({
      id: btoa(`ShoppingCart:${variables.companyId}`),
      __typename: 'ShoppingCart',
    }),
  });

  return cart;
}
