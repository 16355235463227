import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import GenericTextField from '../../../common/components/generic-text-field/generic-text-field';
import { UnionCardBrand } from '../../../common/model/card-brand';
import { FieldFormatType } from '../../../common/model/field-format-type';

interface Props {
  open: boolean;
  cardBrand: UnionCardBrand | string;
  onOkClick: ({ cvv }: { cvv: string }) => void;
  onCancelClick: () => void;
}

export default function CvvDialog(props: Props) {
  let cvvLength: number;
  if (props.cardBrand === 'amex') {
    cvvLength = 4;
  } else {
    cvvLength = 3;
  }

  const validationSchema = Yup.object().shape({
    cvv: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(
        new RegExp(`^\\d{${cvvLength}}$`),
        'O código de segurança precisa conter ' + cvvLength + ' dígitos'
      ),
  });

  return (
    <Dialog open={props.open}>
      <Formik
        initialValues={{
          cvv: '',
        }}
        validationSchema={validationSchema}
        onSubmit={props.onOkClick}
      >
        <Form>
          <DialogContent>
            <DialogContentText>Digite o código de segurança do seu cartão.</DialogContentText>
            <GenericTextField
              id='cvv'
              name='cvv'
              label='Código de segurança'
              fieldType='password'
              fieldFormatType={FieldFormatType.NUMERICAL}
            />
          </DialogContent>
          <DialogActions>
            <Button id='cancel-btn' color='primary' onClick={props.onCancelClick}>
              Cancelar
            </Button>
            <Button id='submit-btn' type='submit' color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
