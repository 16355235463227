import {
  Result,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  PreOrderPostValidationMutationVariables,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
  GetSelectedOrderTypeQuery,
  GetSelectedOrderTypeDocument,
  GetUserAddressQuery,
  GetUserAddressDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { orderValidation } from '../../helpers/order-validation';
import { getSupportedPaymentMethods } from '../../helpers/get-supported-payment-methods';

/**
 * Checks if the user has chosen everything he needs to, in order to post an order.
 */
export async function preOrderPostValidation(
  root: any,
  variables: PreOrderPostValidationMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';

  const shoppingCart = getShoppingCart(variables.companyId, context.cache, context.getCacheKey);
  const selectedOrderType = getSelectedOrderType(context.cache);
  const selectedPaymentMethod = getSelectedPaymentMethod(context.cache);
  const supportedPaymentMethods = await getSupportedPaymentMethods(
    context.client,
    variables.companyId
  );
  const userAddress = getUserAddress(context.cache);

  const errorMsg = orderValidation(
    shoppingCart,
    selectedOrderType,
    selectedPaymentMethod as any,
    supportedPaymentMethods,
    userAddress
  );

  return {
    __typename,
    success: errorMsg === '' ? true : false,
    errorMsg,
  };
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
  });
}

function getSelectedOrderType(cache: InMemoryCache) {
  const selectedOrderTypeQuery = cache.readQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
  });

  return selectedOrderTypeQuery?.selectedOrderType;
}

function getSelectedPaymentMethod(cache: InMemoryCache) {
  const query = cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });

  return query?.selectedPaymentMethod;
}

function getUserAddress(cache: InMemoryCache) {
  const query = cache.readQuery<GetUserAddressQuery>({
    query: GetUserAddressDocument,
  });
  return query?.userAddress;
}
