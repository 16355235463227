import { Result, SignUpMutationVariables } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';
import { logSignUp, logSignUpError } from '../../../analytics/helpers/log-signup';

export async function signUp(
  root: any,
  variables: SignUpMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  let success = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';
  const uri = `v1/mobile/user`;
  const endpoints = getEndpoints();

  try {
    await axios.post(`${endpoints.api}${uri}`, {
      email: variables.input.email,
      password: variables.input.password,
      name: variables.input.name,
    });
    logSignUp({ method: 'email' });
    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    const err = error as AxiosError;
    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      console.warn('Error in SignUp Call', err);

      const errorType = err.response.data.error;
      logSignUpError({ errorMessage: errorType, errorCode: 76 });
      switch (errorType) {
        case 'onyo.user.invalid-data':
          errorMsg = 'E-mail ou senha incorretos. Por favor, confira e tente de novo.';
          break;
        case 'onyo.user.email-duplicated':
          errorMsg =
            'Esse e-mail já foi cadastrado na plataforma Onyo. Você pode ter cadastro em um dos aplicativos da nossa plataforma, como Sapore, Gula Gula, Baked Potato ou Onyo.';
          break;
        case 'onyo.user.invalid-password':
          errorMsg = 'A senha deve ter pelo menos 6 caracteres';
          break;
        case 'onyo.user.email-undeliverable':
          errorMsg = 'E-mail inválido. Por favor, confira e tente de novo.';
          break;
        default:
          logError(error, 'Error in Login Call');
          break;
      }
    } else {
      logError(error, 'Error in Signup Call');
      logSignUpError({ errorCode: 77 });
    }
  }
  return { __typename, success, errorMsg };
}
