/**
 * Validates a given CPF.
 * Returs true if valid or false if invalid
 */
export function validateCPF(cpf?: string | null): boolean {
  let soma: number;
  let resto: number;
  let i: number;

  // Ignore if the field is empty.
  if (!cpf || cpf.length === 0) {
    return true;
  }

  // Remove all non numeric chars from the string.
  const strCPF = cpf.replace(/\D+/gm, '');

  if (strCPF.length !== 11) {
    return false;
  }

  // The algorithm below was taken from: http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js - TestaCPF
  soma = 0;
  if (
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999'
  ) {
    return false;
  }

  for (i = 1; i <= 9; i++) {
    soma = soma + Number(strCPF.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== Number(strCPF.substring(9, 10))) {
    return false;
  }

  soma = 0;
  for (i = 1; i <= 10; i++) {
    soma = soma + Number(strCPF.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== Number(strCPF.substring(10, 11))) {
    return false;
  }

  return true;
}
