import { AppConfig } from '../../../common/model/config';
import { becoVilaOlimpiaTheme } from '../themes/beco-vila-olimpia.theme';
import { ORDER_TYPE_TABLE } from '../../../common/model/order-types';

export const becoVilaOlimpiaConfig: AppConfig = {
  appName: 'beco-vila-olimpia',
  appTitle: 'Beco da Vila Olímpia',
  appDescription: 'Peça aqui no Beco da Vila Olímpia.',
  aggregator: 1789,
  brand: 1685,
  theme: becoVilaOlimpiaTheme,
  faviconPath: '/favicons/favicon-pede-pronto.ico',
  defaultOrderType: ORDER_TYPE_TABLE,
  featureFlags: {
    getBrandThemeFromBackend: false,
    removeLogoFromTheApp: true,
    embeddedApplication: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-beco-vila-olimpia.jpg',
    isCieloMiniApp: false,
  },
};
