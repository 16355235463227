import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericTextField from '../../../common/components/generic-text-field/generic-text-field';
import { UserAddressInput } from '../../../common/generated/graphql';
import { getZipCodeRegex } from '../../../common/helpers/format-zip-code';
import AddressField from '../address-field/address-field';
import { useStyles } from './complete-address-form.style';
import { useAddressFormFields } from './use-address-form.fields';
import { logAddAddressInfo } from '../../../analytics/helpers/log-add-address-info';
interface Props {
  inputAddress: UserAddressInput;
  onCancelClick?: () => void;
  onOkClick: (address: UserAddressInput) => void;
}

export default function CompleteAddressForm(props: Props) {
  const classes = useStyles();
  const fields = useAddressFormFields();

  const onSubmit = useCallback(
    (values) => {
      let address: UserAddressInput;
      if (values.address) {
        address = {
          ...values.address,
          complement: values.addressComplement,
          fields: {
            street: values.address.fields.street,
            number: values.addressNumber,
            district: values.addressDistrict,
            state: values.addressState,
            city: values.addressCity,
            zipCode: values.addressZipCode,
          },
        };
      } else {
        address = {
          ...props.inputAddress,
          complement: values.addressComplement,
          fields: {
            street: props.inputAddress.fields!.street,
            number: values.addressNumber,
            district: values.addressDistrict,
            state: values.addressState,
            city: values.addressCity,
            zipCode: values.addressZipCode,
          },
        };
      }

      logAddAddressInfo({
        type: 'manual',
        latitude: address.latitude,
        longitude: address.longitude,
      });

      props.onOkClick(address);
    },
    [props]
  );

  const validation = Yup.object().shape({
    addressNumber: Yup.number()
      .typeError('Digite apenas números')
      .required('Este campo é obrigatório'),
    addressComplement: Yup.string(),
    addressDistrict: Yup.string().required('Esse campo é obrigatório'),
    addressState: Yup.string().required('Esse campo é obrigatório'),
    addressCity: Yup.string().required('Esse campo é obrigatório'),
    addressZipCode: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(getZipCodeRegex(), 'O CEP precisa estar no seguinte formato: XXXXX-XXX'),
  });

  return (
    <Formik
      validationSchema={validation}
      onSubmit={onSubmit}
      initialValues={{
        addressNumber: props.inputAddress?.fields?.number,
        addressComplement: '',
        addressDistrict: props.inputAddress?.fields?.district,
        addressState: props.inputAddress?.fields?.state,
        addressCity: props.inputAddress?.fields?.city,
        addressZipCode: props.inputAddress?.fields?.zipCode,
      }}
    >
      <Form>
        <DialogContent>
          <AddressField
            id='address-field'
            name='address'
            label='Endereço e número'
            className={classes.field}
            initialValue={props.inputAddress?.fields?.street}
            linkedFields={fields}
          />
          {Object.values(fields).map((input, count) => (
            <GenericTextField key={count} {...input} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button color='primary' id='cancel-btn' onClick={props.onCancelClick}>
            Cancelar
          </Button>
          <Button color='primary' id='ok-btn' type='submit'>
            Ok
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
