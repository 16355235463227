import { getAppConfig } from '../../common/helpers/configs';
import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'select_item_list_category';

interface LogSelectItemListCategory {
  brand_id: number;
  company_id: number;
  item_category: string;
}

export function logSelectItemListCategory({
  brand_id,
  company_id,
  item_category,
}: LogSelectItemListCategory) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brand_id,
      [ParamName.COMPANY_ID]: company_id,
      [ParamName.ITEM_CATEGORY]: item_category,
    },
  });
}

export function logSelectItemListCategoryError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
