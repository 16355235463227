import 'firebase/analytics';
import firebase from 'firebase/app';
import { SHOPPING_CART_CACHE } from '../../model/caches';
import { getCachedData } from '../get-cached-data';
import { ShoppingCart } from '../../generated/graphql';

export async function loadCartsFromCache(cacheName: string = SHOPPING_CART_CACHE) {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return [];
  }

  const shoppingCarts: ShoppingCart[] = [];
  const cache = await caches.open(cacheName);
  const keys = await cache.keys();

  for (const key of keys) {
    const url = new URL(key.url);
    const shoppingCartRes = await cache.match(url.pathname);

    if (shoppingCartRes && shoppingCartRes.ok) {
      const requestClone = shoppingCartRes.clone();
      const data = await getCachedData(requestClone, url.pathname, cache);
      if (data) {
        shoppingCarts.push(data);
      }
    }
  }

  return shoppingCarts;
}
