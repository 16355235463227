import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
  },
  containerHeader: {
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '1.75rem',
  },
  showMoreContainer: {
    cursor: 'pointer',
  },
  iconArrow: {
    color: theme.palette.text.primary,
    fontSize: '1.8rem',
  },
}));
