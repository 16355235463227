import { Box, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { useStyles } from './company-header-highlight.style';
import { OnlineStatus, TimeRule, CompanyHighlight } from '../../../common/generated/graphql';
import { getHighlightMessage } from '../../../common/helpers/get-highlight-message';
import { getStatusText } from '../../../common/helpers/get-status-text.helper';
import { useHighLightStatusStyles } from '../../../common/hooks/use-highlight-status-style';
import getHighlightStatus from '../../../common/helpers/get-highlight-status';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';

export default function CompanyHeaderHightlight() {
  const statusClasses = useHighLightStatusStyles();
  const classes = useStyles();
  const companyData = useGetCompanyDataFragment();

  let highlightMessage: string = getHighlightMessage(companyData?.highlight as CompanyHighlight);

  if (companyData?.onlineStatus === OnlineStatus.Closed && highlightMessage) {
    highlightMessage = getStatusText(
      companyData?.onlineStatus,
      companyData.openingTimes as TimeRule
    );
  }

  if (!highlightMessage) {
    return <div id='company-header-highlight-empty' className={classes.empty} />;
  }

  let highlightStatus = getHighlightStatus(
    highlightMessage,
    companyData?.onlineStatus as OnlineStatus
  );

  return (
    <Box className={clsx(classes.root, statusClasses[highlightStatus])}>
      <Typography variant='body1'>{highlightMessage}</Typography>
    </Box>
  );
}
