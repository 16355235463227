import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';
import { kmDeVantagensTheme } from '../themes/km-de-vantagens.theme';

export const kmDeVantagensConfig: AppConfig = {
  appName: 'km-devantagens',
  appTitle: 'Km de Vantagens',
  appDescription: 'Peça aqui no Km de Vantagens.',
  aggregator: 820,
  takeOutAggregatorId: 21,
  brand: 1179,
  theme: kmDeVantagensTheme,
  faviconPath: '/favicons/favicon-km-de-vantagens.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: true,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
