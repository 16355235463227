import {
  GetCardsDocument,
  GetCardsQuery,
  SelectCardMutation,
  SelectCardDocument,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
  GetOrLoadSelectedPaymentMethodMutationVariables,
  RemoteCard,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getSupportedPaymentMethods } from '../../helpers/get-supported-payment-methods';
import { UnionCardBrand } from '../../model/card-brand';

export async function getOrLoadSelectedPaymentMethod(
  root: any,
  variables: GetOrLoadSelectedPaymentMethodMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const supportedPaymentMethods = await getSupportedPaymentMethods(
    context.client,
    variables.companyId
  );

  // If there is a supported card selected, return it, otherwise try to get it from the backend.
  const selectedPaymentMethod = getSelectedPaymentMethod(context.cache, supportedPaymentMethods);

  if (selectedPaymentMethod) {
    return selectedPaymentMethod;
  }

  const remoteCards = await context.client.query<GetCardsQuery>({
    query: GetCardsDocument,
    fetchPolicy: 'network-only',
  });

  if (remoteCards.errors) {
    console.error('Error fetching remote cards from the backend', remoteCards.errors);
    return;
  } else if (!remoteCards.data || !remoteCards.data.cards) {
    return;
  }

  const validCard = await getFirstValidRemoteCard(
    remoteCards.data.cards as RemoteCard[],
    supportedPaymentMethods,
    context.client
  );

  if (validCard) {
    setSelectedPaymentMethod(context.cache, validCard);
  }

  return validCard;
}

function getSelectedPaymentMethod(
  cache: InMemoryCache,
  supportedPaymentMethods: Set<UnionCardBrand>
) {
  const selectedPaymentMethodQuery = cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });

  if (supportedPaymentMethods.size === 0) {
    return selectedPaymentMethodQuery?.selectedPaymentMethod;
  } else if (
    supportedPaymentMethods.has(
      selectedPaymentMethodQuery?.selectedPaymentMethod?.cardBrand! as UnionCardBrand
    )
  ) {
    return selectedPaymentMethodQuery?.selectedPaymentMethod;
  }
}

function setSelectedPaymentMethod(cache: InMemoryCache, paymentMethod: RemoteCard) {
  cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
    data: {
      __typename: paymentMethod.__typename,
      selectedPaymentMethod: paymentMethod,
    } as any,
  });
}

async function getFirstValidRemoteCard(
  cards: RemoteCard[],
  supportedPaymentMethods: Set<UnionCardBrand>,
  client: ApolloClient<any>
) {
  let validatedCard;
  for (const card of cards) {
    if (supportedPaymentMethods.size === 0 && card.status === 'validated') {
      validatedCard = card;
      selectRemoteCard(validatedCard, client);
      break;
    } else if (
      supportedPaymentMethods.has(card.cardBrand as UnionCardBrand) &&
      card.status === 'validated'
    ) {
      validatedCard = card;
      selectRemoteCard(validatedCard, client);
    }
  }

  return validatedCard;
}

async function selectRemoteCard(card: RemoteCard, client: ApolloClient<any>) {
  await client.mutate<SelectCardMutation>({
    mutation: SelectCardDocument,
    variables: {
      paymentMethod: card,
    },
  });
}
