import {
  RemoveRemoteCardMutationVariables,
  GetSelectedPaymentMethodFromCacheDocument,
  GetSelectedPaymentMethodFromCacheQuery,
} from '../../generated/graphql';
import { InMemoryCache, ApolloClient } from '@apollo/client';
import { getEndpoints } from '../../helpers/configs';
import axios from 'axios';
import { handleAuthError } from '../../helpers/handle-auth-error';
import { logError } from '../../helpers/log-error';

export async function removeRemoteCard(
  root: any,
  variables: RemoveRemoteCardMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const endpoints = getEndpoints();
  const url = `${endpoints.api}v1/mobile/card/${variables.cardId}`;
  let result;

  const selectedCard = context.cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });

  try {
    result = await axios.delete(url);
  } catch (error) {
    handleAuthError(error, context.cache);
    logError(error, 'Error retrieving user cards.');
    throw new Error('Não foi possível obter os seus cartões.');
  }

  if (
    selectedCard?.selectedPaymentMethod?.__typename === 'RemoteCard' &&
    Number(selectedCard.selectedPaymentMethod.numericalId) === variables.cardId
  ) {
    try {
      await context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
        query: GetSelectedPaymentMethodFromCacheDocument,
        data: {
          selectedPaymentMethod: null,
        },
      });
    } catch (error) {
      handleAuthError(error, context.cache);
      logError(error, 'Error retrieving selected PyamentMethod');
      throw new Error('Não foi possível obter o cartão selecionado no cache');
    }
  }

  return result;
}
