import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './receipt-order-value-item.style';

interface Props {
  id?: string;
  label?: string;
  value?: string | number | boolean;
  valueColor?: 'success' | 'default';
  className?: string;
  bold?: boolean;
}

export default function ReceiptOrderValueItem({
  id,
  label,
  value,
  bold,
  className,
  valueColor = 'default',
}: Props) {
  const classes = useStyles();

  return (
    <Grid item container justifyContent='space-between' id={id}>
      <Typography
        id='receipt-order-value-item-label'
        variant={'body2'}
        style={{ maxWidth: '80%' }}
        className={clsx(classes.label, { [classes.textbold]: bold }, className)}
      >
        {label}
      </Typography>
      <Typography
        id='receipt-order-value-item-value'
        variant={'body2'}
        className={clsx(classes.subTitle, classes[valueColor])}
      >
        {value}
      </Typography>
    </Grid>
  );
}
