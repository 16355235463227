import React from 'react';
import { Product } from '../../../common/generated/graphql';
import { Box } from '@material-ui/core';
import { isProductAvailable } from '../../../common/helpers/is-product-available';
import QuantitySimple from '../quantity-simple/quantity-simple';
import SingleSimple from '../single-simple/single-simple';

interface Props {
  parentProduct: Product;
  childProducts: Product[];
  menuItemCacheId: string;
}

export default function SimplesList(props: Props) {
  let isSimple = props.childProducts.some(
    (child) => isProductAvailable(child) && child.maximumChoices! > 1
  );

  if (isSimple) {
    return (
      <Box>
        {props.childProducts
          .filter((child) => showProduct(props.parentProduct, child))
          .map((child) => (
            <QuantitySimple
              key={child.id}
              product={child}
              parentCacheId={props.parentProduct.id}
              menuItemCacheId={props.menuItemCacheId}
              acceptMore={props.parentProduct.chosenQuantity < props.parentProduct.maximumChoices!}
            />
          ))}
      </Box>
    );
  }

  return (
    <Box>
      {props.childProducts
        .filter((child) => showProduct(props.parentProduct, child))
        .map((child) => (
          <SingleSimple
            key={child.id}
            product={child}
            menuItemCacheId={props.menuItemCacheId}
            parentCacheId={props.parentProduct.id}
          />
        ))}
    </Box>
  );
}

function showProduct(parent: Product, child: Product) {
  if (!isProductAvailable(child)) {
    return false;
  }
  /**
   * Show all simples, when we can still make more choices.
   * If we chose all everything we could, show only our choices.
   */
  return parent.chosenQuantity !== parent.maximumChoices! || child.chosenQuantity > 0;
}
