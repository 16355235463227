import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsShareItemList = {
  brandId: string;
  companyId: string;
};

const event = 'share_item_list';

export function logShareItemList({ brandId, companyId }: AnalyticsShareItemList) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.STATIC_MENU]: 'true',
    },
  });
}

export function logShareItemListError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
