import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
      padding: theme.spacing(0, 0.5),
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    preparationText: {
      color: theme.palette.text.secondary,
    },
    blockedIcon: {
      color: theme.palette.error.main,
    },
    blockedText: {
      paddingLeft: theme.spacing(0.2),
      color: theme.palette.error.main,
    },
  })
);
