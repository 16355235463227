import { BundleName, ParamName, Item } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsPurchase = {
  brandId: string;
  companyId: string;
  transactionId: string;
  value: number;
  shipping: number;
  items: Array<Item>;
  coupon?: string;
};

const event = 'purchase';

export function logPurchase({
  brandId,
  companyId,
  transactionId,
  value,
  shipping,
  items,
  coupon,
}: AnalyticsPurchase) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.TRANSACTION_ID]: transactionId,
      [ParamName.VALUE]: value,
      [ParamName.SHIPPING]: shipping,
      [ParamName.COUPON]: coupon,
      [ParamName.ITEMS]: items,
    },
  });
}

export function logPurchaseError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
