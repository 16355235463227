import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { secondaryBackground } from '../../../common/theme-defaults/default-colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    countdownProgress: {
      marginTop: theme.spacing(2),
      width: '50vw',
    },
    countdownContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      backgroundColor: secondaryBackground,
      paddingTop: theme.spacing(4),
    },
    statusText: {
      textAlign: 'center',
      maxWidth: '5rem',
    },
    countdownText: {
      textAlign: 'center',
      padding: theme.spacing(2, 0),
    },
  })
);
