import {
  SetDeviceMetadataMutationVariables,
  GetDeviceMetadataQuery,
  GetDeviceMetadataDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { XDEVICE } from '../../model/default-headers';

export function deviceMetadata(
  root: any,
  variables: SetDeviceMetadataMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  let appId;
  if (variables.input.isEmbedded) {
    appId = `web.${variables.input.appName}.embedded`;
  } else {
    appId = `web.${variables.input.appName}`;
  }
  const deviceToken = variables.input.deficeToken ?? '';
  const deviceMetadata = `${appId}/${variables.input.appVersion} ${variables.input.osName}/${variables.input.osVersion} (${variables.input.deviceId};${deviceToken}; ${variables.input.screenSizeX}x${variables.input.screenSizeY})`;

  context.cache.writeQuery<GetDeviceMetadataQuery>({
    query: GetDeviceMetadataDocument,
    data: { deviceMetadata },
  });

  axios.defaults.headers.common[XDEVICE] = deviceMetadata;

  return deviceMetadata;
}
