import { CompanyEdge, OnlineStatus } from '../generated/graphql';

export function sortCompanies(a?: CompanyEdge | null, b?: CompanyEdge | null): number {
  let result = 0;

  if (!a || !a.node || !b || !b.node) {
    return 0;
  }

  result = sortByOnlineStatus(a, b);
  if (result === 0) {
    result = sortByHighlight(a, b);
  }

  return result;
}

export function sortByOnlineStatus(a: CompanyEdge, b: CompanyEdge): number {
  if (
    a.node?.onlineStatus === OnlineStatus.Online &&
    b.node?.onlineStatus !== OnlineStatus.Online
  ) {
    return -1; // A is online an B is offline -> A comes first
  }

  if (
    a.node?.onlineStatus !== OnlineStatus.Online &&
    b.node?.onlineStatus === OnlineStatus.Online
  ) {
    return 1; // A is offline and B is online -> B comes first
  }

  return 0;
}

export function sortByHighlight(a: CompanyEdge, b: CompanyEdge): number {
  if (a.node?.highlight && !b.node?.highlight) {
    return -1; // Both are online, but A has promotions -> A comes first
  } else if (!a.node?.highlight && b.node?.highlight) {
    return 1; // Both are online, but B has promotions -> B comes first
  }

  return 0;
}
