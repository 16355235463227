import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'view_referral';

export function logViewReferral() {
  logAnalytics({
    event,
  });
}

export function logViewReferralError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
