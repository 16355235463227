import { InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';

import { IsAuthenticatedDocument, IsAuthenticatedQuery } from '../../common/generated/graphql';

export const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      console.error(
        `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
        operation,
        response
      );
      Sentry.setExtras({
        response,
        operation,
        error,
      });
      Sentry.captureException(error);
    }
  }
  if (networkError) {
    if (networkError?.message.includes('Timeout')) {
      console.warn(networkError);
      return;
    }

    const serverError = networkError as ServerError;
    if (serverError.statusCode === 403 || serverError.statusCode === 401) {
      console.warn(networkError);
      const cache: InMemoryCache = operation.getContext().cache;
      if (cache) {
        cache.writeQuery<IsAuthenticatedQuery>({
          query: IsAuthenticatedDocument,
          data: { isAuthenticated: false, authExecuted: true },
        });
      }
    } else {
      console.error(`[Network error]: ${networkError}`, operation, response);
      Sentry.setExtras({
        response,
        operation,
        networkError,
      });
      Sentry.captureException(networkError);
    }
  }
});
