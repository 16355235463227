import { makeStyles } from '@material-ui/core/styles';

export const useHighLightStatusStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  default: {
    backgroundColor: theme.palette.info.superLightest,
    color: theme.palette.info.darkest,
  },
  online: {
    backgroundColor: theme.palette.additionalColors.highligts?.background.primary,
    color: theme.palette.additionalColors.highligts?.text.primary,
  },
  closed: {
    backgroundColor: theme.palette.additionalColors.highligts?.background.secondary,
    color: theme.palette.additionalColors.highligts?.text.secondary,
  },
  offline: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.background.paper,
  },
}));
