import { Box, Grid, List } from '@material-ui/core';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import { useTheme } from '@material-ui/core/styles';
import {
  useGetAggregatorCompaniesQuery,
  DeliveryAddressInput,
} from '../../../common/generated/graphql';
import { getAppConfig, getSharedConfig } from '../../../common/helpers/configs';
import { logError } from '../../../common/helpers/log-error';
import { DeliveryLocation } from '../../../common/model/config';
import AggregatorCompanyListItem from '../aggregator-company-list-item/aggregator-company-list-item';
import EmptyAggregator from '../empty-aggregator/empty-aggregator';
import InfiniteScroll from 'react-infinite-scroll-component';
import cloneDeepWith from 'lodash/cloneDeepWith';
import concat from 'lodash/concat';
import useSizeBreakpoints from '../../../common/hooks/use-size-breakpoints';
import CardContainer from '../card-container/card-container';
import { useStyles } from './aggregator-company-geolocated-list.style';
import { getQueryVariables } from './helpers/aggregator-company-geolocated-list-query-variables';
import { logViewShopList } from '../../../analytics/helpers/log-view-shop-list';
import { logLoadShopList } from '../../../analytics/helpers/log-load-shop-list';
import { useLocation } from 'react-router-dom';

interface Props {
  aggregatorId: number;
  userLocation: DeliveryLocation;
  companyNameStartsWith?: string;
}

export default function AggregatorCompanyGeolocatedList({
  aggregatorId,
  userLocation,
  companyNameStartsWith = '',
}: Props) {
  const appConfig = getAppConfig();
  const sharedConfig = getSharedConfig();
  const isDelivery = appConfig.featureFlags.filterByDeliveryArea;
  const first = sharedConfig.companyPaginationStep;
  const maximumDistanceKilometers = sharedConfig.maximumDistanceKilometers;
  const smallSize = sharedConfig.companyPaginationStep;
  const theme = useTheme();
  const small = smallSize;
  const medium = small * 2;
  const large = small * 3;
  const location = useLocation();

  const sizeItemsGrid = useSizeBreakpoints({ theme, small, medium, large });
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const [hasSetEventLog, setHasSetEventLog] = useState<Boolean>(true);
  const deliveryAddressInput: DeliveryAddressInput = {
    street: userLocation.street,
    number: userLocation.addressNumber,
    complement: userLocation.complement,
    district: userLocation.district,
    city: userLocation.city,
    state: userLocation.stateUf,
    zipCode: userLocation.zipCode,
    country: 'BR',
    geoLat: userLocation.latitude,
    geoLon: userLocation.longitude,
  };

  const { data, loading, error, refetch, fetchMore } = useGetAggregatorCompaniesQuery({
    variables: getQueryVariables({
      aggregatorId: aggregatorId,
      latitude: userLocation.latitude,
      longitude: userLocation.longitude,
      first,
      maximumDistanceKilometers,
      isDelivery,
      companyNameStartsWith,
      deliveryAddress: deliveryAddressInput,
    }),
    onError: (err) => logError(err, 'Error executing Get Aggregator Companies Query'),
  });

  const companies = data?.aggregator?.companies?.edges;

  const fetchMoreData = useCallback(() => {
    if (!companies) {
      return;
    }

    const lastCompany = companies[companies.length - 1];

    const variables = getQueryVariables({
      aggregatorId: aggregatorId,
      latitude: userLocation.latitude,
      longitude: userLocation.longitude,
      cursor: lastCompany?.cursor,
      first,
      maximumDistanceKilometers,
      isDelivery,
      companyNameStartsWith,
      deliveryAddress: deliveryAddressInput,
    });

    fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newCompanies = fetchMoreResult?.aggregator?.companies?.edges;
        if (!newCompanies || newCompanies.length === 0) {
          setHasMore(false);
          return previousResult;
        }

        return cloneDeepWith(previousResult, (value, key) => {
          if (key === 'companies') {
            return {
              ...value,
              edges: concat(value.edges, newCompanies),
            };
          }
        });
      },
    });
  }, [
    companies,
    aggregatorId,
    userLocation.latitude,
    userLocation.longitude,
    first,
    maximumDistanceKilometers,
    isDelivery,
    companyNameStartsWith,
    fetchMore,
    deliveryAddressInput,
  ]);

  useEffect(() => {
    if (!companies?.length) {
      return;
    }
    if (companies?.length < sizeItemsGrid && hasMore) {
      fetchMoreData();
    }
  }, [companies, fetchMoreData, hasMore, sizeItemsGrid]);

  useMemo(() => {
    if (!companies?.length) {
      return;
    }

    if (hasSetEventLog) {
      logViewShopList({ type: 'receber' });
      setHasSetEventLog(false);
    } else {
      logLoadShopList({ type: 'receber', count: companies?.length ?? 0 });
    }
  }, [companies, hasSetEventLog]);

  if (loading) {
    return (
      <Box className={classes.progress}>
        <Progress />
      </Box>
    );
  } else if (error) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados.'
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  } else if (!companies || companies.length === 0) {
    return <EmptyAggregator retry={refetch} path={location.pathname} />;
  }

  return (
    <Box className={classes.root}>
      <InfiniteScroll
        className={classes.infiniteScroll}
        scrollableTarget='main-container'
        dataLength={companies.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<Progress />}
      >
        <List>
          <Grid container>
            {companies.map((company) => (
              <Grid
                item
                key={company!.node!.id}
                className={classes.item}
                {...sharedConfig.numberColumnsBreakPointsAggregator}
              >
                <CardContainer>
                  <AggregatorCompanyListItem company={company!.node!} isDelivery={isDelivery} />
                </CardContainer>
              </Grid>
            ))}
          </Grid>
        </List>
      </InfiniteScroll>
    </Box>
  );
}
