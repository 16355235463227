import '@fortawesome/fontawesome-svg-core/styles.css';
import { useCallback } from 'react';
import {
  Product,
  ProductValidationStatus,
  useDecreaseChosenProductQuantityMutation,
  useIncreaseChosenProductQuantityMutation,
  useValidateProductMutation,
} from '../../common/generated/graphql';
import { logError } from '../../common/helpers/log-error';
import { OnClickButton } from '../../common/model/html-events';

interface UseSimpleQuantity {
  (product: Product, parentCacheId: string, menuItemCacheId: string): {
    addOne: OnClickButton;
    reduceOne: OnClickButton;
  };
}

export const useSimpleQuantity: UseSimpleQuantity = (product, parentCacheId, menuItemCacheId) => {
  const [validateProduct] = useValidateProductMutation({
    variables: { productCacheId: menuItemCacheId },
    onError: (err) => logError(err, 'Error executing Validate Product Mutation'),
  });

  const [increaseQuantity] = useIncreaseChosenProductQuantityMutation({
    variables: {
      input: {
        productCacheId: product.id,
        parentCacheId: parentCacheId,
        menuItemCacheId: menuItemCacheId,
      },
    },
    onError: (err) => logError(err, 'Error executing Increase Chosen Product Quantity Mutation'),
  });

  const [decreaseQuantity] = useDecreaseChosenProductQuantityMutation({
    variables: {
      input: {
        productCacheId: product.id,
        parentCacheId: parentCacheId,
        menuItemCacheId: menuItemCacheId,
      },
    },
    onError: (err) => logError(err, 'Error executing Decrease Chosen Product Quantity Mutation'),
  });

  const addOne = useCallback(
    (event) => {
      event.stopPropagation();
      increaseQuantity();
      if (product.isValid !== ProductValidationStatus.Unknown) {
        validateProduct();
      }
    },
    [increaseQuantity, product.isValid, validateProduct]
  );

  const reduceOne = useCallback(
    (event) => {
      event.stopPropagation();
      decreaseQuantity();
      if (product.isValid !== ProductValidationStatus.Unknown) {
        validateProduct();
      }
    },
    [decreaseQuantity, product.isValid, validateProduct]
  );

  return {
    addOne,
    reduceOne,
  };
};
