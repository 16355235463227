import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsLoadShopList = {
  type: 'retirar' | 'receber';
  count: number;
};

const event = 'load_shop_list';

export function logLoadShopList({ type, count }: AnalyticsLoadShopList) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.TYPE]: type,
      [ParamName.COUNT]: count,
    },
  });
}

export function logLoadShopListError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
