import React, { ReactElement, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField, Box } from '@material-ui/core';
import clsx from 'clsx';

import LabelField from '../label-field/label-field';

import { getOnlyDigits } from '../../helpers/get-only-digits';
import { FieldFormatType } from '../../model/field-format-type';
import { formatCPF } from '../../helpers/format-cpf';
import { formatZipCode } from '../../helpers/format-zip-code';
import { GenericInput } from '../../model/generic-input';
import { formatCardNumber } from '../../helpers/format-card-number';
import { formatCardExpiration } from '../../helpers/format-card-expiration';
import { formatBirthday } from '../../helpers/birthday.helper';
import { formatPhoneNumber } from '../../helpers/format-phone-number';

import { useStyles } from './generic-text-field.style';

export default function GenericTextField(props: GenericInput): ReactElement {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();
  const isOutlinedField = props.variant === 'outlined';

  const handleChange = useCallback(
    (event) => {
      switch (props.fieldFormatType) {
        case FieldFormatType.NUMERICAL:
          setFieldValue(field.name, getOnlyDigits(event.target.value));
          break;
        case FieldFormatType.CPF:
          setFieldValue(field.name, formatCPF(event.target.value));
          break;
        case FieldFormatType.CEP:
          setFieldValue(field.name, formatZipCode(event.target.value));
          break;
        case FieldFormatType.CARD_NUMBER:
          setFieldValue(field.name, formatCardNumber(event.target.value));
          break;
        case FieldFormatType.CARD_EXPIRATION:
          setFieldValue(field.name, formatCardExpiration(event.target.value));
          break;
        case FieldFormatType.BIRTHDAY:
          setFieldValue(field.name, formatBirthday(event.target.value));
          break;
        case FieldFormatType.TELEPHONE:
          setFieldValue(field.name, formatPhoneNumber(event.target.value));
          break;
        default:
          setFieldValue(field.name, event.target.value);
          break;
      }
    },
    [field.name, setFieldValue, props]
  );

  return (
    <Box className='containerGenericField'>
      {isOutlinedField && <LabelField value={props.label} gutterBottom disabled={props.disabled} />}
      <TextField
        id={props.id}
        variant={isOutlinedField ? 'outlined' : 'standard'}
        label={!isOutlinedField && props.label}
        disabled={props.disabled}
        className={clsx(classes.textField, props.className)}
        name={props.name}
        placeholder={props.placeholder}
        type={props.fieldType}
        value={field.value}
        fullWidth={true}
        onChange={handleChange}
        onBlur={field.onBlur}
        error={Boolean(meta.error && meta.touched)}
        helperText={meta.error}
        InputProps={props.InputProps}
        inputProps={props.inputProps}
        margin='none'
        FormHelperTextProps={{ className: classes.helperText }}
      />
    </Box>
  );
}
