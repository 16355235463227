import {
  GetLocalCardDocument,
  GetSelectedPaymentMethodFromCacheDocument,
  GetSelectedPaymentMethodFromCacheQuery,
  MutationsAddLocalCardArgs,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';

export async function addLocalCard(
  root: any,
  variables: MutationsAddLocalCardArgs,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const id = createNewCacheKey('LocalCard');

  // Update root query
  const localCard = {
    id,
    __typename: 'LocalCard',
    ...variables.localCard,
  };

  context.cache.writeQuery({ query: GetLocalCardDocument, data: { localCard } });

  // Update selected payment method in shopping cart.
  context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
    data: {
      selectedPaymentMethod: localCard as any,
    },
  });

  return true;
}
