import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericSelectField from '../../../common/components/generic-select-field/generic-select-field';
import GenericTextField from '../../../common/components/generic-text-field/generic-text-field';
import {
  CustomerData,
  CustomerDataInput,
  LocalCardInput,
  PaymentParameter,
  CartPaymentStatus,
  useCartPaymentFlowQuery,
} from '../../../common/generated/graphql';
import { getBirthdayRegex } from '../../../common/helpers/birthday.helper';
import { getCardNumberRegex } from '../../../common/helpers/format-card-number';
import { getOnlyDigits } from '../../../common/helpers/get-only-digits';
import {
  checkBinCard,
  getCvvRegex,
  getExpirationRegex,
  getShowBirthdayField,
  getShowExpirationField,
  isAmex,
  processExpiration,
} from '../../helpers/register-card-helper';
import { useGetRegisterCardInput } from '../../hooks/use-get-register-card-input';
import CardBirthdayField from '../card-birthday-field/card-birthday-field';
import CardExpirationField from '../card-expiration-field/card-expiration-field';
import DefaultActions from '../../../common/components/default-actions/default-actions';
import { useUpdateCartPayment } from '../../../common/hooks/use-update-cart-payment';
import { useStyles } from './register-card-form.style';
import { logError } from '../../../common/helpers/log-error';

interface Props {
  paymentParameters: PaymentParameter[];
  customerData?: CustomerData | null;
  onSubmit: (localCard: LocalCardInput, customerData?: CustomerDataInput) => void;
}

export default function RegisterCardForm(props: Props) {
  const classes = useStyles();
  const inputProps = useGetRegisterCardInput(props.paymentParameters);
  const { data } = useCartPaymentFlowQuery({
    onError: (err) => logError(err, 'Error executing is authenticated query'),
  });
  const { updateCartPayment } = useUpdateCartPayment();

  const handleOnClose = useCallback(() => {
    updateCartPayment(CartPaymentStatus.CartPayment);
  }, [updateCartPayment]);

  const onSubmit = useCallback(
    (values) => {
      let updatedCustomerData: CustomerDataInput | undefined;
      const showExpirationField = getShowExpirationField(values.cardBrandSelect?.cardBrand?.code);
      const showBirthdayField = getShowBirthdayField(values.cardBrandSelect?.cardBrand?.code);

      if (
        showBirthdayField &&
        moment(values.cardBirthday, 'DD/MM/YYYY').diff(moment(props.customerData?.birthday!)) !== 0
      ) {
        updatedCustomerData = {
          ...updatedCustomerData,
          birthday: moment(values.cardBirthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        };
      }

      const localCard: LocalCardInput = {
        cardNumber: getOnlyDigits(values.cardNumber),
        cardName: values.cardName,
        cardExpiration: showExpirationField ? processExpiration(values.cardExpiration) : '',
        birthday: showBirthdayField ? getOnlyDigits(values.cardBirthday) : '',
        cardCvv: getOnlyDigits(values.cardCvv),
        cardBrand: values.cardBrandSelect.cardBrand.code,
        paymentMethod: values.cardBrandSelect.paymentMethod,
      };

      props.onSubmit(localCard, updatedCustomerData);
    },
    [props]
  );

  const validationSchema = Yup.object().shape({
    cardBrandSelect: Yup.object().required('Esse campo é obrigatório'),
    cardNumber: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(getCardNumberRegex(), 'O cartão precisa ter entre 13 e 19 dígitos')
      .test(
        'check-card-bin',
        'O cartão informado não é permitido para cadastro no Pede Pronto',
        checkBinCard
      ),
    cardName: Yup.string().required('Esse campo é obrigatório'),
    cardExpiration: Yup.string().when('cardBrandSelect', {
      is: (value) => getShowExpirationField(value?.cardBrand?.code),
      then: Yup.string()
        .required('Esse campo é obrigatório')
        .matches(getExpirationRegex(), 'Data de expiração deve ter o seguinte formato: (MM/AA)'),
      otherwise: Yup.string().notRequired(),
    }),
    cardBirthday: Yup.string().when('cardBrandSelect', {
      is: (value) => getShowBirthdayField(value?.cardBrand?.code),
      then: Yup.string()
        .required('Esse campo é obrigatório')
        .matches(
          getBirthdayRegex(),
          'Data de aniversário deve ter o seguinte formato: (DD/MM/AAAA)'
        ),
      otherwise: Yup.string().notRequired(),
    }),
    cardCvv: Yup.string()
      .required('Esse campo é obrigatório')
      .when('cardBrandSelect', {
        is: (value) => isAmex(value?.cardBrand?.code),
        then: Yup.string().matches(getCvvRegex(4), 'O CVV precisa conter 4 dígitos'),
        otherwise: Yup.string().matches(getCvvRegex(3), 'O CVV precisa conter 3 dígitos'),
      }),
  });

  const searchedCard = props.paymentParameters.find(
    (pay) => pay?.cardBrand?.code === data?.cartPaymentFlow.cardBrand
  );

  return (
    <Formik
      initialValues={{
        cardBrandSelect: searchedCard ?? '',
        cardNumber: '',
        cardName: '',
        cardExpiration: '',
        cardBirthday: '',
        cardCvv: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <GenericSelectField {...inputProps.cardBrands} />
        <GenericTextField {...inputProps.cardNumber} />
        <GenericTextField {...inputProps.cardName} />
        <CardExpirationField {...inputProps.cardExpiration} />
        <CardBirthdayField {...inputProps.birthday} />
        <GenericTextField {...inputProps.cardCvv} />

        <Box className={classes.events}>
          <DefaultActions
            secondary={{
              event: handleOnClose,
            }}
          />
        </Box>
      </Form>
    </Formik>
  );
}
