import {
  CustomerData,
  IsAuthenticatedQuery,
  IsAuthenticatedDocument,
  IsEmbeddedQuery,
  IsEmbeddedDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';

export async function customerData(
  root: any,
  variables: any,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<CustomerData | null> {
  let data = null;
  try {
    const dataFromAPI = await getUserDataFromAPI(context.cache);
    data = createCustomerData(dataFromAPI);
    updateAuthStatus(context.cache, true);
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 401 || err.response?.status === 403) {
      updateAuthStatus(context.cache, false);
    }

    updateAuthExecutionStatus(context.cache, true);

    const forceSentryLog = isEmbedded(context.cache);
    logError(error, 'Error retrieving the user data', forceSentryLog);
  }

  return data;
}

async function getUserDataFromAPI(cache: InMemoryCache): Promise<any> {
  const endpoints = getEndpoints();
  const url = `${endpoints.api}v1/mobile/customer/me`;
  const response = await axios.get(url);
  return response.data;
}

function createCustomerData(data: any): CustomerData {
  const cpf = getCpf(data);
  return {
    __typename: 'CustomerData',
    id: btoa(`CustomerData:${data.id}`),
    numericalId: data.numericalId,
    name: data.firstName,
    email: data.email,
    birthday: data.birthday,
    gender: data.gender,
    telephone: data.telephone,
    acceptedTermsDatetime: data.acceptedTermsDatetime,
    validationCellphone: data.validationCellphone,
    cpf,
  };
}

function getCpf(data: any): string | null {
  if (data.document) {
    for (const doc of data.document) {
      if (doc.documentType === 1) {
        return doc.data;
      }
    }
  }
  return null;
}

function updateAuthStatus(cache: InMemoryCache, isAuthenticated: boolean) {
  cache.writeQuery<IsAuthenticatedQuery>({
    query: IsAuthenticatedDocument,
    data: {
      isAuthenticated,
      authExecuted: true,
    },
  });
}

function updateAuthExecutionStatus(cache: InMemoryCache, authExecuted: boolean) {
  const currentValue = cache.readQuery<IsAuthenticatedQuery>({
    query: IsAuthenticatedDocument,
  });

  cache.writeQuery<IsAuthenticatedQuery>({
    query: IsAuthenticatedDocument,
    data: {
      isAuthenticated: currentValue!.isAuthenticated,
      authExecuted,
    },
  });
}

function isEmbedded(cache: InMemoryCache) {
  const result = cache.readQuery<IsEmbeddedQuery>({ query: IsEmbeddedDocument });
  return result?.isEmbedded;
}
