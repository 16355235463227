import {
  ProductCompanyEdge,
  Category,
  PaymentParameter,
  CardBrand,
} from '../../common/generated/graphql';
import { fixDuplicatedPayment } from '../../common/helpers/fix-duplicated-payment';
import {
  CommonParser,
  ParseProductCategories,
  ParsePaymentMethods,
  ParseCompanyAddress,
} from '../models/parsers-types';

export const parseCompanyImg: CommonParser = (company) => {
  let url = '';

  for (const img of company.brand!.images!) {
    if (img && img.context === 'brand-aggregator-company-list' && img.url) {
      url = img.url;
    }
  }

  return url;
};

export const parsePaymentMethods: ParsePaymentMethods = (company) => {
  let parameters: PaymentParameter[] = [];

  if (company && company.parameter) {
    if (company.parameter.paymentParameter) {
      parameters = company.parameter.paymentParameter as PaymentParameter[];
    }

    if (company.parameter.offlinePaymentParameter) {
      parameters = [
        ...parameters,
        ...(company.parameter.offlinePaymentParameter as PaymentParameter[]),
      ];
    }
  }

  const cards = parameters ? parameters.map((pay) => pay.cardBrand) : [];
  return fixDuplicatedPayment(cards as CardBrand[]);
};

// This function map products to categories.
// We need to make this transformation because the category is inside the product when returned by Graphql.
// Furthermore, we need to sort the categories by their associated order.
// Ideally, this should all be processed on the backend side.
export const parseProductCategories: ParseProductCategories = (products) => {
  const categoryMap = new Map<number, { category: Category; products: ProductCompanyEdge[] }>();
  for (const product of products) {
    const category = product.node!.product!.category;
    if (category) {
      if (!categoryMap.has(category.numericalId!)) {
        categoryMap.set(category.numericalId!, { category, products: [] });
      }
      categoryMap.get(category.numericalId!)!.products.push(product);
    }
  }

  const sortedMap = new Map<number, { category: Category; products: ProductCompanyEdge[] }>();
  const sortedKeys = Array.from(categoryMap.keys()).sort(
    (a, b) => categoryMap.get(a)!.category!.order! - categoryMap.get(b)!.category!.order!
  );
  sortedKeys.map((key) => sortedMap.set(key, categoryMap.get(key)!));
  return sortedMap;
};

export const parseCompanyAddress: ParseCompanyAddress = (address) => {
  let parsedAddres = '';

  if (address.street) {
    parsedAddres = `${address.street}, `;
  }

  if (address.number) {
    parsedAddres += `${address.number} `;
  }

  if (address.city) {
    parsedAddres += `${address.city} `;
  }

  if (address.state) {
    parsedAddres += `- ${address.state}`;
  }

  return parsedAddres;
};
