import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useStyles } from './company-header-timeline.style';
import Icon from '../../../common/components/icon/icon';
import { OnlineStatus, TimeRule, OrderTypeParameter } from '../../../common/generated/graphql';
import CompanyPreparationTime from '../../../common/components/company-preparation-time/company-preparation-time';
import { getStatusText } from '../../../common/helpers/get-status-text.helper';
import { getCompanyTimerulesMsg } from '../../../common/helpers/get-company-timerules-msg';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import { getAppConfig } from '../../../common/helpers/configs';
import { getOrderTypeConfig } from '../../../aggregator-home/helpers/get-order-type-config.helper';

export default function CompanyHeaderTimeline(): React.ReactElement {
  const classes = useStyles();
  const companyData = useGetCompanyDataFragment();
  const isDelivery = getAppConfig().featureFlags.filterByDeliveryArea;

  let deliveyTypeConfig: OrderTypeParameter | null | undefined;

  if (isDelivery) {
    deliveyTypeConfig = getOrderTypeConfig({
      orderTypes: companyData?.parameter?.orderTypes as OrderTypeParameter[],
      type: 'delivery',
    });
  }

  const isOpen = companyData?.onlineStatus === OnlineStatus.Online;
  let statusMessage = getStatusText(companyData?.onlineStatus);
  const openingHoursMessage = getCompanyTimerulesMsg(companyData?.openingTimes as TimeRule);

  return (
    <Box className={classes.timeline}>
      <Box
        id='company-header-timeline-status'
        className={`${classes.status} ${isOpen ? classes.statusOpen : classes.statusClosed}`}
      >
        <Icon
          name={isOpen ? 'openDoor' : 'closedDoor'}
          color={isOpen ? 'successDarkest' : 'errorDarkest'}
        />
        <Typography variant='caption' id='company-header-timeline-status-message'>
          {statusMessage}
        </Typography>
      </Box>
      <Typography
        className={classes.atHour}
        variant='caption'
        id='company-header-timeline-opening-message'
      >
        {openingHoursMessage}
      </Typography>
      <Box className={classes.labelAndIcon}>
        <CompanyPreparationTime
          variant='caption'
          preparationTime={companyData?.preparationTime}
          additionalPreparationTime={deliveyTypeConfig?.additionalPreparationTime}
          isDelivery={isDelivery}
          iconName='cutlery'
        />
      </Box>
    </Box>
  );
}
