import { OrderItem, Maybe } from '../../common/generated/graphql';

interface OrderItemTree extends OrderItem {
  children?: Maybe<OrderItemTree>[];
}

export const makeTree = (
  items: OrderItem[] | OrderItemTree[] = [],
  id: number | null = null
): OrderItemTree[] => {
  return items
    .filter((item) => item?.parentId === id)
    .map((item) => ({
      ...item,
      children: makeTree(items, item?.numericalId),
    }));
};
