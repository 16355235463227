import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './card-container.styles';

interface Props {
  title?: string;
  children: React.ReactNode;
}

export default function CardContainer(props: Props) {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root)} variant='outlined' id='card-container-root'>
      <CardContent className={classes.cardContent}>{props.children}</CardContent>
    </Card>
  );
}
