import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'add_payment_info';

type AnalyticsPayment = {
  brandId: string;
  companyId: string;
  paymentType?: string;
  cartValue?: number;
};

export function logAddPaymentInfo({
  brandId,
  companyId,
  paymentType,
  cartValue,
}: AnalyticsPayment) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.PAYMENT_TYPE]: paymentType,
      [ParamName.VALUE]: cartValue,
    },
  });
}

export function logAddPaymentInfoError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
