import 'firebase/analytics';
import firebase from 'firebase/app';
import { UserAddress } from '../../generated/graphql';
import { USER_ADDRESS_CACHE, SW_CACHE_EXIPIRATION } from '../../model/caches';
import { getCacheNoExpiration } from '../get-cache-no-exipration';

export async function saveUserAddressToCache(address: UserAddress) {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return;
  }

  const cache = await caches.open(USER_ADDRESS_CACHE);

  const resp = new Response(JSON.stringify(address), {
    headers: {
      'Content-Type': 'application/json',
      [SW_CACHE_EXIPIRATION]: getCacheNoExpiration(),
    },
  });
  cache.put(`/user-address`, resp);
}
