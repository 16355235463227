import {
  askMeStartAuth,
  init,
  askLocation,
  MiniAppLocationSuccessData,
  MiniAppAuthData,
} from '@bitz/miniapp-gateway';
import { useCallback } from 'react';
import {
  useLoadUserAddressFromBrowserCacheMutation,
  useMiniAppLoginMutation,
  GetCustomerDataDocument,
} from '../../../../generated/graphql';
import { logError } from '../../../../helpers/log-error';

export default function useMiniAppInit() {
  const [login] = useMiniAppLoginMutation({
    onError: (err) => logError(err, 'Error executing Mini App Login Mutation'),
    refetchQueries: [{ query: GetCustomerDataDocument }],
  });
  const [loadUserAddressFromCache] = useLoadUserAddressFromBrowserCacheMutation({
    onError: (err) => logError(err, 'Error on User Address from Browser Mutation'),
  });

  const afterLoginSuccess = useCallback(
    (data: MiniAppAuthData) => {
      login({ variables: { input: data } });
    },
    [login]
  );

  const afterLocationSuccess = useCallback(
    (data: MiniAppLocationSuccessData) => {
      loadUserAddressFromCache({
        variables: { latitude: Number(data.lat), longitude: Number(data.long) },
      });
    },
    [loadUserAddressFromCache]
  );

  const afterLocationError = useCallback(() => {
    loadUserAddressFromCache();
  }, [loadUserAddressFromCache]);

  const afterInitSuccess = useCallback(
    (data) => {
      askMeStartAuth(afterLoginSuccess);
      askLocation(afterLocationSuccess, afterLocationError);
    },
    [afterLocationError, afterLocationSuccess, afterLoginSuccess]
  );

  const miniAppInit = useCallback(() => {
    init(afterInitSuccess);
  }, [afterInitSuccess]);

  return miniAppInit;
}
