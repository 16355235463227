import {
  GetBrandBalanceQueryVariables,
  BrandBalance,
  Maybe,
  BalanceUserDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getEndpoints } from '../../helpers/configs';
import axios from 'axios';
import { handleAuthError } from '../../helpers/handle-auth-error';
import { logError } from '../../helpers/log-error';

export default async function brandBalance(
  root: any,
  variables: GetBrandBalanceQueryVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<BrandBalance> {
  const endpoints = getEndpoints();
  // Note: user=True must have an upper case T
  const url = `${endpoints.api}v2/mobile/brand/${variables.brandId}/balance?user=True`;

  try {
    const result = await axios.get(url);
    const brandBalance = createBrandBalance(variables.brandId, result.data);
    return brandBalance;
  } catch (error) {
    handleAuthError(error, context.cache);
    logError(error, 'Error retrieving brand balance.');
    throw new Error('Não foi possível obter os seus dados.');
  }
}

function createBrandBalance(brandId: number, data: any) {
  return {
    ...data,
    id: btoa(`BrandBalance:${brandId}`),
    __typename: 'BrandBalance',
    user: createUser(data),
  };
}

function createUser(data: any) {
  return {
    ...data?.user,
    __typename: 'BalanceUser',
    document: createDocument(data),
  };
}

function createDocument(data: any) {
  return data?.user?.document.map((document: Maybe<BalanceUserDocument>) => ({
    ...document,
    __typename: 'BalanceUserDocument',
    id: btoa(`BalanceUserDocument:${document?.numericalId}`),
  }));
}
