export function formatPrintedCardNumber(cardMaskedNumber?: string): string {
  if (!cardMaskedNumber || cardMaskedNumber === '') {
    return 'xxxx.xxxx.xxxx.xxxx';
  }

  let init = cardMaskedNumber.substr(0, 4);
  let finish = cardMaskedNumber.substr(cardMaskedNumber.length - 4);

  return `${init}.xxxx.xxxx.${finish}`;
}

export function formatPrintedCardExpiration(expiration?: string | null) {
  if (!expiration || expiration === '') {
    return 'MM/AAAA';
  }

  const date = new Date(expiration);
  const options = { year: 'numeric', month: 'numeric' };
  return date.toLocaleDateString('pt-BR', options);
}
