import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { UserAddressInput, useSetUserAddressMutation } from '../../../common/generated/graphql';
import CompleteAddressForm from '../complete-address-form/complete-address-form';
import CurrentLocation from '../current-location/current-location';
import GmapsAddressForm from '../gmaps-address-form/gmaps-address-form';
import { GeolocationData } from '../../model/geolocation';

interface Props {
  open: boolean;
  showCurrentLocation: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
    },
    field: {
      margin: theme.spacing(1, 0),
    },
    userLocation: {
      flexGrow: 0,
    },
  })
);

export default function GeolocationContainerDialog(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [setUserAddressMutation] = useSetUserAddressMutation();
  const [userAddress, setUserAddress] = useState<UserAddressInput | null>(null);

  const onCancelClick = useCallback(() => {
    setUserAddress(null);
    props.handleClose();
  }, [props]);

  const onUserLocationSuccess = useCallback(
    (data: GeolocationData) => {
      setUserAddressMutation({
        variables: { input: { latitude: data.latitude, longitude: data.longitude } },
      });
      setUserAddress(null);
      props.handleClose();
    },
    [setUserAddressMutation, props]
  );

  const onGmapsAddressSubmit = useCallback((address: UserAddressInput) => {
    setUserAddress(address);
  }, []);

  const onCompleteAddressSubmit = useCallback(
    (completeAddress: UserAddressInput) => {
      setUserAddressMutation({ variables: { input: completeAddress } });
      setUserAddress(null);
      props.handleClose();
    },
    [props, setUserAddressMutation]
  );

  return (
    <Dialog
      id='address-dialog'
      open={props.open}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle id='address-title' className={classes.dialogTitle}>
        {!userAddress ? 'Diga para a gente onde você está?' : 'Confirme o seu endereço'}
      </DialogTitle>
      {props.showCurrentLocation && !userAddress && (
        <DialogContent className={classes.userLocation}>
          <CurrentLocation onLocationSuccess={onUserLocationSuccess} />
        </DialogContent>
      )}
      {!userAddress ? (
        <GmapsAddressForm onCancelClick={onCancelClick} onOkClick={onGmapsAddressSubmit} />
      ) : (
        <CompleteAddressForm
          inputAddress={userAddress}
          onCancelClick={onCancelClick}
          onOkClick={onCompleteAddressSubmit}
        />
      )}
    </Dialog>
  );
}
