import {
  STATUS_CANCELED_ID,
  STATUS_POS_DENIED_ID,
  STATUS_DELIVERED_ID,
  STATUS_CONSUMED_ID,
  STATUS_RECEIVED_ID,
  STATUS_POS_IMPORTED_ID,
  STATUS_POS_IMPORT_ERROR_ID,
  STATUS_PRODUCT_UNAVAILABLE_ID,
} from '../../common/model/order-status';

const stopStatus = [
  STATUS_CANCELED_ID,
  STATUS_POS_DENIED_ID,
  STATUS_DELIVERED_ID,
  STATUS_CONSUMED_ID,
  STATUS_RECEIVED_ID,
  STATUS_POS_IMPORTED_ID,
  STATUS_POS_IMPORT_ERROR_ID,
  STATUS_PRODUCT_UNAVAILABLE_ID,
];

export const stopPollingOrderStatus = (statusCode: number) => {
  return stopStatus.includes(statusCode);
};
