import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_CURBSIDE } from '../../../common/model/order-types';
import { mobitsTheme } from '../themes/mobits.theme';

export const mobitsConfig: AppConfig = {
  appName: 'eldorado-mobits',
  appTitle: 'Drive Through Eldorado',
  appDescription: 'Faça suas compras com o Drive Through do Shopping Eldorado.',
  aggregator: 972,
  brand: 1329,
  theme: mobitsTheme,
  defaultOrderType: ORDER_TYPE_CURBSIDE,
  faviconPath: '/favicons/favicon-eldorado.ico',
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
