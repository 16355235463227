import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    repeatOrderContainer: {
      padding: theme.spacing(1, 1, 0),
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    repeatOrderContent: {
      width: '100%',
    },
    repeatOrderTitle: {
      ...theme.typography.body2,
      color: theme.palette.grey[500],
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    repeatOrderItemText: {
      ...theme.typography.body1,
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightBold as 'bold',
      marginLeft: theme.spacing(0.5),
    },
    repeatOrderItemTextTotal: {
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    repeatOrderAction: {
      cursor: 'pointer',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: theme.spacing(30),
      },
    },
    repeatOrderAmountItem: {
      color: theme.palette.common.white,
      background: theme.palette.primary.light,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 0.5),
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    repeatOrderButton: {
      justifyContent: 'center',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMediumBold,
      marginTop: theme.spacing(2),
    },
    repeatOrderDivider: {
      margin: theme.spacing(1, 0),
    },
    containerItens: {
      position: 'relative',
    },
    containerTextItem: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    collapse: {
      position: 'relative',
      paddingTop: theme.spacing(1),
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `linear-gradient(to top, ${theme.palette.common.white}, transparent)`,
    },
    iconInfo: {
      color: theme.palette.info.dark,
    },
    tooltipInfo: {
      background: theme.palette.info.superLightest,
      color: theme.palette.info.darkest,
    },
    tooltipArrow: {
      color: theme.palette.info.superLightest,
    },
  })
);
