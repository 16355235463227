import { Box, Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { UnionCardBrand } from '../../model/card-brand';
import { useStyles } from './payment-method.style';
import {
  formatPrintedCardExpiration,
  formatPrintedCardNumber,
} from '../../helpers/format-printed-card';
import SelectCardBrandIcon from '../select-card-brand-icon/select-card-brand-icon';

interface Props {
  cardBrand: string;
  cardNumber?: string;
  cardFullMasked?: string;
  cardPrintedName: string;
  cardExpiration?: string;
  primaryButtonText: string;
  cardBackendId?: string;
  onPrimaryButtonClick: () => any;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => any;
}

/**
 * Shows the payment method (card) information as a Card element.
 */
export default function PaymentMethod(props: Props) {
  const classes = useStyles();

  const formatedNumberCard: string = formatPrintedCardNumber(
    props.cardFullMasked ? props.cardFullMasked : props.cardNumber
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.content}>
          <Box className={classes.cardBrandImgContainer}>
            <SelectCardBrandIcon size='extraLarge' card={props.cardBrand as UnionCardBrand} />
          </Box>
          {(props.cardNumber || props.cardFullMasked) && (
            <Typography variant='body2' id='payment-method-number-card'>
              {formatedNumberCard}
            </Typography>
          )}
          <Typography variant='body2'>{props.cardPrintedName}</Typography>
          {props.cardExpiration && (props.cardNumber || props.cardFullMasked) && (
            <Typography variant='body2'>
              {formatPrintedCardExpiration(props.cardExpiration)}
            </Typography>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button
          variant='contained'
          color='primary'
          size='small'
          id='primary-btn'
          onClick={props.onPrimaryButtonClick}
        >
          {props.primaryButtonText}
        </Button>
        {props.secondaryButtonText && props.secondaryButtonText !== '' && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            id='secondary-btn'
            onClick={props.onSecondaryButtonClick}
          >
            {props.secondaryButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
