import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      background: theme.palette.additionalColors.aggregators?.background,
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      padding: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    root: {
      marginBottom: theme.spacing(4),
      borderRadius: '0 0 18px 18px',
      width: '100%',
      display: 'flex',
    },
    container: {
      width: '100%',
      padding: 0,
    },
    rootContainer: {
      borderRadius: theme.spacing(2),
      background: theme.palette.grey[50],
      marginTop: theme.spacing(2),
    },
    products: {
      marginBottom: theme.spacing(3),
    },
    footer: {
      paddingTop: theme.spacing(2),
    },
  })
);
