import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(2),
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: theme.spacing(1, 2),
      boxSizing: 'border-box',
    },
    imgWrap: {
      height: '17.5rem',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.up('sm')]: {
        height: '18.5rem',
        width: '18.5rem',
      },
    },
    img: {
      borderRadius: theme.spacing(2),
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    headerWrap: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    header: {
      flexGrow: 1,
      display: 'flex',
      padding: theme.spacing(2, 0),
      flexWrap: 'wrap',

      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 18.5rem)',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        display: 'grid',
        height: '18.5rem',
        alignContent: 'stretch',
        rowGap: 0,
        gridTemplateAreas: `
           "title title" \n
           "content content"\n
           "content content"\n
           "content content"\n
           "time price"`,
      },
    },
    title: {
      color: theme.palette.grey[900],
      fontWeight: theme.typography.fontWeightMediumBold,
      flexGrow: 1,
      width: '80%',

      [theme.breakpoints.up('sm')]: {
        flexGrow: 1,
        width: '100%',
        height: 'max-content',
        gridArea: 'title',
        alignSelf: 'start',
        minHeight: '2rem',
        marginBottom: theme.spacing(2),
      },
    },
    price: {
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightMediumBold,
      minWidth: 'max-content',
      width: '20%',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: 'max-content',
        minHeight: '2rem',
        alignItems: 'center',
        gridArea: 'price',
        alignSelf: 'end',
        width: '100%',
      },
    },
    description: {
      color: theme.palette.grey[400],
      width: '100%',
      gridArea: 'content',
      alignSelf: 'stretch',

      [theme.breakpoints.up('sm')]: {
        height: 'auto',
        marginTop: theme.spacing(-5),
      },
    },
    preparationTime: {
      width: '100%',
      paddingTop: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        paddingTop: 0,
        height: 'max-content',
        minHeight: '2rem',
        alignItems: 'center',
        gridArea: 'time',
        alignSelf: 'end',
      },
    },
    buttonPosition: {
      padding: theme.spacing(2, 2, 1),
      position: 'sticky',
      bottom: 0,
      left: 0,
      zIndex: theme.zIndex.appBar,
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: '1rem 1rem 0 0',
      boxShadow: `0px -1px 2px 1px ${theme.palette.grey[100]}`,
    },
    embededApplicationUnlogged: {
      paddingBottom: theme.spacing(5),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      background: theme.palette.common.white,
      width: '100%',
      padding: 0,
    },
    buttonSubmit: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      height: '3rem',
      width: '13.75rem',
      maxWidth: '13.75rem',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '.5rem',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
  })
);
