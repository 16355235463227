import React from 'react';
import { Typography, Paper, Grid, Box, Button } from '@material-ui/core';

import Icon from '../../../common/components/icon/icon';
import { useStyles } from './order-coupon-empty-item.style';

interface Props {
  onAddCoupon: VoidFunction;
}

export default function OrderCouponEmptyItem({ onAddCoupon }: Props) {
  const classes = useStyles();

  return (
    <Paper data-testid='coupon-item' className={classes.couponItem} elevation={0}>
      <Grid
        data-testid='content-empty'
        container
        alignItems='center'
        justifyContent='space-between'
        wrap='nowrap'
      >
        <Grid item>
          <Box>
            <Grid container alignItems='center' wrap='nowrap'>
              <Icon
                data-testid='content-empty-icon'
                name='coupon'
                className={classes.couponIcon}
                size='large'
              />
              <Typography data-testid='content-empty-text' className={classes.couponItemEmpty}>
                Você não possui cupom válido para este estabelecimento
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Button
            data-testid='order-coupon-add-button'
            className={classes.couponItemAction}
            color='secondary'
            onClick={onAddCoupon}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
