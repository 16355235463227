import { AppConfig } from '../../../common/model/config';
import { c3PrajaTheme } from '../themes/c3-praja.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const c3PrajaConfig: AppConfig = {
  appName: 'c3-praja',
  appTitle: 'C3 Pra Já',
  appDescription: 'Faça os seus pedidos nas lojas do Shopping Center 3.',
  aggregator: 44,
  brand: 44,
  theme: c3PrajaTheme,
  faviconPath: '/favicons/favicon-c3-praja.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
