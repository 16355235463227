import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
    },
    paper: {
      borderRadius: `1.125rem 1.125rem 0 0`,
      position: 'relative',
      overflow: 'hidden',
      height: `calc(100vh - (${theme.template?.header.size.main.desktop} + ${theme.spacing(2)}px))`,

      [theme.breakpoints.between('xs', 'sm')]: {
        height: `calc(100vh - (${theme.template?.header.size.main.tablet} + ${theme.spacing(
          2
        )}px))`,
      },

      [theme.breakpoints.up('xs')]: {
        width: '600px !important',
        maxWidth: '600px !important',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100vw !important',
        maxWidth: '100vw !important',
        height: `calc(100vh - (${theme.template?.header.size.main.mobile} + ${theme.spacing(
          2
        )}px))`,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
      },
    },
    close: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      justifyContent: 'flex-end',
    },
    btnIcon: {
      width: '2.5rem',
      height: '2.5rem',
      padding: theme.spacing(2),
      cursor: 'pointer',
      background: theme.palette.common.white,
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    closeIcon: {
      width: '1.125rem',
      height: '1.125rem',
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
  })
);
