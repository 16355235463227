import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      fontWeight: theme.typography.fontWeightMediumBold,
      textTransform: 'capitalize',
    },
  })
);
