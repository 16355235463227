import {
  SetScrollToProductMutationVariables,
  ScrollToProductFragment,
  ScrollToProductFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function setScrollToProduct(
  root: any,
  variables: SetScrollToProductMutationVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
): boolean {
  const product = context.cache.readFragment<ScrollToProductFragment>({
    fragment: ScrollToProductFragmentDoc,
    id: context.getCacheKey({ id: variables.productId, __typename: 'Product' }),
  });

  if (!product) {
    console.error('Product not found, ' + variables.productId);
    return false;
  }

  context.cache.writeFragment<ScrollToProductFragment>({
    fragment: ScrollToProductFragmentDoc,
    id: context.getCacheKey({ id: variables.productId, __typename: 'Product' }),
    data: {
      ...product,
      scrollToProduct: variables.value,
    },
  });

  return true;
}
