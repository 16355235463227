import { Typography } from '@material-ui/core';
import React from 'react';
import { Image, Maybe } from '../../generated/graphql';
import { parseImage } from '../../helpers/parse-image';
import { getAppConfig } from '../../helpers/configs';
import { useStyles } from './aggregator-logo.style';
import { LogoPedePronto } from '../logo-pede-pronto/logo-pede-pronto';
import { checkHostName } from '../../helpers/check-host-name';
import { WhiteLabelHostName } from '../../../common/model/white-label-host-name';

interface Props {
  mainHeader?: boolean;
  aggregatorName?: Maybe<string>;
  aggregatorImages?: Maybe<Array<Maybe<Image>>>;
  reduced?: boolean;
}

export function AggregatorLogo(props: Props) {
  const classes = useStyles();
  const { appTitle, featureFlags } = getAppConfig();
  const isPedeProntoAggregator = checkHostName();
  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);

  const {
    useLocalAggregatorHeaderImg,
    hideAppTitle,
    removeLogoFromTheApp,
    localAggregatorImgPath,
    gmapsAddress,
  } = featureFlags;

  if (isPedeProntoAggregator || isStreamshop) return <LogoPedePronto reduced={props.reduced} />;

  if (hideAppTitle && removeLogoFromTheApp)
    return <div id='aggregator-logo-empty' className={classes.empty} />;

  if (!removeLogoFromTheApp) {
    if (useLocalAggregatorHeaderImg) {
      return (
        <img
          src={localAggregatorImgPath}
          className={`${classes.image} ${props.mainHeader && classes.mainHeader} ${
            !gmapsAddress && classes.imageWithoutAddress
          }`}
          id='local-agg-img'
          alt=''
        />
      );
    }

    const headerImage = parseImage(props.aggregatorImages, 'onyo.android.theme.navigation.newLogo');
    const whenHeaderIsEmpty = headerImage !== '';

    if (whenHeaderIsEmpty)
      return (
        <img
          src={headerImage}
          className={`${classes.image} ${props.mainHeader && classes.mainHeader}`}
          id='agg-img'
          alt=''
        />
      );
  }

  return (
    <Typography variant='h5' id='agg-name' className={classes.simpleTextTitle}>
      {props.aggregatorName ?? appTitle}
    </Typography>
  );
}
