import {
  SelectOrderTypeMutationVariables,
  GetSelectedOrderTypeQuery,
  GetSelectedOrderTypeDocument,
  SelectedOrderTypeFieldValueInput,
  SelectedOrderTypeInput,
  SelectedOrderType,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';
import { saveOrderTypeToCache } from '../../browser-cache/order-type/save-order-type-to-cache';

export function selectedOrderType(
  root: any,
  variables: SelectOrderTypeMutationVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const currentOrderTypeQuery = context.cache.readQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
  });

  const orderType: any = createSelectedOrderType(variables.input, currentOrderTypeQuery);

  context.cache.writeQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
    data: { selectedOrderType: orderType },
  });

  saveOrderTypeToCache(orderType);

  return orderType;
}

function createSelectedOrderType(
  input: SelectedOrderTypeInput,
  currentOrderTypeQuery: GetSelectedOrderTypeQuery | null
): SelectedOrderType {
  let updatedOrderType: SelectedOrderType;
  const fieldValues: any = createFieldValues(input.fieldValues as any);

  if (currentOrderTypeQuery && currentOrderTypeQuery.selectedOrderType) {
    updatedOrderType = {
      ...currentOrderTypeQuery.selectedOrderType,
      orderType: input.orderType,
      fieldValues,
    };
  } else {
    updatedOrderType = {
      ...input,
      __typename: 'SelectedOrderType',
      id: createNewCacheKey('SelectedOrderType'),
      fieldValues,
    };
  }

  return updatedOrderType;
}

function createFieldValues(input?: SelectedOrderTypeFieldValueInput[]) {
  if (!input) {
    return null;
  }

  return input.map((value) => ({
    ...value,
    __typename: 'SelectedOrderTypeFieldValue',
    id: createNewCacheKey('SelectedOrderTypeFieldValue'),
  }));
}
