import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsViewItemList = {
  brandId: string;
  companyId: string;
  staticMenu?: 'true';
  count?: number;
};

const event = 'view_item_list';

export function logViewItemList({ brandId, companyId, staticMenu, count }: AnalyticsViewItemList) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.COUNT]: count,
      [ParamName.STATIC_MENU]: staticMenu,
    },
  });
}

export function logViewItemListError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
