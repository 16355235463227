import { mountProducts } from './helper/mount-products';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { Order, Company } from '../../../common/generated/graphql';
import { formatPrintedCardNumber } from '../../../common/helpers/format-printed-card';
import { normalizeTextCompare } from '../../../common/helpers/normalize-text-compare';
import { formatPhoneNumber } from '../../helpers/format-phone-number';
import { CustomerData } from '../../generated/graphql';

interface Props {
  order: Order;
  status?: string;
  orderId?: string;
  user?: CustomerData;
  orderItems?: any;
  company?: Company;
}

export async function sendText({ order, status, orderId, user, orderItems, company }: Props) {
  const url = `https://api.z-api.io/instances/3A759691278AD064CAA36E12B71A6314/token/6AF6B7DF12A3DFCE1190E26A/send-text`;

  const discount = order?.orderExtra?.find((extra) => extra?.key === 'discount');
  const coupon = order?.payments?.find((pay) => pay?.method === 'COUPON');
  const extraDelivery = order?.orderExtra?.find((extra) =>
    normalizeTextCompare(extra?.label)?.includes('entrega')
  );
  const isOffiline = order?.payments?.find((pay) => pay?.method === 'OFFLINE');

  const textDiscount = discount?.value ? `Desconto: ${formatPrice(discount?.value)}` : '';
  const textCoupon = coupon?.value ? `Cupom: ${formatPrice(coupon?.value)}` : '';
  const textDelivery = extraDelivery?.value
    ? `Taxa de entrega: ${formatPrice(extraDelivery?.value)}`
    : '';

  const table = order?.tableReference ? `Mesa: *${order.tableReference}*` : '';
  const body = `⭐⭐⭐ *PEDE PRONTO* ⭐⭐⭐

  *${company?.name?.slice(0, 15).toUpperCase()}* 💜
  *Nº PEDIDO: ${order.displayCode ?? orderId}* 🚀🚀🚀

  *INFORMAÇõES DO PEDIDO*
  ---------------------------------------
  Pagamento: ${isOffiline ? 'Direto com o estabelecimento' : 'Pago via App'}
  ${table}

  *PEDIDO*
  ---------------------------------------
${orderItems.map((item: any) => mountProducts(item)).join('')}

  *PAGAMENTO*
  ---------------------------------------
  ${textDiscount ? textDiscount + '\n  ' : ''}${textDelivery ? textDelivery + '\n  ' : ''}${
    textCoupon ? textCoupon + '\n  ' : ''
  }
  Subtotal: ${formatPrice(order?.totalValue! ?? 0)}
  *Total: ${formatPrice(order.payableValue || 0)}*

  *FORMAS DE PAGAMENTO*
  ---------------------------------------
  ${
    !!order.payments
      ? order?.payments.map((pay: any) =>
          pay?.card
            ? `💳 *${pay?.card?.cardBrand?.name}*\n  ${formatPrintedCardNumber(
                pay?.card?.maskedNumber ?? ''
              )}`
            : ''
        )
      : ''
  }

  *CLIENTE*
  ---------------------------------------
  ${user?.name ?? ''}
  ${formatPhoneNumber(user?.telephone ?? '') ?? ''}

  By *PedePronto* 🚀 🚀 💜`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      phone: company?.companyPhone?.number?.replace('+', ''),
      message: body,
    }),
  };
  try {
    fetch(url, requestOptions)
      .then(() => console.warn('mensagem enviada com sucesso'))
      .then((data) => console.warn(data));
  } catch (error) {
    console.warn(error);
  }
}
