import { OnlineStatus } from '../generated/graphql';
import { HighlightStatus } from '../model/highlight-status';

export default function getHighlightStatus(
  highlightMessage: string,
  status: OnlineStatus
): HighlightStatus {
  if (!highlightMessage) {
    return 'hidden';
  }

  switch (status) {
    case OnlineStatus.Online:
      return 'online';
    case OnlineStatus.Offline:
      return 'offline';
    case OnlineStatus.Closed:
      return 'closed';
    default:
      return 'default';
  }
}
