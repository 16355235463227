import { ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type LogSelectBanner = {
  banner_id: string;
};

const event = 'select_banner';

export function logSelectBanner({ banner_id }: LogSelectBanner) {
  logAnalytics({
    event,
    [ParamName.BANNER_ID]: banner_id,
  });
}

export function logSelectBannerError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
