import { Endpoints } from '../../../common/model/config';

export const refeicaoOnlineEndpoints: Endpoints = {
  api: 'https://api.refeicao.online/',
  graphql: 'https://graphql.refeicao.online/graphql',
  payments: 'https://payments.refeicao.online/',
  authDomain: 'brandapplication.firebaseapp.com',
  enterpriseUserForm: 'https://enterprise-user.refeicao.online/',
};

export const refeicaoDigitalEndpoints: Endpoints = {
  api: 'https://api.refeicao.digital/',
  graphql: 'https://graphql.refeicao.digital/graphql',
  payments: 'https://payments.refeicao.digital/',
  authDomain: 'brandapplication.firebaseapp.com',
  enterpriseUserForm: 'https://enterprise-user.refeicao.digital/',
};

export const refeicaoOnlineStagingEndpoints: Endpoints = {
  api: 'https://api.staging.refeicao.online/',
  graphql: 'https://graphql.staging.refeicao.online/graphql',
  payments: 'https://payments.staging.refeicao.online/',
  authDomain: 'brandapplication.firebaseapp.com',
  enterpriseUserForm: 'https://enterprise-user.staging.refeicao.online/',
};
