import { Container, Dialog, Box, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import StandardDialog from '../../../common/components/standard-dialog/standard-dialog';
import {
  CustomerDataInput,
  LocalCardInput,
  PaymentParameter,
  useAddLocalCardMutation,
  useGetCompanyPaymentMethodsQuery,
  useGetCustomerDataQuery,
  useUpdateCustomerDataMutation,
  CartPaymentStatus,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import RegisterCardForm from '../register-card-form/register-card-form';
import { useStyles } from './register-card-container.style';
import { useUpdateCartPayment } from '../../../common/hooks/use-update-cart-payment';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { logBeginCheckoutError } from '../../../analytics/helpers/log-begin-checkout';
import { logAddPaymentInfoError } from '../../../analytics/helpers/log-add-payment-info';
import { ApolloError } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { checkHostName } from '../../../common/helpers/check-host-name';
import { WhiteLabelHostName } from '../../../common/model/white-label-host-name';

interface Props {
  open: boolean;
}

export default function RegisterCardContainer(props: Props) {
  const classes = useStyles();
  const location = useLocation();
  const headerSpace = useMainHeaderSpace();
  const params = useParams<{
    aggregatorId: string;
    brandId: string;
    companyId: string;
  }>();
  const [errorMsg, setErrorMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const { updateCartPayment } = useUpdateCartPayment();

  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);

  let content;

  const onErrorDialogClose = useCallback(() => {
    setOpenErrorDialog(false);
  }, []);

  const { loading, data, error, refetch } = useGetCompanyPaymentMethodsQuery({
    variables: { companyId: Number(params.companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      logError(err, 'Error executing Get Company Payment Methods Query');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 65 });
    },
  });

  const { loading: customerLoading, data: customerDataQuery } = useGetCustomerDataQuery({
    onError: (err) => {
      logError(err, 'Error executing Get User Data Query');
      logBeginCheckoutError({ errorCode: 66, errorMessage: err.message });
    },
  });

  const [addLocalCard, { loading: addLocalCardLoaging }] = useAddLocalCardMutation({
    onError: (err) => {
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 67 });
      throw new ApolloError(err);
    },
  });

  const [updateCustomerData, { loading: updateCustomerDataLoading }] =
    useUpdateCustomerDataMutation({
      onError: (err) => {
        logAddPaymentInfoError({ errorCode: 68, errorMessage: err.message });
        throw new ApolloError(err);
      },
    });

  const handleOnClose = useCallback(() => {
    updateCartPayment(CartPaymentStatus.CartPayment);
  }, [updateCartPayment]);

  const onSubmit = useCallback(
    async (localCard: LocalCardInput, customerData?: CustomerDataInput) => {
      try {
        if (customerData) {
          await updateCustomerData({ variables: { input: customerData } });
        }

        await addLocalCard({ variables: { localCard } });
        handleOnClose();
      } catch (err) {
        setErrorMsg('Ocorreu um erro ao salvar o seu cartão. Por favor tente novamente.');
        setOpenErrorDialog(true);
        logError(error, 'Updating the user data or saving the user card');
        logAddPaymentInfoError({ errorCode: 69 });
      }
    },
    [addLocalCard, error, handleOnClose, updateCustomerData]
  );

  if (loading || customerLoading || addLocalCardLoaging || updateCustomerDataLoading) {
    content = <Progress />;
  } else if (
    error ||
    !data?.company?.parameter?.paymentParameter ||
    data?.company?.parameter?.paymentParameter.length === 0
  ) {
    content = (
      <NetworkError
        errorMsg='Erro ao carregar os meios de pagamento disponíveis'
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  } else {
    const dataPaymentParameter = isStreamshop
      ? data.company.parameter.paymentParameter.filter((pay) => pay?.cardBrand?.code !== 'alel0')
      : data.company.parameter.paymentParameter;
    content = (
      <RegisterCardForm
        onSubmit={onSubmit}
        paymentParameters={dataPaymentParameter as PaymentParameter[]}
        customerData={customerDataQuery?.customerData}
      />
    );
  }

  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby='modal-metodos-de-pagamento'
      open={props.open}
      classes={{
        container: classes.container,
        paper: `${classes.paper} ${headerSpace.fullDialog}`,
      }}
    >
      <Box className={classes.header}>
        <Box>
          <Typography className={classes.title} align='center' variant='h6'>
            Adicionar Cartão
          </Typography>
          <Typography className={classes.message} align='center' variant='body2'>
            Preencha os campos com os dados do cartão que você deseja utilizar
          </Typography>
        </Box>

        <IconButton
          onClick={handleOnClose}
          className={classes.btnIcon}
          aria-label='fechar-modal-metodos-de-pagamento'
          id='payments-methods-container-close-button'
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Container>{content}</Container>
      <StandardDialog
        title='Ops'
        text={errorMsg}
        open={openErrorDialog}
        onCloseClick={onErrorDialogClose}
      />
    </Dialog>
  );
}
