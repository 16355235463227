import React from 'react';
import { UnionIconOptions } from './helpers/type-icon';

import { IconPath } from './helpers/icon-path';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  name: UnionIconOptions;
}

export default function Icon({ name, id = 'icon', ...props }: Props) {
  return (
    <SvgWrapper id={`icon-${name}`} {...props}>
      <path id='icon-path' d={IconPath[name]} />
    </SvgWrapper>
  );
}
