import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import React from 'react';
import { useStyles } from './company-header-payments.style';
import SelectCardBrandIcon from '../../../common/components/select-card-brand-icon/select-card-brand-icon';
import { UnionCardBrand } from '../../../common/model/card-brand';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import { isDefaultCard } from '../../helpers/isDefaultCard';
import { parsePaymentMethods } from '../../helpers/parsers';
import { Company } from '../../../common/generated/graphql';

export default function CompanyHeaderPayments(): React.ReactElement {
  const classes = useStyles();
  const companyData = useGetCompanyDataFragment();
  const paymentMethods = parsePaymentMethods(companyData as Company);

  return (
    <List className={classes.cardList} id='company-header-payments-list'>
      {paymentMethods.map((card) =>
        card ? (
          <ListItem
            className={classes.card}
            key={card.code}
            id={`company-header-payments-${card.code}`}
          >
            <SelectCardBrandIcon
              card={card.code as UnionCardBrand}
              size='large'
              id={`company-header-payments-icon-${card.code}`}
            />
            {isDefaultCard(card?.code as UnionCardBrand) && (
              <Typography
                variant='caption'
                className={classes.cardName}
                id={`company-header-payments-label-${card.code}`}
              >
                {card.name}
              </Typography>
            )}
          </ListItem>
        ) : (
          <ListItem className={classes.empty} />
        )
      )}
    </List>
  );
}
