import { SharedConfig } from '../../../common/model/config';

export const stagingSharedConfig: SharedConfig = {
  timeout: 30000,
  pollInterval: 15000,
  cacheDurationMinutes: 30,
  maximumDistanceKilometers: 20,
  companyPaginationStep: 6,
  sentryDSN: 'https://8cb7dbdf2506453da416d998cf53191f@sentry.monitor.onyo.com/29',
  numberColumnsBreakPointsAggregator: {
    xs: 12,
    md: 6,
    lg: 4,
  },
};
