import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsViewOrderStatus = {
  orderId: string;
};

const event = 'view_order_status';

export function logViewOrderStatus({ orderId }: AnalyticsViewOrderStatus) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.TRANSACTION_ID]: orderId,
    },
  });
}

export function logViewOrderStatusError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
