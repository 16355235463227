import { OrderFieldParameter, SelectedOrderTypeFieldValueInput } from '../generated/graphql';

/**
 * This function takes the values returned by the Order Fields Dialog Formik and
 * adds extra properties to them.
 */
export function orderFieldValuesToInput(
  fieldValues: any,
  fields: OrderFieldParameter[]
): SelectedOrderTypeFieldValueInput[] {
  const inputValues: SelectedOrderTypeFieldValueInput[] = [];

  for (const key of Object.keys(fieldValues)) {
    const position = Number(key);
    const orderFieldParam = fields.find((orderField) => orderField.field?.position === position);
    if (!orderFieldParam) {
      console.warn('Order field param not found', position);
    }

    const inputField: SelectedOrderTypeFieldValueInput = {
      position: Number(key),
      value: fieldValues[key],
      numericalId: orderFieldParam?.field?.numericalId!,
      label: orderFieldParam?.field?.label!,
    };
    inputValues.push(inputField);
  }

  return inputValues;
}
