import React, { useCallback } from 'react';
import { Typography, Paper, Grid, Box } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { OrderExtra } from '../../../common/generated/graphql';

import Icon from '../../../common/components/icon/icon';
import OrderCouponEmptyItem from '../order-coupon-empty-item/order-coupon-empty-item';

import { useStyles } from './order-coupon-item.style';

interface Props {
  data?: OrderExtra;
}

export default function OrderCouponItem({ data }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ aggregatorId: string; companyId: string }>();
  const location = useLocation();

  const handleDirectCoupon = useCallback(() => {
    history.push(`/${params.aggregatorId}/coupons`, {
      from: location.pathname,
    });
  }, [history, location.pathname, params.aggregatorId]);

  if (!data) {
    return <OrderCouponEmptyItem onAddCoupon={handleDirectCoupon} />;
  }

  return (
    <Paper
      data-testid='coupon-item'
      className={classes.couponItem}
      elevation={0}
      onClick={handleDirectCoupon}
    >
      <Grid container justifyContent='space-between' alignItems='center'>
        <Box>
          <Grid container>
            <Icon data-testid='icon-coupon' name='coupon' color='greyBase' size='large' />
            <Grid item className={classes.containerLabel}>
              <Typography
                data-testid='coupon-code'
                className={classes.couponItemLabel}
                variant='button'
              >
                {data?.label}
              </Typography>
              <Typography data-testid='apply-coupon-text' variant='body2'>
                Cupom aplicado
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid item data-testid='icon-container'>
          <Icon name='navigateNext' color='secondary' size='medium' />
        </Grid>
      </Grid>
    </Paper>
  );
}
