import {
  MutationsRemoveFromCartArgs,
  Product,
  ProductFragmentDoc,
  ProductFragment,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import filter from 'lodash/filter';

export function removeFromCart(
  root: any,
  variables: MutationsRemoveFromCartArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const shoppingCart = getShoppingCart(variables.companyId, context.cache, context.getCacheKey);
  if (!shoppingCart) {
    return false;
  }

  const product = getProduct(variables.productCacheId, context.cache, context.getCacheKey);
  if (!product) {
    console.error('Product not found in cache: ', variables.productCacheId);
    return false;
  }

  const updatedCart = updateShoppingCart(shoppingCart, product);

  context.cache.writeFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: context.getCacheKey({
      id: btoa(`ShoppingCart:${variables.companyId}`),
      __typename: 'ShoppingCart',
    }),
    data: updatedCart,
  });

  return true;
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
  });
}

function getProduct(productId: string, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: getCacheKey({ id: productId, __typename: 'Product' }),
  });
}

function updateShoppingCart(shoppingCart: ShoppingCartFragment, product: ProductFragment) {
  // Create a new array without a product
  const productsClone = filter(shoppingCart.products, (value: Product) => value.id !== product.id);

  let newTotal = shoppingCart.orderTotal - product.totalPrice!;
  if (newTotal < 0) {
    newTotal = 0;
  }

  const updatedCart = {
    ...shoppingCart,
    totalProductsPrice: shoppingCart.totalProductsPrice - product.totalPrice!,
    orderTotal: newTotal,
    products: productsClone as any,
  };

  return updatedCart;
}
