import React, { useState, useCallback, useEffect } from 'react';
import {
  useAcceptTermsAndConditionsMutation,
  useGetCustomerDataQuery,
} from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import TermsDialog from '../terms-dialog/terms-dialog';

export default function TermsDialogContainer() {
  const [open, setOpen] = useState(false);
  const [acceptTerms] = useAcceptTermsAndConditionsMutation({
    onError: (err) => logError(err, 'Error accepting terms and conditions'),
  });
  const { data } = useGetCustomerDataQuery({
    onError: (err) => logError(err, 'Error accepting terms and conditions'),
  });

  const onAccept = useCallback(() => {
    setOpen(false);
    acceptTerms();
  }, [acceptTerms]);

  useEffect(() => {
    if (!data?.customerData) {
      return;
    }

    if (!data.customerData.acceptedTermsDatetime) {
      setOpen(true);
    }
  }, [data]);

  return <TermsDialog open={open} onAcceptClick={onAccept} />;
}
