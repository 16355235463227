import 'firebase/analytics';
import firebase from 'firebase/app';
import { ShoppingCart } from '../../generated/graphql';
import { SHOPPING_CART_CACHE, SW_CACHE_EXIPIRATION } from '../../model/caches';
import { getCacheExpiration } from '../get-cache-expiration';

interface Params {
  companyId?: number;
  shoppingCart?: ShoppingCart;
  pathName?: string;
  cacheName?: string;
  cacheExpirationMinutes?: number;
}

export async function saveCartToCache({
  companyId,
  shoppingCart,
  pathName = 'shopping-cart',
  cacheName = SHOPPING_CART_CACHE,
  cacheExpirationMinutes,
}: Params) {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return;
  }

  const cache = await caches.open(cacheName);

  const resp = new Response(JSON.stringify(shoppingCart), {
    headers: {
      'Content-Type': 'application/json',
      [SW_CACHE_EXIPIRATION]: getCacheExpiration(cacheExpirationMinutes),
    },
  });
  cache.put(`/${pathName}/${companyId}`, resp);
}
