import { useNotification } from '../../common/hooks/use-notification';
import { UseFormCustomer } from '../models/customer-form';
import { useFormik } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { formatCPF } from '../../common/helpers/format-cpf';
import { formatPhoneNumber, getPhoneNumberRegex } from '../../common/helpers/format-phone-number';
import { validateCPF } from '../../common/helpers/validate-cpf';
import { CustomerFormValues } from '../models/customer-form';
import { HandleOnChange } from '../../common/model/html-events';
import { getOnlyDigits } from '../../common/helpers/get-only-digits';
import { useUpdateCustomerDataMutation } from '../../common/generated/graphql';
import { logError } from '../../common/helpers/log-error';

export const useFormCustomer: UseFormCustomer = (cpf, phoneNumber) => {
  const [updateCustomerData] = useUpdateCustomerDataMutation();

  const { setNotify } = useNotification();

  const initialValues = {
    cpf: cpf ? formatCPF(cpf) : '',
    phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : '',
  };

  const validationSchema = yupObject().shape({
    cpf: yupString()
      .required('Esse campo é obrigatório')
      .test('CPF validation', 'Esse CPF é inválido', validateCPF),
    phoneNumber: yupString()
      .required('Esse campo é obrigatório')
      .matches(getPhoneNumberRegex(), 'Por favor entre com um número de telefone celular com DDD'),
  });

  const formikInit = {
    initialValues,
    validationSchema,
    onSubmit: async (values: CustomerFormValues) => {
      let cpfChanged = !cpf || getOnlyDigits(values.cpf) !== cpf;
      let phoneNumberChanged =
        !phoneNumber || getOnlyDigits(values.phoneNumber) !== getOnlyDigits(phoneNumber ?? '');

      if (cpfChanged || phoneNumberChanged) {
        try {
          await updateCustomerData({
            variables: {
              input: {
                cpf: getOnlyDigits(values.cpf),
                telephone: values.phoneNumber,
              },
            },
          });
        } catch (e) {
          setNotify('error', e.message);
          logError(e, 'Erro ao atualizar dados do usuário');
        }
      }
    },
  };

  const formik = useFormik<CustomerFormValues>(formikInit);

  const handleOnChange: HandleOnChange = (e) => {
    let { name } = e.target;

    formik.setFieldValue(
      name,
      name === 'cpf' ? formatCPF(e.target.value) : formatPhoneNumber(e.target.value)
    );
  };

  return {
    formik,
    handleOnChange,
  };
};
