import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';

type AnalyticsError = {
  event: string;
  errorMessage?: string;
  errorCode: number;
};

export type AnalyticsErrorAux = {
  errorMessage?: string;
  errorCode: number;
};

export function logAnalyticsError({ event, errorMessage, errorCode }: AnalyticsError) {
  logAnalytics({
    event: 'maiden',
    [BundleName.ERROR]: {
      [ParamName.ERROR_TYPE]: event,
      [ParamName.ERROR_MESSAGE]: errorMessage,
      [ParamName.ERROR_CODE]: errorCode,
    },
  });
}
