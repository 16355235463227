import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { useStyles } from './label-field.style';

interface Props {
  value?: string | ReactNode;
  gutterBottom?: boolean;
  disabled?: boolean;
}

export default function LabelField({ value = '', gutterBottom, disabled }: Props) {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom={gutterBottom}
      className={clsx(classes.label, { [classes.disabled]: disabled })}
    >
      {value}
    </Typography>
  );
}
