import 'firebase/analytics';
import firebase from 'firebase/app';
import { ORDER_TYPE_CACHE } from '../../model/caches';
import { getCachedData } from '../get-cached-data';

export async function loadOrderTypeFromCache() {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return [];
  }

  const cache = await caches.open(ORDER_TYPE_CACHE);
  const orderTypeRes = await cache.match('/order-type');
  if (orderTypeRes?.ok) {
    const orderTypeClone = orderTypeRes.clone();
    const data = await getCachedData(orderTypeClone, '/order-type', cache);
    return data;
  }
}
