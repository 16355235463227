import { AppConfig } from '../../../common/model/config';
import { viaSulShoppingTheme } from '../themes/viasul-shopping.theme';
import { ORDER_TYPE_COUNTER } from '../../../common/model/order-types';

export const viaSulShoppingConfig: AppConfig = {
  appName: 'viasul-shopping',
  appTitle: 'Viasul Shopping',
  appDescription: 'Peça aqui nos restaurantes do Viasul Shopping.',
  aggregator: 1561,
  brand: 1458,
  theme: viaSulShoppingTheme,
  faviconPath: '/favicons/favicon-north-shopping.ico',
  defaultOrderType: ORDER_TYPE_COUNTER,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
