import { GetCompaniesGroupsByAggregatorsQueryVariables } from '../../../../common/generated/graphql';
import { getSharedConfig } from '../../../../common/helpers/configs';

export function getQueryVariables(
  companyGroupId: number,
  latitude: number,
  longitude: number,
  after: string,
  companyNameStartsWith?: string
) {
  const sharedConfig = getSharedConfig();

  const queryVariables: GetCompaniesGroupsByAggregatorsQueryVariables = {
    companyGroupId,
    latitude,
    longitude,
    maximumDistanceKilometers: sharedConfig.maximumDistanceKilometers,
    first: sharedConfig.companyPaginationStep,
    after,
    companyNameStartsWith,
  };

  if (queryVariables.latitude && queryVariables.longitude) {
    queryVariables.maximumDistanceKilometers = sharedConfig.maximumDistanceKilometers;
  }

  return queryVariables;
}
