import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const space = theme.spacing(1);
  const topPosition = (top?: string | number) => `calc(${top} + ${space}px)`;

  return createStyles({
    root: {
      position: 'fixed',
      height: 'max-content',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      zIndex: theme.zIndex.tooltip,
      top: topPosition(theme.template?.header.size.main.mobile),

      [theme.breakpoints.between('sm', 'md')]: {
        top: topPosition(theme.template?.header.size.main.tablet),
      },

      [theme.breakpoints.up('md')]: {
        top: theme.template?.header.size.main.desktop,
      },
    },
    notify: {
      display: 'flex',
      color: theme.palette.common.white,
      padding: space,
      minHeight: '2.5rem',
      boxSizing: 'border-box',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: `0 0 4px 2px ${theme.palette.grey[300]}`,
      borderRadius: 8,
      margin: space,
      width: `calc(100% - ${space}px})`,
      minWidth: `calc(100% - ${space}px})`,
      maxWidth: `calc(100% - ${space}px})`,

      [theme.breakpoints.up('sm')]: {
        width: '37.5rem',
      },
    },
    icon: {
      width: '2rem',
      height: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      flexGrow: 1,
      fontWeight: theme.typography.fontWeightMediumBold,
      marginLeft: theme.spacing(1),
    },
  });
});

export const useStatusStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      background: theme.palette.primary.main,
    },
    secondary: {
      background: theme.palette.secondary.main,
    },
    error: {
      background: theme.palette.error.main,
    },
    info: {
      background: theme.palette.info.main,
    },
    warning: {
      background: theme.palette.warning.main,
    },
    success: {
      background: theme.palette.success.main,
    },
  })
);
