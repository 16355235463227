import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CompanyDataFragmentDoc, CompanyDataFragment } from '../generated/graphql';
import { getCachedKey } from '../helpers/get-cached-key';

export function useGetCompanyDataFragment() {
  const client = useApolloClient();
  const { companyId } = useParams();

  return client.readFragment<CompanyDataFragment>({
    fragmentName: 'companyData',
    id: getCachedKey('Company', companyId),
    fragment: CompanyDataFragmentDoc,
    variables: { companyId },
  });
}
