import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      height: '0.001rem',
      width: '0.001rem',
      padding: 0,
      margin: 0,
      border: 0,

      '&::selection': {
        color: theme.palette.common.white,
        background: theme.palette.common.white,
      },
    },
    root: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4, 4, 0),
      position: 'relative',
    },
    reduced: {
      padding: 0,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    text: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    toast: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(2),
      background: theme.palette.common.white,
      color: theme.palette.grey[800],
      display: 'inline-flex',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5, 1, 0.5, 0.2),
      fontSize: theme.typography.body2.fontSize,
      borderRadius: 4,
      boxShadow: `0px 0px 4px ${theme.palette.grey[200]}`,
    },
    toastBar: {
      marginRight: theme.spacing(1),
      width: '0.25rem',
      background: theme.palette.success.dark,
      borderRadius: 2,
    },
  })
);
