import { Product } from '../../common/generated/graphql';
import { Item, ParamName } from '../model/analytics-config';

type ItemParams = {
  itemId?: string;
  price?: number;
  itemCategory?: string;
  isOffer?: string;
  quantity?: number;
};

export function mountAnalyticsItem({
  itemId,
  price = 0,
  itemCategory,
  isOffer,
  quantity = 1,
}: ItemParams): Item {
  return {
    [ParamName.ITEM_ID]: itemId,
    [ParamName.PRICE]: price,
    [ParamName.ITEM_CATEGORY]: itemCategory,
    [ParamName.IS_OFFER]: isOffer,
    [ParamName.QUANTITY]: quantity,
  };
}

export function mountAnalyticsItemArray(items?: Product[]): Array<Item> {
  let analyticsItems: Array<Item> = [];

  const mountId = (item: Product) => `${item.numericalId}_${item.totalPrice}`;

  if (items && items.length > 0) {
    const itemsMap = new Map<string, Item>();
    for (let item of items) {
      const id = mountId(item);
      if (item.numericalId && itemsMap.has(id)) {
        const itemInMap = itemsMap.get(id);
        const actualQuantity = itemInMap?.quantity ?? 0;
        const quantity = actualQuantity + 1;
        itemsMap.set(id, { ...itemInMap, quantity });
      } else {
        const newItem = mountAnalyticsItem({
          itemId: item?.numericalId?.toString(),
          price: Number(item?.totalPrice),
          isOffer: item?.offer?.toString(),
          itemCategory: item?.category?.name as string,
          quantity: item.chosenQuantity,
        });
        if (!item.numericalId) {
          analyticsItems.push(newItem);
        } else {
          itemsMap.set(id, newItem);
        }
      }
    }

    return [...analyticsItems, ...Array.from(itemsMap.values())];
  }

  return [];
}
