import { getOnlyDigits } from '../../common/helpers/get-only-digits';
import { UnionCardBrand } from '../../common/model/card-brand';
import { getCardBrand } from '../../common/services/card-brand/card-brand.service';

export function processExpiration(value: string): string {
  const valueNumber = getOnlyDigits(value).substring(0, 4);
  const month = Number(valueNumber.substring(0, 2));
  const year = Number('20' + valueNumber.substring(2, 4));

  const expirationDate = new Date(year, month, 0);
  return expirationDate.toISOString().split('T')[0];
}

export function getShowExpirationField(cardBrandCode: UnionCardBrand): boolean {
  return !isTicket(cardBrandCode);
}

export function getShowBirthdayField(cardBrandCode: UnionCardBrand): boolean {
  return isTicket(cardBrandCode);
}

export function isAmex(cardBrandCode: UnionCardBrand): boolean {
  return cardBrandCode === 'amex';
}

export function getExpirationRegex(): RegExp {
  return new RegExp('^\\d{2}/\\d{2}$');
}

export function getCvvRegex(cvvLength: number): RegExp {
  return new RegExp('^\\d{' + cvvLength + '}$');
}

export async function checkBinCard(bin?: string | null): Promise<boolean> {
  const value = getOnlyDigits(bin ?? '');

  if (value.length > 5) {
    try {
      const response = await getCardBrand(value);
      return Boolean(response?.data.code !== 'Alel0Alimentacao');
    } catch {
      return true;
    }
  } else {
    return true;
  }
}

function isTicket(cardBrandCode: UnionCardBrand): boolean {
  return cardBrandCode === 'ticket';
}
