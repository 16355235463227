import { AppConfig } from '../../../common/model/config';
import { mariaEOBoiTheme } from '../themes/maria-e-o-boi.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const mariaEOBoiConfig: AppConfig = {
  appName: 'maria-eo-boi',
  appTitle: 'Maria e o Boi',
  appDescription: 'Peça aqui no restaurante Maria e o Boi.',
  aggregator: 910,
  brand: 914,
  theme: mariaEOBoiTheme,
  faviconPath: '/favicons/favicon-maria-e-o-boi.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Ipanema
    latitude: -22.986527,
    longitude: -43.202741,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
