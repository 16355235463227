import { LoginSocialMutationVariables, Result } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { encryptText } from '../../helpers/crypto';
import { logError } from '../../helpers/log-error';
import { getEndpoints, getAppConfig } from '../../helpers/configs';
import { logLoginError } from '../../../analytics/helpers/log-login';

export async function loginSocial(
  root: any,
  variables: LoginSocialMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  let success = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';
  const endpoints = getEndpoints();
  const appConfig = getAppConfig();

  try {
    const brandInfoUri = `v2/mobile/brand/${appConfig.brand}/company`;
    const brandInfo = await axios.get(`${endpoints.api}${brandInfoUri}`);

    const encryptedToken = encryptText(variables.input.token, brandInfo.data.reference);

    const loginUri = `v4/mobile/user/login`;
    await axios.post(`${endpoints.api}${loginUri}`, {
      email: variables.input.email,
      credentials: { token: encryptedToken, password: null },
      name: variables.input.name,
      type: 'firebase',
      birthday: null,
    });

    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    logError(error, 'Error in Login Call');
    logLoginError({ errorCode: 79 });
  }

  return { __typename, success, errorMsg };
}
