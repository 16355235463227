import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      paddingTop: theme.spacing(2),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      center: 'center',
    },
    text: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      color: theme.palette.common.black,
    },
    logo: {
      padding: theme.spacing(1, 0, 4),
    },
    empty: {
      display: 'none',
      visibility: 'hidden',
      height: 0,
      overflow: 'hidden',
    },
  })
);
