import { Box } from '@material-ui/core';
import React from 'react';
import { logBeginCheckoutError } from '../../../analytics/helpers/log-begin-checkout';
import RefreshBox from '../../../common/components/refresh-box/refresh-box';
import { useGetOrderCheckErrorsQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';

interface Props {
  orderCheck: () => void;
  loading: boolean;
}

export default function OrderCheckWarning(props: Props): JSX.Element {
  let errorMsg = '';

  const { data, error } = useGetOrderCheckErrorsQuery({
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      logError(err, 'Error executing Get Order Check Errors Query');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 22 });
    },
  });

  if (error) {
    errorMsg = 'Ocorreu um erro na verificação do seu pedido, por favor tente novamente.';
  } else if (data?.orderCheck?.warning && data.orderCheck.warning !== '') {
    // Only display error messages if there is an error or a warning to be displayed.
    errorMsg = data.orderCheck.warning;
  } else if (data?.orderCheck?.error?.message && data.orderCheck.error.message !== '') {
    errorMsg = data?.orderCheck.error.message;
  } else {
    return <Box />;
  }

  return (
    <RefreshBox message={errorMsg} refreshHandler={props.orderCheck} loading={props.loading} />
  );
}
