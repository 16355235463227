import {
  GetOrderCheckExtrasDocument,
  GetOrderCheckExtrasQuery,
  GetSelectedOrderTypeDocument,
  GetSelectedOrderTypeQuery,
  GetSelectedPaymentMethodFromCacheDocument,
  GetSelectedPaymentMethodFromCacheQuery,
  GetUserAddressDocument,
  GetUserAddressQuery,
  OrderExtra,
  OrderItemInput,
  OrderPostRequestMutationVariables,
  OrderPostResult,
  SelectedOrderType,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  PaymentMethod,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { getEndpoints } from '../../helpers/configs';
import { createOrderProductTree } from '../../helpers/create-order-product-tree';
import { encryptCvv } from '../../helpers/crypto';
import { createOrderExtraFields } from '../../helpers/delivery-order-extra-fields';
import { logError } from '../../helpers/log-error';
import { orderValidation } from '../../helpers/order-validation';
import { getSupportedPaymentMethods } from '../../helpers/get-supported-payment-methods';
import { generateCardMaskedNumber } from '../../helpers/generate-card-masked-number';

export async function orderPostRequest(
  root: any,
  variables: OrderPostRequestMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<OrderPostResult> {
  const userAddressQuery = context.cache.readQuery<GetUserAddressQuery>({
    query: GetUserAddressDocument,
  });
  const shoppingCart = getShoppingCart(
    variables.input.companyId,
    context.cache,
    context.getCacheKey
  );
  const selectedOrderType = getSelectedOrderType(context.cache);
  const selectedPaymentMethod = getSelectedPaymentMethod(context.cache);
  const supportedOrderTypes = await getSupportedPaymentMethods(
    context.client,
    variables.input.companyId
  );

  // Stop here if the user hasn't selected everything he needs to post the order..
  const errorMessage = orderValidation(
    shoppingCart,
    selectedOrderType,
    selectedPaymentMethod as any,
    supportedOrderTypes,
    userAddressQuery?.userAddress
  );
  if (errorMessage.length > 0) {
    return createReturnValue(false, null, errorMessage);
  }
  const orderFields = createOrderExtraFields(selectedOrderType!, userAddressQuery?.userAddress);

  const items = createOrderProductTree(
    variables.input.companyId,
    context.cache,
    context.getCacheKey
  );
  const payment = formatPaymentMethod(
    selectedPaymentMethod as PaymentMethod,
    variables.input.cvv ? variables.input.cvv : '',
    shoppingCart!.orderTotal,
    variables.input.brandId,
    context.cache
  );
  const orderExtras = getOrderExtras(context.cache);

  try {
    const orderId = await executeOrderPost(
      variables.input.companyId,
      selectedOrderType as SelectedOrderType,
      orderExtras as OrderExtra[],
      items,
      payment,
      orderFields
    );

    return createReturnValue(true, orderId, '');
  } catch (error) {
    const errorMsg = getErrorMessage(error);
    return createReturnValue(false, null, errorMsg);
  }
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
  });
}

function getSelectedPaymentMethod(cache: InMemoryCache) {
  const paymentMethodQuery = cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });

  return paymentMethodQuery?.selectedPaymentMethod;
}

function getSelectedOrderType(cache: InMemoryCache) {
  const selectedOrderTypeQuery = cache.readQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
  });

  return selectedOrderTypeQuery?.selectedOrderType;
}

function formatPaymentMethod(
  payment: PaymentMethod,
  cvv: string,
  orderTotal: number,
  brandId: number,
  cache: InMemoryCache
) {
  let formattedPayment;

  if (payment.__typename === 'RemoteCard') {
    const encryptedCvv = encryptCvv(cvv, brandId, cache);
    formattedPayment = {
      amount: orderTotal.toFixed(2),
      card: payment.backendId,
      cvv: encryptedCvv,
    };
  } else if (payment.__typename === 'LocalCard') {
    formattedPayment = {
      amount: orderTotal.toFixed(2),
      cvv: '',
      newCard: {
        expiration: payment.cardExpiration !== '' ? payment.cardExpiration : undefined,
        maskedNumber: generateCardMaskedNumber(payment.cardNumber),
        paymentMethod: payment.paymentMethod,
        cardBrand: payment.cardBrand,
        pinValidated: false,
        printedName: payment.cardName,
        singleUse: false,
        status: 'validation-pending',
      },
    };
  } else if (payment.__typename === 'Wallet') {
    formattedPayment = {
      amount: orderTotal.toFixed(2),
      wallet: {
        code: payment.cardBrand,
      },
    };
  } else if (payment.__typename === 'OfflinePayment') {
    formattedPayment = {
      amount: orderTotal.toFixed(2),
      offline: {
        cardBrand: payment.cardBrand,
      },
      cvv: '',
    };
  }

  return formattedPayment;
}

function getOrderExtras(cache: InMemoryCache) {
  const extrasQuery = cache.readQuery<GetOrderCheckExtrasQuery>({
    query: GetOrderCheckExtrasDocument,
  });

  return extrasQuery?.orderCheck?.extras?.map((extra: any) => {
    const newExtra = { ...extra };

    delete newExtra.__typename;

    return newExtra;
  });
}

async function executeOrderPost(
  companyId: number,
  selectedOrderType: SelectedOrderType,
  orderExtras: OrderExtra[],
  items: OrderItemInput[] | undefined,
  payment: any,
  orderFields?: any[]
) {
  const uri = `v3/mobile/company/${companyId}/order`;
  const endpoints = getEndpoints();

  const orderPost = await axios.post(`${endpoints.api}${uri}`, {
    orderType: selectedOrderType.orderType,
    orderFields,
    extras: orderExtras ? orderExtras : [],
    items,
    payment,
  });

  const orderIdArray = (orderPost.data.id as string).split('/');
  const orderId = Number(orderIdArray.pop());

  return orderId;
}

function createReturnValue(
  success: boolean,
  orderId: number | null,
  errorMsg: string | null
): OrderPostResult {
  return {
    __typename: 'OrderPostResult',
    success,
    orderId,
    errorMsg,
  };
}

function getErrorMessage(err: AxiosError) {
  let errorMsg = 'Erro ao enviar o pedido, por favor tente novamente';

  if (err.response && err.response.status >= 400 && err.response.status < 500) {
    console.warn('Error in order POST', err);
    if (err.response.data.error) {
      errorMsg = err.response.data.error.message;
    }
  } else {
    logError(err, 'Error in Order POST Call');
  }

  return errorMsg;
}
