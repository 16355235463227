import { AppConfig } from '../../../common/model/config';
import { goldenSquareTheme } from '../themes/golden-square.theme';
import { ORDER_TYPE_COUNTER } from '../../../common/model/order-types';

export const goldenSquareConfig: AppConfig = {
  appName: 'golden-square',
  appTitle: 'Golden Square',
  appDescription: 'Peça aqui no Golden Square.',
  aggregator: 254,
  brand: 244,
  theme: goldenSquareTheme,
  faviconPath: '/favicons/favicon-golden-square.ico',
  defaultOrderType: ORDER_TYPE_COUNTER,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
