import { Maybe, OrderTypeParameter } from '../../common/generated/graphql';

interface PropsOrderTypes {
  type?: string;
  orderTypes?: Maybe<OrderTypeParameter>[] | null;
}

export function getOrderTypeConfig({ type, orderTypes }: PropsOrderTypes) {
  return orderTypes?.find((orderType) => orderType?.title === type);
}
