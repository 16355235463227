import { AppConfig } from '../../../common/model/config';
import { rioSulTheme } from '../themes/rio-sul.theme';
import { ORDER_TYPE_COUNTER } from '../../../common/model/order-types';

export const shoppingRioSulConfig: AppConfig = {
  appName: 'shopping-rio-sul',
  appTitle: 'Shopping Rio Sul',
  appDescription: 'Peça aqui no Shopping Rio Sul.',
  aggregator: 237,
  brand: 227,
  theme: rioSulTheme,
  faviconPath: '/favicons/favicon-rio-sul.ico',
  defaultOrderType: ORDER_TYPE_COUNTER,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-shopping-rio-sul.png',
  },
};
