import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'end_checkout';

type AnalyticsEndCheckout = {
  brandId: string;
  companyId: string;
  orderId?: string;
};

export function logEndCheckout({ brandId, companyId, orderId }: AnalyticsEndCheckout) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.TRANSACTION_ID]: orderId,
    },
  });
}

export function logEndCheckoutError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
