import { Endpoints } from '../../../common/model/config';

export const onyoAppEndpoints: Endpoints = {
  api: 'https://api.onyo.app/',
  graphql: 'https://graphql.onyo.app/graphql',
  payments: 'https://payments.onyo.app/',
  authDomain: 'user-auth.onyo.app',
  enterpriseUserForm: 'https://enterprise-user.onyo.app/',
};

export const onyoAppStagingEndpoints: Endpoints = {
  api: 'https://api.staging.onyo.app/',
  graphql: 'https://graphql.staging.onyo.app/graphql',
  payments: 'https://payments.staging.onyo.app/',
  authDomain: 'user-auth.onyo.app',
  enterpriseUserForm: 'https://enterprise-user.staging.onyo.app/',
};
