import { AppConfig } from '../../../common/model/config';
import { boulevardRioShopping } from '../themes/boulevard-rio-shopping.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const boulevardRioShoppingConfig: AppConfig = {
  appName: 'boulevard-rio-shopping',
  appTitle: 'Boulevard Rio Shopping',
  appDescription: 'Peça aqui nos restaurantes do Boulevard Rio Shopping.',
  aggregator: 1566,
  brand: 1463,
  theme: boulevardRioShopping,
  faviconPath: '/favicons/favicon-boulevard-rio-shopping.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
