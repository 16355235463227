import {
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
  GetLocalCardDocument,
  GetLocalCardQuery,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function removeLocalCard(
  root: any,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const selectedCard = context.cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });

  const data: any = {
    localCard: null,
  };

  if (
    selectedCard &&
    selectedCard.selectedPaymentMethod &&
    (selectedCard.selectedPaymentMethod.__typename === 'LocalCard' ||
      selectedCard.selectedPaymentMethod.__typename === 'OfflinePayment')
  ) {
    data.selectedPaymentMethod = null;
  }

  context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
    data: { selectedPaymentMethod: data.selectedPaymentMethod },
  });
  context.cache.writeQuery<GetLocalCardQuery>({
    query: GetLocalCardDocument,
    data: { localCard: data.localCard },
  });
}
