import { useCallback, ChangeEvent, useEffect, useState, createRef, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

import { Category, ProductCompanyEdge } from '../../common/generated/graphql';
import {
  UseProductsCategoriesRef,
  CategoryRefMap,
  NavToScrollProps,
} from '../models/use-products-categories-ref-types';

export const useProductsCategoriesRef: UseProductsCategoriesRef = (categories) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme?.breakpoints?.up('md'));
  const headerDeskTopHeight = 0;

  const headerComponent = document.getElementById('ref-company-header');
  const repeatOrderComponent = document.getElementById('ref-company-repeat-order');

  let headerHeightDOM = headerDeskTopHeight;

  const [headerHeight, setHeaderHeight] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const [canScroll, setCanScroll] = useState(true);

  if (!isDesktop && headerComponent) {
    headerHeightDOM = headerComponent?.clientHeight;
  }

  if (repeatOrderComponent) {
    headerHeightDOM += repeatOrderComponent?.clientHeight;
  }

  const categoriesRefMap: CategoryRefMap = categories.reduce(
    (prev: any, cur: { category: Category; products: ProductCompanyEdge[] }) => {
      return {
        ...prev,
        [cur.category.id]: createRef(),
      };
    },
    {}
  );

  const handleChangeCategory = (_: ChangeEvent<{}>, tab: number) => {
    setTabSelected(tab);
  };

  const onClickScroll = useCallback(
    (refId) => {
      let ref = categoriesRefMap[refId];
      if (ref && ref.current) {
        setCanScroll(false);
        window.scrollTo({
          top: ref.current.offsetTop + headerHeight,
          left: 0,
          behavior: 'smooth',
        });
        setTimeout(() => setCanScroll(true), 800);
      }
    },
    [categoriesRefMap, headerHeight]
  );

  const mountNavToScrollProps = (id: string): NavToScrollProps => {
    const tabId = `scrollable-auto-tab-${id}`;
    const panelId = `scrollable-auto-tabpanel-${id}`;

    return {
      tab: {
        id: tabId,
        'aria-controls': panelId,
      },
      panel: {
        id: panelId,
        'aria-labelledby': tabId,
      },
    };
  };

  const handlerScroll = useCallback(
    (e) => {
      if (canScroll && categoriesRefMap && Object.values(categoriesRefMap).length > 0) {
        let refList = Object.values(categoriesRefMap);

        for (let i = 0; i < refList.length; i++) {
          let refItem = refList[i];

          if (tabSelected !== i) {
            let securityMargin = 80;
            const clientReactScreen: ClientRect | undefined =
              refItem?.current?.getBoundingClientRect();
            if (
              clientReactScreen &&
              clientReactScreen.top < securityMargin &&
              clientReactScreen.top > securityMargin - clientReactScreen.height
            ) {
              setTabSelected(i);
              break;
            }
          }
        }
      }
    },
    [canScroll, categoriesRefMap, tabSelected]
  );

  useMemo(() => {
    let heigthDefined = 0;

    if (headerComponent) {
      heigthDefined += headerHeightDOM;
    }

    setHeaderHeight(heigthDefined);
  }, [headerComponent, headerHeightDOM]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);

    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [handlerScroll, headerComponent]);

  return {
    handleChangeCategory,
    onClickScroll,
    categoriesRefMap,
    mountNavToScrollProps,
    tabSelected,
  };
};
