import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { SelectedOrderType } from '../../../../common/generated/graphql';
import OrderTypeExtraHeader from '../order-type-extra-header/order-type-extra-header';

interface Props {
  orderType?: SelectedOrderType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1, 0, 0, 0),
    },
    boldText: {
      fontWeight: 500,
    },
  })
);

export default function OrderTypeFields(props: Props) {
  const classes = useStyles();

  if (!props.orderType?.fieldValues || props.orderType.fieldValues.length === 0) {
    return <Box id='no-fields-root' />;
  }

  return (
    <Box className={classes.root} id='root'>
      <OrderTypeExtraHeader orderType={props.orderType} />
      {props.orderType.fieldValues.map((field) => (
        <Typography variant='body2' key={field?.id}>
          <span className={classes.boldText}>{field?.label}: </span>
          {field?.value}
        </Typography>
      ))}
    </Box>
  );
}
