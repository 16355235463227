import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './company-footer.style';
import { useParams } from 'react-router-dom';
import { CompanyPage } from '../../models/company-page';
import { LogoPedePronto } from '../../../common/components/logo-pede-pronto/logo-pede-pronto';

export function CompanyFooter(): React.ReactElement {
  const classes = useStyles();
  const { pageType } = useParams();

  const isSimpleMenu = pageType === CompanyPage.Simple;

  if (!isSimpleMenu) {
    return <div className={classes.empty} id='company-footer-empty' />;
  }

  return (
    <Box id='company-footer' className={classes.footer}>
      <Typography variant='body2' className={classes.text}>
        Desenvolvido por
      </Typography>
      <Box>
        <LogoPedePronto className={classes.logo} />
      </Box>
    </Box>
  );
}
