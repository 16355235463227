import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { vivendaDoCamaraoTheme } from '../themes/vivenda-do-camarao.theme';

export const vivendaDoCamaraoConfig: AppConfig = {
  appName: 'vivenda-do-camarao',
  appTitle: 'Vivenda do Camarão',
  appDescription: 'Peça aqui na Vivenda do Camarão.',
  aggregator: 1344,
  brand: 5,
  theme: vivendaDoCamaraoTheme,
  faviconPath: '/favicons/favicon-vivenda-do-camarao.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
