import { InMemoryCache } from '@apollo/client';

import { GetDeviceMetadataQuery, GetDeviceMetadataDocument } from '../generated/graphql';

export function getDeviceMetadata(cache: InMemoryCache): string | null | undefined {
  const query = cache.readQuery<GetDeviceMetadataQuery>({
    query: GetDeviceMetadataDocument,
  });
  return query?.deviceMetadata ?? '';
}
