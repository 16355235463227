import { Box } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import GenericTextField from '../../../common/components/generic-text-field/generic-text-field';
import { PaymentParameter } from '../../../common/generated/graphql';
import { GenericInput } from '../../../common/model/generic-input';
import { getShowBirthdayField } from '../../helpers/register-card-helper';
import { UnionCardBrand } from '../../../common/model/card-brand';

export default function CardBirthdayField(props: GenericInput) {
  const { values } = useFormikContext();
  const cardBrand: PaymentParameter = getIn(values, 'cardBrandSelect');
  const showField = getShowBirthdayField(cardBrand.cardBrand?.code! as UnionCardBrand);

  return <Box>{showField && <GenericTextField {...props} />}</Box>;
}
