import { QueryResult } from '@apollo/client';
import { Box, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import 'firebase/analytics';
import firebase from 'firebase/app';
import React, { useCallback, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { logAddShippingInfoError } from '../../../analytics/helpers/log-add-shipping-info';
import NetworkError from '../../../common/components/network-error/network-error';
import OrderTypeFieldsDialog from '../../../common/components/order-type-fields-dialog/order-type-fields-dialog';
import Progress from '../../../common/components/progress/progress';
import {
  Exact,
  GetCompanyOrderTypesQuery,
  OrderFieldParameter,
  OrderTypeParameter,
  SelectedOrderType,
  useGetSelectedOrderTypeQuery,
  useSelectOrderTypeMutation,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { orderFieldValuesToInput } from '../../../common/helpers/order-field-values-to-input';
import {
  getOrderTypeImage,
  getOrderTypeTitle,
  shouldOpenOrderTypeFieldsDialog,
} from '../../../common/helpers/order-type';
import OrderTypeExtraData from '../order-type-extras/order-type-extra-data/order-type-extra-data';
import { useLocation } from 'react-router-dom';

import { DIGITAL_MENU } from '../../../common/model/digital-menu';

interface Props
  extends RouteComponentProps<{ aggregatorId: string; brandId: string; companyId: string }> {
  companyOrderTypes: QueryResult<GetCompanyOrderTypesQuery, Exact<{ companyId: number }>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderTypes: {
      padding: theme.spacing(1, 2),
    },
    orderTypeBtn: {
      padding: theme.spacing(1, 2, 1, 0),
      cursor: 'pointer',
    },
    orderTypeImg: {
      maxWidth: '100%',
      height: 'auto',
    },
    orderTypeImgContainer: {
      width: '3rem',
    },
    orderTypeContainer: {
      width: '4rem',
    },
  })
);

export default withRouter(OrderTypes);

function OrderTypes(props: Props) {
  let content;
  const classes = useStyles();
  const { companyOrderTypes } = props;
  const location = useLocation();
  const isFromDigitalMenu = Boolean(sessionStorage.getItem(DIGITAL_MENU));

  const [openOrderTypeFieldsDialog, setOpenOrderTypeFieldsDialog] =
    useState<OrderTypeParameter | null>(null);

  const selectedOrderTypeQuery = useGetSelectedOrderTypeQuery({
    onError: (err) => {
      logError(err, 'Error executing Get Select Order Type Query');
      logAddShippingInfoError({ errorMessage: err.message, errorCode: 24 });
    },
  });

  const [changeOrderType] = useSelectOrderTypeMutation({
    onError: (err) => {
      logError(err, 'Error executing Select Order Type Mutation');
      logAddShippingInfoError({ errorCode: 25, errorMessage: err.message });
    },
  });

  const onOrderTypeBtnClick = useCallback(
    (orderType: OrderTypeParameter) => {
      if (shouldOpenOrderTypeFieldsDialog(orderType)) {
        setOpenOrderTypeFieldsDialog(orderType);
        firebase.analytics().logEvent('Order_Type_Fields_Input_Opened');
      } else {
        changeOrderType({ variables: { input: { orderType: orderType.title! } } });
      }
    },
    [changeOrderType]
  );

  const onOrderTypeFieldsDialogCancel = useCallback(() => {
    setOpenOrderTypeFieldsDialog(null);
  }, [setOpenOrderTypeFieldsDialog]);

  const onOrderTypeFieldsDialogOk = useCallback(
    (values) => {
      const orderType = openOrderTypeFieldsDialog!;
      setOpenOrderTypeFieldsDialog(null);
      const fieldValues = orderFieldValuesToInput(
        values,
        orderType.orderFields as OrderFieldParameter[]
      );

      changeOrderType({
        variables: { input: { orderType: orderType.title!, fieldValues } },
      });
    },
    [openOrderTypeFieldsDialog, setOpenOrderTypeFieldsDialog, changeOrderType]
  );

  useEffect(() => {
    if (
      companyOrderTypes?.data?.company?.parameter?.orderTypes?.length === 1 &&
      !isFromDigitalMenu
    ) {
      const [orderType] = companyOrderTypes.data.company.parameter.orderTypes;
      if (orderType) {
        changeOrderType({ variables: { input: { orderType: orderType.title! } } });
      }
    }
  }, [changeOrderType, companyOrderTypes, isFromDigitalMenu]);

  if (companyOrderTypes.loading || selectedOrderTypeQuery.loading) {
    content = <Progress />;
  } else if (companyOrderTypes.error || selectedOrderTypeQuery.error) {
    content = (
      <NetworkError
        errorMsg='Erro ao carregar a forma de retirada'
        compact={true}
        retry={companyOrderTypes.refetch}
        error={(companyOrderTypes.error || selectedOrderTypeQuery.error) as Error}
        path={location.pathname}
      />
    );
  } else {
    content = (
      <Box display='flex' alignContent='center' flexWrap='wrap' className={classes.orderTypes}>
        {companyOrderTypes.data!.company!.parameter!.orderTypes!.map((orderType) => (
          <Box
            key={orderType!.id}
            className={classes.orderTypeBtn}
            onClick={onOrderTypeBtnClick.bind(null, orderType!)}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              className={classes.orderTypeContainer}
            >
              <Box className={classes.orderTypeImgContainer} id='order-type-img'>
                <img
                  src={getOrderTypeImgSrc(
                    orderType!.title,
                    selectedOrderTypeQuery.data?.selectedOrderType
                  )}
                  alt=''
                  className={classes.orderTypeImg}
                />
              </Box>
              <Typography variant='caption' align='center'>
                {getOrderTypeTitle(orderType!.title, isFromDigitalMenu)}
              </Typography>
            </Box>
          </Box>
        ))}

        {!isFromDigitalMenu && (
          <OrderTypeExtraData
            orderType={selectedOrderTypeQuery.data?.selectedOrderType as SelectedOrderType}
          />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant='h6'>Selecione o tipo de pedido</Typography>
      <Divider />
      {content}
      <OrderTypeFieldsDialog
        open={openOrderTypeFieldsDialog != null}
        newOrderType={openOrderTypeFieldsDialog!}
        currentOrderType={selectedOrderTypeQuery.data?.selectedOrderType as SelectedOrderType}
        onOkClick={onOrderTypeFieldsDialogOk}
        onCancelClick={onOrderTypeFieldsDialogCancel}
      />
    </Box>
  );
}

function getOrderTypeImgSrc(
  title?: string | null,
  selectedOrderType?: SelectedOrderType | null
): string {
  let imgSrc: string;
  if (selectedOrderType?.orderType === title) {
    imgSrc = getOrderTypeImage(true, title);
  } else {
    imgSrc = getOrderTypeImage(false, title);
  }

  return imgSrc;
}
