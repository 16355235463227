import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  Box,
  ListItem,
  List,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import {
  CartPaymentStatus,
  PaymentParameter,
  useAddOfflinePaymentMutation,
} from '../../../../common/generated/graphql';
import { useStyles } from './payment-offline-options.style';
import { UnionCardBrand } from '../../../../common/model/card-brand';
import SelectCardBrandIcon from '../../../../common/components/select-card-brand-icon/select-card-brand-icon';
import DefaultActions from '../../../../common/components/default-actions/default-actions';
import { useUpdateCartPayment } from '../../../../common/hooks/use-update-cart-payment';

type Payments = Maybe<Array<Maybe<PaymentParameter>>>;

interface Props {
  offlinePayments: Payments;
  hidden: boolean;
  onClose: () => void;
}

export default function PaymentOfflineOptions(props: Props) {
  const classes = useStyles();
  const [addOfflinePayment] = useAddOfflinePaymentMutation();
  const [value, setValue] = React.useState('');
  const { updateCartPayment } = useUpdateCartPayment();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onSelectOfflinePayment = async () => {
    const card = props?.offlinePayments?.find((pay) => pay?.cardBrand?.code === value)?.cardBrand;

    const cardBrand = card?.code as string;
    const cardName = card?.name as string;

    await addOfflinePayment({ variables: { cardBrand, cardName } });
    props.onClose();
    updateCartPayment(CartPaymentStatus.CartPayment);
  };

  const onChange = (code: string) => {
    setValue(code);
  };

  if (props.hidden) {
    return <span id='payment-online-options-empty' className={classes.empty}></span>;
  }

  return (
    <Box className={classes.paymentOffline}>
      <Box className={classes.messageWrap}>
        <Typography
          variant='subtitle2'
          id='payment-dialog-header-message'
          className={classes.message}
          align='center'
        >
          Informamos que este Estabelecimento aceita as seguintes bandeiras em suas maquininhas:
        </Typography>
      </Box>

      <RadioGroup
        classes={{
          root: classes.root,
        }}
        aria-label='offline'
        name='offline'
        value={value}
        onChange={handleChange}
      >
        <List className='payment-parameter-offline-list'>
          {props.offlinePayments?.map((pay) => (
            <ListItem
              id={`offline-option-${pay?.cardBrand?.code}`}
              button
              className={`${classes.item} payment-parameter-offline-list-option`}
              key={pay?.cardBrand?.code}
              onClick={() => onChange(pay?.cardBrand?.code as string)}
            >
              <Box className={classes.wrapIcon}>
                <SelectCardBrandIcon card={pay?.cardBrand?.code as UnionCardBrand} size='large' />
              </Box>
              <Box className={classes.wrapLabel}>
                <FormControlLabel
                  classes={{ root: classes.radio, label: classes.label }}
                  value={pay?.cardBrand?.code}
                  control={<Radio classes={{ root: classes.button }} />}
                  label={pay?.cardBrand?.name}
                />
              </Box>
            </ListItem>
          ))}
        </List>
        <Box className={classes.events}>
          <DefaultActions
            primary={{ event: onSelectOfflinePayment }}
            secondary={{ event: props.onClose }}
          />
        </Box>
      </RadioGroup>
    </Box>
  );
}
