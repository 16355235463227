import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsLogin = {
  method: 'social' | 'google' | 'magic' | 'email' | 'alelovers';
  userId?: string;
};

const event = 'login';

export function logLogin({ method, userId }: AnalyticsLogin) {
  logAnalytics({
    event,
    [BundleName.PROPERTIES]: {
      [ParamName.IS_LOGGED]: 'true',
      [ParamName.METHOD]: method,
      [ParamName.USER_ID]: userId,
    },
  });
}

export function logLoginError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
