import {
  MutationsDecreaseChosenProductQuantityArgs,
  Product,
  ProductChosenQuantityFragmentDoc,
  ProductFragment,
  ProductFragmentDoc,
  ProductPriceFragment,
  ProductPriceFragmentDoc,
  ProductType,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

/**
 * Increases the chosen quantity of a product and of its parent product (if it is a choosable) by 1.
 */
export function decreaseChosenProductQuantity(
  root: any,
  variables: MutationsDecreaseChosenProductQuantityArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  // Get the simple product
  const product = context.cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
  });
  if (!product) {
    console.error(`Product: ${variables.input.productCacheId} not found`);
    return false;
  }

  const updatedProduct = {
    ...product,
    chosenQuantity: product.chosenQuantity - 1,
    scrollToProduct: true,
  };
  if (
    product.productType !== ProductType.Choosable &&
    updatedProduct.chosenQuantity < updatedProduct.minimumChoices!
  ) {
    console.warn('Invalid change. Chosen quantity is less than minimum');
    return false;
  } else if (updatedProduct.chosenQuantity < 0) {
    // Choosables can be in an "Invalid" state.
    console.warn('Invalid change. Chosen quantity is less than 0');
    return false;
  }

  if (variables.input.parentCacheId) {
    const parentUpdateResult = decreaseParentQuantity(
      variables.input.parentCacheId,
      context.cache,
      context.getCacheKey
    );
    if (!parentUpdateResult) {
      return false;
    }
  }

  context.cache.writeFragment({
    fragment: ProductChosenQuantityFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
    data: updatedProduct,
  });

  // Handle menu item.
  if (variables.input.menuItemCacheId && product.productType !== ProductType.Choosable) {
    updateMenuItemPrice(
      product as Product,
      variables.input.menuItemCacheId,
      context.cache,
      context.getCacheKey
    );
  }

  return true;
}

function decreaseParentQuantity(
  parentCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
): boolean {
  const parent = cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
  });

  if (parent && parent.productType === ProductType.Choosable) {
    const updatedParent = { ...parent, chosenQuantity: parent.chosenQuantity - 1 };

    // Choosables can be in an "Invalid" state, so we don't have to check against the min choices
    if (updatedParent.chosenQuantity < 0) {
      console.warn('Invalid change, parent quantity less than 0');
      return false;
    }

    // Write parent changes to cache
    cache.writeFragment({
      fragment: ProductFragmentDoc,
      id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
      data: updatedParent,
    });
  } else {
    console.warn(`Parent not found or is not a choosable`, parentCacheId);
  }

  return true;
}

function updateMenuItemPrice(
  product: Product,
  menuItemCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const menuItem = cache.readFragment<ProductPriceFragment>({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
  });
  if (!menuItem) {
    console.error(`Menu item: ${menuItemCacheId} not found`);
    return;
  }

  // Decrease the menu item total price
  let price = 0;
  if (product.productCompanyByCompanyId && product.productCompanyByCompanyId.price) {
    price = product.productCompanyByCompanyId.price;
  }
  const updatedMenuItem = { ...menuItem, totalPrice: menuItem.totalPrice! - price };

  cache.writeFragment({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
    data: updatedMenuItem,
  });
}
