import { ApolloClient } from '@apollo/client';
import {
  GetCompanyPaymentParamsQuery,
  GetCompanyPaymentParamsDocument,
} from '../generated/graphql';
import { getAppConfig } from './configs';
import { UnionCardBrand } from '../model/card-brand';

export async function getSupportedPaymentMethods(
  client: ApolloClient<any>,
  companyId?: number | null
): Promise<Set<UnionCardBrand>> {
  const config = getAppConfig();

  const supportedPaymentMethods = new Set<UnionCardBrand>();
  if (!companyId) {
    return supportedPaymentMethods;
  } else if (config.featureFlags.isCieloMiniApp) {
    return supportedPaymentMethods.add('cielo_pay');
  }

  const paymentMethods = await client.query<GetCompanyPaymentParamsQuery>({
    query: GetCompanyPaymentParamsDocument,
    variables: { companyId },
  });

  const paymentParams = paymentMethods.data?.company?.parameter?.paymentParameter ?? [];
  const offlinePaymentParams =
    paymentMethods.data?.company?.parameter?.offlinePaymentParameter ?? [];

  for (const param of [...paymentParams, ...offlinePaymentParams]) {
    if (!param?.cardBrand?.code) {
      continue;
    }

    supportedPaymentMethods.add(param.cardBrand.code as UnionCardBrand);
  }

  return supportedPaymentMethods;
}
