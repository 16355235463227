import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Icon from '../../../common/components/icon/icon';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { useStyles } from './list-item-delivery-info.style';
import { ShippingPrice } from '../../../common/generated/graphql';

interface Props {
  additionalDeliveryValue?: ShippingPrice;
}

export default function ListItemDeliveryInfo({ additionalDeliveryValue: shippingPrice }: Props) {
  const classes = useStyles();
  return (
    <Grid item container className={classes.ContentInfo} id='delivery-price-container'>
      <Icon name='flag' />
      <Typography
        id='delivery-price-label'
        component='span'
        variant='body2'
        className={classes.cardText}
      >
        Entrega
      </Typography>
      <Typography
        id='delivery-price-value'
        component='span'
        variant='body2'
        className={clsx(classes.cardText, classes.listItemTextDelivery, {
          [classes.listItemTextDeliveryValue]: Boolean(shippingPrice?.value),
        })}
      >
        {shippingPrice?.value ? formatPrice(shippingPrice?.value) : 'grátis'}
      </Typography>
    </Grid>
  );
}
