import {
  ShoppingCartFragment,
  SelectedOrderType,
  UserAddress,
  PaymentMethod,
} from '../generated/graphql';
import { ORDER_TYPE_DELIVERY } from '../model/order-types';
import { getAppConfig } from './configs';
import { UnionCardBrand } from '../model/card-brand';

export function orderValidation(
  shoppingCart?: ShoppingCartFragment | null,
  selectedOrderType?: SelectedOrderType | null,
  selectedPaymentMethod?: PaymentMethod | null,
  supportedPaymentMethods?: Set<UnionCardBrand> | null,
  userAddress?: UserAddress | null
): string {
  const appConfig = getAppConfig();

  const defaultMessage = 'Você precisa selecionar um meio de pagamento.';

  if (!selectedPaymentMethod) {
    return defaultMessage;
  } else if (
    supportedPaymentMethods &&
    !supportedPaymentMethods.has(selectedPaymentMethod.cardBrand as UnionCardBrand)
  ) {
    return defaultMessage;
  }

  if (!shoppingCart?.products || shoppingCart.products?.length === 0) {
    return 'Você precisa adicionar algum produto no carrinho.';
  }

  if (!selectedOrderType?.orderType || selectedOrderType?.orderType === '') {
    return 'Você precisa selecionar o tipo de pedido.';
  }

  if (
    appConfig.featureFlags.gmapsAddress &&
    selectedOrderType?.orderType === ORDER_TYPE_DELIVERY &&
    !userAddress?.placeId
  ) {
    return 'Você precisa informar o seu endereço.';
  }
  return '';
}
