import 'firebase/analytics';
import firebase from 'firebase/app';
import { getCacheExpiration } from '../get-cache-expiration';
import { ORDER_TYPE_CACHE, SW_CACHE_EXIPIRATION } from '../../model/caches';
import { SelectedOrderType } from '../../generated/graphql';

export async function saveOrderTypeToCache(orderType: SelectedOrderType | null) {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return;
  }

  const cache = await caches.open(ORDER_TYPE_CACHE);

  const resp = new Response(JSON.stringify(orderType), {
    headers: {
      'Content-Type': 'application/json',
      [SW_CACHE_EXIPIRATION]: getCacheExpiration(),
    },
  });
  cache.put(`/order-type`, resp);
}
