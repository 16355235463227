import moment from 'moment';

import { Order } from '../../common/generated/graphql';

export function getMaxPreparationTime(order: Order) {
  const creationTime = getCreationTime(order);
  return moment(creationTime).add(order.preparationTimeMaximum!, 'minutes');
}

export function getCreationTime(order: Order) {
  return moment(new Date(order.creationDatetime!));
}
