import { ThemeOptions } from '@material-ui/core/styles/createTheme';

import { defaultTheme } from '../../config/application/themes/default-theme.theme';
import Color from 'color';
import { SimplePaletteColorOptions } from '@material-ui/core';

export const formatTheme = (theme: ThemeOptions): ThemeOptions => ({
  ...defaultTheme,
  palette: {
    primary: mountPaletteOption(theme.palette?.primary as SimplePaletteColorOptions),
    secondary: mountPaletteOption(theme.palette?.secondary as SimplePaletteColorOptions),
    tertiary: mountPaletteOption(theme.palette?.tertiary as SimplePaletteColorOptions),
    additionalColors: {
      ...defaultTheme.palette?.additionalColors,
      ...theme.palette?.additionalColors,
    },
    success: {
      ...defaultTheme.palette?.success,
      ...theme.palette?.success,
    },
    error: {
      ...defaultTheme.palette?.error,
      ...theme.palette?.error,
    },
    warning: {
      ...defaultTheme.palette?.warning,
      ...theme.palette?.warning,
    },
    info: {
      ...defaultTheme.palette?.info,
      ...theme.palette?.info,
    },
  },
  typography: {
    ...defaultTheme.typography,
    ...theme.typography,
  },
});

export const mountPaletteOption = (
  palette: SimplePaletteColorOptions
): SimplePaletteColorOptions => {
  return {
    ...palette,
    dark: Color(palette.main, 'hex').darken(0.5).hex(),
    darkest: Color(palette.main, 'hex').darken(1).hex(),
    light: Color(palette.main, 'hex').lighten(0.5).hex(),
    lightest: lightestCheck(palette.main, 2),
    superLightest: lightestCheck(palette.main, 3),
  };
};

const lightestCheck = (color: string, light: number): string => {
  let white = '#ffffff';

  if (color === white) return color;

  let whitest = Color(color, 'hex').lighten(light).hex().toLocaleLowerCase();

  return whitest !== white ? whitest : lightestCheck(color, light - 0.1);
};
