import { Box, Button, Container, Typography } from '@material-ui/core';
import 'firebase/analytics';
import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import {
  OrderTypeParameter,
  Product,
  useGetProductDetailsQuery,
  useProductDetailsDialogQuery,
} from '../../../common/generated/graphql';
import { isProductAvailable } from '../../../common/helpers/is-product-available';
import { logError } from '../../../common/helpers/log-error';
import { parseImage } from '../../../common/helpers/parse-image';
import { getProductPrice, getPriceText } from '../../helpers/product-details-helper';
import Choosable from '../choosable/choosable';
import { useStyles } from './product-details.style';
import { useProductQuantity } from '../../hooks/use-product-quantity';
import ChooseQuantity from '../../../common/components/choose-quantity/choose-quantity';
import { useAddToCart } from '../../hooks/use-add-to-cart';
import { getAppConfig } from '../../../common/helpers/configs';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import { getOrderTypeConfig } from '../../../aggregator-home/helpers/get-order-type-config.helper';
import CompanyPreparationTime from '../../../common/components/company-preparation-time/company-preparation-time';
import { ProductDetailsDialog } from '../product-details-dialog/product-details-dialog';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function ProductDetails() {
  const classes = useStyles();
  const { data } = useProductDetailsDialogQuery();
  const { companyId } = useParams<{
    companyId: string;
  }>();
  const location = useLocation();

  const productQuantity = useProductQuantity();
  const companyData = useGetCompanyDataFragment();
  const { featureFlags } = getAppConfig();
  const isDelivery = featureFlags.filterByDeliveryArea;

  let deliveyTypeConfig: OrderTypeParameter | null | undefined;

  if (isDelivery) {
    deliveyTypeConfig = getOrderTypeConfig({
      orderTypes: companyData?.parameter?.orderTypes as OrderTypeParameter[],
      type: 'delivery',
    });
  }

  const { onAddToCartClick } = useAddToCart(data?.productDetailsDialog.product ?? 0);

  const {
    data: productData,
    loading,
    error,
    refetch,
  } = useGetProductDetailsQuery({
    variables: {
      productId: data?.productDetailsDialog.product ?? 0,
      companyId: Number(companyId),
    },
    skip: !data?.productDetailsDialog.product,
    notifyOnNetworkStatusChange: true,
    onError: (err) => logError(err, 'Error executing Get Product Details Query'),
  });

  useMemo(() => {
    if (!data?.productDetailsDialog.active && productQuantity.value !== 1) {
      productQuantity.clearQuantity();
    }
  }, [data, productQuantity]);

  if (loading) {
    return (
      <ProductDetailsDialog active={data?.productDetailsDialog.active}>
        <Progress />
      </ProductDetailsDialog>
    );
  }

  if (error || !productData || !productData.productDetails) {
    return (
      <ProductDetailsDialog active={data?.productDetailsDialog.active}>
        <NetworkError
          errorMsg='Erro ao carregar os produtos'
          retry={refetch}
          error={error as Error}
          path={location.pathname}
        />
      </ProductDetailsDialog>
    );
  }

  let img = parseImage(productData!.productDetails!.images, 'product-detail-horizontal');

  return (
    <ProductDetailsDialog active={data?.productDetailsDialog.active}>
      <Box className={classes.root} id='content-root'>
        <Box className={classes.headerWrap}>
          {img && (
            <Box className={classes.imgWrap} justifyContent='center'>
              <img className={classes.img} src={img} alt='' />
            </Box>
          )}
          <Box className={classes.header}>
            <Typography className={classes.title} variant='h6'>
              {productData.productDetails.name}
            </Typography>
            <Typography className={classes.price} variant='subtitle1' noWrap={true}>
              {getProductPrice(productData.productDetails as Product)}
            </Typography>
            <Typography className={classes.description} variant='caption'>
              {productData.productDetails.fullDescription}
            </Typography>
            <Box className={classes.preparationTime}>
              <CompanyPreparationTime
                variant='caption'
                preparationTime={companyData?.preparationTime}
                additionalPreparationTime={deliveyTypeConfig?.additionalPreparationTime}
                isDelivery={isDelivery}
                iconName='cutlery'
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <>
            {productData.productDetails.subproducts &&
              productData.productDetails.subproducts?.length > 0 && (
                <Box>
                  {productData.productDetails.subproducts
                    .filter((subproduct) => isProductAvailable(subproduct as Product))
                    .map((subproduct) => (
                      <Choosable
                        product={subproduct! as Product}
                        menuItemCacheId={productData!.productDetails!.id}
                        key={subproduct!.id}
                      />
                    ))}
                </Box>
              )}
          </>
        </Box>
      </Box>
      <Box
        className={clsx(classes.buttonPosition, {
          [classes.embededApplicationUnlogged]: featureFlags.embededApplicationUnlogged,
        })}
      >
        <Container className={classes.buttonContainer} id='product-details-order'>
          <ChooseQuantity {...productQuantity} />

          <Button
            id='add-to-cart-btn'
            variant='contained'
            color='secondary'
            disableElevation
            className={classes.buttonSubmit}
            onClick={() =>
              onAddToCartClick(productQuantity.value, productData?.productDetails as Product)
            }
          >
            <span>Adicionar</span>
            <span>
              {getPriceText(productQuantity.value, productData.productDetails?.totalPrice)}
            </span>
          </Button>
        </Container>
      </Box>
    </ProductDetailsDialog>
  );
}
