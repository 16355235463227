import { AppConfig } from '../../../common/model/config';
import { conjuntoNacionalBrasilia } from '../themes/conjunto-nacional.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const conjuntoNacionalBrasiliaConfig: AppConfig = {
  appName: 'conjunto-nacional-brasilia',
  appTitle: 'Shopping Conjunto Nacional Brasília',
  appDescription: 'Peça aqui nos restaurantes do Shopping Conjunto Nacional Brasília.',
  aggregator: 1565,
  brand: 1462,
  theme: conjuntoNacionalBrasilia,
  faviconPath: '/favicons/favicon-shopping-conjunto-nacional.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
