import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    choosableContainer: {
      margin: theme.spacing(2, 0),
    },
    choosable: {
      padding: 0,
    },
    titleWrap: {
      color: theme.palette.primary.dark,
      background: theme.palette.primary.superLightest,
      padding: theme.spacing(2),
      display: 'flex',
      width: '100%',
      borderRadius: '0.25rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    validationError: {
      color: theme.palette.error.dark,
      background: theme.palette.error.superLightest,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    validationErrorText: {
      color: theme.palette.error.dark,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    validationErrorRequired: {
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(2),
    },
    choosableContent: {
      width: '100%',
    },
  })
);
