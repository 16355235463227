import { AppConfig } from '../../../common/model/config';
import { maniaDeChurrascoTheme } from '../themes/mania-de-churrasco.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const maniaDelivery: AppConfig = {
  appName: 'mania-delivery',
  appTitle: 'Mania de Churrasco Delivery',
  appDescription: 'Peça aqui no Mania de Churrasco.',
  aggregator: 890,
  takeOutAggregatorId: 1279,
  brand: 23,
  theme: maniaDeChurrascoTheme,
  faviconPath: '/favicons/favicon-mania-de-churrasco.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
  },
};
