import moment, { Moment } from 'moment';
import {
  WeekedayTimeRuleConnection,
  WeekedayTimeRule,
  ExceptionTimeRuleConnection,
} from '../generated/graphql';

export function getOpeningTimes(
  currentDate: Moment,
  weekdayTimeRules?: WeekedayTimeRuleConnection
): WeekedayTimeRule[] {
  if (!weekdayTimeRules) {
    return [];
  }

  const openingTimes: WeekedayTimeRule[] = [];
  for (const timeRule of weekdayTimeRules.edges) {
    if (!timeRule?.node) {
      continue;
    }

    if (timeRule.node.day === currentDate.get('day')) {
      openingTimes.push(timeRule.node);
    }
  }

  if (openingTimes.length <= 1) {
    return openingTimes;
  }

  const sortedOpeningTimes = openingTimes.sort((a, b) => {
    const aTimeFrom = moment(a.timeFrom, 'HH:mm');
    const bTimeFrom = moment(b.timeFrom, 'HH:mm');

    return aTimeFrom.unix() - bTimeFrom.unix();
  });

  return sortedOpeningTimes;
}

export function hasTimeRuleException(
  currentDate: Moment,
  exceptionTimeRules?: ExceptionTimeRuleConnection
) {
  if (!exceptionTimeRules) {
    return false;
  }

  for (const exception of exceptionTimeRules.edges) {
    if (!exception?.node?.date) {
      continue;
    }

    const exceptionDate = moment(exception.node.date);
    if (currentDate.isSame(exceptionDate, 'day')) {
      return true;
    }
  }

  return false;
}
