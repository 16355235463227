import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexGrow: 1,
    },
    paper: {
      maxWidth: '30rem',
      borderRadius: 18,
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    titleWrap: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      padding: theme.spacing(3, 3, 2),
      textAlign: 'center',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    btnIcon: {
      padding: theme.spacing(2),
      position: 'absolute',
      right: 0,
      top: 0,
    },
    closeIcon: {
      width: 18,
      height: 18,
    },
    content: {
      display: 'flex',
      flexGrow: 1,
    },
  })
);
