import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './progress.style';

interface ProgressProps {
  inner?: boolean;
}

export default function Progress({ inner = false }: ProgressProps) {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress
        size={inner ? 24 : 40}
        id='spinner'
        className={`${classes.progress} ${inner && classes.inner}`}
      />
    </Box>
  );
}
