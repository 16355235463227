import { AppConfig } from '../../../common/model/config';
import { pantanalShoppingTheme } from '../themes/pantanal-shopping.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const pantanalShoppingConfig: AppConfig = {
  appName: 'pantanal-shopping',
  appTitle: 'Pantanal Shopping',
  appDescription: 'Peça aqui no Pantanal Shopping.',
  aggregator: 1563,
  brand: 1460,
  theme: pantanalShoppingTheme,
  faviconPath: '/favicons/favicon-pantanal-shopping.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
