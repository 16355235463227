import { AppConfig } from '../../../common/model/config';
import { patioPaulistaTheme } from '../themes/patio-paulista.theme';
import { ORDER_TYPE_COUNTER } from '../../../common/model/order-types';

export const patioPaulistaConfig: AppConfig = {
  appName: 'golden-paulista',
  appTitle: 'Patio Paulista',
  appDescription: 'Peça aqui no Pátio Paulista.',
  aggregator: 37,
  brand: 37,
  theme: patioPaulistaTheme,
  faviconPath: '/favicons/favicon-onyo.ico',
  defaultOrderType: ORDER_TYPE_COUNTER,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
