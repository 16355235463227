export enum BundleName {
  ECOMMERCE = 'ecommerce',
  ERROR = 'error',
  PROPERTIES = 'properties',
}

export enum ParamName {
  ADDRESS = 'address',
  AGGREGATOR_ID = 'aggregator_id',
  APP_VERSION = 'app_version',
  BANNER_ID = 'banner_id',
  BRAND_ID = 'brand_id',
  BROWSER_NAME = 'browser_name',
  BROWSER_VERSION = 'browser_version',
  CODE = 'code',
  COMPANY_ID = 'company_id',
  COUNT = 'count',
  COUPON = 'coupon',
  DEVICE = 'device',
  ERROR_CODE = 'error_code',
  ERROR_MESSAGE = 'error_message',
  ERROR_TYPE = 'error_type',
  EVENT_NAME = 'event_name',
  IS_LOGGED = 'is_logged',
  IS_OFFER = 'is_offer',
  ITEM_CATEGORY = 'item_category',
  ITEM_CATEGORY_ID = 'item_category_id',
  ITEM_ID = 'item_id',
  ITEMS = 'items',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  METHOD = 'method',
  MOBILE_MODEL = 'mobile_model',
  MOBILE_VENDOR = 'mobile_vendor',
  OS_NAME = 'os_name',
  OS_VERSION = 'os_version',
  PAYMENT_TYPE = 'payment_type',
  PRICE = 'price',
  QUANTITY = 'quantity',
  REFERRAL_ID = 'referral_id',
  RESEND = 'resend',
  SEARCH_TERM = 'search_term',
  SHIPPING = 'shipping',
  SHIPPING_TYPE = 'shipping_type',
  STATIC_MENU = 'static_menu',
  TRANSACTION_ID = 'transaction_id',
  TYPE = 'type',
  USER_ID = 'user_id',
  VALUE = 'value',
  WHITE_LABEL = 'white_label',
}
export interface Item {
  [ParamName.ITEM_ID]?: string;
  [ParamName.PRICE]?: number;
  [ParamName.ITEM_CATEGORY]?: string;
  [ParamName.IS_OFFER]?: string;
  [ParamName.QUANTITY]: number;
}
