import { Box, Container, LinearProgress, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StoreIcon from '@material-ui/icons/Store';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import StandardDialog from '../../../common/components/standard-dialog/standard-dialog';
import {
  useGetOrderStatusLazyQuery,
  useOrderPostRequestMutation,
  useResetShoppingCartMutation,
  GetShoppingCartProductsDocument,
  useGetOrderDataLazyQuery,
  useGetShoppingCartQuery,
  Product,
} from '../../../common/generated/graphql';
import { getOnlyDigits } from '../../../common/helpers/get-only-digits';
import { logError } from '../../../common/helpers/log-error';
import {
  STATUS_BACKEND_RECEIVED,
  STATUS_CANCELED,
  STATUS_PAYMENT_AUTHORIZED,
  STATUS_POS_DENIED,
  STATUS_POS_RECEIVED,
} from '../../../common/model/order-status';
import MissingCardNumberDialog from '../missing-card-number-dialog/missing-card-number-dialog';
import { useLocation } from 'react-router-dom';
import { useStyles } from './countdown.style';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { logPurchase, logPurchaseError } from '../../../analytics/helpers/log-purchase';
import { normalizeTextCompare } from '../../../common/helpers/normalize-text-compare';
import { mountAnalyticsItemArray } from '../../../analytics/helpers/item-utils';
import { logEndCheckout } from '../../../analytics/helpers/log-end-checkout';

import { SEND_MESSAGE_WHATS } from '../../../common/model/digital-menu';

interface Props {
  pollInterval?: number;
}

export default function Countdown(props: Props) {
  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();
  const history = useHistory();
  const params = useParams<{
    aggregatorId: string;
    brandId: string;
    companyId: string;
  }>();
  const location = useLocation<{ cvv: string }>();

  let pollInterval: number = 5000; // default value

  if (props.pollInterval) {
    pollInterval = props.pollInterval;
  }

  let cvv: string | undefined;
  if (location.state) {
    cvv = location.state.cvv;
  }

  // Local state
  const [status, setStatus] = useState('sending-order');
  const [dialogMsg, setDialogMsg] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [openDialogToAskMissingCardNumber, setOpenDialogToAskMissingCardNumber] = useState(false);
  const [missingCardNumber, setMissingCardNumber] = useState<string | null>(null);

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
    history.replace(`/${params.aggregatorId}/${params.brandId}/${params.companyId}/cart`);
  }, [history, params.aggregatorId, params.brandId, params.companyId]);

  // Apollo
  const [orderPost] = useOrderPostRequestMutation({
    onError: (err) => {
      logError(err, 'Error executing Order POST Request Mutation');
      logPurchaseError({ errorCode: 48, errorMessage: err.message });
    },
  });

  const [getOrderStatus, { data: orderStatusData, variables, stopPolling }] =
    useGetOrderStatusLazyQuery({
      pollInterval,
      onError: (err) => {
        logError(err, 'Error executing Get Order Status Lazy Query');
        setDialogTitle('Ops, ocorreu um erro com o seu pedido.');
        setDialogMsg('Erro ao processar o pedido, por favor tente novamente mais tarde.');
        setOpenDialog(true);
        logPurchaseError({ errorMessage: err.message, errorCode: 49 });
      },
    });

  const { data: shoppingCartQuery } = useGetShoppingCartQuery({
    variables: { companyId: Number(params.companyId) },
    fetchPolicy: 'network-only',
  });

  const [getOrderData] = useGetOrderDataLazyQuery({
    onError: (err) => {
      logError(err, 'Error executing Get Order Data Lazy Query');
      logPurchaseError({ errorCode: 50, errorMessage: err.message });
    },
    onCompleted: (data) => {
      if (data.order) {
        const order = data.order;
        const shipping = order?.orderExtra?.find((extra) =>
          normalizeTextCompare(extra?.label)?.includes('entrega')
        )?.value;
        const coupon = order?.orderExtra?.find((extra) =>
          normalizeTextCompare(extra?.key)?.includes('discount')
        )?.label;
        logPurchase({
          brandId: params.brandId,
          companyId: params.companyId,
          transactionId: atob(order.id).substring(6),
          value: Number(order.totalValue),
          shipping: Number(shipping),
          items: mountAnalyticsItemArray(shoppingCartQuery?.shoppingCart?.products as Product[]),
          coupon,
        });
      }
    },
  });

  const [resetCart] = useResetShoppingCartMutation({
    variables: { companyId: Number(params.companyId) },
    refetchQueries: [
      {
        query: GetShoppingCartProductsDocument,
        variables: { companyId: Number(params.companyId) },
      },
    ],
    onError: (err) => {
      logError(err, 'Error executing Reset Shopping Cart Mutation');
      logPurchaseError({ errorMessage: err.message, errorCode: 51 });
    },
  });

  // Missing card number
  const handleMissingCardNumberDialogClose = () => {
    setOpenDialogToAskMissingCardNumber(false);
    history.replace(`/${params.aggregatorId}/${params.brandId}/${params.companyId}/cart`);
  };

  if (
    orderStatusData &&
    orderStatusData.orderStatus.shouldRequestCardNumber &&
    !openDialogToAskMissingCardNumber &&
    !missingCardNumber
  ) {
    stopPolling!();
    setOpenDialogToAskMissingCardNumber(true);
  }

  const onMissingCardNumberSubmit = useCallback(
    (result: { missingCardNumber: string }) => {
      const onlyDigitsMissingCardNumber = getOnlyDigits(result.missingCardNumber);
      setOpenDialogToAskMissingCardNumber(false);
      setMissingCardNumber(onlyDigitsMissingCardNumber);
      getOrderStatus({
        variables: {
          input: {
            orderId: variables!.input.orderId,
            brandId: Number(params.brandId),
            missingCardCvv: cvv,
            missingCardNumber: onlyDigitsMissingCardNumber,
          },
        },
      });
    },
    [setOpenDialogToAskMissingCardNumber, variables, getOrderStatus, params.brandId, cvv]
  );

  // Side effects
  useEffect(() => {
    const executeOrderPost = async () => {
      const result = await orderPost({
        variables: {
          input: {
            brandId: Number(params.brandId),
            companyId: Number(params.companyId),
            cvv,
          },
        },
      });

      if (result && result.data && !result.errors && result.data.orderPostRequest.success) {
        const orderId = result.data.orderPostRequest.orderId!;

        getOrderData({
          variables: {
            orderId,
          },
        });

        getOrderStatus({
          variables: {
            input: {
              orderId,
              brandId: Number(params.brandId),
              missingCardCvv: cvv,
              missingCardNumber: null,
            },
          },
        });
      } else if (result && result.data && !result.errors) {
        setDialogTitle('Ops, ocorreu um erro com o seu pedido.');
        setDialogMsg(result.data.orderPostRequest.errorMsg!);
        setOpenDialog(true);
        logPurchaseError({
          errorCode: 52,
          errorMessage: result.data.orderPostRequest.errorMsg ?? '',
        });
      } else {
        setDialogTitle('Ops, ocorreu um erro com o seu pedido.');
        setDialogMsg('Erro ao processar o pedido, por favor tente novamente mais tarde.');
        setOpenDialog(true);
        logPurchaseError({ errorCode: 53 });
      }
    };

    executeOrderPost();
  }, [cvv, getOrderStatus, orderPost, params.brandId, params.companyId, getOrderData]);

  useEffect(() => {
    if (orderStatusData) {
      switch (orderStatusData.orderStatus.status) {
        case STATUS_CANCELED:
        case STATUS_POS_DENIED:
          setDialogTitle('Ops, ocorreu um erro com o seu pedido.');
          setDialogMsg(orderStatusData.orderStatus.errorMsg!);
          setOpenDialog(true);
          logPurchaseError({ errorMessage: orderStatusData.orderStatus.status, errorCode: 54 });
          break;

        case STATUS_BACKEND_RECEIVED:
        case STATUS_PAYMENT_AUTHORIZED:
        case STATUS_POS_RECEIVED:
          setStatus(orderStatusData.orderStatus.status);
          break;

        default:
          resetCart();
          logEndCheckout({
            brandId: params.brandId,
            companyId: params.companyId,
            orderId: variables?.input.orderId.toString(),
          });
          sessionStorage.setItem(SEND_MESSAGE_WHATS, 'true');
          history.replace(`/${params.aggregatorId}/receipt/${variables!.input.orderId}`);
      }
    }
  }, [
    orderStatusData,
    history,
    params.aggregatorId,
    params.brandId,
    params.companyId,
    resetCart,
    variables,
  ]);

  // Render
  return (
    <Box>
      <Box display='flex' flexDirection='column' className={classes.root}>
        <MainHeader />
        <Container className={`${classes.countdownContainer} ${headerSpace.measures}`}>
          <Typography variant='h6' className={classes.countdownText}>
            AGUARDE, O SEU PEDIDO ESTÁ SENDO ENVIADO PARA A LOJA
          </Typography>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Box>{getStatusIcon(status)}</Box>
            <Typography id='status-text' variant='subtitle2' className={classes.statusText}>
              {getStatusText(status)}
            </Typography>
            <LinearProgress color='primary' className={classes.countdownProgress} />
          </Box>
          <Typography variant='body2' className={classes.countdownText}>
            Por favor mantenha essa tela aberta até que o seu pedido seja confirmado
          </Typography>
        </Container>
      </Box>
      <MissingCardNumberDialog
        open={openDialogToAskMissingCardNumber}
        onOkClick={onMissingCardNumberSubmit}
        onCancelClick={handleMissingCardNumberDialogClose}
      />
      <StandardDialog
        open={openDialog}
        title={dialogTitle}
        text={dialogMsg}
        onCloseClick={onDialogClose}
      />
    </Box>
  );
}

function getStatusText(status: string): string {
  switch (status) {
    case 'sending-order':
      return 'Enviando o pedido';

    case STATUS_BACKEND_RECEIVED:
      return 'Aguardando confirmação do pagamento';

    case STATUS_PAYMENT_AUTHORIZED:
    case STATUS_POS_RECEIVED:
      return 'Pagamento aprovado, aguardando confirmação da loja';
  }

  return '';
}

function getStatusIcon(status: string) {
  switch (status) {
    case 'sending-order':
      return <CloudUploadIcon fontSize='large' />;

    case STATUS_BACKEND_RECEIVED:
      return <AccessTimeIcon fontSize='large' />;

    case STATUS_PAYMENT_AUTHORIZED:
    case STATUS_POS_RECEIVED:
      return <StoreIcon fontSize='large' />;
  }
}
