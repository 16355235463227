import { AppConfig } from '../../../common/model/config';
import { pizzaMakersTheme } from '../themes/pizza-makers.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const pizzaMakersConfig: AppConfig = {
  appName: 'pizza-makers',
  appTitle: 'Pizza Makers',
  appDescription: 'Peça aqui no Pizza Makers.',
  aggregator: 1075,
  brand: 55,
  theme: pizzaMakersTheme,
  faviconPath: '/favicons/favicon-pizza-makers.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
