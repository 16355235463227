import { Product } from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

/**
 * Sets the totalPrice client parameter for each product as they are loaded
 * from the backend
 */
export function setTotalPriceInitialValue(
  root: Product,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
): number {
  let price = 0;

  if (root.totalPrice) {
    price = root.totalPrice;
  } else if (root.productCompanyByCompanyId && root.productCompanyByCompanyId.price) {
    price = root.productCompanyByCompanyId.price;
  }

  return price;
}
