import React, { useCallback, useEffect } from 'react';
import { Box, Typography, Button, Theme, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';
import { logAnalyticsError } from '../../../analytics/helpers/log-analytics-error';

interface Props {
  errorMsg: string;
  retry?: () => void;
  compact?: boolean;
  error: ApolloError | AxiosError | Error | undefined;
  path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
    },
    element: {
      padding: theme.spacing(1, 0),
    },
  })
);

export default function NetworkError(props: Props) {
  const classes = useStyles();

  const { retry } = props;
  const onRetryClick = useCallback(async () => {
    if (retry) {
      try {
        await retry();
      } catch (e) {
        if (process.env.NODE_ENV === 'production') {
          logAnalyticsError({
            event: 'crash',
            errorCode: 86,
            errorMessage: `${props.path} - ${props.error?.message}`,
          });
        }
      }
    }
  }, [props, retry]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      logAnalyticsError({
        event: 'crash',
        errorCode: 85,
        errorMessage: `${props.path} - ${props.error?.message}`,
      });
    }
  }, [props, props.error]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      {!props.compact && (
        <Box className={classes.element}>
          <Typography variant='h5' align='center'>
            Ops,
          </Typography>
        </Box>
      )}
      <Box className={clsx({ [classes.element]: !props.compact })}>
        <Typography align='center'>{props.errorMsg}</Typography>
      </Box>
      {props.retry && (
        <Box className={clsx({ [classes.element]: !props.compact })}>
          <Button
            color='primary'
            onClick={onRetryClick}
            id='retry-btn'
            variant={props.compact ? 'text' : 'contained'}
          >
            Tentar novamente
          </Button>
        </Box>
      )}
    </Box>
  );
}
