import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footerRoot: {
    marginTop: theme.spacing(2),
  },
  footerContainer: {
    alignItems: 'center',
  },
  footerContainerText: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '30%',
      paddingBottom: theme.spacing(0),
    },
  },
  footerTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
  },
}));
