import cloneDeep from 'lodash/clone';

import {
  ResetShoppingCartMutationVariables,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  GetSelectedOrderTypeQuery,
  GetSelectedOrderTypeDocument,
  ShoppingCart,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { saveCartToCache } from '../../browser-cache/shopping-cart/save-cart-to-cache';
import { loadCartCompanyFromCache } from '../../browser-cache/shopping-cart/load-cart-company-from-cache';
import { removeCartFromCache } from '../../browser-cache/shopping-cart/remove-cart-from-cache';
import { removeOrderTypeFromCache } from '../../browser-cache/order-type/remove-order-type-from-cache';

import { SHOPPING_CART_LAST_ORDER_CACHE } from '../../model/caches';

const cacheExpirationMinutes = 43200; //30 dias

export async function resetShoppingCart(
  root: any,
  variables: ResetShoppingCartMutationVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const shoppingCart = getShoppingCart(variables.companyId, context.cache, context.getCacheKey);
  const shoppingCartFullCache = await loadCartCompanyFromCache(variables.companyId);

  if (!shoppingCart) {
    console.error('Could not reset shopping cart for company: ' + variables.companyId);
    return false;
  }

  const originalCart = {
    ...shoppingCart,
    orderTotal: 0,
    totalExtras: 0,
    totalProductsPrice: 0,
    products: [],
  };

  saveCartToCache({
    companyId: variables.companyId,
    shoppingCart: cloneDeep(shoppingCartFullCache as ShoppingCart),
    pathName: 'shopping-cart-last-order',
    cacheName: SHOPPING_CART_LAST_ORDER_CACHE,
    cacheExpirationMinutes,
  });
  writeUpdatedCartToApolloCache(originalCart, context.cache, context.getCacheKey);
  removeCartFromCache(variables.companyId);
  removeOrderTypeFromCache();
  resetOrderType(context.cache);

  return true;
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    id: getCacheKey({ id: btoa(`ShoppingCart:${companyId}`), __typename: 'ShoppingCart' }),
    fragmentName: 'shoppingCart',
  });
}

function writeUpdatedCartToApolloCache(
  cart: ShoppingCartFragment,
  cache: InMemoryCache,
  getCacheKey: any
) {
  cache.writeFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({ id: cart.id, __typename: 'ShoppingCart' }),
    data: cart,
  });
}

function resetOrderType(cache: InMemoryCache) {
  cache.writeQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
    data: { selectedOrderType: null },
  });
}
