import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const indicatorWidth = '2rem';

  return createStyles({
    root: {
      position: 'fixed',
      left: 0,
      top: theme.template?.header.size.home.mobile,
      height: theme.template?.header.size.nav.mobile,
      zIndex: theme.zIndex.appBar,
      width: '100%',

      [theme.breakpoints.between('sm', 'md')]: {
        top: theme.template?.header.size.home.tablet,
        height: theme.template?.header.size.nav.tablet,
        alignItems: 'start',
      },

      [theme.breakpoints.up('md')]: {
        top: theme.template?.header.size.home.desktop,
        height: theme.template?.header.size.nav.desktop,
      },

      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        background: theme.palette.primary.main,
      },
    },
    fixed: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 480,
      },
    },
    tab: {
      textTransform: 'none',
      background: theme.palette.primary.main,
      padding: 0,
      color: theme.palette.common.white,
      opacity: 1,
      '& p': {
        opacity: 0.6,
      },
    },
    indicator: {
      height: '0.25rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: `calc(50% - (${indicatorWidth}/2))`,
        width: indicatorWidth,
        height: '0.25rem',
        background: theme.palette.secondary.main,
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      },
    },
    selected: {
      '& p': {
        opacity: 1,
      },
    },
    label: {
      fontWeight: 'bold',
    },
  });
});
