import { Box, TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { useIsGmapsReadyQuery, UserAddressInput } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { logWarning } from '../../../common/helpers/log-warning';
import { parseAddress } from '../../../common/helpers/parse-address';
import { CompleteUserAddressInput } from '../../model/complete-user-address-input';
import './address-field.scss';

interface Props {
  id: string;
  label?: string;
  name: string;
  submitOnClick?: boolean;
  placeholder?: string;
  className?: any;
  initialValue?: string;
  linkedFields?: CompleteUserAddressInput;
}

export default function AddressField(props: Props) {
  const [field, meta] = useField(props);
  const { setFieldValue, submitForm } = useFormikContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { data } = useIsGmapsReadyQuery({
    onError: (err) => logError(err, 'Error executing Is Gmaps Ready Query'),
  });

  const handleChange = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (!place) {
      return;
    }

    const userAddress = parseUserAddress(place);
    setFieldValue(field.name, userAddress);

    if (props.linkedFields) {
      updateLinkedFields(userAddress, props.linkedFields, setFieldValue);
    }

    if (props.submitOnClick) {
      submitForm();
    }
  }, [setFieldValue, field.name, props.linkedFields, props.submitOnClick, submitForm]);

  useEffect(() => {
    if (!data?.isGmapsReady) {
      return;
    }

    let listener: google.maps.MapsEventListener;

    if (inputRef.current) {
      if (props.initialValue) {
        inputRef.current.value = props.initialValue;
      }

      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, {
        componentRestrictions: {
          country: 'br',
        },
      });

      listener = autocompleteRef.current.addListener('place_changed', handleChange);
    }

    return function cleanup() {
      if (listener) {
        listener.remove();
      }
    };
  }, [handleChange, inputRef, data, props.initialValue]);

  return (
    <Box>
      <TextField
        id={props.id}
        label={props.label}
        type='text'
        placeholder={props.placeholder}
        fullWidth={true}
        inputRef={inputRef}
        color='primary'
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={meta.error && meta.touched ? true : false}
        helperText={meta.error}
      />
    </Box>
  );
}

function parseUserAddress(place: google.maps.places.PlaceResult): UserAddressInput {
  const addressFields = parseAddress(place.formatted_address, place.address_components);
  if (!place.geometry?.location.lat() || !place.geometry?.location.lng()) {
    logWarning('No coordinates for address', place);
  }

  return {
    latitude: place.geometry?.location.lat() ?? 0,
    longitude: place.geometry?.location.lng() ?? 0,
    formattedAddress: place.formatted_address,
    placeId: place.place_id,
    fields: addressFields,
  };
}

function updateLinkedFields(
  userAddress: UserAddressInput,
  linkedFields: CompleteUserAddressInput,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) {
  setFieldValue(linkedFields.addressNumber.name, userAddress.fields?.number, false);
  setFieldValue(linkedFields.addressDistrict.name, userAddress.fields?.district, false);
  setFieldValue(linkedFields.addressCity.name, userAddress.fields?.city, false);
  setFieldValue(linkedFields.addressState.name, userAddress.fields?.state, false);
  setFieldValue(linkedFields.addressZipCode.name, userAddress.fields?.zipCode, false);
}
