import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsSignUp = {
  method: 'social' | 'google' | 'magic' | 'email';
};

const event = 'sign_up';

export function logSignUp({ method }: AnalyticsSignUp) {
  logAnalytics({
    event,
    [BundleName.PROPERTIES]: {
      [ParamName.METHOD]: method,
    },
  });
}

export function logSignUpError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
