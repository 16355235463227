import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { formatCardNumber, getCardNumberRegex } from '../../../common/helpers/format-card-number';

interface Props {
  open: boolean;
  onOkClick: ({ missingCardNumber }: { missingCardNumber: string }) => void;
  onCancelClick: () => void;
}

export default function MissingCardNumberDialog(props: Props) {
  const validationSchema = Yup.object().shape({
    missingCardNumber: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(getCardNumberRegex(), 'O cartão precisa ter entre 13 e 19 dígitos'),
  });

  return (
    <Dialog open={props.open}>
      <Formik
        initialValues={{
          missingCardNumber: '',
        }}
        validationSchema={validationSchema}
        onSubmit={props.onOkClick}
        render={({
          values,
          touched,
          errors,
          isSubmitting,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Por motivos de segurança, redigite o número do seu cartão.
              </DialogContentText>
              <TextField
                autoFocus={true}
                margin='dense'
                id='missingCardNumber'
                name='missingCardNumber'
                label='Número do Cartão'
                type='tel'
                fullWidth={true}
                value={values.missingCardNumber}
                onChange={(event) => {
                  setFieldValue('missingCardNumber', formatCardNumber(event.target.value));
                }}
                onBlur={handleBlur}
                error={
                  touched.missingCardNumber &&
                  errors.missingCardNumber !== undefined &&
                  errors.missingCardNumber !== ''
                }
                helperText={
                  errors.missingCardNumber && touched.missingCardNumber && errors.missingCardNumber
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                id='cancel-btn'
                color='primary'
                disabled={isSubmitting}
                onClick={props.onCancelClick}
              >
                Cancelar
              </Button>
              <Button id='submit-btn' type='submit' color='primary' disabled={isSubmitting}>
                Ok
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}
