import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  OrderFieldParameter,
  OrderFieldType,
  OrderTypeParameter,
  SelectedOrderType,
} from '../../generated/graphql';
import { getPhoneNumberRegex } from '../../helpers/format-phone-number';
import { validateCPF } from '../../helpers/validate-cpf';
import { ORDER_TYPE_CURBSIDE, ORDER_TYPE_TABLE } from '../../model/order-types';
import OrderTypeField from '../order-type-field/order-type-field';

interface Props {
  open: boolean;
  newOrderType: OrderTypeParameter;
  currentOrderType: SelectedOrderType | null;
  onOkClick: (values: { [id: string]: string }) => void;
  onCancelClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
    },
  })
);

export default function OrderTypeFieldsDialog(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!props.newOrderType?.orderFields || props.newOrderType.orderFields.length === 0) {
    return <Box />;
  }

  const initialValues = getInitialValues(props.newOrderType, props.currentOrderType);
  const validationSchema = getValidationSchema(props.newOrderType);

  return (
    <Dialog open={props.open} fullScreen={fullScreen} fullWidth={true} maxWidth='sm'>
      <DialogTitle className={classes.dialogTitle}>
        {getDialogTitle(props.newOrderType.title!)}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={props.onOkClick}
      >
        <Form>
          <DialogContent>
            <DialogContentText id='dialog-title'>
              {props.newOrderType.description}
            </DialogContentText>
            {props.newOrderType.orderFields!.map((orderTypeField) => (
              <OrderTypeField
                key={orderTypeField?.id}
                id={orderTypeField?.id!}
                label={orderTypeField!.field!.label!}
                name={orderTypeField!.field!.position!.toString()}
                orderFieldType={orderTypeField?.field?.fieldType!}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button id='cancel-btn' color='primary' onClick={props.onCancelClick}>
              Cancelar
            </Button>
            <Button id='submit-btn' type='submit' color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

function getDialogTitle(orderType: string): string {
  switch (orderType) {
    case ORDER_TYPE_CURBSIDE:
      return 'Qual é o seu carro?';
    case ORDER_TYPE_TABLE:
      return 'Qual o número da sua mesa?';
    default:
      return 'Onde você está?';
  }
}

function getInitialValues(
  newOrderType: OrderTypeParameter | null,
  currentOrderType: SelectedOrderType | null
) {
  const initialValues: { [id: string]: string } = {};
  if (!newOrderType || !newOrderType.orderFields) {
    return initialValues;
  }

  for (const orderTypeField of newOrderType.orderFields) {
    const label = orderTypeField!.field!.position!.toString();
    initialValues[label] = getInitialValue(newOrderType.title!, orderTypeField!, currentOrderType);
  }

  return initialValues;
}

function getInitialValue(
  newOrderTypeTitle: string,
  newOrderTypeField: OrderFieldParameter,
  currentOrderType: SelectedOrderType | null
): string {
  if (newOrderTypeTitle !== currentOrderType?.orderType || !currentOrderType?.fieldValues) {
    return '';
  }

  let newOrderTypeFieldValue = '';
  for (const currentOrderTypeField of currentOrderType.fieldValues) {
    if (
      currentOrderTypeField &&
      currentOrderTypeField.position === newOrderTypeField.field?.position
    ) {
      newOrderTypeFieldValue = currentOrderTypeField.value;
      break;
    }
  }

  return newOrderTypeFieldValue;
}

function getValidationSchema(orderType: OrderTypeParameter | null) {
  const validationSchemaConstructor: { [id: string]: Yup.Schema<string | number> } = {};
  if (!orderType) {
    return Yup.object().shape(validationSchemaConstructor);
  }

  for (const orderTypeField of orderType.orderFields!) {
    const position = orderTypeField!.field!.position!;
    let validation;
    const type = orderTypeField!.field!.fieldType!;
    switch (type) {
      case OrderFieldType.Text:
        validation = Yup.string();
        break;
      case OrderFieldType.Numeric:
        validation = Yup.number();
        break;
      case OrderFieldType.Telephone:
        validation = Yup.string().matches(
          getPhoneNumberRegex(),
          orderTypeField!.field!.invalidErrorMessage!
        );
        break;
      case OrderFieldType.Cpf:
        validation = Yup.string().test(
          'CPF validation',
          orderTypeField!.field!.invalidErrorMessage!,
          validateCPF
        );
        break;
      default:
        validation = Yup.string();
        console.warn(`Order Type Field ${type} not supported`);
        break;
    }
    if (orderTypeField!.field!.required) {
      validation = validation.required(orderTypeField!.field!.requiredErrorMessage!);
    }
    if (orderTypeField!.field!.maxLength && orderTypeField!.field!.maxLength > 0) {
      const maxLength =
        type === OrderFieldType.Numeric
          ? Number('9'.repeat(orderTypeField!.field!.maxLength))
          : orderTypeField!.field!.maxLength;
      validation = validation.max(maxLength, orderTypeField!.field!.invalidErrorMessage!);
    }
    validationSchemaConstructor[position] = validation as any;
  }
  return Yup.object().shape(validationSchemaConstructor);
}
