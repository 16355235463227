import {
  METHOD_CREDIT_ID,
  METHOD_DEBIT_ID,
  METHOD_VOUCHER_ID,
  METHOD_CREDIT_DEBIT_ID,
  METHOD_WALLET_ID,
  METHOD_COUPON_ID,
  METHOD_REDEEM_ID,
  METHOD_PREPAID_REDEEM_ID,
  METHOD_ONYO_ID,
  METHOD_OFFLINE_ID,
} from '../../common/model/payment-method-choices';
import { PaymentMethod } from '../../common/model/payment-method-types';

type Attr = {
  description: string;
};

const paymentMethodMap: Record<PaymentMethod, Attr> = {
  [METHOD_CREDIT_ID]: { description: 'crédito' },
  [METHOD_DEBIT_ID]: { description: 'débito' },
  [METHOD_VOUCHER_ID]: { description: 'voucher' },
  [METHOD_CREDIT_DEBIT_ID]: { description: 'crédito/ débito' },
  [METHOD_WALLET_ID]: { description: 'carteira' },
  [METHOD_COUPON_ID]: { description: 'cupom' },
  [METHOD_REDEEM_ID]: { description: 'resgate' },
  [METHOD_PREPAID_REDEEM_ID]: { description: 'resgate pré-pago' },
  [METHOD_ONYO_ID]: { description: 'onyo' },
  [METHOD_OFFLINE_ID]: { description: '' }, //já vem a descrição correta - não precisa complementar
};

export function getPaymentMethodDescription(method?: number | null): string {
  const methodData = paymentMethodMap[method as PaymentMethod];
  return methodData?.description ?? '';
}
