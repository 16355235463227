import {
  MutationsSelectCardArgs,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export async function selectCard(
  root: any,
  variables: MutationsSelectCardArgs,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  if (variables.remoteCard) {
    await context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
      query: GetSelectedPaymentMethodFromCacheDocument,
      data: {
        selectedPaymentMethod: {
          ...variables.remoteCard,
          __typename: 'RemoteCard',
        } as any,
      },
    });
  } else if (variables.localCard) {
    await context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
      query: GetSelectedPaymentMethodFromCacheDocument,
      data: {
        selectedPaymentMethod: {
          ...variables.localCard,
          __typename: 'LocalCard',
        } as any,
      },
    });
  }

  return true;
}
