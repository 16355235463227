import { AppConfig } from '../../../common/model/config';
import { pedeProntoTheme } from '../themes/pede-pronto.theme';
import { ORDER_TYPE_TABLE } from '../../../common/model/order-types';

export const allianzParkConfig: AppConfig = {
  appName: 'allianz-park',
  appTitle: 'Pede Pronto',
  appDescription: 'Peça aqui com o Pede Pronto.',
  aggregator: 1883,
  brand: 1779,
  theme: pedeProntoTheme,
  faviconPath: '/favicons/favicon-pede-pronto.ico',
  defaultOrderType: ORDER_TYPE_TABLE,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-pede-pronto.png',
  },
};
