import { Product, ProductValidationStatus } from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function setIsValidInitialValue(
  root: Product,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
): ProductValidationStatus {
  let isValid = ProductValidationStatus.Unknown;
  if (root.isValid) {
    isValid = root.isValid;
  }

  return isValid;
}
