import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.common.white,
      borderRadius: '18px 18px 0 0',
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
      },
    },
    rootSimple: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    appresentation: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 0, 2, 0),
    },
    wrapInfo: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
      flexDirection: 'column',
    },
    img: {
      width: '5.375rem',
      height: 'auto',
      borderRadius: '1.25rem',
    },
    emptyImg: {
      width: '5.375rem',
      height: '5.375rem',
      borderRadius: '1.25rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      color: theme.palette.primary.darkest,
    },
    subtitle: {
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    labelAndIcon: { display: 'flex', alignItems: 'center' },
    icon: { paddingRight: theme.spacing(1) },
    cardList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    card: {
      width: 'auto',
      padding: theme.spacing(0.5, 1),
      margin: 0,
    },
    cardName: {
      marginLeft: theme.spacing(0.5),
    },
    empty: {
      display: 'none',
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
  })
);
