import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { graoEspressoTheme } from '../themes/grao-espresso.theme';

export const graoEspressoConfig: AppConfig = {
  appName: 'grao-espresso',
  appTitle: 'Grão Espresso',
  appDescription: 'Peça aqui no Grão Espresso.',
  aggregator: 138,
  brand: 141,
  theme: graoEspressoTheme,
  faviconPath: '/favicons/favicon-grao-espresso.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
