import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { Box, Container } from '@material-ui/core';

import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import {
  ProductCompanyEdge,
  useGetCompanyDataQuery,
  useProductFilterQuery,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { parseProductCategories } from '../../helpers/parsers';
import { applyFilters } from '../../helpers/apply-filters';
import CompanyContainer from '../company-container/company-container';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { useUpdateProductFilter } from '../../../common/hooks/use-update-product-filter';
import { useProductDetailsDialog } from '../../../common/hooks/use-product-details-dialog';
import {
  logViewItemList,
  logViewItemListError,
} from '../../../analytics/helpers/log-view-item-list';
import { CompanyPage } from '../../models/company-page';

import { redirectAllianz } from './helpers/redirect-allianz';
import { checkHostName } from '../../../common/helpers/check-host-name';
import { useLocation } from 'react-router-dom';

import { useSearchParams } from '../../../common/hooks/use-search-params';

import { DIGITAL_MENU, DIGITAL_MENU_URL } from '../../../common/model/digital-menu';
import { WhiteLabelHostName } from '../../../common/model/white-label-host-name';

export default function CompanyHome(): React.ReactElement {
  const history = useHistory();
  const headerSpace = useMainHeaderSpace();
  const { onCloseProductDetails } = useProductDetailsDialog();
  const { companyId, brandId, pageType, aggregatorId } = useParams<{
    aggregatorId: string;
    companyId: string;
    brandId: string;
    pageType: string;
  }>();
  const location = useLocation();
  const searchParams = useSearchParams();
  const isFromDigitalMenu = searchParams.get(DIGITAL_MENU);
  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);

  const lockMenu = isFromDigitalMenu || isStreamshop;
  const isRedirect = redirectAllianz(brandId);

  const isSimpleMenu = pageType === CompanyPage.Simple;
  const { data: dataFilter } = useProductFilterQuery();
  const { clearProductQuery } = useUpdateProductFilter();
  const digitalMenuPath = `${history.location.pathname}${history.location.search}`;

  const { data, loading, error } = useGetCompanyDataQuery({
    variables: { companyId: Number(companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      logError(err, 'Error executing Get Company Data query');
      logViewItemListError({ errorMessage: err.message, errorCode: 42 });
    },
  });

  useMemo(() => {
    if (!data) {
      return;
    }

    const count = data?.productCompanies?.edges.length ?? 0;

    if (isSimpleMenu) {
      logViewItemList({ brandId, companyId, staticMenu: 'true', count });
    } else {
      logViewItemList({ brandId, companyId, count });
    }
  }, [brandId, companyId, isSimpleMenu, data]);

  useEffect(() => {
    return () => clearProductQuery();
  }, [clearProductQuery]);

  useEffect(() => {
    return () => onCloseProductDetails();
  }, [onCloseProductDetails]);

  useEffect(() => {
    if (lockMenu) {
      isFromDigitalMenu && sessionStorage.setItem(DIGITAL_MENU, isFromDigitalMenu);
      sessionStorage.setItem(DIGITAL_MENU_URL, digitalMenuPath);
    } else {
      sessionStorage.removeItem(DIGITAL_MENU);
    }
  }, [
    digitalMenuPath,
    history.location.pathname,
    history.location.search,
    isFromDigitalMenu,
    isStreamshop,
    lockMenu,
    searchParams,
  ]);

  if (isRedirect) {
    history.replace(`/${aggregatorId}/4663/4584/company`);
  }

  if (loading) {
    return (
      <Container>
        <Progress />
      </Container>
    );
  }

  const handleReloadPage = () => {
    window.location.reload();
  };

  if (error || !data?.company) {
    return (
      <Container>
        <MainHeader />

        <Box className={headerSpace.measures}>
          <NetworkError
            errorMsg='Erro ao carregar as informações do restaurante'
            retry={handleReloadPage}
            error={error as Error}
            path={location.pathname}
          />
        </Box>
      </Container>
    );
  }

  const filteredProducts = applyFilters({
    products: data?.productCompanies?.edges as ProductCompanyEdge[],
    query: dataFilter?.productFilter.query as string,
    minSearch: 3,
  });

  const categories = Array.from(parseProductCategories(filteredProducts).values());

  return <CompanyContainer categories={categories} />;
}
