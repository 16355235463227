import { useState, useEffect } from 'react';
import moment from 'moment';

import { getAppConfig } from '../../../common/helpers/configs';
import { getSharedConfig } from '../../../common/helpers/configs';

import { useIsAuthenticatedQuery, useGetLastOrdersQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';

export default function usePollLastOrders() {
  const { pollInterval } = getSharedConfig();
  const appConf = getAppConfig();
  const [isRefetching, setIsRefetching] = useState(false);
  const [fetchError, setFetchError] = useState<any>(null);

  const { data: isAuthenticatedData, loading: authLoading } = useIsAuthenticatedQuery({
    onError: (err) => logError(err, 'Error executing Is Authenticated Query'),
  });

  const { data, error, startPolling, stopPolling, refetch, loading } = useGetLastOrdersQuery({
    variables: {
      fromDatetime: moment().startOf('day').toISOString(),
      brand: appConf.brand,
      limit: 2,
    },
    skip: !(isAuthenticatedData && isAuthenticatedData.isAuthenticated),
    onError: (err) => logError(err, 'Error executing Get Orders Query'),
  });

  useEffect(() => {
    const refetchAndPoll = async () => {
      try {
        if (isAuthenticatedData?.isAuthenticated) {
          setIsRefetching(true);
          await refetch(); // Force query refetch every time the page is reloaded.
          setIsRefetching(false);

          startPolling(pollInterval);
        } else {
          stopPolling();
        }
      } catch (error) {
        logError(error, 'Error executing Get Orders Query');
        setFetchError(error);
      }
    };
    refetchAndPoll();
  }, [isAuthenticatedData, startPolling, stopPolling, refetch, pollInterval]);

  return {
    data: data?.lastOrders?.edges ?? [],
    error: error || fetchError,
    loading: loading || authLoading,
    isRefetching,
    refetch,
  };
}
