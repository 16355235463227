import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardList: {
      display: 'flex',
      flexWrap: 'wrap',

      '& > *:first-child': {
        paddingLeft: 0,
      },
    },
    card: {
      width: 'auto',
      padding: theme.spacing(0.5, 1),
      margin: 0,
    },
    cardName: {
      marginLeft: theme.spacing(0.5),
    },
    empty: {
      display: 'none',
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
  })
);
