import {
  CreateNewShoppingCartMutationVariables,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { newShoppingCart, addNewCartToApolloCache } from '../../helpers/new-shopping-cart';

export function createNewShoppingCart(
  root: any,
  variables: CreateNewShoppingCartMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): boolean {
  const shoppingCart = context.cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: context.getCacheKey({
      id: btoa(`ShoppingCart:${variables.companyId}`),
      __typename: 'ShoppingCart',
    }),
  });

  if (shoppingCart) {
    return true;
  }

  const newCart = newShoppingCart(variables.companyId);
  addNewCartToApolloCache(newCart, context.cache);
  return true;
}
