import { Product, ProductType } from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

/**
 * Sets the totalPrice client parameter for each product as they are loaded
 * from the backend
 */
export function hasChoosableSubproducts(
  root: Product,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
): boolean {
  let result = false;
  if (root.subproducts && root.subproducts.length > 0 && root.subproducts[0]) {
    result = root.subproducts[0].productType === ProductType.Choosable;
  }

  return result;
}
