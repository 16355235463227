import { AppConfig } from '../../../common/model/config';
import { gulaGulaTheme } from '../themes/gula-gula.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const gulaGulaConfig: AppConfig = {
  appName: 'gula-gula',
  appTitle: 'Gula Gula',
  appDescription: 'Peça aqui no Gula Gula.',
  aggregator: 473,
  brand: 1,
  theme: gulaGulaTheme,
  faviconPath: '/favicons/favicon-gula-gula.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Ipanema
    latitude: -22.986527,
    longitude: -43.202741,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
