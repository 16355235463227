import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  title: string;
  text: string;
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export default function StandardDialog(props: Props) {
  const classes = useStyles();

  return (
    <Dialog open={props.open} onClose={props.onCloseClick}>
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <Button
        id='close-button'
        className={classes.button}
        variant='outlined'
        color='primary'
        onClick={props.onCloseClick}
      >
        Fechar
      </Button>
    </Dialog>
  );
}
