import { useCallback, useState } from 'react';
import { getAppConfig } from '../helpers/configs';

interface UseCheckMiniapp {
  (): {
    isFromMiniapp: boolean;
    checkMiniappList: (appNameList: string[]) => void;
  };
}

export const useCheckMiniapp: UseCheckMiniapp = () => {
  const [isFromMiniapp, setIsFromMiniapp] = useState(false);
  const { appName, featureFlags } = getAppConfig();

  const checkMiniappList = useCallback(
    (appNameList: string[]) => {
      const inTheList = appNameList.includes(appName);
      const { embeddedApplication } = featureFlags;

      setIsFromMiniapp(inTheList && embeddedApplication);
    },
    [appName, featureFlags, setIsFromMiniapp]
  );

  return {
    isFromMiniapp,
    checkMiniappList,
  };
};
