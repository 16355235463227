import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import 'firebase/analytics';
import { ResetPasswordMutationVariables, Result } from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';
import { handleAuthError } from '../../helpers/handle-auth-error';
import { logResetPasswordError } from '../../../analytics/helpers/log-reset-password';

export async function resetPassword(
  root: any,
  variables: ResetPasswordMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    const endpoints = getEndpoints();
    const url = `${endpoints.api}v1/mobile/user/password-reset`;
    await axios.post(url, { email: variables.email });

    return { __typename: 'Result', success: true, errorMsg: null };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAuthError(error, context.cache);
      logError(error, 'Error in Order Check Call');
      logResetPasswordError({
        errorMessage: error.message,
        errorCode: error.response?.status as number,
      });
    }

    return {
      __typename: 'Result',
      success: false,
      errorMsg: 'Error sending password reset',
    };
  }
}
