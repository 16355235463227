import { useHistory, useParams } from 'react-router-dom';

interface ToCountdown {
  (cvv?: string): void;
}

export function useToCountdown(): { toCountdown: ToCountdown } {
  const { push } = useHistory();
  const { aggregatorId, brandId, companyId } = useParams<{
    aggregatorId: string;
    brandId: string;
    companyId: string;
  }>();

  const toCountdown: ToCountdown = (cvv) =>
    cvv
      ? push(`/${aggregatorId}/${brandId}/${companyId}/countdown`, {
          cvv,
        })
      : push(`/${aggregatorId}/${brandId}/${companyId}/countdown`);

  return {
    toCountdown,
  };
}
