import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageWrap: {
      padding: theme.spacing(0, 3),
    },
    message: {
      color: theme.palette.grey[600],
    },
    empty: {
      display: 'none',
    },
    footer: {
      padding: theme.spacing(3),
      margin: theme.spacing(2),
      background: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
  })
);
