import { LogoutMutationVariables } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { resetLocalCache, initLocalCache } from '../../../config/apollo/local-cache';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import * as configs from '../../helpers/configs';
import { clearCachedData } from '../../browser-cache/clear-cached-data';
import axios from 'axios';
import { logError } from '../../helpers/log-error';

export async function logout(
  root: any,
  variables: LogoutMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const uri = `v1/mobile/user/logout`;
  const endpoints = configs.getEndpoints();

  firebase.auth().signOut();
  firebase.analytics().setUserProperties({
    email: null,
  });

  try {
    await axios.post(`${endpoints.api}${uri}`);
  } catch (error) {
    logError(error, 'Error executing logout');
  }

  clearCachedData();
  resetLocalCache();
  initLocalCache();
  sessionStorage.clear();
  localStorage.clear();
}
