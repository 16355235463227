import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    couponItem: {
      background: theme.palette.grey[100],
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(4),
      cursor: 'pointer',
    },
    couponItemTitle: {
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      marginBottom: theme.spacing(2),
    },
    couponItemLabel: {
      ...theme.typography.subtitle1,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      color: theme.palette.text.secondary,
    },
    containerLabel: {
      marginLeft: theme.spacing(0.5),
    },
    couponValidityData: {
      color: theme.palette.error.main,
    },
    couponValue: {
      color: theme.palette.text.secondary,
    },
    couponItemEmpty: {
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightBold as 'bold',
      textTransform: 'initial',
      marginLeft: theme.spacing(1),
    },
    couponIcon: {
      color: theme.palette.grey[400],
    },
  })
);
