import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleText: {
      fontWeight: 500,
    },
    logoContainer: {
      paddingRight: theme.spacing(1),
    },
    logo: {
      borderRadius: '10%',
      width: '3rem',
      height: 'auto',
    },
    button: {
      margin: theme.spacing(1),
    },
    termsContainer: {
      height: '50vh',
    },
    [theme.breakpoints.down('sm')]: {
      termsContainer: {
        height: '85vh',
      },
    },
    termsIframe: {
      border: 'none',
      width: '100%',
      height: '100%',
    },
    title: {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightMediumBold,
      textDecoration: 'underline',
      textAlign: 'center',
    },
    contentText: {
      ...theme.typography.body1,
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      paddingBottom: theme.spacing(3),
    },
    noContent: {
      height: '100%',
    },
    noContentText: {
      ...theme.typography.h6,
    },
  })
);
