import React from 'react';
import { Box, Grid, Fade } from '@material-ui/core';

import { OrderExtra } from '../../../common/generated/graphql';

import OrderCouponItem from '../order-coupon-item/order-coupon-item';

import { useStyles } from './order-coupon.style';

interface Props {
  data?: OrderExtra;
}

export default function OrderCoupon({ data }: Props) {
  const classes = useStyles();

  return (
    <Grid container justifyContent='space-between'>
      <Box id='cart-payment-container' className={classes.container}>
        <Fade in={true} timeout={500}>
          <Box className={classes.OrderCouponBox}>
            <OrderCouponItem data={data} />
          </Box>
        </Fade>
      </Box>
    </Grid>
  );
}
