import { AppConfig } from '../../../common/model/config';
import { shoppingNovaIguacuTheme } from '../themes/shopping-nova-iguacu.theme';
import { ORDER_TYPE_COUNTER } from '../../../common/model/order-types';

export const shoppingNovaIguacuConfig: AppConfig = {
  appName: 'nova-iguacu',
  appTitle: 'Shopping Nova Iguaçú',
  appDescription: 'Peça aqui nos restaurantes do Shopping Nova Iguaçú.',
  aggregator: 1562,
  brand: 1459,
  theme: shoppingNovaIguacuTheme,
  faviconPath: '/favicons/favicon-shopping-nova-iguacu.ico',
  defaultOrderType: ORDER_TYPE_COUNTER,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
