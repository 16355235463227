import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'add_shipping_info';

type AnalyticsShipping = {
  brandId: string;
  companyId: string;
  cartValue?: number;
  shippingType?: string;
  shipping?: number;
};

export function logAddShippingInfo({
  brandId,
  companyId,
  cartValue,
  shippingType,
  shipping,
}: AnalyticsShipping) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.SHIPPING_TYPE]: shippingType,
      [ParamName.SHIPPING]: shipping,
      [ParamName.VALUE]: cartValue,
    },
  });
}

export function logAddShippingInfoError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
