import { UnionAggregatorName } from '../model/aggregator-name';

interface CheckHostName {
  (aggregator?: UnionAggregatorName): boolean;
}

export const checkHostName: CheckHostName = (aggregator = 'pedepronto' as UnionAggregatorName) => {
  const hostname = window.location.hostname.toLowerCase();

  return hostname.includes(aggregator);
};
