import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      background: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      zIndex: theme.zIndex.snackbar,
      height: theme.template?.header.size.main.mobile,

      [theme.breakpoints.between('sm', 'md')]: {
        height: theme.template?.header.size.main.tablet,
        alignItems: 'start',
      },

      [theme.breakpoints.up('md')]: {
        height: theme.template?.header.size.main.desktop,
      },
    },
    iconBtn: {
      paddingLeft: 0,
    },
    container: {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
      },
    },
    nav: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'max-content',
      paddingTop: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
    },
    link: {
      textDecoration: 'none',
    },
    navWithoutBack: {
      paddingLeft: theme.spacing(2),
    },
    icon: {
      color: theme.palette.common.white,
    },
    profile: {
      padding: theme.spacing(2, 0),
    },
  });
});
