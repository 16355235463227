import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: { maxWidth: '80%' },
    subTitle: { fontWeight: theme.typography.fontWeightMediumBold },
    textbold: { fontWeight: theme.typography.fontWeightMediumBold },
    success: {
      color: theme.palette.success.main,
    },
    default: {
      color: theme.palette.text.primary,
    },
  })
);
