import { Product } from '../../common/generated/graphql';
import { formatPrice } from '../../common/helpers/format-price.helper';

/**
 * Retrieves the formatted price for a given product
 * Note, it will always use the first value from the product company.
 */
export function getProductPrice(product?: Product): string {
  if (!product || !product.productCompanyByCompanyId) {
    return '';
  }

  const price = product.productCompanyByCompanyId.price;
  if (!price || price === 0) {
    return '';
  }

  return formatPrice(price);
}

export function getPositiveProductPrice(product?: Product): string {
  const formattedPrice = getProductPrice(product);
  return formattedPrice ? '+ ' + formattedPrice : formattedPrice;
}

export function getPriceText(quantity: number, totalPrice?: number | null): string {
  if (totalPrice) {
    return `R$ ${(totalPrice * quantity).toFixed(2).toString().replace('.', ',')}`;
  }

  return 'R$ 0';
}
