import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.additionalColors.aggregators?.background,
    },
    pane: {
      overflowY: 'scroll',
    },
    bottomTab: {
      boxShadow: '0px -2px 3px -1px rgba(0, 0, 0, .5)',
      borderRadius: '15px 15px 0 0',
      zIndex: 10,
    },
  })
);
