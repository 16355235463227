import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { orlaRioTheme } from '../themes/orla-rio.theme';

export const orlaRioConfig: AppConfig = {
  appName: 'orlario',
  appTitle: 'Orla Rio',
  appDescription: 'Peça aqui na Orla do Rio.',
  aggregator: 1286,
  brand: 1182,
  theme: orlaRioTheme,
  faviconPath: '/favicons/favicon-orla-rio.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Ipanema
    latitude: -22.986527,
    longitude: -43.202741,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
