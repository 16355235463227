import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './layout-desktop.style';

type Props = {
  children: React.ReactNode;
};

export default function LayoutDesktop({ children }: Props) {
  const classes = useStyles();
  return (
    <Grid item md={4} className={classes.root}>
      {children}
    </Grid>
  );
}
