import { ApolloClient, InMemoryCache } from '@apollo/client';
import { clearDeprecatedBrowserCache } from '../../browser-cache/clear-deprecated-browser-cache';

export function clearDeprecatedCache(
  root: any,
  variables: any,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  clearDeprecatedBrowserCache();
  return true;
}
