export const METHOD_CREDIT = 'credit';
export const METHOD_DEBIT = 'debit';
export const METHOD_VOUCHER = 'voucher';
export const METHOD_CREDIT_DEBIT = 'credit-debit';
export const METHOD_WALLET = 'wallet';
export const METHOD_COUPON = 'coupon';
export const METHOD_REDEEM = 'redeem';
export const METHOD_PREPAID_REDEEM = 'prepaid-redeem';
export const METHOD_ONYO = 'onyo';
export const METHOD_OFFLINE = 'offline';

export const METHOD_CREDIT_ID = 1;
export const METHOD_DEBIT_ID = 2;
export const METHOD_VOUCHER_ID = 3;
export const METHOD_CREDIT_DEBIT_ID = 4;
export const METHOD_WALLET_ID = 5;
export const METHOD_COUPON_ID = 6;
export const METHOD_REDEEM_ID = 7;
export const METHOD_PREPAID_REDEEM_ID = 8;
export const METHOD_ONYO_ID = 9;
export const METHOD_OFFLINE_ID = 10;
