import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const onyoTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#f65a02',
    },
    secondary: {
      main: '#520075',
    },
    /**
     * @todo add tertiary do cliente
     */
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#f65a02',
      thirdParty: {
        facebook: {
          main: '#3360ff',
          light: '#809bff',
          dark: '#283b66',
          contrastText: '#ffffff',
        },
      },
    },
  },
};
