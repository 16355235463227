import { addLocalCard } from '../../common/resolvers/add-local-card/add-local-card.resolver';
import { addOfflinePayment } from '../../common/resolvers/add-offline-payment/add-offline-payment.resolver';
import { addToCart } from '../../common/resolvers/add-to-cart/add-to-cart.resolver';
import { customerData } from '../../common/resolvers/customer-data/customer-data.resolver';
import { decreaseChosenProductQuantity } from '../../common/resolvers/decrease-chosen-product-quantity/decrease-chosen-product-quantity.resolver';
import { flipChosenProductQuantity } from '../../common/resolvers/flip-chosen-product-quantity/flip-chosen-product-quantity.resolver';
import { getOrLoadSelectedPaymentMethod } from '../../common/resolvers/get-or-load-selected-payment-method/get-or-load-selected-payment-method.resolver';
import { hasChoosableSubproducts } from '../../common/resolvers/has-choosable-subproducts/has-choosable-subproducts.resolver';
import { increaseChosenProductQuantity } from '../../common/resolvers/increase-chosen-product-quantity/increase-chosen-product-quantity.resolver';
import { loadShoppingCartsFromBrowserCache } from '../../common/resolvers/load-shopping-carts-from-browser-cache/load-shopping-carts-from-browser-cache.resolver';
import { loadSelectedOrderTypeFromBrowserCache } from '../../common/resolvers/load-selected-order-type-from-browser-cache/load-selected-order-type-from-browser-cache.resolver';
import { loginSocial } from '../../common/resolvers/login-social/login-social.resolver';
import { login } from '../../common/resolvers/login/login.resolver';
import { logout } from '../../common/resolvers/logout/logout.resolver';
import { orderCheckRequest } from '../../common/resolvers/order-check-request/order-check-request.resolver';
import { orderPostRequest } from '../../common/resolvers/order-post-request/order-post-request.resolver';
import { orderStatus } from '../../common/resolvers/order-status/order-status.resolver';
import { preOrderPostValidation } from '../../common/resolvers/pre-order-post-validation/pre-order-post-validation.resolver';
import { selectedOrderType } from '../../common/resolvers/selected-order-type/selected-order-type.resolver';
import { removeFromCart } from '../../common/resolvers/remove-from-cart/remove-from-cart.resolver';
import { removeLocalCard } from '../../common/resolvers/remove-local-card/remove-local-card.resolver';
import { removeRemoteCard } from '../../common/resolvers/remove-remote-card/remove-remote-card.resolver';
import { resetPassword } from '../../common/resolvers/reset-password/reset-password.resolver';
import { resetShoppingCart } from '../../common/resolvers/reset-shopping-cart/reset-shopping-cart.resolver';
import { saveShoppingCartToBrowserCache } from '../../common/resolvers/save-shopping-cart-to-browser-cache/save-shopping-cart-to-browser-cache.resolver';
import { selectCard } from '../../common/resolvers/select-card/select-card.resolver';
import { setChosenQuantityInitialValue } from '../../common/resolvers/set-chosen-quantity-initial-value/set-chosen-quantity-initial-value.resolver';
import { setIsValidInitialValue } from '../../common/resolvers/set-is-valid-initial-value/set-is-valid-initial-value.resolver';
import { setScrollToProduct } from '../../common/resolvers/set-scroll-to-product/set-scroll-to-product.resolver';
import { setTotalPriceInitialValue } from '../../common/resolvers/set-total-price-initial-value/set-total-price-initial-value.resolver';
import { shoppingCartProducts } from '../../common/resolvers/shopping-cart-products/shopping-cart-products.resolver';
import { shoppingCart } from '../../common/resolvers/shopping-cart/shopping-cart.resolver';
import { signUp } from '../../common/resolvers/signup/signup.resolver';
import { updateCustomerData } from '../../common/resolvers/update-customer-data/update-customer-data.resolver';
import { validateProduct } from '../../common/resolvers/validate-product/validate-product.resolver';
import { createNewShoppingCart } from '../../common/resolvers/create-new-shoppping-cart/create-new-shopping-cart.resolver';
import { userAddress } from '../../common/resolvers/user-address/user-address.resolver';
import { loadUserAddressFromBrowserCache } from '../../common/resolvers/load-user-address-from-browser-cache/load-user-address-from-browser-cache.resolver';
import { partnerLogin } from '../../common/resolvers/partner-login/partner-login.resolver';
import brandBalance from '../../common/resolvers/brand-balance/brand-balance.resolver';
import cards from '../../common/resolvers/cards/cards.resolver';
import orders from '../../common/resolvers/orders/orders.resolver';
import { deviceMetadata } from '../../common/resolvers/device-metadata/device-metadata.resolver';
import { clearDeprecatedCache } from '../../common/resolvers/clear-deprecated-cache/clear-deprecated-cache.resolver';
import { setGmapsReady } from '../../common/resolvers/set-gmaps-ready/set-gmaps-ready.resolver';
import { magicLinkCreate } from '../../common/resolvers/magic-link-create/magic-link-create.resolver';
import { magicLinkLogin } from '../../common/resolvers/magic-link-login/magic-link-login.resolver';
import { miniAppLogin } from '../../common/resolvers/mini-app-login/mini-app-login.resolver';
import { selectWallet } from '../../common/resolvers/select-wallet/select-wallet.resolver';
import { acceptTermsAndConditions } from '../../common/resolvers/accept-terms-and-conditions/accept-terms-and-conditions.resolver';

export const localResolvers = {
  Query: {
    shoppingCartProducts,
    orderStatus,
    shoppingCart,
    customerData,
    cards,
    orders,
    brandBalance,
  },
  Mutation: {
    flipChosenProductQuantity,
    increaseChosenProductQuantity,
    decreaseChosenProductQuantity,
    validateProduct,
    getOrLoadSelectedPaymentMethod,
    addLocalCard,
    addOfflinePayment,
    removeLocalCard,
    removeRemoteCard,
    selectCard,
    selectWallet,
    addToCart,
    removeFromCart,
    selectedOrderType,
    orderCheckRequest,
    orderPostRequest,
    preOrderPostValidation,
    login,
    loginSocial,
    partnerLogin,
    signUp,
    logout,
    miniAppLogin,
    updateCustomerData,
    resetPassword,
    setScrollToProduct,
    resetShoppingCart,
    loadShoppingCartsFromBrowserCache,
    loadSelectedOrderTypeFromBrowserCache,
    loadUserAddressFromBrowserCache,
    saveShoppingCartToBrowserCache,
    createNewShoppingCart,
    userAddress,
    deviceMetadata,
    clearDeprecatedCache,
    setGmapsReady,
    magicLinkCreate,
    magicLinkLogin,
    acceptTermsAndConditions,
  },
  Product: {
    chosenQuantity: setChosenQuantityInitialValue,
    isValid: setIsValidInitialValue,
    totalPrice: setTotalPriceInitialValue,
    hasChoosableSubproducts,
    scrollToProduct: () => false,
  },
};
