import React, { useCallback } from 'react';
import { Typography, Box } from '@material-ui/core';
import { PaymentParameter } from '../../../../common/generated/graphql';
import { useStyles } from './payment-online-options.style';
import PaymentSimpleOptions from '../payment-simple-options/payment-simple-options';
import PaymentSelectedOptions from '../payment-selected-options/payment-selected-options';
import RemovePaymentDialog from '../remove-payment-dialog/remove-payment-dialog';
import { Maybe } from 'graphql/jsutils/Maybe';

import { DIGITAL_MENU } from '../../../../common/model/digital-menu';

interface Props {
  hidden: boolean;
  hasOffline: boolean | null | undefined;
  onClose: () => void;
  setOffline: (offline: boolean) => void;
  payments: Maybe<Array<Maybe<PaymentParameter>>>;
}

export default function PaymentOnlineOptions(props: Props) {
  const classes = useStyles();
  const [removeDialog, setRemove] = React.useState(false);

  const isFromDigitalMenu = Boolean(sessionStorage.getItem(DIGITAL_MENU));

  const [cardToRemove, setCardToRemove] = React.useState<number | undefined>(undefined);

  const handleCloseRemove = useCallback(() => {
    setRemove(false);
  }, []);

  const handleRemoveCard = useCallback((id: number | undefined) => {
    setRemove(true);
    setCardToRemove(id);
  }, []);

  if (props.hidden) {
    return <span id='payment-online-options-empty' className={classes.empty}></span>;
  }

  return (
    <>
      <Box id='payment-online-options'>
        <Box className={classes.messageWrap}>
          <Typography
            variant='subtitle2'
            id='payment-dialog-header-message'
            className={classes.message}
            align='center'
          >
            Escolha uma das opções aceitas por este estabelecimento
          </Typography>
        </Box>
        <PaymentSelectedOptions closeDialog={props.onClose} openRemoveDialog={handleRemoveCard} />
        <PaymentSimpleOptions
          setOffline={() => props.setOffline(true)}
          payments={props.payments}
          hasOffline={props.hasOffline}
        />
        {props.hasOffline && (
          <Box
            id='payment-dialog-footer-message'
            className={`${classes.messageWrap} ${classes.footer}`}
          >
            {isFromDigitalMenu ? (
              <Typography variant='body2' className={classes.message} align='center'>
                Nós da <b>Pede Pronto</b> estamos empenhados em lhe proporcionar a melhor
                experiência de alimentação durante um jogo de futebol, relaxe e aproveite. Hoje é
                tudo por nossa conta!!!
              </Typography>
            ) : (
              <Typography variant='body2' className={classes.message} align='center'>
                Caso você escolha a opção de <b>"Pagar na Entrega"</b>, poderá haver variações de
                bandeiras aceitas de acordo com o Estabelecimento selecionado.
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <RemovePaymentDialog
        open={removeDialog}
        onClose={handleCloseRemove}
        cardToRemove={cardToRemove as number}
      />
    </>
  );
}
