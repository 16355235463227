import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
    },
    paper: {
      width: '100vw',
      borderRadius: 18,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      position: 'relative',
    },
    close: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      justifyContent: 'flex-end',
    },
    header: {
      padding: theme.spacing(4, 0, 3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    card: {
      paddingBottom: theme.spacing(1),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      marginBottom: theme.spacing(1),
    },
    message: {
      color: theme.palette.grey[600],
    },
    btnIcon: {
      padding: theme.spacing(2),
    },
    closeIcon: {
      width: 18,
      height: 18,
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingBottom: theme.spacing(4),
    },
  })
);
