import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme.palette.common.white,
      paddingBottom: theme.spacing(1),
    },
    logo: {
      width: '3.5rem',
      height: '3.5rem',
      background: theme.palette.primary.main,
    },
    containerText: {
      paddingLeft: theme.spacing(1),
      maxWidth: '90%',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    seeMapContainer: {
      padding: theme.spacing(0, 2),
    },
    seeMap: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  })
);
