import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { logAddShippingInfoError } from '../../analytics/helpers/log-add-shipping-info';
import {
  OrderTypeParameter,
  SelectedOrderTypeFieldValueInput,
  useGetCompanyOrderTypesQuery,
  useSelectOrderTypeMutation,
} from '../../common/generated/graphql';
import { logError } from '../../common/helpers/log-error';
import { useSearchParams } from '../../common/hooks/use-search-params';

export const useSelectTable = () => {
  const ORDER_TYPE = 'table';

  const { companyId } = useParams<{ companyId: string }>();
  const searchParams = useSearchParams();
  const table = searchParams.get(ORDER_TYPE);

  const companyOrderTypes = useGetCompanyOrderTypesQuery({
    variables: { companyId: Number(companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err: Error) => {
      logError(err, 'Error executing Get Company Order Types Query');
      logAddShippingInfoError({ errorCode: 23, errorMessage: err.message });
    },
  });

  const [changeOrderType] = useSelectOrderTypeMutation({
    onError: (err) => {
      logError(err, 'Error executing Select Order Type Mutation');
      logAddShippingInfoError({ errorCode: 25, errorMessage: err.message });
    },
  });

  const applyTableNumber = useCallback(
    (fieldValues: SelectedOrderTypeFieldValueInput) => {
      changeOrderType({
        variables: { input: { orderType: ORDER_TYPE, fieldValues: [fieldValues] } },
      });
    },
    [changeOrderType]
  );

  useEffect(() => {
    if (table) {
      const orderType = companyOrderTypes?.data?.company?.parameter?.orderTypes?.find(
        (item) => item?.title === ORDER_TYPE
      ) as OrderTypeParameter;

      if (orderType) {
        applyTableNumber(mountSelectedOrderType(orderType, table));
      }
    }
  }, [applyTableNumber, companyOrderTypes, table]);
};

const mountSelectedOrderType = (
  orderType: OrderTypeParameter,
  value: string
): SelectedOrderTypeFieldValueInput => {
  const orderField = orderType.orderFields?.length ? orderType.orderFields[0]?.field : undefined;

  return {
    label: orderField?.label ?? '',
    numericalId: orderField?.numericalId ?? 0,
    position: orderField?.position ?? 0,
    value,
  };
};
