import { AppConfig } from '../../../common/model/config';
import { novaAmericaShopping } from '../themes/nova-america-shopping.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const novaAmericaShoppingConfig: AppConfig = {
  appName: 'nova-america-shopping',
  appTitle: 'Shopping Nova América',
  appDescription: 'Peça aqui no Shopping Nova América.',
  aggregator: 256,
  brand: 246,
  theme: novaAmericaShopping,
  faviconPath: '/favicons/nova-america-shopping.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
