import { useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

interface Props {
  className?: string;
  reduced?: boolean;
}

export function LogoPedePronto({ className, reduced = false }: Props) {
  const theme = useTheme();
  const [completeLogo, setCompleteLogo] = useState(true);

  const svgWidth = completeLogo ? 143 : 60;
  const height = 49;

  const updateLogo = useCallback(() => {
    const { width } = window.screen;
    const { sm } = theme.breakpoints.values;
    const reduz = width < sm && reduced;

    setCompleteLogo(!reduz);
  }, [reduced, theme.breakpoints.values]);

  useMemo(() => {
    updateLogo();
  }, [updateLogo]);

  useEffect(() => {
    window.addEventListener('resize', updateLogo);

    return () => {
      window.removeEventListener('resize', updateLogo);
    };
  }, [updateLogo]);

  return (
    <svg
      id='logo-pede-pronto'
      width={svgWidth}
      height={height}
      className={className}
      viewBox={`0 0 ${svgWidth} ${height}`}
    >
      <path
        d='M21.0796 3.27911C9.43802 3.27911 0 12.7388 0 24.407C0 36.0752 9.43802 45.5349 21.0796 45.5349C32.7211 45.5349 42.1591 36.0752 42.1591 24.407C42.1624 12.7388 32.7243 3.27911 21.0796 3.27911ZM21.0796 37.7495C13.728 37.7495 7.76752 31.7754 7.76752 24.407C7.76752 17.0386 13.728 11.0644 21.0796 11.0644C28.4311 11.0644 34.3916 17.0386 34.3916 24.407C34.3916 31.7754 28.4311 37.7495 21.0796 37.7495Z'
        fill='#DE4A82'
      />
      <path
        d='M37.222 22.6415L33.0913 18.726L30.0623 21.8532L37.222 28.6384L50.0401 16.4914L47.0533 13.3251L37.222 22.6415Z'
        fill='#FFDE00'
      />
      {completeLogo && (
        <>
          <path
            d='M92.3293 43.0753C87.9061 43.0753 84.6951 39.9677 84.6951 35.6841C84.6951 31.4006 87.9061 28.293 92.3293 28.293C96.7526 28.293 99.9636 31.4006 99.9636 35.6841C99.9636 39.9677 96.7526 43.0753 92.3293 43.0753ZM92.3293 32.6873C90.5971 32.6873 89.3393 33.9479 89.3393 35.6841C89.3393 37.4204 90.5971 38.681 92.3293 38.681C94.0616 38.681 95.3193 37.4204 95.3193 35.6841C95.3193 33.9479 94.0616 32.6873 92.3293 32.6873Z'
            fill='#DE4A82'
          />
          <path
            d='M135.366 43.0753C130.942 43.0753 127.731 39.9677 127.731 35.6841C127.731 31.4006 130.942 28.293 135.366 28.293C139.789 28.293 143 31.4006 143 35.6841C143 39.9677 139.789 43.0753 135.366 43.0753ZM135.366 32.6873C133.633 32.6873 132.376 33.9479 132.376 35.6841C132.376 37.4204 133.633 38.681 135.366 38.681C137.098 38.681 138.356 37.4204 138.356 35.6841C138.356 33.9479 137.098 32.6873 135.366 32.6873Z'
            fill='#DE4A82'
          />
          <path
            d='M122.977 43.0755C120.442 43.0755 117.419 42.0624 117.419 37.2283V24.5503H122.164V28.6873H127.397L125.762 32.8308H122.167V36.427C122.167 37.9222 122.889 38.6812 124.316 38.6812C124.732 38.6812 125.187 38.5639 125.736 38.3163L127.332 42.0266C126.038 42.7595 124.722 43.0755 122.977 43.0755Z'
            fill='#DE4A82'
          />
          <path
            d='M74.1255 42.6942V28.6871H78.0872L78.8185 30.3908L79.0362 30.0422C79.7805 28.85 80.9375 28.293 82.6763 28.293H84.7725L83.1378 32.8599H81.412C79.878 32.8599 78.8867 33.9675 78.8867 35.6841V42.6975H74.1255V42.6942Z'
            fill='#DE4A82'
          />
          <path
            d='M57.2615 48.5251V28.6872H61.3045L61.9415 30.1693L62.1657 29.912C63.0952 28.8403 64.3562 28.2963 65.913 28.2963C69.5368 28.2963 72.27 31.4658 72.27 35.6712C72.27 39.8928 69.5433 43.0754 65.9292 43.0754C64.4635 43.0754 63.2675 42.6226 62.3737 41.7268L62.0065 41.3587V48.5251H57.2615V48.5251ZM64.808 32.6873C63.0757 32.6873 61.818 33.948 61.818 35.6842C61.818 37.4204 63.0757 38.681 64.808 38.681C66.5402 38.681 67.798 37.4204 67.798 35.6842C67.798 33.948 66.5402 32.6873 64.808 32.6873Z'
            fill='#DE4A82'
          />
          <path
            d='M57.2615 25.9541V6.11947H61.3045L61.9415 7.60162L62.1657 7.34428C63.0952 6.27257 64.3562 5.72858 65.913 5.72858C69.5368 5.72858 72.3773 8.89808 72.3773 13.1035C72.3773 17.3251 69.5433 20.5077 65.9292 20.5077C64.4635 20.5077 63.2675 20.0549 62.3737 19.1591L62.0065 18.791V25.9574H57.2615V25.9541ZM64.808 10.1196C63.0757 10.1196 61.818 11.3803 61.818 13.1165C61.818 14.8527 63.0757 16.1134 64.808 16.1134C66.5403 16.1134 67.798 14.8527 67.798 13.1165C67.798 11.3803 66.5403 10.1196 64.808 10.1196Z'
            fill='#DE4A82'
          />
          <path
            d='M96.1937 20.5077C92.5699 20.5077 89.8367 17.3382 89.8367 13.1329C89.8367 8.9112 92.5634 5.72866 96.1774 5.72866C97.6432 5.72866 98.8392 6.18145 99.7329 7.07725L100.1 7.44534V0.278931H104.845V20.1169H100.802L100.165 18.6347L99.9409 18.892C99.0114 19.9638 97.7504 20.5077 96.1937 20.5077ZM97.2954 10.1197C95.5632 10.1197 94.3054 11.3803 94.3054 13.1166C94.3054 14.8528 95.5632 16.1134 97.2954 16.1134C99.0277 16.1134 100.285 14.8528 100.285 13.1166C100.289 11.3803 99.0309 10.1197 97.2954 10.1197Z'
            fill='#DE4A82'
          />
          <path
            d='M110.633 42.6942V35.1434C110.633 33.6222 109.872 32.7133 108.602 32.7133C107.337 32.7133 106.583 33.6319 106.583 35.1727V42.6942H101.838V28.6871H105.771L106.512 30.4103L106.73 30.0748C107.51 28.8761 108.725 28.293 110.444 28.293C113.395 28.293 115.375 30.3908 115.375 33.5114V42.6942H110.633Z'
            fill='#DE4A82'
          />
          <path
            d='M81.2282 20.5076C76.7562 20.5076 73.7532 17.5889 73.7532 13.2468C73.7532 11.0577 74.4519 9.17819 75.7747 7.81331C77.0942 6.44844 78.9109 5.72528 81.0267 5.72528C83.1067 5.72528 84.891 6.43215 86.1877 7.76771C87.4845 9.10652 88.1767 10.9568 88.1832 13.1165C88.1832 13.5139 88.154 14.1002 88.1117 14.4455H78.3032L78.3844 14.7224C78.7419 15.9146 79.8989 16.6247 81.4784 16.6247C82.1382 16.6247 82.8597 16.5856 83.7925 16.1491L85.8269 19.4262C84.5269 20.133 82.9442 20.5076 81.2282 20.5076ZM81.1209 9.4909C79.8339 9.52673 78.7647 10.302 78.3974 11.4649L78.3097 11.7451H83.8379L83.7859 11.4877C83.5422 10.2955 82.4729 9.49416 81.1274 9.49416L81.1209 9.4909Z'
            fill='#DE4A82'
          />
          <path
            d='M114.039 20.5076C109.567 20.5076 106.564 17.5889 106.564 13.2468C106.564 11.0577 107.263 9.17819 108.586 7.81331C109.905 6.44844 111.722 5.72528 113.838 5.72528C115.918 5.72528 117.702 6.43215 118.999 7.76771C120.296 9.10652 120.988 10.9568 120.994 13.1165C120.994 13.5139 120.965 14.1002 120.923 14.4455H111.114L111.196 14.7224C111.553 15.9146 112.71 16.6247 114.29 16.6247C114.949 16.6247 115.671 16.5856 116.604 16.1491L118.638 19.4262C117.341 20.133 115.759 20.5076 114.039 20.5076ZM113.932 9.4909C112.645 9.52673 111.576 10.302 111.209 11.4649L111.121 11.7451H116.649L116.597 11.4877C116.353 10.2955 115.284 9.49416 113.939 9.49416L113.932 9.4909Z'
            fill='#DE4A82'
          />
        </>
      )}
    </svg>
  );
}
