import 'firebase/analytics';
import moment from 'moment';
import { SW_CACHE_EXIPIRATION } from '../model/caches';

export async function getCachedData(res: Response, path: string, cache: Cache) {
  const expirationDate = res.headers.get(SW_CACHE_EXIPIRATION);
  const expired = isExpired(expirationDate);

  if (expired) {
    await cache.delete(path);
    return;
  }

  return await res.json();
}

function isExpired(dateStr: string | null): boolean {
  if (!dateStr) {
    return true;
  }

  const cacheDate = moment.utc(dateStr);
  if (moment.utc().isAfter(cacheDate)) {
    return true;
  }

  return false;
}
