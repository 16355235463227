import { useCallback } from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { ProductFilterDocument, ProductFilterQuery } from '../generated/graphql';

interface UseUpdateProductFilter {
  (): {
    setProductQuery: (query: string) => void;
    setProductActive: (active: boolean) => void;
    clearProductQuery: () => void;
  };
}

export const useUpdateProductFilter: UseUpdateProductFilter = () => {
  const client = useApolloClient();

  const setProductQuery = useCallback(
    (query) => {
      const data = client.readQuery<ProductFilterQuery>({
        query: ProductFilterDocument,
      });

      updateProductFilter(client, query, data?.productFilter.active as boolean);
    },
    [client]
  );

  const clearProductQuery = useCallback(() => {
    updateProductFilter(client, '', false);
  }, [client]);

  const setProductActive = useCallback(
    (active: boolean) => {
      const data = client.readQuery<ProductFilterQuery>({
        query: ProductFilterDocument,
      });

      updateProductFilter(client, data?.productFilter.query as string, active);
    },
    [client]
  );

  return { setProductQuery, setProductActive, clearProductQuery };
};

const updateProductFilter = (client: ApolloClient<object>, query: string, active: boolean) => {
  client.writeQuery<ProductFilterQuery>({
    query: ProductFilterDocument,
    data: {
      productFilter: {
        __typename: 'ProductFilter',
        query,
        active,
      },
    },
  });
};
