import { ApolloClient, ApolloLink } from '@apollo/client';

import { authLink } from './apollo/auth-link';
import { errorLink } from './apollo/error-link';
import { createHttpLink } from './apollo/http-link';
import { initLocalCache, localCache } from './apollo/local-cache';
import { localResolvers } from './apollo/local-resolvers';
import { createTimeoutLink } from './apollo/timeout-link';
import { retryLink } from './apollo/retry-link';

export function createApolloClient() {
  const httpLink = createHttpLink();
  const timeoutLink = createTimeoutLink();

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([retryLink, authLink, errorLink, timeoutLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV !== 'production',
    cache: localCache,
    resolvers: localResolvers,
    assumeImmutableResults: true,
  });

  initLocalCache();

  return apolloClient;
}
