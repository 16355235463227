import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { useStyles, useStatusStyles } from './notify.style';
import Icon from '../../../common/components/icon/icon';
import { UnionNotifyOption } from '../../model/notify';
import { UnionIconOptions } from '../icon/helpers/type-icon';
import { useNotifyQuery } from '../../generated/graphql';

export default function Notify() {
  const { data } = useNotifyQuery();
  const classes = useStyles();
  const statusClass = useStatusStyles();
  const status = data?.notify.status as UnionNotifyOption;

  return (
    <Slide direction='down' in={data?.notify.active}>
      <Box className={classes.root}>
        <Box className={clsx(classes.notify, statusClass[status])}>
          <Box className={classes.icon}>
            <Icon name={selectIcon(status)} color='light' />
          </Box>
          <Typography className={classes.text} variant='body2'>
            {data?.notify.message}
          </Typography>
        </Box>
      </Box>
    </Slide>
  );
}

function selectIcon(status: UnionNotifyOption): UnionIconOptions {
  switch (status) {
    case 'success':
      return 'check';
    case 'error':
      return 'error';
    case 'info':
      return 'info';
    case 'warning':
      return 'warn';
    default:
      return 'circle';
  }
}
