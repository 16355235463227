import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
    label: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    labelPrimary: {
      color: theme.palette.primary.main,
    },
    menu: {
      zIndex: theme.zIndex.tooltip,
    },
  })
);
