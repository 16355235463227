import { Box, Button, Container } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import StandardDialog from '../../../common/components/standard-dialog/standard-dialog';
import {
  useGetSelectedPaymentMethodFromCacheQuery,
  useGetShoppingCartQuery,
  usePreOrderPostValidationMutation,
} from '../../../common/generated/graphql';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { logError } from '../../../common/helpers/log-error';
import CvvDialog from '../cvv-dialog/cvv-dialog';
import { UnionCardBrand } from '../../../common/model/card-brand';
import { useToCountdown } from '../../hooks/use-to-countdown';
import { useStyles } from './send-order-button.style';
import { FormikErrors } from 'formik';
import { CustomerFormValues } from '../../models/customer-form';
import { logPurchaseError } from '../../../analytics/helpers/log-purchase';

interface Props {
  disableButton: boolean;
  customerHandleOnSubmit: () => void;
  customerHandleValidate: () => Promise<FormikErrors<CustomerFormValues>>;
}

export default function SendOrderButton(props: Props): JSX.Element {
  let orderTotal: string;
  const { toCountdown } = useToCountdown();
  const classes = useStyles();
  const params = useParams<{
    companyId: string;
  }>();
  const [openCvvDialog, setOpenCvvDialog] = useState(false);

  const [openOrderPostErrorDialog, setOpenOrderPostErrorDialog] = useState(false);
  const [orderPostErrorMsg, setOrderPostErrorMsg] = useState('');

  const handleCvvDialogClose = () => {
    setOpenCvvDialog(false);
  };

  const handleOrderPostErrorDialogClose = useCallback(() => {
    setOpenOrderPostErrorDialog(false);
  }, []);

  const { data: paymentMethodData } = useGetSelectedPaymentMethodFromCacheQuery({
    onError: (err) => {
      logError(err, 'Error executing Get Selected Payment Method From Cache Query');
      logPurchaseError({ errorMessage: err.message, errorCode: 26 });
    },
  });

  const {
    data,
    loading,
    error: shoppingCartError,
  } = useGetShoppingCartQuery({
    variables: { companyId: Number(params.companyId) },
    onError: (err) => {
      logError(err, 'Error executing Get Shopping Cart Query');
      logPurchaseError({ errorCode: 27, errorMessage: err.message });
    },
  });

  const [preOrderPostValidation] = usePreOrderPostValidationMutation({
    onError: (err) => {
      setOrderPostErrorMsg('Ocorreu um erro ao validar o seu pedido. Por favor tente novamente.');
      setOpenOrderPostErrorDialog(true);
      logError(err, 'Error executing Pre Order Post Validation Mutation');
      logPurchaseError({ errorMessage: err.message, errorCode: 28 });
    },
    onCompleted: (result) => {
      if (result.preOrderPostValidation.success) {
        let payment: any = paymentMethodData!.selectedPaymentMethod;

        if (payment!.__typename === 'RemoteCard' && payment?.paymentMethod !== 'offline') {
          setOpenCvvDialog(true);
        } else {
          toCountdown();
        }
      } else {
        setOrderPostErrorMsg(result.preOrderPostValidation.errorMsg!);
        setOpenOrderPostErrorDialog(true);
        logPurchaseError({
          errorMessage: result.preOrderPostValidation.errorMsg ?? '',
          errorCode: 29,
        });
      }
    },
  });

  let selectedCardBrand: UnionCardBrand;
  if (paymentMethodData && paymentMethodData.selectedPaymentMethod) {
    selectedCardBrand = paymentMethodData.selectedPaymentMethod.cardBrand as UnionCardBrand;
  }

  // Call order POST
  const onSendOrderClick = useCallback(async () => {
    let validate = await props.customerHandleValidate();
    let isValid = !validate.phoneNumber && !validate.phoneNumber;

    if (isValid) {
      props.customerHandleOnSubmit();
      preOrderPostValidation({
        variables: { companyId: Number(params.companyId) },
      });
    }
  }, [params.companyId, preOrderPostValidation, props]);

  const onCvvSubmit = useCallback(
    ({ cvv }: { cvv: string }) => {
      setOpenCvvDialog(false);
      toCountdown(cvv);
    },
    [toCountdown]
  );

  if (
    !data ||
    loading ||
    shoppingCartError ||
    !data.shoppingCart ||
    data.shoppingCart.orderTotal === 0
  ) {
    orderTotal = 'R$ 0,00';
  } else {
    orderTotal = formatPrice(data.shoppingCart.orderTotal);
  }

  return (
    <Box>
      <Container className={classes.root}>
        <Button
          id='send-order-btn'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={onSendOrderClick}
          disabled={props.disableButton}
        >
          Enviar pedido por {orderTotal}
        </Button>
      </Container>

      <CvvDialog
        open={openCvvDialog}
        cardBrand={selectedCardBrand}
        onOkClick={onCvvSubmit}
        onCancelClick={handleCvvDialogClose}
      />

      <StandardDialog
        open={openOrderPostErrorDialog}
        onCloseClick={handleOrderPostErrorDialogClose}
        title=''
        text={orderPostErrorMsg}
      />
    </Box>
  );
}
