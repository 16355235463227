import {
  ORDER_TYPE_COUNTER,
  ORDER_TYPE_TO_GO,
  ORDER_TYPE_ROOM_SERVICE,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_TABLE,
  ORDER_TYPE_COUPON,
  ORDER_TYPE_CURBSIDE,
  ORDER_TYPE_TRUCK_STOP,
  ORDER_TYPE_EVENT,
  ORDER_TYPE_TABLE_DIGITAL_MENU,
} from '../model/order-types';
import { OrderTypeParameter } from '../generated/graphql';
import { getAppConfig } from './configs';

export function getOrderTypeImage(isSelected: boolean, title?: string | null): string {
  if (isSelected) {
    switch (title) {
      case ORDER_TYPE_COUNTER:
        return '/order-types/ic_order_type_cutlerly_selected.svg';
      case ORDER_TYPE_TO_GO:
        return '/order-types/ic_order_type_bag_selected.svg';
      case ORDER_TYPE_DELIVERY:
        return '/order-types/ic_order_type_delivery_selected.svg';
      case ORDER_TYPE_ROOM_SERVICE:
        return '/order-types/ic_order_type_room_service_selected.svg';
      case ORDER_TYPE_TRUCK_STOP:
        return '/order-types/ic_order_type_room_service_selected.svg';
      case ORDER_TYPE_TABLE:
        return '/order-types/ic_order_type_table_selected.svg';
      case ORDER_TYPE_COUPON:
        return '/order-types/ic_order_type_bag_selected.svg';
      case ORDER_TYPE_CURBSIDE:
        return '/order-types/ic_order_type_drive_thru_selected.svg';
      case ORDER_TYPE_EVENT:
        return '/order-types/ic_order_type_event_selected.svg';
      default:
        return '/order-types/ic_order_type_cutlerly_selected.svg';
    }
  } else {
    switch (title) {
      case ORDER_TYPE_COUNTER:
        return '/order-types/ic_order_type_cutlerly.svg';
      case ORDER_TYPE_TO_GO:
        return '/order-types/ic_order_type_bag.svg';
      case ORDER_TYPE_DELIVERY:
        return '/order-types/ic_order_type_delivery.svg';
      case ORDER_TYPE_ROOM_SERVICE:
        return '/order-types/ic_order_type_room_service.svg';
      case ORDER_TYPE_TRUCK_STOP:
        return '/order-types/ic_order_type_room_service.svg';
      case ORDER_TYPE_TABLE:
        return '/order-types/ic_order_type_table.svg';
      case ORDER_TYPE_COUPON:
        return '/order-types/ic_order_type_bag.svg';
      case ORDER_TYPE_CURBSIDE:
        return '/order-types/ic_order_type_drive_thru.svg';
      case ORDER_TYPE_EVENT:
        return '/order-types/ic_order_type_event.svg';
      default:
        return '/order-types/ic_order_type_cutlerly.svg';
    }
  }
}

export function getOrderTypeTitle(title?: string | null, isFromDigitalMenu = false): string {
  if (title === ORDER_TYPE_TABLE && isFromDigitalMenu) {
    title = ORDER_TYPE_TABLE_DIGITAL_MENU;
  }

  switch (title) {
    case ORDER_TYPE_COUNTER:
      return 'Retirar na bandeja';
    case ORDER_TYPE_TO_GO:
      return 'Levar pra viagem';
    case ORDER_TYPE_DELIVERY:
      return 'Delivery';
    case ORDER_TYPE_ROOM_SERVICE:
      return 'Room Service';
    case ORDER_TYPE_TRUCK_STOP:
      return 'Casa do Caminhoneiro';
    case ORDER_TYPE_TABLE:
      return 'Comer no restaurante';
    case ORDER_TYPE_TABLE_DIGITAL_MENU:
      return 'Comer na cadeira';
    case ORDER_TYPE_COUPON:
      return 'Na Loja';
    case ORDER_TYPE_CURBSIDE:
      return 'Drive Thru';
    case ORDER_TYPE_EVENT:
      return 'Retirar no local';
    default:
      return 'Retirar na loja';
  }
}

export function shouldOpenOrderTypeFieldsDialog(orderTypeParam: OrderTypeParameter): boolean {
  const useGmapsAddress = getAppConfig().featureFlags.gmapsAddress;

  // Ignore delivery if we are using Google Maps to get the user address
  if (useGmapsAddress && orderTypeParam.title === ORDER_TYPE_DELIVERY) {
    return false;
  } else if (orderTypeParam.orderFields && orderTypeParam.orderFields.length > 0) {
    return true;
  }

  return false;
}
