import {
  SaveShoppingCartToBrowserCacheMutationVariables,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { saveCartToCache } from '../../browser-cache/shopping-cart/save-cart-to-cache';

export function saveShoppingCartToBrowserCache(
  root: any,
  variables: SaveShoppingCartToBrowserCacheMutationVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const shoppingCart = getShoppingCart(variables.companyId, context.cache, context.getCacheKey);
  if (!shoppingCart) {
    return false;
  }

  const productsClone = cloneProductsArray(
    shoppingCart.products,
    context.cache,
    context.getCacheKey
  );
  const shoppingCartClone = cloneDeepWith(shoppingCart, (value, key) => {
    if (key === 'totalExtras') {
      return 0;
    } else if (key === 'orderTotal') {
      return shoppingCart.totalProductsPrice;
    } else if (key === 'products') {
      return productsClone;
    }
  });

  saveCartToCache({ companyId: variables.companyId, shoppingCart: shoppingCartClone });

  return true;
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({ id: btoa(`ShoppingCart:${companyId}`), __typename: 'ShoppingCart' }),
  });
}

function cloneProductsArray(
  products: ProductAndSubproductsFragment[],
  cache: InMemoryCache,
  getCacheKey: any
) {
  const productsClone: ProductAndSubproductsFragment[] = [];

  for (const product of products) {
    const clone = cloneProduct(product.id, cache, getCacheKey);
    if (clone) {
      productsClone.push(clone);
    }
  }

  return productsClone;
}

function cloneProduct(productId: string, cache: InMemoryCache, getCacheKey: any) {
  const product = getProduct(productId, cache, getCacheKey);
  if (!product) {
    return;
  }

  const subproducts = cloneSubproducts(product, cache, getCacheKey);

  const clone = cloneDeepWith(product, (value, key) => {
    if (key === 'subproducts') {
      return subproducts;
    }
  });

  return clone;
}

function getProduct(productId: string, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ProductAndSubproductsFragment>({
    fragment: ProductAndSubproductsFragmentDoc,
    fragmentName: 'productAndSubproducts',
    id: getCacheKey({ id: productId, __typename: 'Product' }),
  });
}

function cloneSubproducts(
  product: ProductAndSubproductsFragment,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const subproducts: ProductAndSubproductsFragment[] = [];
  if (product.subproducts) {
    for (const subproductFrag of product.subproducts) {
      if (!subproductFrag) {
        continue;
      }

      const subproduct = cloneProduct(subproductFrag.id, cache, getCacheKey);
      if (subproduct) {
        subproducts.push(subproduct);
      }
    }
  }

  return subproducts;
}
