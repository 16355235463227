import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsInitialization = {
  userId?: string;
  isLogged?: 'true' | 'false';
};

const event = 'initialization';

export function logInitialization({ userId, isLogged = 'false' }: AnalyticsInitialization) {
  const configs = getAppConfig();
  logAnalytics({
    event,
    [BundleName.PROPERTIES]: {
      [ParamName.APP_VERSION]: process.env.REACT_APP_VERSION,
      [ParamName.BROWSER_NAME]: browserName,
      [ParamName.BROWSER_VERSION]: browserVersion,
      [ParamName.DEVICE]: deviceType,
      [ParamName.IS_LOGGED]: isLogged,
      [ParamName.MOBILE_MODEL]: mobileModel,
      [ParamName.MOBILE_VENDOR]: mobileVendor,
      [ParamName.OS_NAME]: osName,
      [ParamName.OS_VERSION]: osVersion,
      [ParamName.USER_ID]: userId,
      [ParamName.WHITE_LABEL]: configs.appName.replace('-', ''),
      [ParamName.LATITUDE]: configs.defaultLocation?.latitude,
      [ParamName.LONGITUDE]: configs.defaultLocation?.longitude,
    },
  });
}

export function logInitializationError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
