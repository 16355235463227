import React, { useCallback } from 'react';
import {
  Container,
  Box,
  Button,
  Typography,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    errorImg: {
      height: '12rem',
      width: 'auto',
    },
    element: {
      padding: theme.spacing(1, 0),
    },
  })
);

export default withRouter(NotFoundHandler);
function NotFoundHandler(props: RouteComponentProps) {
  const classes = useStyles();

  const onClick = useCallback(() => {
    props.history.replace('/');
  }, [props.history]);

  return (
    <Container className={classes.root}>
      <Typography variant='h5' align='center'>
        Ops,
      </Typography>
      <Box className={classes.element} display='flex' justifyContent='center'>
        <img src='/error.png' alt='' className={classes.errorImg} />
      </Box>
      <Typography align='center'>
        Não encontramos essa página. Clique abaixo para voltar.
      </Typography>
      <Box className={classes.element} display='flex' justifyContent='center'>
        <Button color='primary' onClick={onClick} id='back-button'>
          Voltar
        </Button>
      </Box>
    </Container>
  );
}
