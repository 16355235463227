import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './receipt-order-simple.style';
import { getTextSimples } from '../../helpers/get-simples';
import { OrderItemTree } from '../receipt-page/receipt-page';
import { Maybe } from '../../../common/generated/graphql';

interface Props {
  items?: Maybe<OrderItemTree>[];
}

export default function ReceiptOrderSimple({ items }: Props) {
  const classes = useStyles();
  const textSimples = getTextSimples(items ?? []);

  return (
    <Grid container>
      <Grid item>
        {textSimples?.map((item, index) => (
          <Typography
            id='receipt-order-simple-details'
            key={String(index)}
            variant={'body2'}
            className={classes.orderDetails}
          >
            {item as any}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}
