import { getCompanyTimerulesMsg } from './get-company-timerules-msg';
import { OnlineStatus, TimeRulesFragment, TimeRule } from '../generated/graphql';

export function getStatusText(
  status?: OnlineStatus | null,
  timeRules?: TimeRulesFragment | TimeRule
): string {
  switch (status) {
    case OnlineStatus.Closed:
      if (timeRules) {
        return getCompanyTimerulesMsg(timeRules);
      }
      return 'Fechado';

    case OnlineStatus.Offline:
      return 'Indisponível';

    case OnlineStatus.Online:
      return 'Aberto';

    default:
      return '';
  }
}
