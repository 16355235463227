import {
  MutationsAddToCartArgs,
  Product,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
  ProductClientPropsFragmentDoc,
  ProductValidationStatus,
  ProductWithDescriptionAndSubproductsFragment,
  ProductWithDescriptionAndSubproductsFragmentDoc,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import clone from 'lodash/clone';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';
import { newShoppingCart, addNewCartToApolloCache } from '../../helpers/new-shopping-cart';

export function addToCart(
  root: any,
  variables: MutationsAddToCartArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  let isNewShoppingCart: boolean;
  let shoppingCart: ShoppingCartFragment;

  const cachedShoppingCart = getShoppingCart(
    variables.companyId,
    context.cache,
    context.getCacheKey
  );

  if (!cachedShoppingCart) {
    isNewShoppingCart = true;
    shoppingCart = newShoppingCart(variables.companyId);
  } else {
    isNewShoppingCart = false;
    shoppingCart = clone(cachedShoppingCart);
  }

  addProductsToCart(
    shoppingCart,
    variables.productCacheId,
    variables.productQuantity,
    context.cache,
    context.getCacheKey
  );

  if (isNewShoppingCart) {
    addNewCartToApolloCache(shoppingCart, context.cache);
  } else {
    updateExistingCart(shoppingCart, variables.companyId, context.cache, context.getCacheKey);
  }

  resetProductTree(variables.productCacheId, true, context.cache, context.getCacheKey);
  return true;
}

function addProductsToCart(
  shoppingCart: ShoppingCartFragment,
  productCacheId: string,
  productQuantity: number,
  cache: InMemoryCache,
  getCacheKey: any
): ShoppingCartFragment {
  shoppingCart.products = clone(shoppingCart.products);

  for (let i = 0; i < productQuantity; i++) {
    let newProduct = duplicateProductTree(cache, getCacheKey, productCacheId, true);
    shoppingCart.products.push(newProduct as ProductAndSubproductsFragment);
    shoppingCart.totalProductsPrice = shoppingCart.totalProductsPrice + newProduct!.totalPrice!;
    shoppingCart.orderTotal = shoppingCart.orderTotal + newProduct!.totalPrice!;
  }

  return shoppingCart;
}

function duplicateProductTree(
  cache: InMemoryCache,
  getCacheKey: any,
  productId: string,
  isRoot: boolean
): Product | undefined {
  const product = getProduct(productId, isRoot, cache, getCacheKey);
  if (!product || (!isRoot && product.chosenQuantity === 0)) {
    return;
  }

  const subproductsClone = cloneSubproducts(product, isRoot, cache, getCacheKey);

  // Clone the product using a new cache id, in order to not overwrite the current product in the cache.
  const productClone = cloneDeepWith(product, (value, key) => {
    if (key === 'id') {
      return createNewCacheKey('Product');
    } else if (isRoot && key === 'chosenQuantity') {
      // Set the root product (menu item) quantity here, because it should only be set when the product is valid and is added to the shopping cart
      return 1;
    } else if (key === 'subproducts') {
      return subproductsClone;
    }
  });

  writeNewProductToApolloCache(productClone, isRoot, cache, getCacheKey);
  return productClone;
}

function cloneSubproducts(
  product: ProductAndSubproductsFragment,
  isRoot: boolean,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const subproductsClone: Product[] = [];

  if (product.subproducts) {
    for (const subproduct of product.subproducts) {
      const subproductClone = duplicateProductTree(cache, getCacheKey, subproduct!.id, false);
      if (subproductClone) {
        subproductsClone.push(subproductClone);
      }
    }
  }

  return subproductsClone;
}

function resetProductTree(
  productId: string,
  isRoot: boolean,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const product = getProduct(productId, isRoot, cache, getCacheKey);
  if (
    !product ||
    (product.chosenQuantity === 0 && product.isValid === ProductValidationStatus.Unknown)
  ) {
    return;
  }

  if (product.subproducts) {
    for (const subproduct of product.subproducts) {
      resetProductTree(subproduct!.id, false, cache, getCacheKey);
    }
  }

  resetProduct(product, isRoot, cache, getCacheKey);
}

function resetProduct(
  product: ProductAndSubproductsFragment | ProductWithDescriptionAndSubproductsFragment,
  isRoot: boolean,
  cache: InMemoryCache,
  getCacheKey: any
) {
  let originalPrice = 0;
  if (isRoot && product.productCompanyByCompanyId) {
    originalPrice = product.productCompanyByCompanyId.price!;
  }

  cache.writeFragment({
    id: getCacheKey({ __typename: 'Product', id: product.id }),
    fragment: ProductClientPropsFragmentDoc,
    data: {
      ...product,
      chosenQuantity: 0,
      isValid: ProductValidationStatus.Unknown,
      totalPrice: originalPrice,
    },
  });
}

function getShoppingCart(
  companyId: number,
  cache: InMemoryCache,
  getCacheKey: any
): ShoppingCartFragment | null {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
  });
}

function updateExistingCart(
  shoppingCart: ShoppingCartFragment,
  companyId: number,
  cache: InMemoryCache,
  getCacheKey: any
) {
  cache.writeFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
    data: shoppingCart,
  });
}

function getProduct(productId: string, isRoot: boolean, cache: InMemoryCache, getCacheKey: any) {
  const fragment = getProductFragment(isRoot);

  return cache.readFragment<
    ProductWithDescriptionAndSubproductsFragment | ProductAndSubproductsFragment
  >({
    fragment: fragment.fragmentDoc,
    id: getCacheKey({ __typename: 'Product', id: productId }),
    fragmentName: fragment.fragmentName,
  });
}

function writeNewProductToApolloCache(
  product: ProductAndSubproductsFragment | ProductWithDescriptionAndSubproductsFragment,
  isRoot: boolean,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const fragment = getProductFragment(isRoot);
  cache.writeFragment({
    fragment: fragment.fragmentDoc,
    fragmentName: fragment.fragmentName,
    id: getCacheKey({ __typename: 'Product', id: product.id }),
    data: product,
  });
}

function getProductFragment(isRoot: boolean) {
  let fragment;
  if (isRoot) {
    fragment = {
      fragmentDoc: ProductWithDescriptionAndSubproductsFragmentDoc,
      fragmentName: 'productWithDescriptionAndSubproducts',
    };
  } else {
    fragment = {
      fragmentDoc: ProductAndSubproductsFragmentDoc,
      fragmentName: 'productAndSubproducts',
    };
  }

  return fragment;
}
