import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      width: '18rem',
      height: '12rem',
    },
    cardBrandImgContainer: {
      marginBottom: theme.spacing(0.5),
    },
    cardBrandImg: {
      width: 'auto',
      height: '1.2rem',
    },
  })
);
