import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      flexDirection: 'column',
    },
    title: {
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
    },
    item: {
      marginBottom: theme.spacing(3),
    },
    boxLoading: {
      justifyContent: 'center',
      display: 'flex',
      padding: '30px',
    },
    message: {
      background: theme.palette.primary.light,
      color: theme.palette.grey[800],
      padding: theme.spacing(2, 4),
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: 12,
    },
    checked: {
      textDecoration: 'line-through',
      color: theme.palette.grey[600],
    },
    messageText: {
      color: theme.palette.common.white,
    },
    eventListTitle: {
      color: theme.palette.primary.main,
      ...theme.typography.body1,
      fontWeight: 'bold',
      fontSize: '1.3rem',
      textAlign: 'center',
      marginBottom: theme.spacing(0.5),
    },
    eventListInfo: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    eventInfoContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);
