import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './tag-version.style';

export function TagVersion() {
  const classes = useStyles();

  return process.env.NODE_ENV !== 'production' ? (
    <Box className={classes.root}>
      <Typography variant='caption' className={classes.text}>
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  ) : (
    <span className={classes.empty} />
  );
}
