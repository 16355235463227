import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import {
  GetOrderStatusQueryVariables,
  GetSelectedPaymentMethodFromCacheDocument,
  GetSelectedPaymentMethodFromCacheQuery,
  IsExecutingPaymentCallDocument,
  IsExecutingPaymentCallQuery,
  LocalCard,
  OrderStatus,
} from '../../generated/graphql';
import { CieloPayPaymentHandler } from '../../helpers/cielo-pay-payment-handler';
import { getEndpoints } from '../../helpers/configs';
import { logError } from '../../helpers/log-error';
import { executePaymentCall } from '../../helpers/payment-call';
import { PaymentCall } from '../../model/payment-call.interface';

export async function orderStatus(
  root: any,
  variables: GetOrderStatusQueryVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<OrderStatus> {
  let shouldRequestCardNumber = false;
  try {
    const endpoints = getEndpoints();
    const url = `${endpoints.api}v2/mobile/order/${variables.input.orderId}`;
    const orderStatusRequest = await axios.get(url);

    const isPaymentExecuting = context.cache.readQuery<IsExecutingPaymentCallQuery>({
      query: IsExecutingPaymentCallDocument,
    });

    if (
      !isPaymentExecuting?.isExecutingPaymentCall &&
      orderStatusRequest?.data?.payment?.paymentCall &&
      Object.keys(orderStatusRequest.data.payment.paymentCall).length !== 0
    ) {
      const payment = orderStatusRequest.data.payment;
      const paymentCall: PaymentCall = orderStatusRequest.data.payment.paymentCall;
      const card = getSelectedLocalCard(context.cache);
      if (isMissingCardNumber(orderStatusRequest, variables.input.missingCardNumber, card)) {
        shouldRequestCardNumber = true;
      } else if (paymentCall.action === 'start_cielo_pay_payment_flow') {
        const handler = new CieloPayPaymentHandler(
          payment.value,
          paymentCall.merchantNumber,
          payment.numericalId,
          payment.step,
          context.cache
        );
        handler.executePayment();
      } else {
        await executePaymentCall(
          variables.input.orderId,
          orderStatusRequest.data.payment,
          variables.input.brandId,
          variables.input.missingCardCvv,
          variables.input.missingCardNumber,
          card,
          context.cache
        );
      }
    }

    return {
      id: btoa(`OrderStatus:${variables.input.orderId}`),
      __typename: 'OrderStatus',
      receiptMessage: orderStatusRequest.data.receiptMessage,
      status: orderStatusRequest.data.status,
      numericalId: variables.input.orderId,
      shouldRequestCardNumber,
      creationDatetime: orderStatusRequest.data.creationDatetime,
      errorMsg: orderStatusRequest.data.error ? orderStatusRequest.data.error.message : null,
    };
  } catch (error) {
    const err = error as AxiosError;
    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      console.warn('Error getting the order status', error);
    } else {
      logError(error, 'Error in get order status call');
    }

    return {
      id: btoa(`OrderStatus:${variables.input.orderId}`),
      __typename: 'OrderStatus',
      errorMsg: 'Erro ao tentar obter o status do pedido.',
      status: null,
      numericalId: null,
      company: null,
      displayCode: null,
      preparationTimeMaximum: null,
      preparationTimeMinimum: null,
      receiptMessage: null,
      creationDatetime: null,
      shouldRequestCardNumber,
    };
  }
}

function getSelectedLocalCard(cache: InMemoryCache) {
  const paymentMethodQuery = cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });
  if (!paymentMethodQuery || !paymentMethodQuery.selectedPaymentMethod) {
    console.error('No payment method selected');
    return null;
  }

  return paymentMethodQuery.selectedPaymentMethod as LocalCard;
}

function isMissingCardNumber(
  orderStatusRequest: any,
  missingCardNumber: string | null | undefined,
  card: LocalCard | null
) {
  return (
    orderStatusRequest.data.payment.paymentCall.payload.includes('[[cardNumber]]') &&
    !missingCardNumber &&
    (!card || !card.cardNumber)
  );
}
