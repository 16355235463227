import { Box } from '@material-ui/core';
import React from 'react';
import { useGetAggregatorImagesQuery } from '../../../common/generated/graphql';
import AggregatorHeader from '../aggregator-header/aggregator-header';
import AggregatorTabsContent from '../aggregator-tabs-content/aggregator-tabs-content';
import { logError } from '../../../common/helpers/log-error';
import { BrandHighlightsDialog } from '../../../common/components/brand-highlights-dialog/brand-highlights-dialog';
import { dataHighlights } from './helpers/data-highlights';

interface Props {
  aggregatorId: number;
  companyNameStartsWith?: string;
}

export default function AggregatorTabsContainer({
  aggregatorId,
  companyNameStartsWith = '',
}: Props) {
  const { data } = useGetAggregatorImagesQuery({
    variables: { aggregatorId },
    fetchPolicy: 'cache-only',
    onError: (err) => logError(err, 'Error executing Get Aggregator Images'),
  });

  const showBrandHightlights = !(companyNameStartsWith || companyNameStartsWith.length);

  return (
    <Box>
      <AggregatorHeader
        aggregatorName={data?.aggregator?.name}
        aggregatorImages={data?.aggregator?.images}
        withTabs
      />
      <AggregatorTabsContent
        aggregatorId={aggregatorId}
        companyNameStartsWith={companyNameStartsWith}
      />

      {showBrandHightlights
        ? dataHighlights.map((highLight) => (
            <BrandHighlightsDialog
              data-testid='brand-highlight'
              key={highLight.company}
              data={highLight}
            />
          ))
        : null}
    </Box>
  );
}
