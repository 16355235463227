import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { refeicaoOnlineTheme } from '../themes/refeicao-online.theme';

export const refeicaoOnlineConfig: AppConfig = {
  appName: 'refeicao-online',
  appTitle: 'Refeição Online',
  appDescription: 'Peça aqui.',
  aggregator: 839,
  takeOutAggregatorId: 21,
  brand: 847,
  theme: refeicaoOnlineTheme,
  faviconPath: '',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: true,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
