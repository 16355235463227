import { createTheme, MuiThemeProvider, SimplePaletteColorOptions } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { BrandTheme, useGetBrandThemeQuery } from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import { getAppConfig } from '../../helpers/configs';
import { formatTheme, mountPaletteOption } from '../../helpers/format-theme';

/**
 * Handles the app theme. Will set the default theme or the brand theme taken from the backend.
 */
export default function ThemeHandler(props: React.PropsWithChildren<any>): React.ReactElement {
  const appConfig = getAppConfig();
  const skip = !appConfig.featureFlags.getBrandThemeFromBackend;

  // Default Onyo theme
  const [theme, setTheme] = useState(formatTheme(appConfig.theme));

  const { loading } = useGetBrandThemeQuery({
    variables: { brandId: appConfig.brand },
    skip,
    onCompleted: (data) => {
      if (!data?.brandTheme?.brandThemeColor || data.brandTheme.brandThemeColor.length === 0) {
        if (!skip) {
          console.warn('Empty brand theme returned, using default');
          Sentry.captureMessage(
            `Empty brand theme for brandId: ${appConfig.brand}. Using default...`,
            Sentry.Severity.Warning
          );
        }
      } else {
        const palette = parseBrandPalette(data.brandTheme as BrandTheme);
        setTheme({ ...theme, palette });
      }
    },
    onError: (err) => logError(err, 'Error executing Get Brand Theme Query'),
  });

  if (loading) {
    return <MuiThemeProvider theme={createTheme(theme)}>{props.children}</MuiThemeProvider>;
  }

  return <MuiThemeProvider theme={createTheme(theme)}>{props.children}</MuiThemeProvider>;
}

function parseBrandPalette(brandTheme: BrandTheme) {
  const palette: any = {};

  for (const color of brandTheme.brandThemeColor!) {
    if (color && color.key === 'primaryColor') {
      palette.primary = mountPaletteOption({ main: color.value } as SimplePaletteColorOptions);
    } else if (color && color.key === 'darkPrimaryColor') {
      palette.secondary = mountPaletteOption({ main: color.value } as SimplePaletteColorOptions);
    }
  }

  return palette;
}
