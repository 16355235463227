import { getAppConfig } from '../../common/helpers/configs';
import { BundleName, Item, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'select_reorder';

interface LogSelectReorder {
  brand_id: number;
  company_id: number;
  items: Item[];
}

export function logSelectReorder({ brand_id, company_id, items }: LogSelectReorder) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brand_id,
      [ParamName.COMPANY_ID]: company_id,
      [ParamName.ITEMS]: items,
    },
  });
}

export function logSelectReorderError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
