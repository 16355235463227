import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  GetShoppingCartProductsDocument,
  Product,
  useAddToCartMutation,
  useValidateProductMutation,
  useSaveShoppingCartToBrowserCacheMutation,
  GetOrderTotalDocument,
} from '../../common/generated/graphql';
import { logError } from '../../common/helpers/log-error';
import { useNotification } from '../../common/hooks/use-notification';
import { useProductDetailsDialog } from '../../common/hooks/use-product-details-dialog';
import { logAddToCart, logAddToCartError } from '../../analytics/helpers/log-add-to-cart';
import { mountAnalyticsItem } from '../../analytics/helpers/item-utils';

interface OnAddToCartClick {
  (quantity: number, productDetails?: Product): void;
}

interface UseAddToCart {
  (productId: number): { onAddToCartClick: OnAddToCartClick };
}

export const useAddToCart: UseAddToCart = (productId) => {
  const { setNotify } = useNotification();
  const { onCloseProductDetails } = useProductDetailsDialog();
  const { brandId, companyId } = useParams<{
    aggregatorId: string;
    brandId: string;
    companyId: string;
  }>();

  const [validateProduct] = useValidateProductMutation({
    onError: (err) => {
      logError(err, 'Error executing Validate Product Mutation');
      logAddToCartError({ errorCode: 59, errorMessage: err.message });
    },
  });
  const [addToCart] = useAddToCartMutation({
    onError: (err) => {
      logError(err, 'Error executing Add to Cart Mutation');
      logAddToCartError({ errorCode: 60, errorMessage: err.message });
    },
  });
  const [saveToCache] = useSaveShoppingCartToBrowserCacheMutation({
    onError: (err) => {
      logError(err, 'Error executing Save Shopping CArt to Browser Cache Mutation');
      logAddToCartError({ errorCode: 61, errorMessage: err.message });
    },
  });

  const onAddToCartClick = useCallback(
    async (quantity, productDetails) => {
      if (!productDetails) {
        setNotify('error', 'este produto esta indisponível.');
      } else {
        const isValid = await validateProduct({
          variables: { productCacheId: productDetails!.id },
        });

        if (isValid && isValid.data && isValid.data.validateProduct && !isValid.errors) {
          const result = await addToCart({
            variables: {
              companyId: Number(companyId),
              productCacheId: productDetails!.id,
              productQuantity: quantity,
            },
            refetchQueries: [
              {
                query: GetShoppingCartProductsDocument,
                variables: { companyId: Number(companyId) },
              },
              {
                query: GetOrderTotalDocument,
                variables: { companyId: Number(companyId) },
              },
            ],
          });

          if (result && result.data && result.data.addToCart && !result.errors) {
            saveToCache({
              variables: { companyId: Number(companyId) },
            });

            logAddToCart({
              brandId,
              companyId,
              items: [
                mountAnalyticsItem({
                  itemId: productDetails.numericalId?.toString(),
                  price: Number(productDetails.totalPrice),
                  quantity: quantity,
                }),
              ],
            });
          } else {
            setNotify('error', 'Ocorreu um erro ao adicionar esse produto no carrinho.');

            logAddToCartError({ errorCode: 62 });
          }
          onCloseProductDetails();
        } else {
          setNotify(
            'error',
            'É preciso escolher todos os itens obrigatórios antes de adicionar à sacola.'
          );

          logAddToCartError({ errorCode: 63 });
        }
      }
    },
    [validateProduct, onCloseProductDetails, setNotify, addToCart, companyId, saveToCache, brandId]
  );

  return { onAddToCartClick };
};
