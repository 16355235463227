import { Box, Divider, Typography } from '@material-ui/core';
import React, { useCallback, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import {
  useGetOrLoadSelectedPaymentMethodMutation,
  PaymentMethod,
  CartPaymentStatus,
  useCartPaymentFlowQuery,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { useStyles } from './cart-payment.style';
import PaymentsMethodsContainer from '../payments-methods-dialog/payments-methods-container/payments-methods-container';
import SelectedPaymentMethod from '../selected-payment-method/selected-payment-method';
import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import RegisterCardContainer from '../../../register-card-dialog/components/register-card-container/register-card-container';
import { useUpdateCartPayment } from '../../../common/hooks/use-update-cart-payment';
import { logAddPaymentInfoError } from '../../../analytics/helpers/log-add-payment-info';
import { useLocation } from 'react-router-dom';

export default function CartPayment() {
  const params = useParams<{
    companyId: string;
  }>();
  const classes = useStyles();
  const { updateCartPayment } = useUpdateCartPayment();
  const { data: dataCartPayment } = useCartPaymentFlowQuery({
    onError: (err) => {
      logError(err, 'Error executing is authenticated query');
      logAddPaymentInfoError({ errorCode: 17, errorMessage: err.message });
    },
  });
  const location = useLocation();

  const [getSelectedPaymentMethod, { data, loading, error }] =
    useGetOrLoadSelectedPaymentMethodMutation({
      variables: { companyId: Number(params.companyId) },
      onError: (err) => {
        logError(err, 'Error executing Get Or Load Selected Payment Method Mutation');
        logAddPaymentInfoError({ errorMessage: err.message, errorCode: 18 });
      },
    });

  const handleOpenPaymentsMethods = useCallback(() => {
    updateCartPayment(CartPaymentStatus.SelectPayment);
  }, [updateCartPayment]);

  useLayoutEffect(() => {
    getSelectedPaymentMethod();
  }, [dataCartPayment, getSelectedPaymentMethod]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <NetworkError
        errorMsg='Erro ao carregar os meios de pagamento'
        compact={true}
        retry={getSelectedPaymentMethod}
        error={error}
        path={location.pathname}
      />
    );
  }

  return (
    <>
      {data?.getOrLoadSelectedPaymentMethod?.__typename !== 'Wallet' && (
        <Box id='cart-payment-container' className={classes.container}>
          <Typography id='choose-payment-method' variant='h6'>
            Forma de Pagamento
          </Typography>
          <Divider />
          <Box className={classes.paymentMethods}>
            <SelectedPaymentMethod
              selectedPayment={data?.getOrLoadSelectedPaymentMethod as PaymentMethod}
              openPaymentMethodDialog={handleOpenPaymentsMethods}
            />
          </Box>

          <PaymentsMethodsContainer
            open={dataCartPayment?.cartPaymentFlow.status === CartPaymentStatus.SelectPayment}
          />
          <RegisterCardContainer
            open={dataCartPayment?.cartPaymentFlow.status === CartPaymentStatus.RegisterCard}
          />
        </Box>
      )}
    </>
  );
}
