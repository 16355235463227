import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      alignSelf: 'center',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    infoWrap: {
      display: 'flex',
      flexGrow: 1,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
    },
    wrapIcon: {
      padding: theme.spacing(1),
      alignSelf: 'flex-start',
    },
    wrapText: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    number: {
      color: theme.palette.grey[800],
    },
    name: {
      color: theme.palette.grey[800],
    },
    empty: {
      display: 'none',
    },
  })
);
