import { MagicLinkResult, MagicLinkCreateMutationVariables } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';

export async function magicLinkCreate(
  root: any,
  variables: MagicLinkCreateMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<MagicLinkResult> {
  const __typename = 'MagicLinkResult';
  const uri = `v1/magiclink/create`;
  let success = false;
  let alreadyExist = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';

  try {
    const endpoints = getEndpoints();
    const resp = await axios.post(`${endpoints.api}${uri}`, {
      email: variables.input.email,
    });

    alreadyExist = resp.data.message === 'onyo.magic-link.user_already_exists';
    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    const err = error as AxiosError;
    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      console.warn('Error in Magic Link Create Call', err);

      const errorType = err.response.data.error;
      switch (errorType) {
        case 'onyo.user.invalid-email':
          errorMsg = 'E-mail invalido ou inexistente. Por favor, confira e tente de novo.';
          break;
        default:
          logError(error, 'Error in MagicLink Create Call');
          break;
      }
    } else {
      logError(error, 'Error in MagicLink Create Call');
    }
  }

  return { __typename, success, errorMsg, alreadyExist };
}
