import React from 'react';
import { getSize } from '../../helpers/get-size';
import { useGetColor } from '../../hooks/use-get-color';
import { SvgProps } from '../../model/svg-props';

export default function SvgWrapper({ viewBox = '0 0 40 40', ...props }: SvgProps) {
  const iconSize = getSize(props.size);
  const { getColor } = useGetColor();

  return (
    <svg
      id={props.id}
      role='icon'
      height={iconSize}
      width={iconSize}
      className={props?.className}
      viewBox={viewBox}
      fill={getColor(props.color)}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      {props.children}
    </svg>
  );
}
