import React, { useCallback } from 'react';
import { Box, Tab, Tabs, Typography, Container } from '@material-ui/core';

import { useSelectedTabQuery } from '../../../common/generated/graphql';
import { useUpdateSelectedTab } from '../../../common/hooks/use-update-selected-tab';
import { logError } from '../../../common/helpers/log-error';
import useUserCoords from '../../hooks/use-user-coords';
import AggregatorCompanyGeolocatedList from '../aggregator-company-geolocated-list/aggregator-company-geolocated-list';
import AggregatorsGroupsCompaniesContainer from '../aggregators-groups-companies-container/aggregators-groups-companies-container';
import NoAddressAggregator from '../no-address-aggregator/no-address-aggregator';
import AggregatorTabPanel from '../aggregator-tab-panel/aggregator-tab-panel';

import { useStyles } from './aggregator-tabs-content.style';
import { useHomeHeaderSpace } from '../../../common/hooks/use-header-space';

interface Props {
  aggregatorId: number;
  companyNameStartsWith?: string;
}

export default function AggregatorTabsContent({ aggregatorId, companyNameStartsWith = '' }: Props) {
  const userLocation = useUserCoords();
  const headerHomeSpace = useHomeHeaderSpace();
  const classes = useStyles();
  const { updateSelectedTab } = useUpdateSelectedTab();

  const { data } = useSelectedTabQuery({
    onError: (err) => logError(err, 'Error executing is authenticated query'),
  });

  const selectedtab = data?.selectedTab as number;

  const tabSchema = [
    { key: 0, id: 'aggregator-tab-delivery', label: 'Receber' },

    { key: 1, id: 'aggregator-tab-take-out', label: 'Retirar' },
  ];

  const handleChange = useCallback(
    (_, tabIndex: number) => {
      updateSelectedTab(tabIndex);
    },
    [updateSelectedTab]
  );

  return (
    <Box>
      <Tabs
        id='aggregator-tabs'
        value={selectedtab}
        onChange={handleChange}
        variant='fullWidth'
        indicatorColor='primary'
        classes={{
          indicator: classes.indicator,
          root: classes.root,
          fixed: classes.fixed,
        }}
      >
        {tabSchema.map((tab) => (
          <Tab
            id={tab.id}
            role='tab'
            key={tab.key}
            className={classes.tab}
            classes={{ selected: classes.selected }}
            label={
              <Typography variant='body1' className={classes.label}>
                {tab.label}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Container className={headerHomeSpace.home}>
        <AggregatorTabPanel value={selectedtab} index={0}>
          {userLocation ? (
            <AggregatorCompanyGeolocatedList
              userLocation={userLocation}
              aggregatorId={aggregatorId}
              companyNameStartsWith={companyNameStartsWith}
            />
          ) : (
            <NoAddressAggregator />
          )}
        </AggregatorTabPanel>

        <AggregatorTabPanel value={selectedtab} index={1}>
          {userLocation ? (
            <AggregatorsGroupsCompaniesContainer
              aggregatorId={aggregatorId}
              userLocation={userLocation}
              companyNameStartsWith={companyNameStartsWith}
            />
          ) : (
            <NoAddressAggregator />
          )}
        </AggregatorTabPanel>
      </Container>
    </Box>
  );
}
