import React, { useCallback, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import { useStyles } from './empty-aggregator.style';
import { logAnalyticsError } from '../../../analytics/helpers/log-analytics-error';
interface Props {
  retry: () => void;
  path: string;
}

export default function EmptyAggregator(props: Props) {
  const classes = useStyles();

  const onRetryClick = useCallback(async () => {
    try {
      await props.retry();
    } catch (e) {
      if (process.env.NODE_ENV === 'production') {
        logAnalyticsError({
          event: 'load_shop_list',
          errorCode: 88,
          errorMessage: `${props.path} - Error: No data found`,
        });
      }
    }
  }, [props]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      logAnalyticsError({
        event: 'load_shop_list',
        errorCode: 87,
        errorMessage: `${props.path} - Error: No data found`,
      });
    }
  }, [props.path]);

  return (
    <Box id='empty-aggregator' className={classes.root}>
      <Box className={classes.imgContainer}>
        <img className={classes.img} src='/city.png' alt='' />
      </Box>
      <Typography variant='h6' align='center' gutterBottom={true}>
        Não há nenhum restaurante próximo disponível no momento
      </Typography>

      <Box className={classes.btnContainer}>
        <Button id='retry-btn' variant='contained' color='primary' onClick={onRetryClick}>
          Tentar novamente
        </Button>
      </Box>
    </Box>
  );
}
