import { ProductDetailsDialogQuery, ProductDetailsDialogDocument } from './../generated/graphql';
import { ApolloClient, useApolloClient } from '@apollo/client';

interface UseProductDetailsDialog {
  (): {
    onActiveProductDetails: (product: number) => void;
    onCloseProductDetails: () => void;
  };
}

interface UpdateProductDetails {
  (client: ApolloClient<Object>, active?: boolean, product?: number): void;
}

export const useProductDetailsDialog: UseProductDetailsDialog = () => {
  const client = useApolloClient();

  const onActiveProductDetails = (product: number) => updateProduct(client, true, product);

  const onCloseProductDetails = () => updateProduct(client);

  return { onActiveProductDetails, onCloseProductDetails };
};

const updateProduct: UpdateProductDetails = (client, active = false, product = 0) => {
  client.writeQuery<ProductDetailsDialogQuery>({
    query: ProductDetailsDialogDocument,
    data: {
      productDetailsDialog: {
        __typename: 'ProductDetailsDialog',
        active,
        product,
      },
    },
  });
};
