import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      padding: theme.spacing(2),
    },
    content: {
      justifyContent: 'center',
    },
    item: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
    },
  })
);
