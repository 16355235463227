import { Box } from '@material-ui/core';
import shuffle from 'lodash/shuffle';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import cloneDeepWith from 'lodash/cloneDeepWith';
import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import { Location } from '../../../common/model/config';
import concat from 'lodash/concat';
import { sortCompanies } from '../../../common/helpers/companies-sort';
import { getAppConfig, getSharedConfig } from '../../../common/helpers/configs';
import {
  CompanySharedEdge,
  CompanyGroupEdge,
  Maybe,
  useGetCompaniesGroupsByAggregatorsQuery,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import AggregatorsGroupsCompaniesContainerList from '../aggregators-groups-companies-container-list/aggregators-groups-companies-container-list';
import { useStyles } from './aggregators-groups-companies-container.style';
import { getQueryVariables } from './helpers/aggregators-groups-company-list-query-variables';
import { logViewShopList } from '../../../analytics/helpers/log-view-shop-list';
import { logLoadShopList } from '../../../analytics/helpers/log-load-shop-list';
import { useLocation } from 'react-router-dom';

interface Props {
  aggregatorId: number;
  userLocation: Location;
  companyNameStartsWith?: string;
}

export interface CompanyGroup {
  company: CompanySharedEdge;
  companyGroupName?: string;
}

const takeOutAggregatorIdDefault = 21;

export default function AggregatorsGroupsCompaniesContainer({
  companyNameStartsWith = '',
  userLocation,
  aggregatorId,
}: Props) {
  const classes = useStyles();
  const appConfig = getAppConfig();
  const sharedConfig = getSharedConfig();
  const [hasSetEventLog, setHasSetEventLog] = useState<Boolean>(true);
  const [numberCompanies, setNumberCompanies] = useState<number>(0);
  const location = useLocation();

  const { data, loading, error, refetch, fetchMore } = useGetCompaniesGroupsByAggregatorsQuery({
    variables: getQueryVariables(
      appConfig.takeOutAggregatorId ?? takeOutAggregatorIdDefault,
      userLocation.latitude,
      userLocation.longitude,
      '',
      companyNameStartsWith
    ),
    onError: (err) => logError(err, 'Error executing Get Aggregator Companies Query'),
  });

  const companiesGroupsRequest = data?.aggregators?.edges as Maybe<CompanyGroupEdge>[];
  const hasMore = data?.aggregators?.pageInfo?.hasNextPage as boolean;

  const handleSetTotalCompanies = useCallback((total: number) => {
    setNumberCompanies(total);
  }, []);

  const fetchMoreData = useCallback(() => {
    const endCursor = data?.aggregators?.pageInfo.endCursor as string;
    const variables = getQueryVariables(
      appConfig.takeOutAggregatorId ?? takeOutAggregatorIdDefault,
      userLocation.latitude,
      userLocation.longitude,
      endCursor,
      companyNameStartsWith
    );

    if (!companiesGroupsRequest) {
      return;
    }

    fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        let newGroupsCompanies = fetchMoreResult?.aggregators?.edges;

        if (!fetchMoreResult) {
          return previousResult;
        }

        if (appConfig.featureFlags.shuffleCompanyArray) {
          newGroupsCompanies = shuffle(newGroupsCompanies);
          newGroupsCompanies = [...newGroupsCompanies].sort((a, b) =>
            sortCompanies(a as any, b as any)
          );
        }

        return cloneDeepWith(previousResult, (value, key) => {
          if (key === 'aggregators') {
            return {
              ...value,
              edges: concat(value.edges, newGroupsCompanies),
              pageInfo: fetchMoreResult?.aggregators?.pageInfo,
            };
          }
        });
      },
    });
  }, [
    data,
    appConfig.takeOutAggregatorId,
    appConfig.featureFlags.shuffleCompanyArray,
    userLocation.latitude,
    userLocation.longitude,
    companyNameStartsWith,
    companiesGroupsRequest,
    fetchMore,
  ]);

  useEffect(() => {
    if (!numberCompanies) {
      return;
    }
    if (numberCompanies < sharedConfig.companyPaginationStep && hasMore) {
      fetchMoreData();
    }
  }, [
    sharedConfig.companyPaginationStep,
    numberCompanies,
    hasMore,
    fetchMoreData,
    companiesGroupsRequest,
  ]);

  useMemo(() => {
    if (!numberCompanies) {
      return;
    }

    if (hasSetEventLog) {
      logViewShopList({ type: 'retirar' });
      setHasSetEventLog(false);
    } else {
      logLoadShopList({ type: 'retirar', count: numberCompanies });
    }
  }, [numberCompanies, hasSetEventLog]);

  if (loading) {
    return (
      <Box className={classes.progress}>
        <Progress />
      </Box>
    );
  } else if (error) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados.'
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  }

  return (
    <AggregatorsGroupsCompaniesContainerList
      companiesGroups={companiesGroupsRequest}
      setTotalCompanies={handleSetTotalCompanies}
      numberCompanies={numberCompanies}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
      refetch={refetch}
    />
  );
}
