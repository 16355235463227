import { Dispatch, useCallback } from 'react';
import React, { createContext, ReactNode, useState } from 'react';

type Callback = () => void;

interface EventConfirmationModalProviderProps {
  children: ReactNode;
}

export interface EventConfirmationModalsContextData {
  onClose: () => void;
  onShowModal: () => void;
  onConfirm: () => void;
  setCallbackConfirm: Dispatch<React.SetStateAction<Callback | null | undefined>>;
  active: boolean;
}

export const EventConfirmationModalContext = createContext<EventConfirmationModalsContextData>({
  active: false,
} as EventConfirmationModalsContextData);

export const EventConfirmationModalProvider = ({
  children,
}: EventConfirmationModalProviderProps) => {
  const [active, setActive] = useState(false);
  const [callbackConfirm, setCallbackConfirm] = useState<Callback | null>();

  const onConfirm = useCallback(() => {
    callbackConfirm && callbackConfirm();
  }, [callbackConfirm]);

  const onClose = useCallback(() => {
    setActive(false);
  }, []);

  const onShowModal = useCallback(() => {
    setActive(true);
  }, []);

  return (
    <EventConfirmationModalContext.Provider
      value={{ onClose, onShowModal, onConfirm, active, setCallbackConfirm }}
    >
      {children}
    </EventConfirmationModalContext.Provider>
  );
};
