import { SharedConfig, Endpoints, AppConfig } from '../model/config';
import config from 'react-global-configuration';

export function getSharedConfig(): SharedConfig {
  return config.get('sharedConfig');
}

export function getEndpoints(): Endpoints {
  return config.get('endpoints');
}

export function getAppConfig(): AppConfig {
  return config.get('appConfig');
}
