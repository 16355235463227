import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    OrderCouponContainer: {
      padding: theme.spacing(1, 2),
    },
    noCardText: {
      marginBottom: theme.spacing(1),
    },
    couponItem: {
      background: theme.palette.grey[100],
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    couponIcon: {
      color: theme.palette.grey[400],
      minWidth: '2rem',
      minHeight: '2rem',
    },
    couponItemEmpty: {
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[400],
      ...theme.typography.caption,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      textTransform: 'initial',
      [theme.breakpoints.up('sm')]: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightBold as 'bold',
      },
    },
    boxText: {},
    couponItemAction: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      textTransform: 'initial',
    },
  })
);
