import { Image, Maybe } from '../generated/graphql';

/**
 * Return a given image from the array, or an empty string
 */
export function parseImage(
  images: Array<Maybe<Image>> | null | undefined,
  context: string,
  alt?: string
): string {
  if (!images) {
    return alt ? alt : '';
  }

  for (const img of images) {
    if (img && img.url && img.context === context) {
      return img.url;
    }
  }

  return alt ? alt : '';
}
