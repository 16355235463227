import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(1),
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appBar,
      paddingBottom: 'calc(env(safe-area-inset-bottom) * 1.5)',
    },
    button: {
      width: '100%',
      height: '3rem',
    },
    errorDialogTitle: {
      textAlign: 'center',
    },
  })
);
