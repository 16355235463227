import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    padding: theme.spacing(2, 8, 1, 0),
  },
  cardText: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    color: theme.palette.grey[700],
  },
  listItemAvatar: {
    width: '4rem',
    height: '4rem',
  },
  cardContent: {
    paddingLeft: theme.spacing(1),
  },
  ContentInfo: {
    alignItems: 'center',
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  listItemTextTitle: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
    color: theme.palette.text.secondary,
  },
  listItemTextGray: {
    color: theme.palette.action.disabled,
  },
  listItemIcon: {
    fontSize: '0.875rem',
    textAlign: 'center',
    color: theme.palette.action.disabled,
  },
  listItemTextMargin: {
    marginLeft: '.25rem',
  },
  listItemSeparatorMargin: {
    marginLeft: '.5rem',
  },
  listItemTextOpen: { color: theme.palette.success.dark },
  listItemTextDelivery: {
    color: theme.palette.success.dark,
    fontWeight: theme.typography.fontWeightMediumBold,
    marginLeft: theme.spacing(0.25),
  },
  listItemTextDeliveryValue: {
    padding: theme.spacing(0, 1),
    background: theme.palette.warning.superLightest,
    color: theme.palette.warning.darkest,
    borderRadius: '0.25rem',
    marginLeft: theme.spacing(0.25),
  },
  listItemTextClose: { color: theme.palette.error.main },
  listItemHighlight: {
    justifyContent: 'flex-start',
    borderRadius: '0.25rem',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  highlightOnline: {
    backgroundColor: theme.palette.additionalColors.highligts?.background.primary,
    color: theme.palette.additionalColors.highligts?.text.primary,
  },
  highlightClosed: {
    backgroundColor: theme.palette.additionalColors.highligts?.background.secondary,
    color: theme.palette.additionalColors.highligts?.text.secondary,
  },
  highlightOffline: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.background.paper,
  },
  lessHighlightOffline: {
    backgroundColor: 'transparent',
  },
  fadedImage: {
    filter: 'grayscale(1)',
  },
}));
