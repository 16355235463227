import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderDataQuery, Order } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { getSharedConfig } from '../../../common/helpers/configs';
import { stopPollingOrderStatus } from '../../helpers/stop-polling-order-status';

import Progress from '../../../common/components/progress/progress';
import NetworkError from '../../../common/components/network-error/network-error';
import ReceiptPage from '../receipt-page/receipt-page';
import {
  logViewOrderStatus,
  logViewOrderStatusError,
} from '../../../analytics/helpers/log-view-order-status';
import { useLocation } from 'react-router-dom';

export default function Receipt() {
  const sharedConfig = getSharedConfig();
  const params = useParams<{ orderId: string; aggregatorId: string }>();
  const location = useLocation();

  const {
    data: dataOrder,
    error: errorOrder,
    loading: loadingOrder,
    refetch: refetchOrder,
    stopPolling,
  } = useGetOrderDataQuery({
    variables: {
      orderId: Number(params.orderId),
    },
    pollInterval: sharedConfig.pollInterval,
    onError: (err) => {
      logError(err, 'Error executing Get Orders Query');
      logViewOrderStatusError({ errorCode: 64, errorMessage: err.message });
    },
  });

  useEffect(() => {
    logViewOrderStatus({ orderId: params.orderId });
  }, [params.orderId]);

  const order = dataOrder?.order as Order;

  if (loadingOrder) {
    return <Progress />;
  } else if (errorOrder) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados.'
        retry={refetchOrder}
        error={errorOrder}
        path={location.pathname}
      />
    );
  } else if (order?.status && stopPollingOrderStatus(order.status)) {
    stopPolling();
  }

  return <ReceiptPage order={order} />;
}
