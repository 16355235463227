import { Result } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';

export async function acceptTermsAndConditions(
  root: any,
  variables: any,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  try {
    const endpoints = getEndpoints();
    const url = `${endpoints.api}v1/mobile/terms/accept`;
    await axios.post(url);

    return {
      __typename: 'Result',
      success: true,
      errorMsg: '',
    };
  } catch (error) {
    logError(error, 'Error trying to accept terms and conditions');
    return {
      __typename: 'Result',
      success: false,
      errorMsg: 'Erro ao aceitar os termos e condições.',
    };
  }
}
