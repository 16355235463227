import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconMastercard(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path fill='#ff5f00' d='M25.407 10.284h-10.815v19.433h10.815v-19.433z'></path>
        <path
          fill='#eb001b'
          d='M15.279 20c0-3.948 1.854-7.45 4.704-9.716-2.094-1.648-4.738-2.644-7.622-2.644-6.832 0-12.36 5.528-12.36 12.36s5.528 12.36 12.36 12.36c2.884 0 5.528-0.996 7.622-2.644-2.85-2.232-4.704-5.768-4.704-9.716z'
        ></path>
        <path
          fill='#f79e1b'
          d='M39.999 20c0 6.832-5.528 12.36-12.36 12.36-2.884 0-5.528-0.996-7.622-2.644 2.884-2.266 4.704-5.768 4.704-9.716s-1.854-7.45-4.704-9.716c2.094-1.648 4.738-2.644 7.622-2.644 6.832 0 12.36 5.562 12.36 12.36z'
        ></path>
      </>
    </SvgWrapper>
  );
}
