import {
  askLocation,
  MiniAppLocationErrorData,
  MiniAppLocationSuccessData,
} from '@bitz/miniapp-gateway';
import { useCallback, useState } from 'react';
import {
  logAddAddressInfo,
  logAddAddressInfoError,
} from '../../../analytics/helpers/log-add-address-info';
import { GeolocationData, GeolocationResult } from '../../model/geolocation';

export default function useMiniAppGeolocation(
  onGeolocationSuccess?: (data: GeolocationData) => void
): GeolocationResult {
  const [geolocationData, setGeolocationData] = useState<GeolocationData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const afterLocationSuccess = useCallback(
    (data: MiniAppLocationSuccessData) => {
      const geolocationData: GeolocationData = {
        latitude: Number(data.lat),
        longitude: Number(data.long),
      };

      logAddAddressInfo({
        type: 'auto',
        latitude: geolocationData.latitude,
        longitude: geolocationData.longitude,
      });
      setGeolocationData(geolocationData);
      setLoading(false);
      if (onGeolocationSuccess) {
        onGeolocationSuccess(geolocationData);
      }
    },
    [onGeolocationSuccess]
  );

  const afterLocationError = useCallback((data: MiniAppLocationErrorData) => {
    setError(data.message);
    logAddAddressInfoError({ errorMessage: data.message, errorCode: 82 });
    setLoading(false);
  }, []);

  const geolocation = useCallback(() => {
    askLocation(afterLocationSuccess, afterLocationError);
    setLoading(true);
  }, [afterLocationError, afterLocationSuccess]);

  return { geolocation, geolocationData, loading, error };
}
