import Fingerprint2, { V18Component } from 'fingerprintjs2';
import { useCallback } from 'react';
import { osName, osVersion } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import {
  useSetDeviceMetadataMutation,
  SetDeviceMetadataMutationVariables,
} from '../../../../generated/graphql';
import { getAppConfig } from '../../../../helpers/configs';
import { getWindowDimensions } from '../../../../hooks/window-dimensions';

export default function useFingerprint() {
  const location = useLocation();
  const [setUserAgent] = useSetDeviceMetadataMutation();

  const onFingerprintReady = useCallback(
    (murmur: string, components: V18Component[]) => {
      const appConfig = getAppConfig();
      const windowDimensions = getWindowDimensions();

      const variables: SetDeviceMetadataMutationVariables = {
        input: {
          appName: appConfig.appName,
          appVersion: process.env.REACT_APP_VERSION!,
          isEmbedded: location.search.includes('token'),
          osName,
          osVersion,
          deviceId: murmur,
          screenSizeX: windowDimensions.width,
          screenSizeY: windowDimensions.height,
        },
      };
      setUserAgent({ variables });
    },
    [location.search, setUserAgent]
  );

  const generateFingerprint = useCallback(() => {
    if ((window as any).requestIdleCallback) {
      (window as any).requestIdleCallback(() => {
        Fingerprint2.getV18({}, onFingerprintReady);
      });
    } else {
      setTimeout(() => {
        Fingerprint2.getV18({}, onFingerprintReady);
      }, 500);
    }
  }, [onFingerprintReady]);

  return generateFingerprint;
}
