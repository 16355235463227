import {
  MutationsValidateProductArgs,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
  ProductChosenQuantityFragmentDoc,
  ProductIsValidFragmentDoc,
  ProductType,
  ProductValidationStatus,
  Product,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { isProductAvailable } from '../../helpers/is-product-available';

export function validateProduct(
  root: any,
  variables: MutationsValidateProductArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  return validateProductTree(variables.productCacheId, context.cache, context.getCacheKey, true);
}

function validateProductTree(
  productId: string,
  cache: InMemoryCache,
  getCacheKey: any,
  isRoot: boolean
): boolean {
  let isValid = true;

  const product = getProduct(productId, cache, getCacheKey);
  // Ignore products
  if (!product || !isProductAvailable(product as Product)) {
    return true;
  }

  // Ignore products that weren't chosen
  if (product.productType === ProductType.Simple && product.chosenQuantity === 0) {
    updateProductValidationStatus(product, true, cache, getCacheKey);
    return true;
  }

  // Validate children
  let validCount = 0;
  if (product.subproducts) {
    for (const subproduct of product.subproducts) {
      const isSubproductValid = validateProductTree(subproduct!.id, cache, getCacheKey, false);

      isValid = isValid && isSubproductValid;
      if (isSubproductValid) {
        validCount++;
      }
    }
  }

  if (product.productType === ProductType.Choosable) {
    isValid = validateChoosableAndUpdateQty(product, isValid, validCount, cache, getCacheKey);
    updateProductValidationStatus(product, isValid, cache, getCacheKey);
  } else if (isRoot) {
    updateProductValidationStatus(product, isValid, cache, getCacheKey);
  } else {
    // Simples or child menu items are always valid
    updateProductValidationStatus(product, true, cache, getCacheKey);
  }

  return isValid;
}

function getProduct(
  productId: string,
  cache: InMemoryCache,
  getCacheKey: any
): ProductAndSubproductsFragment | null {
  return cache.readFragment<ProductAndSubproductsFragment>({
    fragment: ProductAndSubproductsFragmentDoc,
    id: getCacheKey({ __typename: 'Product', id: productId }),
    fragmentName: 'productAndSubproducts',
  });
}

function validateChoosableAndUpdateQty(
  product: ProductAndSubproductsFragment,
  isValid: boolean,
  validCount: number,
  cache: InMemoryCache,
  getCacheKey: any
) {
  if (product.hasChoosableSubproducts) {
    isValid = validateChoosableOfChosables(product, validCount, cache, getCacheKey);
    // Note: a choosable that contains other choosables, will consider as its chosen quantities the number of valid choosable children.
    updateChosenQuantity(product, validCount, cache, getCacheKey);
  } else {
    isValid = validateRegularChoosable(product) && isValid;
  }

  return isValid;
}

function validateRegularChoosable(product: ProductAndSubproductsFragment): boolean {
  let isValid = true;
  if (
    product.chosenQuantity < product.minimumChoices! ||
    product.chosenQuantity > product.maximumChoices!
  ) {
    isValid = false;
  }

  return isValid;
}

/**
 * Validate if a choosable with other choosables as its children is valid.
 */
function validateChoosableOfChosables(
  product: ProductAndSubproductsFragment,
  validChildrenCount: number,
  cache: InMemoryCache,
  getCacheKey: any
) {
  let isValid = true;
  if (
    validChildrenCount < product.minimumChoices! ||
    validChildrenCount > product.maximumChoices!
  ) {
    isValid = false;
  }

  return isValid;
}

function updateChosenQuantity(
  product: ProductAndSubproductsFragment,
  validChildrenCount: number,
  cache: InMemoryCache,
  getCacheKey: any
) {
  cache.writeFragment({
    id: getCacheKey({ id: product.id, __typename: 'Product' }),
    fragment: ProductChosenQuantityFragmentDoc,
    data: { ...product, chosenQuantity: validChildrenCount },
  });
}

function updateProductValidationStatus(
  product: ProductAndSubproductsFragment,
  isValid: boolean,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const validationStatus = isValid
    ? ProductValidationStatus.Valid
    : ProductValidationStatus.Invalid;

  cache.writeFragment({
    id: getCacheKey({ id: product.id, __typename: 'Product' }),
    fragment: ProductIsValidFragmentDoc,
    data: { ...product, isValid: validationStatus },
  });
}
