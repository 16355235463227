import { Endpoints } from '../../../common/model/config';

export const onyoComEndpoints: Endpoints = {
  api: 'https://api.onyo.com/',
  graphql: 'https://graphql.onyo.com/graphql',
  payments: 'https://payments.onyo.com/',
  authDomain: 'user-auth.onyo.com',
  enterpriseUserForm: 'https://enterprise-user.onyo.com/',
};

export const onyoComStagingEndpoints: Endpoints = {
  api: 'https://api.staging.onyo.com/',
  graphql: 'https://graphql.staging.onyo.com/graphql',
  payments: 'https://payments.staging.onyo.com/',
  authDomain: 'user-auth.onyo.com',
  enterpriseUserForm: 'https://enterprise-user.staging.onyo.com/',
};
