import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconVisa(props: Props) {
  return (
    <SvgWrapper {...props}>
      <path
        fill='#000'
        d='M20.745 17.671c-0.023 1.799 1.604 2.803 2.829 3.4 1.259 0.613 1.681 1.005 1.677 1.553-0.010 0.838-1.004 1.208-1.935 1.223-1.624 0.025-2.568-0.438-3.319-0.789l-0.585 2.737c0.753 0.347 2.148 0.65 3.594 0.663 3.394 0 5.615-1.675 5.627-4.273 0.013-3.297-4.561-3.48-4.529-4.953 0.011-0.447 0.437-0.924 1.372-1.045 0.462-0.061 1.739-0.108 3.186 0.558l0.568-2.648c-0.778-0.284-1.779-0.555-3.024-0.555-3.195 0-5.442 1.698-5.46 4.129zM34.689 13.77c-0.62 0-1.142 0.361-1.375 0.916l-4.849 11.577h3.392l0.675-1.865h4.145l0.391 1.865h2.99l-2.609-12.494h-2.76zM35.163 17.145l0.979 4.691h-2.681l1.702-4.691zM16.633 13.77l-2.674 12.494h3.232l2.672-12.494h-3.231zM11.851 13.77l-3.364 8.504-1.361-7.231c-0.16-0.807-0.79-1.273-1.491-1.273h-5.5l-0.077 0.363c1.129 0.245 2.412 0.64 3.189 1.063 0.476 0.258 0.611 0.484 0.768 1.098l2.578 9.97h3.416l5.237-12.494h-3.394z'
      ></path>
    </SvgWrapper>
  );
}
