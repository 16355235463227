import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    quantityBtn: {
      width: '2rem',
      height: '2rem',
      borderRadius: theme.spacing(2),
      border: 'none',
      minWidth: 'auto',
      minHeight: 'auto',
      margin: 0,
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      background: theme.palette.grey[100],
    },
    value: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2rem',
      height: '2rem',
      border: 'none',
      color: theme.palette.grey[700],
      fontWeight: theme.typography.fontWeightBold as 'bold',
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);
