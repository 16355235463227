import uniqid from 'uniqid';

/**
 * Creates a new cache key in the following format: "[Typename]:[Numerical ID]".
 */
export function createNewCacheKey(typename: string): string {
  const id = uniqid.time();
  const newCacheKey = btoa(`${typename}:${id}`);
  return newCacheKey;
}
