import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const securityPad = 'env(safe-area-inset-bottom)';

  return createStyles({
    buttonContainer: {
      position: 'fixed',
      bottom: theme.spacing(1),
      left: 0,
      right: 0,
      marginBottom: 0,
      zIndex: theme.zIndex.drawer,
      paddingBottom: securityPad,

      [theme.breakpoints.down('xs')]: {
        background: theme.palette.common.white,
        borderRadius: '1rem 1rem 0 0',
        bottom: 0,
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: `calc(${theme.spacing(2)}px + ${securityPad})`,
      },
    },
    button: {
      width: '100%',
      height: '3rem',
      marginBottom: securityPad,
    },
    buttonContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textLabel: {
      marginLeft: theme.spacing(2),
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    empty: {
      display: 'none',
      visibility: 'hidden',
      width: 0,
      height: 0,
    },
  });
});
