import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      maxWidth: '100%',
      backgroundColor: theme.palette.primary.main,
      zIndex: 10,
      padding: theme.spacing(2, 0),
      flexWrap: 'wrap',
      height: theme.template?.header.size.home.mobile,

      [theme.breakpoints.between('sm', 'md')]: {
        height: theme.template?.header.size.home.tablet,
        alignItems: 'start',
      },

      [theme.breakpoints.up('md')]: {
        height: theme.template?.header.size.home.desktop,
      },
    },
    _withTabs: {
      paddingBottom: 0,
    },
    _withoutBrand: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row-reverse',
    },
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    addressWrap: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        width: '70%',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      },
    },
    userWrap: {
      width: '30%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
    logoWrap: {
      width: '30%',
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    logo: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(2),
        flexGrow: 1,
      },
    },
    growImageContainer: {
      flexGrow: 1,
    },
    image: {
      maxHeight: '2.5rem',
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    simpleTextTitle: {
      fontWeight: 'bold',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    addressPicker: {
      flexGrow: 1,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('xs')]: {
        order: 3,
        width: '100%',
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        order: 'inherit',
      },
    },
    tabOptions: {
      flexGrow: 1,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('xs')]: {
        order: 3,
        width: '100%',
        margin: 0,
      },
    },
  })
);
