export const ORDER_TYPE_COUNTER = 'counter';
export const ORDER_TYPE_TO_GO = 'to-go';
export const ORDER_TYPE_CURBSIDE = 'curbside';
export const ORDER_TYPE_TABLE = 'table';
export const ORDER_TYPE_DELIVERY = 'delivery';
export const ORDER_TYPE_CARD = 'card';
export const ORDER_TYPE_PAYMENT = 'payment';
export const ORDER_TYPE_COUPON = 'coupon';
export const ORDER_TYPE_ROOM_SERVICE = 'room-service';
export const ORDER_TYPE_PREPAID_POINTS = 'prepaid-points';
export const ORDER_TYPE_TRUCK_STOP = 'truck-stop';
export const ORDER_TYPE_EVENT = 'event';
export const ORDER_TYPE_TABLE_DIGITAL_MENU = 'table-digital-menu';

export const ORDER_TYPE_COUNTER_ID = 1;
export const ORDER_TYPE_TO_GO_ID = 2;
export const ORDER_TYPE_CURBSIDE_ID = 3;
export const ORDER_TYPE_TABLE_ID = 4;
export const ORDER_TYPE_DELIVERY_ID = 5;
export const ORDER_TYPE_CARD_ID = 6;
export const ORDER_TYPE_PAYMENT_ID = 7;
export const ORDER_TYPE_COUPON_ID = 8;
export const ORDER_TYPE_ROOM_SERVICE_ID = 9;
export const ORDER_TYPE_PREPAID_POINTS_ID = 10;
export const ORDER_TYPE_TRUCK_STOP_ID = 11;
export const ORDER_TYPE_EVENT_ID = 12;
export const ORDER_TYPE_TABLE_DIGITAL_MENU_ID = 100;
