export const BRANDS_ALLIANZ_REDIRECT = [
  '4822',
  '4664',
  '4665',
  '4823',
  '4824',
  '4825',
  '4826',
  '4827',
  '4828',
  '4829',
  '4830',
  '4831',
  '4832',
  '4833',
  '4834',
  '4835',
  '4836',
];

export const redirectAllianz = (brandId: string): boolean => {
  const isRedirect = BRANDS_ALLIANZ_REDIRECT.includes(brandId);
  return isRedirect;
};
