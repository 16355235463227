import React from 'react';
import { SelectedOrderType } from '../../../../common/generated/graphql';
import { Box, Typography, Divider, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ORDER_TYPE_DELIVERY, ORDER_TYPE_ROOM_SERVICE } from '../../../../common/model/order-types';
import { getAppConfig } from '../../../../common/helpers/configs';

interface Props {
  orderType?: SelectedOrderType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
  })
);

export default function OrderTypeExtraHeader(props: Props) {
  const classes = useStyles();

  if (!props.orderType) {
    return <Box id='no-order-type' />;
  }

  return (
    <Box>
      <Typography variant='subtitle1' className={classes.title} id='header-title'>
        {getTitle(props.orderType)}
      </Typography>
      <Divider />
    </Box>
  );
}

function getTitle(selectedOrderType: SelectedOrderType): string {
  const useGmaps = getAppConfig().featureFlags.gmapsAddress;

  if (
    (useGmaps && selectedOrderType.orderType === ORDER_TYPE_DELIVERY) ||
    selectedOrderType.orderType === ORDER_TYPE_ROOM_SERVICE
  ) {
    return 'Endereço de Entrega:';
  } else if (useGmaps) {
    return 'Endereço de Retirada:';
  } else {
    return 'Dados do Pedido:';
  }
}
