import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      overflow: 'hidden',
      boxSizing: 'border-box',
      width: '37.5rem',
      height: '42.5rem',

      [theme.breakpoints.down('sm')]: {
        width: '18.5rem',
        height: '31.25rem',
      },
    },
    close: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    imgWrap: {
      position: 'absolute',
      left: 0,
      top: 0,
      overflow: 'hidden',
      boxSizing: 'border-box',
      width: '37.5rem',
      height: '42.5rem',

      [theme.breakpoints.down('sm')]: {
        width: '18.5rem',
        height: '31.25rem',
      },
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    button: {
      marginBottom: theme.spacing(8),
      fontWeight: theme.typography.fontWeightMediumBold,

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    superText: {
      wordBreak: 'break-word',
      padding: theme.spacing(0.5),
      display: 'inline-block',
      position: 'absolute',
      maxWidth: '50%',
      maxHeight: theme.spacing(7),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
      color: theme.palette.common.white,
      lineHeight: 'normal',
      fontWeight: theme.typography.fontWeightBold as 'bold',
      textShadow: `
        0px 0px 2rem ${theme.palette.common.black},
        0px 2rem 2rem rgba(0,0,0,0.75),
        0px -2rem 2rem rgba(0,0,0,0.75)
      `,
      [theme.breakpoints.down('sm')]: {
        maxHeight: theme.spacing(6),
        maxWidth: '70%',
        padding: theme.spacing(0.5),
      },
    },
  })
);
