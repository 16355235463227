import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Checkbox, CircularProgress, Box } from '@material-ui/core';

import firebase from 'firebase';

import { useStyles } from './receipt-order-list.style';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { OrderItemTree } from '../receipt-page/receipt-page';

import ReceiptOrderChoosable from '../receipt-order-choosable/receipt-order-choosable';
import ReceiptOrderValueItem from '../receipt-order-value-item/receipt-order-value-item';
import { Maybe } from '../../../common/generated/graphql';
import { STATUS_READY_ID } from '../../../common/model/order-status';

interface Props {
  order: OrderItemTree[];
  orderId?: string;
  selectedEventItems: number[];
  setSelectedEventItems: React.Dispatch<React.SetStateAction<number[]>>;
  userId?: number;
  isEvent: boolean;
  status: Maybe<number> | undefined;
}

interface RemoteItems {
  name: Maybe<string> | undefined;
  received: boolean;
}

export default function ReceiptOrder({
  order,
  orderId,
  selectedEventItems,
  setSelectedEventItems,
  userId,
  isEvent,
  status,
}: Props) {
  const isProdEnv = process.env.NODE_ENV === 'production';
  const classes = useStyles();
  var database = firebase.database();

  const refTable = `orders/${orderId}`;
  const [remoteOrdersItems, setRemoteOrdersItems] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const push = useCallback(() => {
    const itemArray: RemoteItems[] = [];

    order.forEach((item: OrderItemTree) => {
      itemArray[item.numericalId as number] = {
        name: item.name,
        received: false,
      };
    });

    database
      .ref(refTable)
      .set({
        ...itemArray,
      })
      .catch(alert);
  }, [database, order, refTable]);

  const getData = useCallback(() => {
    const dbRef = firebase.database().ref();
    dbRef
      .child(refTable)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          let valores = snapshot.val();
          setRemoteOrdersItems(valores);
        } else if (isEvent && isProdEnv) {
          push();
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isEvent, isProdEnv, push, refTable]);

  const handleSelectedItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedEventItems([...selectedEventItems, Number(event.target.name)]);
    } else {
      setSelectedEventItems(
        selectedEventItems.filter((item) => item !== Number(event.target.name))
      );
    }
  };

  var starCountRef = firebase.database().ref(`orders`);
  starCountRef.on('child_changed', (snapshot) => {
    const data = snapshot.val();
    if (snapshot.exists()) {
      setRemoteOrdersItems(data);
      setSelectedEventItems((selected) => selected.filter((id) => !data[id]?.received));
    }
  });

  var isChecked = (numericalId: number) =>
    selectedEventItems.includes(numericalId) ||
    remoteOrdersItems[numericalId]?.received ||
    status !== STATUS_READY_ID ||
    false;

  useEffect(() => {
    getData();
  }, [getData, orderId]);

  return (
    <Grid container className={classes.root}>
      {isEvent ? (
        <Grid className={classes.eventInfoContainer}>
          <Typography className={classes.eventListInfo} variant='body2'>
            Selecione quais itens você
          </Typography>
          <Typography className={classes.eventListInfo} variant='body2'>
            quer retirar agora
          </Typography>
        </Grid>
      ) : (
        <Typography variant='body2' className={classes.title} gutterBottom>
          Seu pedido
        </Typography>
      )}

      {!loading &&
        order.map((item, index) => (
          <Grid container key={String(index)}>
            {item.name && (
              <Grid
                id='receipt-order-list-container'
                item
                container
                justifyContent='space-between'
                className={classes.item}
              >
                <Grid item container alignItems='center'>
                  <Grid item>
                    {isEvent && (
                      <Checkbox
                        color={'primary'}
                        checked={isChecked(item.numericalId as number)}
                        name={item?.numericalId?.toString()}
                        disabled={
                          remoteOrdersItems[item?.numericalId as number]?.received ||
                          status !== STATUS_READY_ID
                        }
                        key={item.numericalId}
                        onChange={handleSelectedItemsChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                  </Grid>
                  <Grid item xs>
                    <Grid container>
                      <ReceiptOrderValueItem
                        bold
                        label={item?.name}
                        value={formatPrice(item?.unitPrice)}
                        className={
                          isEvent && isChecked(item.numericalId as number) ? classes.checked : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container>
                  <ReceiptOrderChoosable items={item?.children} />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      {isEvent && (
        <Box className={classes.message}>
          <Typography variant='body2' className={classes.messageText}>
            Não se esqueça de retirar todos os itens comprados até o término do Evento! O Pede
            Pronto não é responsável por itens comprados e não retirados até o término do Evento,
            nem por serviços de conectividade de internet. Em caso de dúvidas procure os promotores
            do Pede Pronto durante o Evento.
          </Typography>
        </Box>
      )}

      {loading && (
        <Box className={classes.boxLoading}>
          <CircularProgress size={40} />
        </Box>
      )}
    </Grid>
  );
}
