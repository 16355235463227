import { AxiosError } from 'axios';
import { InMemoryCache } from '@apollo/client';
import { IsAuthenticatedQuery, IsAuthenticatedDocument } from '../generated/graphql';

export function handleAuthError(error: AxiosError, cache: InMemoryCache) {
  if (error.response?.status === 401 || error.response?.status === 403) {
    cache.writeQuery<IsAuthenticatedQuery>({
      query: IsAuthenticatedDocument,
      data: { isAuthenticated: false, authExecuted: true },
    });
  }
}
