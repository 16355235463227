import React from 'react';
import { useParams } from 'react-router';
import AggregatorCompanyGeolocatedContainer from '../aggregator-company-geolocated-container/aggregator-company-geolocated-container';
import { getAppConfig } from '../../../common/helpers/configs';
import AggregatorCompanyList from '../aggregator-company-list/aggregator-company-list';
import AggregatorTabsContainer from '../aggregator-tabs-container/aggregator-tabs-container';

export default function AggregatorHome() {
  const appConfig = getAppConfig();
  const params = useParams<{ aggregatorId: string; companyNameStartsWith: string }>();

  if (appConfig.featureFlags.gmapsAddress && !appConfig.featureFlags.showOptionsTabsAggregators) {
    return <AggregatorCompanyGeolocatedContainer aggregatorId={Number(params.aggregatorId)} />;
  } else if (
    appConfig.featureFlags.gmapsAddress &&
    appConfig.featureFlags.showOptionsTabsAggregators &&
    appConfig.takeOutAggregatorId
  ) {
    return (
      <AggregatorTabsContainer
        aggregatorId={Number(params.aggregatorId)}
        companyNameStartsWith={params.companyNameStartsWith}
      />
    );
  }
  return <AggregatorCompanyList aggregatorId={Number(params.aggregatorId)} />;
}
