import { getOnlyDigits } from './get-only-digits';

export function getZipCodeRegex(): RegExp {
  return new RegExp('^\\d{5}\\-\\d{3}$');
}

export function formatZipCode(value?: string): string {
  if (!value) {
    return '';
  }

  let digits = getOnlyDigits(value);

  if (digits.length > 8) {
    digits = digits.substring(0, 8);
  }

  const prefix = digits.substring(0, 2);
  const middle = digits.substring(2, 5);
  const sufix = digits.substring(5);

  let formatedResult: string;

  if (middle === '') {
    formatedResult = prefix;
  } else {
    if (sufix === '') {
      formatedResult = `${prefix}${middle}`;
    } else {
      formatedResult = `${prefix}${middle}-${sufix}`;
    }
  }

  return formatedResult;
}
