import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { SelectedTabDocument, SelectedTabQuery } from '../generated/graphql';

interface UpdateSelectedTab {
  (): {
    updateSelectedTab: (selectedTab: number) => void;
  };
}

export const useUpdateSelectedTab: UpdateSelectedTab = () => {
  const client = useApolloClient();

  const updateSelectedTab = useCallback(
    (selectedTab: number) => {
      client.writeQuery<SelectedTabQuery>({
        query: SelectedTabDocument,
        data: {
          selectedTab,
        },
      });
    },
    [client]
  );

  return {
    updateSelectedTab,
  };
};
