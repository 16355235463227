import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const pizzaHutTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#dd1a32',
    },
    secondary: {
      main: '#000000',
    },
    /**
     * @todo add tertiary do cliente
     */
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#dd1a32',
    },
  },
};
