import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Order } from '../../../common/generated/graphql';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { useStyles } from './receipt-order-values.style';

import ReceiptOrderValueItem from '../receipt-order-value-item/receipt-order-value-item';
import { normalizeTextCompare } from '../../../common/helpers/normalize-text-compare';

interface Props {
  orderData: Order;
}

export default function ReceiptOrderValues({ orderData }: Props) {
  const classes = useStyles();

  const delivery = orderData?.orderExtra?.find((extra) => extra?.key === 'additional');
  const discount = orderData?.orderExtra?.find((extra) => extra?.key === 'discount');
  const coupon = orderData?.payments?.find((pay) => pay?.method === 'COUPON');
  const extraDelivery = orderData?.orderExtra?.find((extra) =>
    normalizeTextCompare(extra?.label)?.includes('entrega')
  );

  return (
    <Grid container className={classes.root}>
      <ReceiptOrderValueItem
        id='receipt-order-values-subtotal'
        label='Subtotal'
        value={formatPrice(orderData?.totalValue!)}
      />
      {extraDelivery && (
        <ReceiptOrderValueItem
          id='receipt-order-values-delivey-tax'
          label='Taxa de entrega'
          value={`${formatPrice(extraDelivery?.value)}`}
        />
      )}

      {!!discount && (
        <ReceiptOrderValueItem
          id='receipt-order-values-discount-value'
          label='Desconto'
          value={`${formatPrice(discount.value)}`}
          valueColor='success'
        />
      )}

      {!!coupon && (
        <ReceiptOrderValueItem
          id='receipt-order-values-coupon-value'
          label='Cupom'
          value={`-${formatPrice(coupon.value)}`}
          valueColor='success'
        />
      )}

      {!!delivery && (
        <ReceiptOrderValueItem
          id='receipt-order-values-delivey-tax'
          label='Entrega'
          value={`+ ${formatPrice(delivery.value)}`}
          valueColor='default'
        />
      )}

      <Grid item container justify='space-between' className={classes.totalContainer}>
        <Typography variant={'body1'} className={classes.totalText}>
          Total
        </Typography>
        <Typography
          id='receipt-order-values-delivey-payable-value'
          variant={'body1'}
          className={classes.totalText}
        >
          {formatPrice(orderData?.payableValue!)}
        </Typography>
      </Grid>
    </Grid>
  );
}
