import 'firebase/analytics';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelectWalletMutation } from '../../generated/graphql';
import { getAppConfig } from '../../helpers/configs';
import { useCustomerSession } from '../../hooks/use-customer-session';
import { UnionCardBrand } from '../../model/card-brand';
import Progress from '../progress/progress';
import useCustomerLogin from './hooks/use-customer-login/use-customer-login';
import useFingerprint from './hooks/use-fingerprint/use-fingerprint';
import useLoadFromBrowserCache from './hooks/use-load-from-browser-cache/use-load-from-browser-cache';
import useMiniAppInit from './hooks/use-mini-app-init/use-mini-app-init';

export default function Startup(props: PropsWithChildren<any>) {
  const { customerSession } = useCustomerSession();
  const loadFromBrowserCache = useLoadFromBrowserCache();
  const customerLogin = useCustomerLogin();
  const generateFingerprint = useFingerprint();
  const miniAppLogin = useMiniAppInit();
  const [selectWallet] = useSelectWalletMutation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initApp = async () => {
      await loadFromBrowserCache();

      generateFingerprint();

      const config = getAppConfig();
      if (config.featureFlags.isCieloMiniApp) {
        miniAppLogin();
        const cieloPayOption: UnionCardBrand = 'cielo_pay';
        selectWallet({ variables: { cardBrand: cieloPayOption } });
      } else if (customerSession) {
        await customerLogin();
      }

      setLoading(false);
    };
    initApp();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Progress />;
  }

  return <div>{props.children}</div>;
}
