import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { getAppConfig } from '../../helpers/configs';
import { useSetGmapsReadyMutation } from '../../generated/graphql';

export default function PageSetup(props: React.PropsWithChildren<any>) {
  const config = getAppConfig();
  const [setGmapsReady] = useSetGmapsReadyMutation();

  (window as any).initMaps = useCallback(() => {
    setGmapsReady({ variables: { ready: true } });
  }, [setGmapsReady]);

  return (
    <div>
      <Helmet>
        <title>{config.appTitle}</title>
        <link id='favicon' rel='icon' href={config.faviconPath} type='image/x-icon' />
        <meta name='description' content={config.appDescription} />
        {config.featureFlags.gmapsAddress && (
          <script
            async
            defer
            src='https://maps.googleapis.com/maps/api/js?key=AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc&libraries=places&callback=initMaps'
          ></script>
        )}
      </Helmet>
      {props.children}
    </div>
  );
}
