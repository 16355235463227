import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
    },
    paper: {
      width: '100vw',
      maxWidth: '30rem',
      borderRadius: '1.125rem',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.between('xs', 'sm')]: {
        maxWidth: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        overflowX: 'hidden',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      padding: theme.spacing(3, 3, 2),
      textAlign: 'center',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    message: {
      marginTop: theme.spacing(1),
      color: theme.palette.grey[600],
    },
    btnIcon: {
      padding: theme.spacing(2),
      position: 'absolute',
      right: 0,
      top: 0,
    },
    closeIcon: {
      width: '1.125rem',
      height: '1.125rem',
    },
  })
);
