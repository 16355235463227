import React, { ReactElement, useCallback } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { useFormikContext, useField } from 'formik';
import { GenericSelectInput } from '../../model/generic-select-input';

export default function GenericSelectField(props: GenericSelectInput): ReactElement {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event) => {
      setFieldValue(props.name, event.target.value);
    },
    [props.name, setFieldValue]
  );

  return (
    <FormControl
      error={meta.error && meta.touched ? true : false}
      className={props.className}
      fullWidth={true}
    >
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        id={props.id}
        value={field.value}
        onChange={handleChange}
        inputProps={{ name: field.name, id: `${props.id}-input` }}
        placeholder={props.placeholder}
      >
        {props.data?.map((data, itr) => (
          <MenuItem key={itr} value={data.value} id={`menu-item-${itr}`}>
            {data.text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id={`${props.id}-helper-text`}>
        {meta.error && meta.touched ? meta.error : ''}
      </FormHelperText>
    </FormControl>
  );
}
