import { Box, ListItem, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { useStyles } from './product-list-item.style';
import { useParams } from 'react-router-dom';
import { ProductListItemProps, MountProductImg } from '../../models/product-list-item-props';
import { CompanyPage } from '../../models/company-page';
import { useProductDetailsDialog } from '../../../common/hooks/use-product-details-dialog';
import { logViewItem } from '../../../analytics/helpers/log-view-item';
import { mountAnalyticsItem } from '../../../analytics/helpers/item-utils';

export default function ProductListItem(props: ProductListItemProps): React.ReactElement {
  const classes = useStyles();
  const { onActiveProductDetails } = useProductDetailsDialog();
  let { pageType, companyId, brandId } = useParams<{
    pageType: string;
    companyId: string;
    brandId: string;
  }>();

  const isSimpleMenu = pageType === CompanyPage.Simple;

  const onClickToProduct = useCallback(() => {
    if (props.product.node!.product!.numericalId && !isSimpleMenu) {
      onActiveProductDetails(props.product.node!.product!.numericalId);

      const product = mountAnalyticsItem({
        itemId: props.product.node!.product!.numericalId.toString(),
        price: Number(props.product.node!.price),
        itemCategory: props.product.node!.product!.category?.name!,
        isOffer: props.product.node!.product!.offer?.toString(),
      });
      logViewItem({ brandId, companyId, items: [product] });
    }
  }, [props.product, isSimpleMenu, onActiveProductDetails, brandId, companyId]);

  const mountProductImg: MountProductImg = useCallback((productCompany, definedClass) => {
    for (const img of productCompany!.product!.images!) {
      if (img && img.context === 'menu-product' && img.url) {
        return <img src={img.url} className={definedClass} alt='' />;
      }
    }
  }, []);

  const node = props.product.node!;
  const desc = node!.product?.shortDescription;

  return (
    <ListItem
      divider={!props.last}
      onClick={onClickToProduct}
      className={`${classes.listItem} ${isSimpleMenu && classes.simple}`}
      classes={{
        divider: classes.divider,
      }}
      id={`list-item-component-${node!.id}`}
    >
      <Box className={classes.info}>
        <Typography className={classes.title} variant='body1'>
          {node!.product!.name}
        </Typography>
        <Typography className={classes.price} variant='body1'>
          {formatPrice(node!.price!)}
        </Typography>
        {desc && desc !== 'None' && (
          <Typography className={classes.description} variant='body2'>
            {desc}
          </Typography>
        )}
      </Box>
      <Box className={classes.wrapImg} onClick={onClickToProduct}>
        {mountProductImg(node!, classes.productImg)}
      </Box>
    </ListItem>
  );
}
