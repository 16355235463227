import { InMemoryCache } from '@apollo/client';
import { loadOrderTypeFromCache } from '../../browser-cache/order-type/load-order-type-from-cache';
import { GetSelectedOrderTypeQuery, GetSelectedOrderTypeDocument } from '../../generated/graphql';

export async function loadSelectedOrderTypeFromBrowserCache(
  root: any,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const orderType = await loadOrderTypeFromCache();
  context.cache.writeQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
    data: { selectedOrderType: orderType },
  });

  return true;
}
