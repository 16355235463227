import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './inner-input-label.style';

interface Props {
  label: string;
  required?: boolean;
}

export default function InnerInputLabel({ label, required }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <span id='inner-input-label' className={classes.label}>
        {label}
      </span>
      {required && <span id='inner-input-label-required'>Obrigatório *</span>}
    </Box>
  );
}
