import { Maybe } from 'graphql/jsutils/Maybe';
import { UnionSizeOptions } from '../model/theme-options';
export const getSize = (size: Maybe<UnionSizeOptions> = 'medium'): number => {
  const defaultUnit = 8;

  switch (size) {
    case 'small':
      return defaultUnit * 1;
    case 'medium':
      return defaultUnit * 2;
    case 'large':
      return defaultUnit * 3;
    case 'extraLarge':
      return defaultUnit * 4;
    default:
      return defaultUnit * 2;
  }
};
