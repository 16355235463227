import React from 'react';

import { Grid, Typography, Box, Fade } from '@material-ui/core';

import { CompanySharedEdge, Maybe } from '../../../common/generated/graphql';

import { useStyles } from './aggregator-footer.styles';
import StackAvatars from '../../../common/components/stack-avatars/stack-avatars';

interface hiddenCompaniesParse {
  src?: string;
  alt: string;
  disabled: boolean;
}

interface Props {
  companiesCard: Maybe<CompanySharedEdge>[];
  showDetail(): void;
  nameAggregator?: string | null;
  visibleNames: string;
  companiesHiddenAvatar: hiddenCompaniesParse[];
  limitShowAvatar: number;
  limitShowCompanies: number;
}

export default function AggregatorFooter(props: Props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.footerRoot} id='container-item'>
      {props.companiesCard.length > props.limitShowCompanies && (
        <Fade in={true} timeout={1000}>
          <Grid id='conteiner-footer' container direction='row' className={classes.footerContainer}>
            <Box
              id='show-details'
              className={classes.footerContainerText}
              onClick={props.showDetail}
            >
              <Typography variant='h6' className={classes.footerTitle}>
                Conheça outras opções
              </Typography>
              <Typography id='visibles-names'>{`No ${props.nameAggregator} você também encontra: ${props.visibleNames}`}</Typography>
            </Box>
            <Box>
              <StackAvatars avatars={props.companiesHiddenAvatar} limit={props.limitShowAvatar} />
            </Box>
          </Grid>
        </Fade>
      )}
    </Grid>
  );
}
