import { getOnlyDigits } from './get-only-digits';

export function formatCardExpiration(value: string): string {
  const valueNumber = getOnlyDigits(value).substring(0, 4);
  if (valueNumber.length > 2) {
    return valueNumber.substring(0, 2) + '/' + valueNumber.substring(2, 4);
  } else {
    return valueNumber;
  }
}
