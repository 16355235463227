import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Address } from '../../../common/generated/graphql';
import { useStyles } from './aggregator-container-header.style';
import { getDistanceLatLonKm } from '../../../common/helpers/get-distance-lat-lon-km';
import useUserCoords from '../../hooks/use-user-coords';

interface Props {
  title?: string | null;
  onClickIcon(): void;
  icon?: 'ArrowBack' | 'ArrowForward';
  showAction?: boolean;
  address?: Address | null;
}

export default function AggregatorContainerHeader(props: Props) {
  const classes = useStyles();
  const userLocation = useUserCoords();
  let distanceKm = undefined;

  if (userLocation && props.address?.geoLat && props.address?.geoLon) {
    distanceKm = getDistanceLatLonKm({
      startLatitude: userLocation.latitude,
      startLongitude: userLocation.longitude,
      endLatitude: props.address?.geoLat,
      endLongitude: props.address?.geoLon,
    });
  }

  return (
    <Grid container>
      <Grid container item className={classes.containerHeader}>
        <Typography id='header-title' variant='h6' className={classes.title}>
          {props.title}
        </Typography>
        {props.showAction && (
          <Box
            id='header-arrow-click'
            className={classes.showMoreContainer}
            onClick={props.onClickIcon}
          >
            {props.icon === 'ArrowBack' ? (
              <ArrowBackIcon
                data-testid='header-arrow-back'
                id='header-arrow-back'
                className={classes.iconArrow}
              />
            ) : (
              <ArrowForwardIcon
                data-testid='header-arrow-forward'
                id='header-arrow-forward'
                className={classes.iconArrow}
              />
            )}
          </Box>
        )}
      </Grid>
      {!!distanceKm && (
        <Typography id='header-distance' variant='caption'>
          {`Localizado à ${distanceKm.toFixed(1)} km`}
        </Typography>
      )}
    </Grid>
  );
}
