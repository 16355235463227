import { ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type LogViewBanner = {
  banner_id: string;
};

const event = 'view_banner';

export function logViewBanner({ banner_id }: LogViewBanner) {
  logAnalytics({
    event,
    [ParamName.BANNER_ID]: banner_id,
  });
}

export function logViewBannerError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
