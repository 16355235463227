import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    paper: {
      borderRadius: `1.125rem`,
      position: 'relative',
      overflow: 'hidden',
      width: '23rem',
      textAlign: 'center',
      padding: theme.spacing(4),
      boxSizing: 'border-box',
    },
    description: {
      fontSize: '0.875rem',
      maxWidth: '12.5rem',
      alignSelf: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    close: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      justifyContent: 'flex-end',
    },
    btnIcon: {
      width: '2.5rem',
      height: '2.5rem',
      padding: theme.spacing(2),
      cursor: 'pointer',
      background: theme.palette.common.white,
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    closeIcon: {
      width: '1.125rem',
      height: '1.125rem',
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
    },
    button: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      textTransform: 'capitalize',
    },
    logo: {
      width: '14.35rem',
      alignSelf: 'center',
    },
  })
);
