import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    OrderCouponBox: {
      padding: theme.spacing(1, 0),
    },
  })
);
