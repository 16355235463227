import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const refeicaoOnlineTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#757575',
    },
    /**
     * @todo add tertiary do cliente
     */
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#f65a02',
    },
  },
};
