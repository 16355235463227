import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  GetLastOrdersDocument,
  IsAuthenticatedDocument,
  useIsAuthenticatedQuery,
  useLogoutMutation,
} from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import { getAppConfig } from '../../helpers/configs';
import { useStyles } from './profile-menu.style';
import Icon from '../icon/icon';
import SuggestRestaurantModal from '../suggest-restaurant-modal/suggest-restaurant-modal';
import { logLogout, logLogoutError } from '../../../analytics/helpers/log-logout';
import { ApolloError } from '@apollo/client';
import { logSelectMenuOptions } from '../../../analytics/helpers/log-select-menu-options';
import { logSelectHelp } from '../../../analytics/helpers/log-select-help';

interface Props {
  mainHeader?: boolean;
}

export default function ProfileMenu({ mainHeader = false }: Props) {
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useStyles();
  const params = useParams<{ aggregatorId: string }>();
  const appConfig = getAppConfig();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const { data, loading, error } = useIsAuthenticatedQuery({
    onError: (err) => {
      logError(err, 'Error executing Is Authenticated Query');
      logLogoutError({ errorCode: 30, errorMessage: err.message });
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!data!.isAuthenticated) {
      history.push(`/${params.aggregatorId}/login`, { from: location.pathname });
    } else {
      logSelectMenuOptions();
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    setAnchorEl(null);
    setOpenModal(!openModal);
  };

  const handleProfile = useCallback(() => {
    history.push(`/${params.aggregatorId}/user-profile`, { backUrl: location.pathname });
  }, [history, location, params]);

  const handleOrders = useCallback(() => {
    history.push(`/${params.aggregatorId}/orders`, { backUrl: location.pathname });
  }, [history, location, params.aggregatorId]);

  const handleDiscountForm = useCallback(() => {
    history.push(`/${params.aggregatorId}/discount-form`, { backUrl: location.pathname });
  }, [history, location, params]);

  const handleCoupons = useCallback(() => {
    history.push(`/${params.aggregatorId}/coupons`, { backUrl: location.pathname });
  }, [history, location, params]);

  const handleAbout = useCallback(() => {
    logSelectHelp({
      transaction_id: null,
      value: null,
    });

    history.push('/about', { backUrl: location.pathname });
  }, [history, location]);

  const [logout] = useLogoutMutation({
    refetchQueries: [{ query: IsAuthenticatedDocument }, { query: GetLastOrdersDocument }],
    onError: (err) => {
      logLogoutError({ errorMessage: err.message, errorCode: 31 });
      throw new ApolloError(err);
    },
  });
  const handleLogout = useCallback(async () => {
    logLogout();
    await logout();
    window.location.reload();
  }, [logout]);

  if (loading || error) {
    return <Box />;
  }

  return (
    <>
      <Box>
        <Box className={classes.root} onClick={handleClick} id='profile-menu-button'>
          <IconButton aria-controls='simple-menu' aria-haspopup='true'>
            <Icon name='user' size={mainHeader ? 'large' : 'extraLarge'} color={'light'} />
          </IconButton>
          {!data!.isAuthenticated && (
            <Typography variant='body1' className={classes.label}>
              Entrar
            </Typography>
          )}
        </Box>
        <Menu
          style={{ zIndex: theme.zIndex.tooltip }}
          className={classes.menu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!appConfig.featureFlags.embeddedApplication && (
            <MenuItem id='profile-button' onClick={handleProfile}>
              Meu Perfil
            </MenuItem>
          )}
          <MenuItem id='orders-button' onClick={handleOrders}>
            Pedidos
          </MenuItem>
          <MenuItem id='discount-form-button' onClick={handleCoupons}>
            Cupons
          </MenuItem>
          <MenuItem id='discount-form-button' onClick={handleDiscountForm}>
            Descontos
          </MenuItem>
          <MenuItem id='about-button' onClick={handleAbout}>
            Ajuda
          </MenuItem>
          {!appConfig.featureFlags.embeddedApplication && (
            <MenuItem id='profile-menu-item-logout' onClick={handleLogout}>
              Sair
            </MenuItem>
          )}
        </Menu>
      </Box>
      <SuggestRestaurantModal open={openModal} onClose={handleChange} />
    </>
  );
}
