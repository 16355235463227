import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useGetOrderTotalQuery, OrderExtra } from '../../../common/generated/graphql';
import { formatPrice } from '../../../common/helpers/format-price.helper';
import { logError } from '../../../common/helpers/log-error';
import { useStyles } from './order-total.style';

import NetworkError from '../../../common/components/network-error/network-error';
import OrderCoupon from '../order-coupon/order-coupon';
import { useLocation } from 'react-router-dom';

interface Props
  extends RouteComponentProps<{
    aggregatorId: string;
    brandId: string;
    companyId: string;
  }> {}

export default withRouter(OrderTotal);
function OrderTotal(props: Props): JSX.Element {
  const classes = useStyles();
  let orderTotal: string;
  const { data, loading, error, refetch } = useGetOrderTotalQuery({
    variables: { companyId: Number(props.match.params.companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err) => logError(err, 'Error executing Get Order Total Query'),
  });

  const location = useLocation();

  const header = (
    <Box>
      <Typography variant='h6'>Total do Pedido</Typography>
      <Divider />
    </Box>
  );

  if (!loading && error) {
    return (
      <Box>
        {header}
        <NetworkError
          errorMsg='Erro ao obter o total do pedido'
          compact={true}
          retry={refetch}
          error={error}
          path={location.pathname}
        />
      </Box>
    );
  }

  if (loading || !data || !data.shoppingCart || data.shoppingCart.orderTotal === 0) {
    orderTotal = 'R$ 0,00';
  } else {
    orderTotal = formatPrice(data.shoppingCart.orderTotal);
  }

  const couponData = data?.orderCheck?.extras?.find((extra: any) => extra?.key === 'coupon');

  const extras: any =
    data && data!.orderCheck && data!.orderCheck.extras ? data.orderCheck.extras : [];

  return (
    <>
      <List>
        {header}

        {extras
          .filter((extra: any) => extra.value)
          .map((orderExtra: any) => (
            <ListItem
              key={orderExtra!.id ?? orderExtra!.key}
              className={`${classes.item} ${orderExtra!.value < 0 && classes.discount}`}
            >
              <Typography variant='body1' className='order-extra-label'>
                {orderExtra!.label === 'BOX DELIVERY' ? 'Entrega' : orderExtra!.label}
              </Typography>
              <Typography variant='body1' className='order-extra-value'>
                {formatPrice(orderExtra!.value)}
              </Typography>
            </ListItem>
          ))}
        <ListItem className={classes.item}>
          <Typography variant='body1'>Total</Typography>
          <Typography variant='body1' id='order-total'>
            {orderTotal}
          </Typography>
        </ListItem>
      </List>
      <OrderCoupon data={couponData as OrderExtra} />
    </>
  );
}
