import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core';

interface PropsResize {
  theme: Theme;
  small?: number;
  medium?: number;
  large?: number;
}

const defaultSmall = 3;
const defaultMedium = defaultSmall * 2;
const defaultLarge = defaultSmall * 3;

function useSizeBreakpoints({
  theme,
  small = defaultSmall,
  medium = defaultMedium,
  large = defaultLarge,
}: PropsResize) {
  let size = small;
  const bkLarge = useMediaQuery(theme?.breakpoints?.up('lg'));
  const bkMedium = useMediaQuery(theme.breakpoints?.up('md'));

  if (bkLarge) {
    size = large;
  } else if (bkMedium) {
    size = medium;
  } else {
    size = small;
  }

  return size;
}

export default useSizeBreakpoints;
