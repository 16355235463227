import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const becoVilaOlimpiaTheme: ThemeOptions = {
  palette: {
    primary: { main: '#000000' },
    secondary: {
      main: '#00008B',
    },
    /**
     * @todo add tertiary do cliente
     */
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#c62b39',
    },
  },
};
