import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import { Company } from '../../../common/generated/graphql';

import { useStyles } from './receipt-header.style';
import { parseImage } from '../../../common/helpers/parse-image';

interface Props {
  company: Company;
}

export default function ReceiptHeader({ company }: Props) {
  const classes = useStyles();

  return (
    <Grid item container alignItems='center' wrap='nowrap' className={classes.root}>
      <Grid item>
        <Avatar
          id='receipt-head-logo'
          alt='loja'
          className={classes.logo}
          src={parseImage(company?.brand?.images, 'brand-aggregator-company-list')}
        >
          {company?.name?.trimLeft()?.charAt(0)}
        </Avatar>
      </Grid>
      <Grid item container className={classes.containerText} direction='column' zeroMinWidth xs>
        <Grid item zeroMinWidth xs>
          <Typography id='receipt-company-name' variant='h6' className={classes.title} noWrap>
            {company?.name}
          </Typography>
        </Grid>
        <Grid item zeroMinWidth xs>
          <Typography id='receipt-company-adress' variant='body2' color='textSecondary'>
            {`${company?.address?.street}, ${company?.address?.number} - ${company?.address?.city}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
