export const whatsappRedirect = (
  order: string,
  ec: string,
  name: string,
  email: string,
  phone: number
): string => {
  const message = encodeURI(`
  * * * *PEDE PRONTO* * * *

  *CONSUMIDOR*: ${name.toLocaleUpperCase()} (${email.toLocaleLowerCase()})

  *EC*: ${ec.toLocaleUpperCase()}

  *NÚMERO DO PEDIDO*: ${order}
  `);

  return `https://wa.me/${phone}?text=${message}`;
};
