import * as Sentry from '@sentry/browser';
import { getSharedConfig } from '../common/helpers/configs';

export function initSentry() {
  const sharedConfig = getSharedConfig();
  if (sharedConfig.sentryDSN && sharedConfig.sentryDSN !== '') {
    Sentry.init({
      release: process.env.REACT_APP_VERSION,
      dsn: sharedConfig.sentryDSN,
    });
  }
}
