import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { PaymentMethod as PaymentMethodType } from '../../../common/generated/graphql';
import { useStyles } from './selected-payment-method.style';
import { Maybe } from 'graphql/jsutils/Maybe';
import PaymentMethod from '../../../common/components/payment-method/payment-method';
import formatPaymentMethod from '../../../common/helpers/format-payment-method';

interface Props {
  selectedPayment: Maybe<PaymentMethodType>;
  openPaymentMethodDialog: () => void;
}

export default function SelectedPaymentMethod(props: Props) {
  const classes = useStyles();

  const onChangePaymentMethodClick = () => props.openPaymentMethodDialog();

  if (!props.selectedPayment) {
    return (
      <Box id='without-selected-payment'>
        <Typography variant='body1' className={classes.noCardText}>
          Você não tem nenhum cartão cadastrado.
        </Typography>
        <Button
          id='add-new-payment-method'
          variant='contained'
          color='secondary'
          onClick={onChangePaymentMethodClick}
        >
          modos de pagamento
        </Button>
      </Box>
    );
  }

  if (props.selectedPayment?.__typename === 'Wallet') {
    return <Box id='empty' />;
  }

  const paymentMethodsArgs = formatPaymentMethod(props.selectedPayment);

  return (
    <Box id='common-payment-method'>
      {paymentMethodsArgs && (
        <PaymentMethod
          data-testid='payment-method'
          {...paymentMethodsArgs}
          primaryButtonText='Trocar'
          onPrimaryButtonClick={onChangePaymentMethodClick}
        />
      )}
    </Box>
  );
}
