import React, { useRef } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Collapse,
  Button,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined as IconInfo } from '@material-ui/icons';

import { formatPrice } from '../../../common/helpers/format-price.helper';
import { useStyles } from './repeat-order.style';
import { ShoppingCart } from '../../../common/generated/graphql';

interface Props {
  onRepeatOrder: () => void;
  cartCompany: ShoppingCart;
}

export default function RepeatOrder({ cartCompany, onRepeatOrder }: Props) {
  const [showMore, setShowMore] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const collapsedHeight = cartCompany?.products.length > 3 ? 80 : 40;
  const classes = useStyles();
  const ref = useRef(null);

  const handleChange = () => {
    setShowMore((showMore) => !showMore);
    setShowInfo(false);
  };

  const handleChangeInfo = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowInfo((setShowInfo) => !setShowInfo);
  };

  const handleRepeatOrder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onRepeatOrder();
  };

  return (
    <Grid container className={classes.repeatOrderContainer} id='ref-company-repeat-order'>
      <Grid item zeroMinWidth className={classes.repeatOrderContent}>
        <Paper
          id='repeat-order-container-click'
          className={classes.repeatOrderAction}
          onClick={handleChange}
          elevation={1}
          innerRef={ref}
        >
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography className={classes.repeatOrderTitle}>Peça novamente</Typography>
            </Grid>
            <Grid item>
              <Tooltip
                id='repeat-order-tooltip'
                title='Os preços podem sofrer alterações'
                placement='bottom-end'
                arrow={true}
                classes={{ tooltip: classes.tooltipInfo, arrow: classes.tooltipArrow }}
                open={showInfo}
                onClick={(event) => handleChangeInfo(event)}
              >
                <IconInfo className={classes.iconInfo} id='repeat-order-tooltip-icon' />
              </Tooltip>
            </Grid>
          </Grid>
          <Collapse in={showMore} collapsedSize={collapsedHeight} className={classes.collapse}>
            <Box className={classes.containerItens}>
              {cartCompany?.products.map((item, index) => (
                <Box
                  key={String(index)}
                  id='repeat-order-item'
                  className={classes.containerTextItem}
                >
                  <Typography
                    component='span'
                    className={classes.repeatOrderAmountItem}
                    variant='body2'
                  >
                    {'1x'}
                  </Typography>
                  <Typography component='span' className={classes.repeatOrderItemText} noWrap>
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            {!showMore ? (
              <Box className={classes.gradient} />
            ) : (
              <>
                <Divider light className={classes.repeatOrderDivider} />
                <Grid container justifyContent='space-between'>
                  <Typography
                    component='span'
                    id='repeat-order-total'
                    className={classes.repeatOrderItemTextTotal}
                  >
                    Total
                  </Typography>
                  <Typography
                    component='span'
                    id='repeat-order-total-show-more'
                    className={classes.repeatOrderItemTextTotal}
                  >
                    {formatPrice(cartCompany?.totalProductsPrice)}
                  </Typography>
                </Grid>
                <Button
                  id='repeat-order-button'
                  variant='contained'
                  color='secondary'
                  fullWidth
                  className={classes.repeatOrderButton}
                  onClick={(event) => handleRepeatOrder(event)}
                >
                  Repetir pedido
                </Button>
              </>
            )}
          </Collapse>

          {!showMore && (
            <Typography id='repeat-order-total' className={classes.repeatOrderItemTextTotal}>
              {formatPrice(cartCompany?.totalProductsPrice)}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
