import { Box } from '@material-ui/core';
import React from 'react';
import { SelectedOrderType } from '../../../../common/generated/graphql';
import { ORDER_TYPE_DELIVERY } from '../../../../common/model/order-types';
import OrderTypeCompanyAddress from '../order-type-company-address/order-type-company-address';
import OrderTypeDeliveryAddress from '../order-type-delivery-address/order-type-delivery-address';
import OrderTypeFields from '../order-type-fields/order-type-fields';
import { getAppConfig } from '../../../../common/helpers/configs';

interface Props {
  orderType?: SelectedOrderType;
}

export default function OrderTypeExtraData(props: Props) {
  const appConfig = getAppConfig();
  const useGmaps = appConfig.featureFlags.gmapsAddress;

  if (!props.orderType) {
    return <Box id='no-order-type' />;
  }

  if (useGmaps && props.orderType.orderType === ORDER_TYPE_DELIVERY) {
    return <OrderTypeDeliveryAddress orderType={props.orderType} />;
  } else if (useGmaps) {
    return <OrderTypeCompanyAddress orderType={props.orderType} />;
  } else {
    return <OrderTypeFields orderType={props.orderType} />;
  }
}
