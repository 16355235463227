import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const novaAmericaShopping: ThemeOptions = {
  palette: {
    primary: {
      main: '#013765',
    },
    secondary: {
      main: '#0391cf',
    },
    /**
     * @todo add tertiary do cliente
     */
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#b9a69c',
    },
  },
};
