import { OrderItemTree } from '../components/receipt-page/receipt-page';
import { Maybe } from '../../common/generated/graphql';

function filterSimple(items: Maybe<OrderItemTree>[] = []): Maybe<OrderItemTree>[] {
  let simples = items?.reduce(function (prev: any, curr: any) {
    let arr = [];
    let prodName = curr?.name;

    if (curr?.productType !== 'SIMPLE') {
      prodName = null;
    }
    arr = [...prev, prodName, ...filterSimple(curr?.children)];

    return arr;
  }, []);

  return simples;
}

export function getTextSimples(simples: Maybe<OrderItemTree>[]) {
  const dataSimples = filterSimple(simples);

  const textSimples = dataSimples.filter((el) => {
    return !!el;
  });

  return textSimples;
}
