export const firebaseOnyoStaging = {
  apiKey: 'AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc',
  databaseURL: 'https://brandapplication.firebaseio.com',
  projectId: 'brandapplication',
  storageBucket: 'brandapplication.appspot.com',
  messagingSenderId: '240285417545',
  appId: '1:240285417545:web:e43552bfd3682ec8fc7f98',
  measurementId: 'G-3H981N66E5',
};

export const firebaseOnyoProduction = {
  apiKey: 'AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc',
  databaseURL: 'https://brandapplication.firebaseio.com',
  projectId: 'brandapplication',
  storageBucket: 'brandapplication.appspot.com',
  messagingSenderId: '240285417545',
  appId: '1:240285417545:web:0a3c8bc6ce5d62aefc7f98',
  measurementId: 'G-TT5DQT5P9V',
};
