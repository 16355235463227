import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import AddressField from '../address-field/address-field';
import { UserAddressInput } from '../../../common/generated/graphql';

interface Props {
  onCancelClick: () => void;
  onOkClick: (address: UserAddressInput) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
    },
    field: {
      margin: theme.spacing(1, 0),
    },
    userLocation: {
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function GmapsAddressForm(props: Props) {
  const classes = useStyles();
  const onSubmit = useCallback(
    (values) => {
      props.onOkClick(values.address);
    },
    [props]
  );

  const validation = Yup.object().shape({
    address: Yup.object().test(
      'Required',
      'Esse campo é obrigatório',
      (value: any) =>
        value?.latitude && value?.longitude && value?.placeId && value?.formattedAddress
    ),
  });

  return (
    <Formik
      validationSchema={validation}
      onSubmit={onSubmit}
      initialValues={{
        address: '',
      }}
    >
      <Form>
        <DialogContent>
          <AddressField
            id='address-field'
            name='address'
            label='Endereço e número'
            placeholder='Buscar endereço e número'
            className={classes.field}
            submitOnClick={true}
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' id='cancel-btn' onClick={props.onCancelClick}>
            Cancelar
          </Button>
          <Button color='primary' id='ok-btn' type='submit'>
            Ok
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
