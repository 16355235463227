import { GetCardsQueryVariables, GetOrdersQuery } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { getEndpoints } from '../../helpers/configs';
import { handleAuthError } from '../../helpers/handle-auth-error';
import { logError } from '../../helpers/log-error';

export default async function orders(
  root: any,
  variables: GetCardsQueryVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<GetOrdersQuery[]> {
  const endpoints = getEndpoints();
  const url = `${endpoints.api}v3/mobile/orders`;

  try {
    const { data } = await axios.get(url);
    return data.orders;
  } catch (error) {
    handleAuthError(error as AxiosError, context.cache);
    logError(error, 'Error retrieving user orders.');
    throw new Error('Não foi possível obter os seus pedidos.');
  }
}
