import { AppConfig } from '../../../common/model/config';
import { northShoppingJoqueiTheme } from '../themes/north-shopping-joquei.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const northShoppingJoqueiConfig: AppConfig = {
  appName: 'north-shopping',
  appTitle: 'North Shopping Jóquei',
  appDescription: 'Peça aqui nos restaurantes do North Shopping Jóquei.',
  aggregator: 1560,
  brand: 1457,
  theme: northShoppingJoqueiTheme,
  faviconPath: '/favicons/favicon-north-shopping.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
