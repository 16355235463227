import * as forge from 'node-forge';
import { InMemoryCache } from '@apollo/client';
import { GetCvvCriptoKeyQuery, GetCvvCriptoKeyDocument } from '../generated/graphql';
import { PaymentCall } from '../model/payment-call.interface';

export function encryptCvv(cvv: string, brandId: number, cache: InMemoryCache): string {
  let encryptedCvv = '';

  const query = cache.readQuery<GetCvvCriptoKeyQuery>({
    query: GetCvvCriptoKeyDocument,
    variables: { brandId },
  });

  if (query) {
    const key = query.brandBalance.reference;
    encryptedCvv = encryptText(cvv.toString(), key);
  }

  return encryptedCvv;
}

export function encryptTicketCardNumber(cardNumber: string, paymentCall: PaymentCall): string {
  const asn1Key = forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.SEQUENCE, true, [
    // AlgorithmIdentifier
    forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.SEQUENCE, true, [
      // algorithm
      forge.asn1.create(
        forge.asn1.Class.UNIVERSAL,
        forge.asn1.Type.OID,
        false,
        forge.asn1.oidToDer(forge.pki.oids['rsaEncryption']).getBytes()
      ),
      // parameters (null)
      forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.NULL, false, ''),
    ]),
    // subjectPublicKey
    forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.BITSTRING, false, [
      // RSAPublicKey
      forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.SEQUENCE, true, [
        // modulus (n)
        forge.asn1.create(
          forge.asn1.Class.UNIVERSAL,
          forge.asn1.Type.INTEGER,
          false,
          atob(paymentCall.publicKeyModulus)
        ),
        // publicExponent (e)
        forge.asn1.create(
          forge.asn1.Class.UNIVERSAL,
          forge.asn1.Type.INTEGER,
          false,
          atob(paymentCall.publicKeyExponent)
        ),
      ]),
    ]),
  ]);

  // const derBuffer = forge.asn1.toDer(asn1Key);
  const pubKey: any = forge.pki.publicKeyFromAsn1(asn1Key);
  return btoa(pubKey.encrypt(cardNumber, 'RSA-OAEP'));
}

/**
 * Enctrypts the CVV using the RSA-OAEP cypher.
 * The algorithm only works with chunks of text of length less than or equal to 64 characters. Therefore we break the text in chunks before encrypting.
 * Note: the key comes from the "reference" parameter in the "v2/mobile/brand/{brand_id}/balance?user=True" call.
 * There is one unique key per user / brand pair. All keys are stored in the backend_serverkey table.
 */
export function encryptText(text: string, key: string) {
  const asn1Key = forge.asn1.fromDer(atob(key));
  const pubKey: any = forge.pki.publicKeyFromAsn1(asn1Key);

  let encrypted = '';

  const textChunks = text.match(/.{1,64}/g);
  textChunks!.forEach((textChunk) => {
    encrypted += pubKey.encrypt(textChunk, 'RSA-OAEP');
  });

  return btoa(encrypted);
}
