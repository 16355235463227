import { UnionIconOptions } from '../../common/components/icon/helpers/type-icon';
import { TypesOrder } from './types-order';
import {
  ORDER_TYPE_COUNTER_ID,
  ORDER_TYPE_TO_GO_ID,
  ORDER_TYPE_CURBSIDE_ID,
  ORDER_TYPE_TABLE_ID,
  ORDER_TYPE_DELIVERY_ID,
  ORDER_TYPE_CARD_ID,
  ORDER_TYPE_PAYMENT_ID,
  ORDER_TYPE_COUPON_ID,
  ORDER_TYPE_ROOM_SERVICE_ID,
  ORDER_TYPE_PREPAID_POINTS_ID,
  ORDER_TYPE_TRUCK_STOP_ID,
  ORDER_TYPE_EVENT_ID,
  ORDER_TYPE_TABLE_DIGITAL_MENU_ID,
} from '../../common/model/order-types';

type Attr = {
  label: string;
  icon: UnionIconOptions;
};

const typesMap: Record<TypesOrder, Attr> = {
  [ORDER_TYPE_COUNTER_ID]: {
    label: 'Retirar na bandeja',
    icon: 'tray',
  },
  [ORDER_TYPE_TO_GO_ID]: {
    label: 'Retirar embalado',
    icon: 'bag',
  },
  [ORDER_TYPE_CURBSIDE_ID]: {
    label: 'Drive Thru',
    icon: 'truckService',
  },
  [ORDER_TYPE_TABLE_ID]: {
    label: 'Comer na mesa',
    icon: 'table',
  },
  [ORDER_TYPE_TABLE_DIGITAL_MENU_ID]: {
    label: 'Comer na cadeira',
    icon: 'table',
  },
  [ORDER_TYPE_DELIVERY_ID]: {
    label: 'Receber',
    icon: 'delivery',
  },
  [ORDER_TYPE_CARD_ID]: {
    label: 'Receber',
    icon: 'bag',
  },
  [ORDER_TYPE_PAYMENT_ID]: {
    label: 'Receber',
    icon: 'bag',
  },
  [ORDER_TYPE_COUPON_ID]: {
    label: 'Receber',
    icon: 'coupon',
  },
  [ORDER_TYPE_ROOM_SERVICE_ID]: {
    label: 'Room service',
    icon: 'truckService',
  },
  [ORDER_TYPE_PREPAID_POINTS_ID]: {
    label: 'Comer no restaurante',
    icon: 'coupon',
  },
  [ORDER_TYPE_TRUCK_STOP_ID]: {
    label: 'Drive thru',
    icon: 'truckService',
  },
  [ORDER_TYPE_EVENT_ID]: {
    label: 'Retirar no local',
    icon: 'store',
  },
};

export function getOrderType(orderType: number, isFromDigitalMenu: boolean) {
  if (orderType === ORDER_TYPE_TABLE_ID && isFromDigitalMenu) {
    orderType = ORDER_TYPE_TABLE_DIGITAL_MENU_ID;
  }

  return typesMap[orderType as TypesOrder];
}
