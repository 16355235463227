import { BundleName, ParamName, Item } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsBeginCheckout = {
  brandId: string;
  companyId: string;
  value: number;
  items: Array<Item>;
};

const event = 'begin_checkout';

export function logBeginCheckout({ brandId, companyId, value, items }: AnalyticsBeginCheckout) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.VALUE]: value,
      [ParamName.ITEMS]: items,
    },
  });
}

export function logBeginCheckoutError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
