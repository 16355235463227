import { ORDER_TYPE_DELIVERY } from './../model/order-types';
import { UserAddress, SelectedOrderType } from '../generated/graphql';
import * as OrderFields from '../model/delivery-order-fields-ids';
import concat from 'lodash/concat';

interface ExtraFields {
  id: number;
  value: string | number;
}

export function createOrderExtraFields(
  selectedOrderType?: SelectedOrderType | null,
  userAddress?: UserAddress | null
): any[] | undefined {
  const deliveryOrderExtraFields = createDeliveryOrderExtraFields(userAddress, selectedOrderType);
  const formattedOrderFields = selectedOrderType?.fieldValues?.map((field) => ({
    id: field?.numericalId!,
    value: field?.value!,
  }));

  let orderFields;
  if (formattedOrderFields && deliveryOrderExtraFields) {
    orderFields = concat(deliveryOrderExtraFields, formattedOrderFields);
  } else if (formattedOrderFields) {
    orderFields = formattedOrderFields;
  } else if (deliveryOrderExtraFields) {
    orderFields = deliveryOrderExtraFields;
  }

  return orderFields;
}

function createDeliveryOrderExtraFields(
  userAddress?: UserAddress | null,
  selectedOrderType?: SelectedOrderType | null
): any[] | undefined {
  if (!userAddress || !userAddress.fields) {
    return;
  }

  const extrasFields: ExtraFields[] = [
    {
      id: OrderFields.ORDER_FIELD_STREET_ID,
      value: userAddress.fields.street,
    },
    {
      id: OrderFields.ORDER_FIELD_NUMBER_ID,
      value: userAddress.fields.number,
    },
    {
      id: OrderFields.ORDER_FIELD_COMPLEMENT_ID,
      value: userAddress.complement,
    },
    {
      id: OrderFields.ORDER_FIELD_DISTRICT_ID,
      value: userAddress.fields.district,
    },
    {
      id: OrderFields.ORDER_FIELD_CITY_ID,
      value: userAddress.fields.city,
    },
    {
      id: OrderFields.ORDER_FIELD_ZIP_CODE_ID,
      value: userAddress.fields.zipCode,
    },
  ];

  if (selectedOrderType?.orderType === ORDER_TYPE_DELIVERY) {
    extrasFields.push(
      {
        id: OrderFields.ORDER_FIELD_LATITUDE,
        value: userAddress.latitude,
      },
      {
        id: OrderFields.ORDER_FIELD_LONGITUDE,
        value: userAddress.longitude,
      }
    );
  }

  return extrasFields;
}
