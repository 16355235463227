import {
  MutationsIncreaseChosenProductQuantityArgs,
  Product,
  ProductFragment,
  ProductFragmentDoc,
  ProductPriceFragment,
  ProductPriceFragmentDoc,
  ProductType,
  ProductChosenQuantityFragment,
  ProductChosenQuantityFragmentDoc,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

/**
 * Increases the chosen quantity of a product by 1.
 */
export function increaseChosenProductQuantity(
  root: any,
  variables: MutationsIncreaseChosenProductQuantityArgs,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  // Get the simple product
  const product = context.cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
  });
  if (!product) {
    console.error(`Product: ${variables.input.productCacheId} not found`);
    return false;
  }

  const updatedProduct = {
    ...product,
    chosenQuantity: product.chosenQuantity + 1,
    scrollToProduct: true,
  };
  if (updatedProduct.chosenQuantity > updatedProduct.maximumChoices!) {
    console.warn('Invalid change. Chosen quantity is more than maximum');
    return false;
  }

  // Handle parent
  if (variables.input.parentCacheId) {
    const parentUpdateResult = increaseParentQuantity(
      variables.input.parentCacheId,
      context.cache,
      context.getCacheKey
    );
    if (!parentUpdateResult) {
      return false;
    }
  }

  // Write product changes to cache
  context.cache.writeFragment<ProductChosenQuantityFragment>({
    fragment: ProductChosenQuantityFragmentDoc,
    id: context.getCacheKey({ id: variables.input.productCacheId, __typename: 'Product' }),
    data: updatedProduct,
  });
  // Handle menu item.
  if (variables.input.menuItemCacheId && product.productType !== ProductType.Choosable) {
    updateMenuItemPrice(
      product as Product,
      variables.input.menuItemCacheId,
      context.cache,
      context.getCacheKey
    );
  }

  return true;
}

function increaseParentQuantity(
  parentCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
): boolean {
  const parent = cache.readFragment<ProductFragment>({
    fragment: ProductFragmentDoc,
    id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
  });

  if (parent && parent.productType === ProductType.Choosable) {
    const updatedParent = { ...parent, chosenQuantity: parent.chosenQuantity + 1 };
    if (updatedParent.chosenQuantity > updatedParent.maximumChoices!) {
      console.warn('Invalid change, quantity greater than parent maximum');
      return false;
    }

    // Write parent changes to cache
    cache.writeFragment<ProductChosenQuantityFragment>({
      fragment: ProductChosenQuantityFragmentDoc,
      id: getCacheKey({ id: parentCacheId, __typename: 'Product' }),
      data: updatedParent,
    });
  } else {
    console.warn(`Parent not found or is not a choosable`, parentCacheId);
  }

  return true;
}

function updateMenuItemPrice(
  product: Product,
  menuItemCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
) {
  const menuItem = cache.readFragment<ProductPriceFragment>({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
  });
  if (!menuItem) {
    console.error(`Menu item: ${menuItemCacheId} not found`);
    return;
  }

  // Increase the menu item total price
  let price = 0;
  if (product.productCompanyByCompanyId && product.productCompanyByCompanyId.price) {
    price = product.productCompanyByCompanyId.price;
  }
  const updatedMenuItem = { ...menuItem, totalPrice: menuItem.totalPrice! + price };

  cache.writeFragment<ProductPriceFragment>({
    fragment: ProductPriceFragmentDoc,
    id: getCacheKey({ id: menuItemCacheId, __typename: 'Product' }),
    data: updatedMenuItem,
  });
}
