import React, { useCallback } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Typography, Box, ListItem, List } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { PaymentParameter, CartPaymentStatus } from '../../../../common/generated/graphql';
import { useStyles } from './payment-simple-options.style';
import { UnionCardBrand } from '../../../../common/model/card-brand';
import SelectCardBrandIcon from '../../../../common/components/select-card-brand-icon/select-card-brand-icon';
import { useUpdateCartPayment } from '../../../../common/hooks/use-update-cart-payment';
import Icon from '../../../../common/components/icon/icon';

import { DIGITAL_MENU } from '../../../../common/model/digital-menu';

interface Props {
  payments: Maybe<Array<Maybe<PaymentParameter>>>;
  hasOffline: boolean | null | undefined;
  setOffline: () => void;
}

export default function PaymentSimpleOptions(props: Props) {
  const classes = useStyles();
  const { updateCartPayment } = useUpdateCartPayment();
  const isFromDigitalMenu = Boolean(sessionStorage.getItem(DIGITAL_MENU));

  const creditList =
    props.payments &&
    props.payments.filter(checkHasCredit).map((pay, index, arr) => {
      let isQuote = arr && index === arr.length - 1;

      return `${pay?.cardBrand?.name}${isQuote ? '.' : ', '}`;
    });

  const handleOpenCardRegister = useCallback(
    (card?: UnionCardBrand) => {
      updateCartPayment(CartPaymentStatus.RegisterCard, card);
    },
    [updateCartPayment]
  );

  return (
    <>
      <Typography variant='body2' className={classes.title}>
        Selecione o modo de pagamento
      </Typography>
      <List>
        {props.payments &&
          props.payments.filter(checkHasDebit).map((pay) => (
            <ListItem
              id={`payment-parameter-${pay?.cardBrand?.code}-option`}
              button
              key={pay?.cardBrand?.code}
              className={classes.item}
              onClick={() => handleOpenCardRegister(pay?.cardBrand?.code as UnionCardBrand)}
            >
              <Box className={classes.itemWrapIcon}>
                <SelectCardBrandIcon card={pay?.cardBrand?.code as UnionCardBrand} size='large' />
              </Box>
              <Box className={classes.itemWrapText}>
                <Typography variant='body2' className={classes.itemTitle}>
                  {pay?.cardBrand?.name}
                </Typography>
              </Box>
              <Box>
                <AddIcon color='secondary' fontSize='small' />
              </Box>
            </ListItem>
          ))}
        {creditList && creditList.length > 0 && (
          <ListItem
            id='payment-parameter-common-option'
            button
            className={classes.item}
            onClick={() => handleOpenCardRegister()}
          >
            <Box className={classes.itemWrapIcon}>
              <Icon name='creditCardDouble' color='dark' size='large' />
            </Box>
            <Box className={classes.itemWrapText}>
              <Typography variant='body2' className={classes.itemTitle}>
                Cartão de Crédito
              </Typography>

              <Typography
                id='payment-parameter-common-option-cardnames'
                className={classes.itemExtraText}
                variant='caption'
              >
                {creditList}
              </Typography>
            </Box>
            <Box>
              <AddIcon color='secondary' fontSize='small' />
            </Box>
          </ListItem>
        )}
        {props.hasOffline && (
          <ListItem
            id='payment-parameter-offline-option'
            button
            className={classes.item}
            onClick={props.setOffline}
          >
            <Box className={classes.itemWrapIcon}>
              {isFromDigitalMenu ? (
                <Icon name='heart' color='secondary' size='large' />
              ) : (
                <Icon name='localPayment' color='dark' size='large' />
              )}
            </Box>
            <Box className={classes.itemWrapText}>
              <Typography variant='body2' className={classes.itemTitle}>
                {isFromDigitalMenu ? 'Cortesia ;)' : 'Pagar na entrega'}
              </Typography>
            </Box>
            <Box>
              <AddIcon color='secondary' fontSize='small' />
            </Box>
          </ListItem>
        )}
      </List>
    </>
  );
}

const checkHasCredit = (pay: any) => pay.paymentMethod === 'credit';
const checkHasDebit = (pay: any) => pay.paymentMethod === 'debit';
