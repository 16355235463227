import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './share-simple-menu.style';
import Icon from '../../../common/components/icon/icon';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import { useNotification } from '../../../common/hooks/use-notification';
import { logShareItemList } from '../../../analytics/helpers/log-share-item-list';
import { logViewReferral } from '../../../analytics/helpers/log-view-referral';

interface Props {
  reduced?: boolean;
}

export default function ShareSimpleMenu({ reduced = false }: Props) {
  const companyData = useGetCompanyDataFragment();
  const { setNotify } = useNotification();
  const companyName = companyData?.name;
  const classes = useStyles();
  const url = window.location.href;
  const textRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { companyId, brandId } = useParams<{
    companyId: string;
    brandId: string;
  }>();

  const handleSharePage = useCallback(async () => {
    logShareItemList({ brandId, companyId });

    if (navigator.share) {
      await navigator.share({
        title: 'Pede Pronto',
        text: `veja só o cardápio de ${companyName}`,
        url,
      });
    } else {
      textRef.current?.select();
      textRef.current?.setSelectionRange(0, 99999);
      document.execCommand('copy');

      setNotify('success', 'Link copiado para área de transferência');
    }
  }, [brandId, companyId, companyName, setNotify, textRef, url]);

  useEffect(() => {
    logViewReferral();
  });

  return (
    <Box
      id='share-simple-menu'
      onClick={handleSharePage}
      className={clsx(classes.root, {
        [classes.reduced]: reduced,
      })}
    >
      <IconButton className={classes.button}>
        <Icon name='share' color='secondary' />
      </IconButton>
      {!reduced && (
        <Typography className={classes.text} variant='body2'>
          Compartilhe com amigos
        </Typography>
      )}

      <input
        readOnly
        type='text'
        id='share-simple-menu-input'
        ref={textRef}
        className={classes.hidden}
        value={url}
      />
    </Box>
  );
}
