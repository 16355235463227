import Dialog from '@material-ui/core/Dialog';
import { Box, IconButton } from '@material-ui/core';
import 'firebase/analytics';
import React, { ReactNode } from 'react';
import { Close as CloseIcon } from '@material-ui/icons';

import { useStyles } from './product-details-dialog.style';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { useProductDetailsDialog } from '../../../common/hooks/use-product-details-dialog';

interface DialogProps {
  active?: boolean;
  children: ReactNode;
}

export function ProductDetailsDialog({ active = false, children }: DialogProps) {
  const { onCloseProductDetails } = useProductDetailsDialog();
  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();

  return (
    <Dialog
      id='product-details-dialog'
      onClose={onCloseProductDetails}
      aria-labelledby='modal-detalhes-produto'
      open={active}
      classes={{
        container: classes.container,
        paper: `${classes.paper} ${headerSpace.fullDialog}`,
      }}
    >
      <Box className={classes.close}>
        <IconButton
          onClick={onCloseProductDetails}
          className={classes.btnIcon}
          aria-label='fechar-modal-detalhes-produto'
          id='close-product-details-dialog'
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      {active && children}
    </Dialog>
  );
}
