import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Container, IconButton } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetAggregatorImagesQuery } from '../../generated/graphql';
import { useStyles } from './main-header.style';
import { AggregatorLogo } from '../aggregator-logo/aggregator-logo';
import { checkHostName } from '../../helpers/check-host-name';
import ProfileMenu from '../profile-menu/profile-menu';
import { getAppConfig } from '../../helpers/configs';
import { logReturnShop } from '../../../analytics/helpers/log-return-shop';
import { WhiteLabelHostName } from '../../../common/model/white-label-host-name';

import { DIGITAL_MENU, DIGITAL_MENU_URL } from '../../../common/model/digital-menu';

interface Props {
  withoutBackButton?: boolean;
  isCompany?: boolean;
}

export function MainHeader({ withoutBackButton = false, isCompany = false }: Props) {
  const location = useLocation<{ from: string }>();
  const classes = useStyles();
  const isPedePronto = checkHostName();
  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);

  const isFromDigitalMenu = Boolean(sessionStorage.getItem(DIGITAL_MENU));
  const digitalMenuUrl = sessionStorage.getItem(DIGITAL_MENU_URL) ?? '/';

  const lockMenu = isFromDigitalMenu || isStreamshop;

  const { aggregatorId, companyId, brandId } = useParams<{
    aggregatorId: string;
    companyId: string;
    brandId: string;
  }>();
  const { useLocalAggregatorHeaderImg, removeLogoFromTheApp } = getAppConfig().featureFlags;
  const skip = isPedePronto || useLocalAggregatorHeaderImg || removeLogoFromTheApp;

  const { data } = useGetAggregatorImagesQuery({
    variables: {
      aggregatorId: Number(aggregatorId),
    },
    skip,
  });

  const history = useHistory();

  const onBackClick = useCallback(() => {
    if (location.state?.from) {
      if (location.state?.from.includes('company')) {
        logReturnShop({ companyId, brandId });
      }
      history.goBack();
    } else {
      if (brandId && companyId && !isCompany) {
        logReturnShop({ companyId, brandId });
        history.replace(`/${aggregatorId}/${brandId}/${companyId}/company`);
      } else {
        history.replace(`/${aggregatorId}/aggregator`);
      }
    }
  }, [location.state, history, brandId, companyId, isCompany, aggregatorId]);

  return (
    <Box className={classes.root} id='ref-main-header-component'>
      <Container className={classes.container}>
        <Box
          className={`${classes.nav} ${(withoutBackButton || lockMenu) && classes.navWithoutBack}`}
        >
          {!withoutBackButton && !lockMenu && (
            <IconButton id='main-header-back-button' onClick={onBackClick}>
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
          )}
          <Link
            to={lockMenu ? digitalMenuUrl : `/${aggregatorId}/aggregator`}
            className={classes.link}
            id='main-hader-link-to-home'
          >
            <AggregatorLogo
              mainHeader
              aggregatorName={data?.aggregator?.name}
              aggregatorImages={data?.aggregator?.images}
            />
          </Link>
        </Box>
        <Box className={classes.profile}>
          <ProfileMenu mainHeader />
        </Box>
      </Container>
    </Box>
  );
}
