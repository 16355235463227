import {
  startPaymentsFlow,
  MiniAppPaymentSuccessData,
  startRefundFlow,
  MiniAppPaymentData,
} from '@bitz/miniapp-gateway';
import { CieloPayTransactionStatus } from '../model/cielo-pay-transaction-status';
import { OnyoPaymentResponse } from '../model/payment-response';
import { logError } from './log-error';
import { sendPaymentResponse } from './send-payment-response';
import * as Sentry from '@sentry/browser';
import { InMemoryCache } from '@apollo/client';
import { IsExecutingPaymentCallQuery, IsExecutingPaymentCallDocument } from '../generated/graphql';

export class CieloPayPaymentHandler {
  constructor(
    private value: number,
    private merchantNumber: string,
    private paymentId: number,
    private step: string,
    private cache: InMemoryCache
  ) {}

  public executePayment() {
    this.updateCache(true);

    startPaymentsFlow(
      {
        value: this.value,
        merchantId: this.merchantNumber,
      },
      (data) => this.onPaymentSuccess(data),
      (data) => this.onPaymentError(data),
      (data) => this.onPaymentCancelled(data)
    );
  }

  private async onPaymentSuccess(data: MiniAppPaymentSuccessData) {
    try {
      const paymentResponse: OnyoPaymentResponse = {
        body: '',
        headers: {},
        statusCode: 200,
        step: this.step,
        requiredFields: {
          transactionStatus: CieloPayTransactionStatus.SUCCESS,
          transactionId: data.id,
          merchantId: data.merchantId ?? '',
        },
      };

      await sendPaymentResponse(paymentResponse, this.paymentId);
      this.updateCache(false);
    } catch (err) {
      logError(err, 'Error sending success payment response');
      this.refundPayment(data.id);
    }
  }

  private async onPaymentError(data: MiniAppPaymentData) {
    try {
      const paymentResponse: OnyoPaymentResponse = {
        body: '',
        headers: {},
        statusCode: 400,
        step: this.step,
        requiredFields: {
          transactionStatus: CieloPayTransactionStatus.ERROR,
          transactionId: '',
          merchantId: data.merchantId ?? '',
        },
      };

      Sentry.captureEvent({
        message: 'Cielo Pay payment error',
        level: Sentry.Severity.Error,
        extra: data as any,
      });

      await sendPaymentResponse(paymentResponse, this.paymentId);
      this.updateCache(false);
    } catch (err) {
      logError(err, 'Error sending error payment response');
      this.updateCache(false);
    }
  }

  private async onPaymentCancelled(data: MiniAppPaymentData) {
    try {
      const paymentResponse: OnyoPaymentResponse = {
        body: '',
        headers: {},
        statusCode: 400,
        step: this.step,
        requiredFields: {
          transactionStatus: CieloPayTransactionStatus.CANCELLED,
          transactionId: '',
          merchantId: data.merchantId ?? '',
        },
      };

      Sentry.captureEvent({
        message: 'Cielo Pay payment error',
        level: Sentry.Severity.Warning,
        extra: data as any,
      });

      await sendPaymentResponse(paymentResponse, this.paymentId);
      this.updateCache(false);
    } catch (err) {
      logError(err, 'Error sending cancel payment response');
      this.updateCache(false);
    }
  }

  private refundPayment(transactionId: string) {
    startRefundFlow(
      { id: transactionId },
      () => {
        this.updateCache(false);
        Sentry.captureEvent({
          message: `Refund success for transaction id: ${transactionId}`,
          level: Sentry.Severity.Info,
        });
      },
      (data) => {
        this.updateCache(false);
        Sentry.captureEvent({
          message: `Refund error for transaction id: ${transactionId}`,
          extra: data as any,
          level: Sentry.Severity.Error,
        });
      }
    );
  }

  private updateCache(paymentExecutionStatus: boolean) {
    this.cache.writeQuery<IsExecutingPaymentCallQuery>({
      query: IsExecutingPaymentCallDocument,
      data: {
        isExecutingPaymentCall: paymentExecutionStatus,
      },
    });
  }
}
