import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
      },
    },
    image: {
      maxHeight: '2.5rem',
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    imageWithoutAddress: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    mainHeader: {
      height: '2rem',
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        height: '1.75rem',
      },
    },
    simpleTextTitle: {
      fontWeight: 'bold',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    empty: {
      display: 'none',
      visibility: 'hidden',
      overflow: 'hiiden',
    },
  })
);
