import { AppConfig } from '../../../common/model/config';
import { iguatemiTheme } from '../themes/iguatemi.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const iguatemiConfig: AppConfig = {
  appName: 'iguatemi-one',
  appTitle: 'Iguatemi One',
  appDescription: 'Peça aqui no Iguatemi One.',
  aggregator: 69,
  brand: 1297,
  theme: iguatemiTheme,
  faviconPath: '/favicons/favicon-iguatemi.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
