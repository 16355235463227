import {
  CustomerData,
  UpdateCustomerDataMutationVariables,
  CustomerDataInput,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';
import { handleAuthError } from '../../helpers/handle-auth-error';

export async function updateCustomerData(
  root: any,
  variables: UpdateCustomerDataMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<CustomerData | null> {
  try {
    let document;
    if (variables.input.cpf) {
      document = createDocument(variables.input.cpf);
    }

    const data = createData(variables.input, document);
    const endpoints = getEndpoints();
    const url = `${endpoints.api}v1/mobile/customer/me`;
    const response = await axios.patch(url, data);

    return createCustomerData(response);
  } catch (error) {
    handleAuthError(error, context.cache);
    logError(error, 'Error updating user data');
    throw new Error('Não foi possível atualizar os seus dados. Por favor tente novamente.');
  }
}

function createDocument(cpf: string) {
  return [
    {
      data: cpf,
      documentType: 1,
      numericalId: -1,
    },
  ];
}

function createData(input: CustomerDataInput, document: any) {
  return {
    firstName: input.name,
    telephone: input.telephone,
    birthday: input.birthday,
    gender: input.gender,
    document,
  };
}

function createCustomerData(response: AxiosResponse<any>): CustomerData {
  const cpf: string | undefined = getCpfFromResponse(response);
  return {
    __typename: 'CustomerData',
    id: btoa(`CustomerData:${response.data.id}`),
    numericalId: response.data.numericalId,
    name: response.data.firstName,
    email: response.data.email,
    birthday: response.data.birthday,
    gender: response.data.gender,
    telephone: response.data.telephone,
    acceptedTermsDatetime: response.data.acceptedTermsDatetime,
    validationCellphone: response.data.validationCellphone,
    cpf,
  };
}

function getCpfFromResponse(response: AxiosResponse<any>): string | undefined {
  if (response.data.document) {
    for (const doc of response.data.document) {
      if (doc.documentType === 1) {
        return doc.data;
      }
    }
  }
}
