import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 'initial',
      background: 'white',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2, 0, 0, 2),
    },
  })
);
