import { NotifyQuery, NotifyDocument } from './../generated/graphql';
import { UnionNotifyOption } from './../model/notify';
import { useCallback } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';

interface UseNotification {
  (): {
    setNotify: (status: UnionNotifyOption, message: string) => void;
  };
}

interface UpdateNotify {
  (
    client: ApolloClient<Object>,
    status: UnionNotifyOption,
    message?: string,
    active?: boolean
  ): void;
}

export const useNotification: UseNotification = () => {
  const client = useApolloClient();

  const setNotify = useCallback(
    (status: UnionNotifyOption, message: string) => {
      updateNotify(client, status, message, true);

      setTimeout(() => {
        updateNotify(client, status);
      }, 3000);
    },
    [client]
  );

  return { setNotify };
};

const updateNotify: UpdateNotify = (client, status, message = '', active = false) => {
  client.writeQuery<NotifyQuery>({
    query: NotifyDocument,
    data: {
      notify: {
        __typename: 'Notify',
        status,
        message,
        active,
      },
    },
  });
};
