import { Box, Grid, Container } from '@material-ui/core';
import 'firebase/analytics';
import shuffle from 'lodash/shuffle';
import React, { useEffect, useState } from 'react';
import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import { sortCompanies } from '../../../common/helpers/companies-sort';
import {
  CompanyEdge,
  DeliveryAddressInput,
  useGetAggregatorCompaniesQuery,
} from '../../../common/generated/graphql';
import { getAppConfig, getSharedConfig } from '../../../common/helpers/configs';
import { logError } from '../../../common/helpers/log-error';
import AggregatorCompanyListItem from '../aggregator-company-list-item/aggregator-company-list-item';
import AggregatorHeader from '../aggregator-header/aggregator-header';
import EmptyAggregator from '../empty-aggregator/empty-aggregator';
import CardContainer from '../card-container/card-container';
import { useStyles } from './aggregator-company-list.style';
import { useHomeHeaderSpace } from '../../../common/hooks/use-header-space';
import { useLocation } from 'react-router-dom';
import useUserCoords from '../../hooks/use-user-coords';

interface Props {
  aggregatorId: number;
}

export default function AggregatorCompanyList(props: Props) {
  const classes = useStyles();
  const headerHomeSpace = useHomeHeaderSpace();
  const appConfig = getAppConfig();
  const sharedConfig = getSharedConfig();
  const [companies, setCompanies] = useState<CompanyEdge[]>([]);
  const location = useLocation();
  const userLocation = useUserCoords();

  const deliveryAddressInput: DeliveryAddressInput = {
    street: userLocation?.street ?? '',
    number: userLocation?.addressNumber ?? '',
    complement: userLocation?.complement ?? '',
    district: userLocation?.district ?? '',
    city: userLocation?.city ?? '',
    state: userLocation?.stateUf ?? '',
    zipCode: userLocation?.zipCode ?? '',
    country: 'BR',
    geoLat: userLocation?.latitude ?? 0,
    geoLon: userLocation?.longitude ?? 0,
  };

  const { data, loading, error, refetch } = useGetAggregatorCompaniesQuery({
    variables: { aggregatorId: props.aggregatorId, deliveryAddress: deliveryAddressInput },
    onError: (err) => logError(err, 'Error executing Get Aggregator Companies Query'),
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    let companyArray = data?.aggregator?.companies?.edges as CompanyEdge[] | undefined | any;
    if (!companyArray) {
      return;
    }

    if (appConfig.featureFlags.shuffleCompanyArray) {
      companyArray = shuffle(companyArray);
      companyArray = [...companyArray].sort((a, b) => sortCompanies(a as any, b as any));
    }

    setCompanies(companyArray);
  }, [data, appConfig]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados desse shopping.'
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  } else if (!data || data.aggregator?.companies?.edges.length === 0) {
    return <EmptyAggregator retry={refetch} path={location.pathname} />;
  }

  return (
    <Box>
      <AggregatorHeader
        aggregatorName={data!.aggregator!.name!}
        aggregatorImages={data!.aggregator!.images}
      />

      <Container className={`${classes.container} ${headerHomeSpace.homeWithoutNav}`}>
        <Grid container>
          {companies.map((company) => (
            <Grid
              item
              key={company!.node!.id}
              className={classes.item}
              {...sharedConfig.numberColumnsBreakPointsAggregator}
            >
              <CardContainer key={company!.node!.id}>
                <AggregatorCompanyListItem company={company!.node!} />
              </CardContainer>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
