import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconDinersClub(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path
          fill='#006095'
          d='M23.433 36.25c8.661 0.043 16.567-7.244 16.567-16.109 0-9.693-7.906-16.394-16.567-16.391h-7.454c-8.765-0.003-15.979 6.699-15.979 16.391 0 8.867 7.214 16.152 15.979 16.109h7.454z'
        ></path>
        <path
          fill='#fff'
          d='M19.304 28.774v-17.602c3.45 1.357 5.899 4.783 5.905 8.801-0.006 4.019-2.455 7.442-5.905 8.802zM6.823 19.973c0.008-4.015 2.453-7.439 5.901-8.8v17.598c-3.448-1.36-5.893-4.782-5.901-8.798zM16.015 5.095c-8.009 0.003-14.499 6.661-14.501 14.878 0.002 8.216 6.492 14.873 14.501 14.875 8.012-0.002 14.503-6.659 14.504-14.875-0.001-8.217-6.492-14.875-14.504-14.878z'
        ></path>
      </>
    </SvgWrapper>
  );
}
