import { AppConfig } from '../../../common/model/config';
import { natalShopping } from '../themes/natal-shopping.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const natalShoppingConfig: AppConfig = {
  appName: 'natal-shopping',
  appTitle: 'Natal Shopping',
  appDescription: 'Peça aqui nos restaurantes do Natal Shopping.',
  aggregator: 223,
  brand: 213,
  theme: natalShopping,
  faviconPath: '/favicons/favicon-natal-shopping.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
