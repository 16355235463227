import config from 'react-global-configuration';
import {
  AppConfig,
  DEVELOPMENT,
  Endpoints,
  PRODUCTION,
  SharedConfig,
  STAGING,
  MINI_APP_STAGING,
  MINI_APP_PRODUCTION,
  QA,
} from '../common/model/config';
import { c3PrajaConfig } from './application/app-configurations/c3-praja.config';
import { chefsClubConfig } from './application/app-configurations/chefs-club.config';
import { developmentConfig } from './application/app-configurations/development.config';
import { gulaGulaConfig } from './application/app-configurations/gula-gula.config';
import { morumbiConfig } from './application/app-configurations/morumbi.config';
import { onyoAppConfig } from './application/app-configurations/onyo-app.config';
import { pedeProntoConfig } from './application/app-configurations/pede-pronto.config';
import { porDentroConfig } from './application/app-configurations/por-dentro.config';
import { vilaOlimpiaConfig } from './application/app-configurations/vila-olimpia.config';
import {
  c3PrajaEndpoints,
  c3PrajaStagingEndpoints,
} from './application/endpoint-configurations/c3-praja.endpoint.config';
import { developmentEndpoints } from './application/endpoint-configurations/development.endpoint.config';
import {
  onyoAppEndpoints,
  onyoAppStagingEndpoints,
} from './application/endpoint-configurations/onyo-app.endpoint.config';
import {
  onyoComEndpoints,
  onyoComStagingEndpoints,
} from './application/endpoint-configurations/onyo-com.endpoint.config';
import {
  refeicaoOnlineEndpoints,
  refeicaoOnlineStagingEndpoints,
  refeicaoDigitalEndpoints,
} from './application/endpoint-configurations/refeicao-online.endpoint.config';
import { developmentSharedConfig } from './application/shared-configurations/development.config';
import { productionSharedConfig } from './application/shared-configurations/production.config';
import { stagingSharedConfig } from './application/shared-configurations/staging.config';
import { qaSharedConfig } from './application/shared-configurations/qa.config';
import { arabiaConfig } from './application/app-configurations/arabia.config';
import { maniaDeChurrascoConfig } from './application/app-configurations/mania-de-churrasco.config';
import {
  eldoradoEndpoints,
  eldoradoStagingEndpoints,
} from './application/endpoint-configurations/eldorado.endpoint.config';
import { qaEndpoints } from './application/endpoint-configurations/qa.endpoint.config';
import { eldoradoConfig } from './application/app-configurations/eldorado.config';
import { pizzaMakersConfig } from './application/app-configurations/pizza-makers.config';
import { pizzaHutConfig } from './application/app-configurations/pizza-hut.config';
import { mariaEOBoiConfig } from './application/app-configurations/maria-e-o-boi.config';
import { gendaiConfig } from './application/app-configurations/gendai.config';
import { zucaBurguerConfig } from './application/app-configurations/zuca-burguer.config';
import { johnnysTasteConfig } from './application/app-configurations/johnnys-taste.config';
import { piadinaConfig } from './application/app-configurations/piadina.config';
import { maniaDelivery } from './application/app-configurations/mania-delivery.config';
import { kmDeVantagensConfig } from './application/app-configurations/km-de-vantagens.config';
import { orlaRioConfig } from './application/app-configurations/orla-rio.config';
import { refeicaoOnlineConfig } from './application/app-configurations/refeicao-online.config';
import { graoEspressoConfig } from './application/app-configurations/grao-espresso.config';
import { vivendaDoCamaraoConfig } from './application/app-configurations/vivenda-do-camarao.config';
import { bakedPotatoConfig } from './application/app-configurations/baked-potato.config';
import { allyaConfig } from './application/app-configurations/allya.config';
import { mobitsConfig } from './application/app-configurations/mobits.config';
import { iguatemiConfig } from './application/app-configurations/iguatemi.config';
import { goldenSquareConfig } from './application/app-configurations/golden-square.config';
import { patioPaulistaConfig } from './application/app-configurations/patio-paulista.config';
import { amPmConfig } from './application/app-configurations/am-pm.config';
import { cieloMiniAppConfig } from './application/app-configurations/cielo-mini-app.config';
import { bitzMiniAppConfig } from './application/app-configurations/bitz-mini-app.config';
import { northShoppingJoqueiConfig } from './application/app-configurations/north-shopping-joquei.config';
import { viaSulShoppingConfig } from './application/app-configurations/viasul-shopping.config';
import { shoppingNovaIguacuConfig } from './application/app-configurations/shopping-nova-iguacu.config';
import { portoVelhoShoppingConfig } from './application/app-configurations/porto-velho-shopping.config';
import { boulevardRioShoppingConfig } from './application/app-configurations/boulevard-rio-shopping.config';
import { greenTableConfig } from './application/app-configurations/green-table.config';
import { conjuntoNacionalBrasiliaConfig } from './application/app-configurations/conjunto-nacional.config';
import { natalShoppingConfig } from './application/app-configurations/natal-shopping.config';
import { novaAmericaShoppingConfig } from './application/app-configurations/nova-america-shopping.config';
import { northShoppingFortalezaConfig } from './application/app-configurations/north-shopping-fortaleza.config';
import { pantanalShoppingConfig } from './application/app-configurations/pantanal-shopping.config';
import { becoVilaOlimpiaConfig } from './application/app-configurations/beco-vila-olimpia.config';
import { qaPedeProntoConfig } from './application/app-configurations/qa-pede-pronto.config';
import { shoppingRioSulConfig } from './application/app-configurations/shopping-rio-sul.config';
import { allianzParkConfig } from './application/app-configurations/allianz-park.config';
import { liveloConfig } from './application/app-configurations/livelo.config';
import { meuAleloConfig } from './application/app-configurations/meu-alelo.config';
import { streamShopConfig } from './application/app-configurations/stream-shop.config';
import { checkHostName } from '../common/helpers/check-host-name';
import { WhiteLabelHostName } from '../common/model/white-label-host-name';

export function globalConfig() {
  const sharedConfig = getSharedConfig();
  const endpoints = getEndpoints();
  const appConfig = getAppConfig();
  config.set({ sharedConfig, endpoints, appConfig });
}

function getSharedConfig(): SharedConfig {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case PRODUCTION:
    case MINI_APP_PRODUCTION:
      return productionSharedConfig;
    case STAGING:
    case MINI_APP_STAGING:
      return stagingSharedConfig;
    case QA:
      return qaSharedConfig;
    case DEVELOPMENT:
      return developmentSharedConfig;
    default:
      return developmentSharedConfig;
  }
}

function getEndpoints(): Endpoints {
  const env = process.env.REACT_APP_ENV;

  if (env === DEVELOPMENT) {
    return developmentEndpoints;
  } else if (env === QA) {
    return qaEndpoints;
  } else if (checkHostName('c3praja.com')) {
    return env === PRODUCTION ? c3PrajaEndpoints : c3PrajaStagingEndpoints;
  } else if (checkHostName('onyo.app')) {
    return env === PRODUCTION ? onyoAppEndpoints : onyoAppStagingEndpoints;
  } else if (checkHostName('refeicao.online')) {
    return env === PRODUCTION ? refeicaoOnlineEndpoints : refeicaoOnlineStagingEndpoints;
  } else if (checkHostName('refeicao.digital')) {
    return env === PRODUCTION ? refeicaoDigitalEndpoints : refeicaoOnlineStagingEndpoints;
  } else if (checkHostName('shoppingeldorado.com.br')) {
    return env === PRODUCTION ? eldoradoEndpoints : eldoradoStagingEndpoints;
  } else {
    return env === PRODUCTION ? onyoComEndpoints : onyoComStagingEndpoints;
  }
}

function getAppConfig(): AppConfig {
  const env = process.env.REACT_APP_ENV;

  if (env === DEVELOPMENT) return developmentConfig;
  else if (env === MINI_APP_STAGING || env === MINI_APP_PRODUCTION) return cieloMiniAppConfig;
  else if (env === QA) return qaPedeProntoConfig;
  else if (checkHostName()) return pedeProntoConfig;
  else if (checkHostName('bitzminiapp')) return bitzMiniAppConfig;
  else if (checkHostName('arabia')) return arabiaConfig;
  else if (checkHostName('c3praja')) return c3PrajaConfig;
  else if (checkHostName('chefsclub')) return chefsClubConfig;
  else if (checkHostName('gulagula')) return gulaGulaConfig;
  else if (checkHostName('morumbi')) return morumbiConfig;
  else if (checkHostName('onyo.app')) return onyoAppConfig;
  else if (checkHostName('pordentro')) return porDentroConfig;
  else if (checkHostName('maniadechurrasco')) return maniaDeChurrascoConfig;
  else if (checkHostName('maniadelivery')) return maniaDelivery;
  else if (checkHostName('vilaolimpia')) return vilaOlimpiaConfig;
  else if (checkHostName('shoppingeldorado')) return eldoradoConfig;
  else if (checkHostName('pizzamakers')) return pizzaMakersConfig;
  else if (checkHostName('ph')) return pizzaHutConfig;
  else if (checkHostName('mariaeoboi')) return mariaEOBoiConfig;
  else if (checkHostName('gendai')) return gendaiConfig;
  else if (checkHostName('zucaburguer')) return zucaBurguerConfig;
  else if (checkHostName('johnnystaste')) return johnnysTasteConfig;
  else if (checkHostName('piadina')) return piadinaConfig;
  else if (checkHostName('kmdevantagens')) return kmDeVantagensConfig;
  else if (checkHostName('orlario')) return orlaRioConfig;
  else if (checkHostName('app.refeicao.online')) return refeicaoOnlineConfig;
  else if (checkHostName('app.staging.refeicao.online')) return refeicaoOnlineConfig;
  else if (checkHostName('graoespresso')) return graoEspressoConfig;
  else if (checkHostName('vivendadocamarao')) return vivendaDoCamaraoConfig;
  else if (checkHostName('bakedpotato')) return bakedPotatoConfig;
  else if (checkHostName('allya')) return allyaConfig;
  else if (checkHostName('mobits')) return mobitsConfig;
  else if (checkHostName('iguatemi')) return iguatemiConfig;
  else if (checkHostName('goldensquare')) return goldenSquareConfig;
  else if (checkHostName('patiopaulista')) return patioPaulistaConfig;
  else if (checkHostName('ampm')) return amPmConfig;
  else if (checkHostName('northshoppingjoquei')) return northShoppingJoqueiConfig;
  else if (checkHostName('viasul')) return viaSulShoppingConfig;
  else if (checkHostName('shoppingnovaiguacu')) return shoppingNovaIguacuConfig;
  else if (checkHostName('pvshopping')) return portoVelhoShoppingConfig;
  else if (checkHostName('shoppingboulevard')) return boulevardRioShoppingConfig;
  else if (checkHostName('gt')) return greenTableConfig;
  else if (checkHostName('conjuntonacionaldf')) return conjuntoNacionalBrasiliaConfig;
  else if (checkHostName('natalshopping')) return natalShoppingConfig;
  else if (checkHostName('novaamerica')) return novaAmericaShoppingConfig;
  else if (checkHostName('nfs')) return northShoppingFortalezaConfig;
  else if (checkHostName('pantanalshopping')) return pantanalShoppingConfig;
  else if (checkHostName('beco')) return becoVilaOlimpiaConfig;
  else if (checkHostName('riosul')) return shoppingRioSulConfig;
  else if (checkHostName('ap.refeicao.online')) return allianzParkConfig;
  else if (checkHostName('livelo')) return liveloConfig;
  else if (checkHostName('meualelo')) return meuAleloConfig;
  else if (checkHostName(WhiteLabelHostName.STREAM_SHOP)) return streamShopConfig;
  else return onyoAppConfig;
}
