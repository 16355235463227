import * as Sentry from '@sentry/browser';

export function logError(error: any, msg: string, forceSentry?: boolean) {
  if (process.env.NODE_ENV !== 'test') {
    if (!forceSentry && (error?.response?.status === 401 || error?.response?.status === 403)) {
      console.warn(msg, error);
    } else if (!forceSentry && error?.message.includes('Timeout')) {
      console.warn(msg, error);
    } else {
      console.error(msg, error);
      Sentry.setExtras({ error, msg });
      Sentry.captureException(error);
    }
  }
}
