import { AppConfig } from '../../../common/model/config';
import { gendaiTheme } from '../themes/gendai.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const gendaiConfig: AppConfig = {
  appName: 'gendai',
  appTitle: 'Gendai',
  appDescription: 'Peça aqui no Gendai.',
  aggregator: 1076,
  brand: 29,
  theme: gendaiTheme,
  faviconPath: '/favicons/favicon-gendai.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
