import { BundleName, ParamName, Item } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsRemoveFromCart = {
  brandId: string;
  companyId: string;
  items: Item[];
};

const event = 'remove_from_cart';

export function logRemoveFromCart({ brandId, companyId, items }: AnalyticsRemoveFromCart) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.ITEMS]: items,
    },
  });
}

export function logRemoveFromCartError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
