import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        color: theme.palette.grey[800],
      },
    },
    status: {
      padding: theme.spacing(0.5),
      height: '1.25rem',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(0.5),
    },
    statusOpen: {
      background: theme.palette.success.superLightest,
      color: theme.palette.success.darkest,
    },
    statusClosed: {
      background: theme.palette.error.superLightest,
      color: theme.palette.error.darkest,
    },
    labelAndIcon: { display: 'flex', alignItems: 'center' },
    icon: { paddingRight: theme.spacing(1) },
    atHour: {
      paddingRight: theme.spacing(3),
    },
  })
);
