import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(0),
    },
    suggestRestaurantContainer: {
      height: '85vh',
    },
    [theme.breakpoints.down('sm')]: {
      suggestRestaurantContainer: {
        height: '100vh',
      },
    },
    suggestRestaurantIframe: {
      border: 'none',
      width: '100%',
      height: '100vh',
    },
    headerContainerTitle: {
      background: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    headerTitle: {
      color: theme.palette.common.white,
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      headerTitle: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold as 'bold',
        padding: theme.spacing(2, 0, 0, 0),
      },
    },
    buttonClose: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    buttonCloseIcon: {
      color: theme.palette.common.white,
    },
  })
);
