import { useEffect } from 'react';
import { logAddPaymentInfo } from '../../../../analytics/helpers/log-add-payment-info';
import { logAddShippingInfo } from '../../../../analytics/helpers/log-add-shipping-info';
import {
  GetOrderTotalQuery,
  GetSelectedOrderTypeQuery,
  GetSelectedPaymentMethodFromCacheQuery,
} from '../../../../common/generated/graphql';
import { normalizeTextCompare } from '../../../../common/helpers/normalize-text-compare';

interface LogCartAnalytics {
  orderTypeQuery?: GetSelectedOrderTypeQuery;
  paymentQuery?: GetSelectedPaymentMethodFromCacheQuery;
  brandId: number;
  companyId: number;
  totalQuery?: GetOrderTotalQuery;
}

export const useLogCartAnalytics = ({
  orderTypeQuery,
  paymentQuery,
  brandId,
  companyId,
  totalQuery,
}: LogCartAnalytics) => {
  useEffect(() => {
    if (orderTypeQuery && totalQuery && totalQuery.orderCheck) {
      const shipping = totalQuery.orderCheck?.extras?.find((shipp) =>
        normalizeTextCompare(shipp?.label)?.includes('entrega')
      );
      if (orderTypeQuery.selectedOrderType?.orderType) {
        logAddShippingInfo({
          brandId: brandId.toString(),
          companyId: companyId.toString(),
          cartValue: totalQuery.shoppingCart?.orderTotal,
          shippingType: orderTypeQuery.selectedOrderType?.orderType.toString(),
          shipping: shipping?.value ?? 0,
        });
      }
    }
  }, [companyId, brandId, orderTypeQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (paymentQuery && paymentQuery?.selectedPaymentMethod) {
      logAddPaymentInfo({
        brandId: brandId.toString(),
        companyId: companyId.toString(),
        paymentType: paymentQuery.selectedPaymentMethod.__typename,
        cartValue: totalQuery?.shoppingCart?.orderTotal,
      });
    }
  }, [companyId, brandId, paymentQuery]); // eslint-disable-line react-hooks/exhaustive-deps
};
