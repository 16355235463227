import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { amPmTheme } from '../themes/am-pm.theme';

export const amPmConfig: AppConfig = {
  appName: 'am-pm',
  appTitle: 'Am/Pm',
  appDescription: 'Peça aqui no am/pm.',
  aggregator: 1552,
  takeOutAggregatorId: 21,
  brand: 1449,
  theme: amPmTheme,
  faviconPath: '/favicons/favicon-ampm.png',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: false,
    showOptionsTabsAggregators: true,
    filterByDeliveryArea: false,
    removeLogoFromTheApp: false,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-ampm.png',
  },
};
