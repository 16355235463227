import {
  GetUserAddressDocument,
  GetUserAddressQuery,
  LoadUserAddressFromBrowserCacheMutationVariables,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { loadUserAddressFromCache } from '../../browser-cache/user-address/load-user-address-from-cache';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';

export async function loadUserAddressFromBrowserCache(
  root: any,
  variables: LoadUserAddressFromBrowserCacheMutationVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const address = await loadUserAddressFromCache();
  if (address) {
    context.cache.writeQuery<GetUserAddressQuery>({
      query: GetUserAddressDocument,
      data: { userAddress: address },
    });
  } else if (variables.latitude && variables.longitude) {
    context.cache.writeQuery<GetUserAddressQuery>({
      query: GetUserAddressDocument,
      data: {
        userAddress: {
          id: createNewCacheKey('UserAddress'),
          __typename: 'UserAddress',
          latitude: variables.latitude,
          longitude: variables.longitude,
          formattedAddress: null,
          placeId: null,
          complement: null,
          fields: null,
        },
      },
    });
  }

  return true;
}
