interface PropsCoords {
  startLatitude: number;
  startLongitude: number;
  endLatitude: number;
  endLongitude: number;
}
export function getDistanceLatLonKm({
  startLatitude,
  startLongitude,
  endLatitude,
  endLongitude,
}: PropsCoords) {
  let earthRadius = 6371; // Radius of the earth in km
  let dLat = deg2rad(endLatitude - startLatitude); // deg2rad below
  let dLon = deg2rad(endLongitude - startLongitude);
  let calc =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(startLatitude)) *
      Math.cos(deg2rad(endLatitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(calc), Math.sqrt(1 - calc));
  let distance = earthRadius * c; // Distance in km
  return distance;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
