import React from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { Product, ProductValidationStatus } from '../../../common/generated/graphql';
import ChoosablesList from '../choosables-list/choosables-list';
import SimplesList from '../simples-list/simples-list';
import { useStyles } from './choosable.style';

interface Props {
  product: Product;
  menuItemCacheId: string;
  is2ndLevel?: boolean;
}

export default function Choosable({
  product,
  menuItemCacheId,
  is2ndLevel,
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  const renderedComponent = renderChoosable(product, menuItemCacheId, classes);

  if (is2ndLevel) {
    return renderedComponent;
  }

  return (
    <Box className={classes.choosableContainer}>
      <Box className={classes.choosable}>{renderedComponent}</Box>
    </Box>
  );
}

function renderChoosable(product: Product, menuItemCacheId: string, classes: any) {
  const invalidChoose = product.isValid === ProductValidationStatus.Invalid;

  return (
    <Box className={classes.choosableContent}>
      <Box>
        <Box className={`${classes.titleWrap} ${invalidChoose ? classes.validationError : ''}`}>
          <Typography
            variant='body1'
            classes={{ body1: invalidChoose && classes.validationErrorText }}
          >
            {product.name}
          </Typography>
          {invalidChoose && (
            <Typography
              variant='body1'
              classes={{ body1: classes.validationErrorText }}
              className={`${classes.validationErrorRequired} choosable-required-hint`}
            >
              Obrigatório *
            </Typography>
          )}
        </Box>
        <Typography variant='body2'>{product.fullDescription}</Typography>
      </Box>
      <List>
        {product.hasChoosableSubproducts ? (
          <ChoosablesList
            childProducts={product.subproducts as Product[]}
            menuItemCacheId={menuItemCacheId}
          />
        ) : (
          <SimplesList
            childProducts={product.subproducts as Product[]}
            parentProduct={product!}
            menuItemCacheId={menuItemCacheId}
          />
        )}
      </List>
    </Box>
  );
}
