import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import {
  GetShoppingCartProductsDocument,
  useGetShoppingCartProductsQuery,
  useRemoveFromCartMutation,
  useSaveShoppingCartToBrowserCacheMutation,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import CartProductItem from '../cart-product-item/cart-product-item';
import {
  logRemoveFromCart,
  logRemoveFromCartError,
} from '../../../analytics/helpers/log-remove-from-cart';
import { logBeginCheckoutError } from '../../../analytics/helpers/log-begin-checkout';
import { mountAnalyticsItem } from '../../../analytics/helpers/item-utils';
import { useLocation } from 'react-router-dom';

interface Props
  extends RouteComponentProps<{ aggregatorId: string; brandId: string; companyId: string }> {}

export default withRouter(CartProductList);
function CartProductList(props: Props): JSX.Element {
  const companyId = Number(props.match.params.companyId);
  const brandId = props.match.params.brandId;
  let content;

  const location = useLocation();

  const { data, loading, error, refetch } = useGetShoppingCartProductsQuery({
    variables: { companyId },
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      logError(err, 'Error executing Get Shopping Cart Products');
      logBeginCheckoutError({ errorCode: 19, errorMessage: err.message });
    },
  });

  const [removeFromCart] = useRemoveFromCartMutation({
    onError: (err) => {
      logError(err, 'Error executing Remove From Cart Mutation');
      logRemoveFromCartError({ errorMessage: err.message, errorCode: 20 });
    },
  });
  const [saveToCache] = useSaveShoppingCartToBrowserCacheMutation({
    onError: (err) => {
      logError(err, 'Error executing Save Shopping Cart to Browser Cache Mutation');
      logRemoveFromCartError({ errorCode: 21, errorMessage: err.message });
    },
  });

  const onRemoveProductClick = useCallback(
    async (productCacheId: string) => {
      const item = data?.shoppingCartProducts.find((i) => i.id === productCacheId);
      const formatedItem = mountAnalyticsItem({
        itemId: item?.numericalId.toString(),
        price: item?.totalPrice,
        quantity: 1,
      });

      logRemoveFromCart({
        brandId: brandId,
        companyId: companyId.toString(),
        items: [formatedItem],
      });

      await removeFromCart({
        variables: { companyId, productCacheId },
        refetchQueries: [
          {
            query: GetShoppingCartProductsDocument,
            variables: { companyId },
          },
        ],
      });
      saveToCache({
        variables: { companyId },
      });
    },
    [companyId, brandId, removeFromCart, saveToCache, data]
  );

  if (loading) {
    content = <Progress />;
  } else if (error) {
    content = (
      <NetworkError
        errorMsg='Erro ao carregar os produtos do carrinho'
        retry={refetch}
        compact={true}
        error={error}
        path={location.pathname}
      />
    );
  } else {
    content = (
      <Box>
        {data!.shoppingCartProducts!.length > 0 ? (
          data!.shoppingCartProducts!.map((product, index) => (
            <CartProductItem
              key={product.id}
              productCacheId={product.id}
              productName={product.name}
              subproductsDescription={product.subproductsDescription}
              price={product.totalPrice}
              onDeleteClick={onRemoveProductClick}
              showDivider={index < data!.shoppingCartProducts!.length - 1}
            />
          ))
        ) : (
          <ListItem>
            <Typography id='empty-cart-warning' variant='body1'>
              Você ainda não escolheu nenhum produto
            </Typography>
          </ListItem>
        )}
      </Box>
    );
  }

  return (
    <List>
      <Typography variant='h6'>Meu Pedido</Typography>
      <Divider />
      {content}
    </List>
  );
}
