import { Product, ProductType } from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function setChosenQuantityInitialValue(
  root: Product,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
): number {
  let chosenQuantity = 0;
  if (root.chosenQuantity) {
    // If there is already a chosen quantity, use that.
    chosenQuantity = root.chosenQuantity;
  } else if (isProductHiddenOrUnavailable(root)) {
    chosenQuantity = 0;
  } else if (root.productType !== ProductType.Choosable && root.minimumChoices) {
    chosenQuantity = root.minimumChoices;
  } else if (root.subproducts) {
    // Check if there is a pre-selected child for a choosable.
    for (const child of root.subproducts) {
      if (
        child &&
        child.minimumChoices &&
        child.minimumChoices > 0 &&
        child.productType !== ProductType.Choosable
      ) {
        chosenQuantity = chosenQuantity + child.minimumChoices;
      }
    }
  }

  return chosenQuantity;
}

function isProductHiddenOrUnavailable(product: Product): boolean {
  if (product.hidden) {
    return true;
  }

  if (product.productCompanyByCompanyId) {
    if (product.productCompanyByCompanyId.hidden || product.productCompanyByCompanyId.unavailable) {
      return true;
    }
  }

  return false;
}
