import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    cursor: 'pointer',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
}));
