import { BrandHighlights } from '../../../../common/model/brand-highlights';
import brandImageSmall from '../../../../images/brand-promotion-mobile.jpg';

import brandImageLarge from '../../../../images/brand-promotion-desktop.jpg';
import brandImageLargeGendai from '../../../../images/brand-promotion-desktop-gendai.png';
import brandImageSmallGendai from '../../../../images/brand-promotion-mobile-gendai.png';

const dateToUtc = (date: string) => {
  return new Date(date).toUTCString();
};

export const dataHighlights: BrandHighlights[] = [
  {
    id: 'baked-1',
    company: 'baked',
    whitelabels: ['meualelo', 'localhost'],
    buttonText: 'peça agora',
    startAt: dateToUtc('2021-08-19T00:00:00'),
    endAt: dateToUtc('2021-09-16T23:59:00'),
    aggregatorId: 820,
    desktopImage: brandImageLarge,
    mobileImage: brandImageSmall,
  },
  {
    id: 'gendai-1',
    company: 'gendai',
    whitelabels: ['meualelo', 'localhost'],
    buttonText: 'peça agora',
    startAt: dateToUtc('2021-09-17T00:01:00'),
    endAt: dateToUtc('2021-10-17T23:59:00'),
    aggregatorId: 820,
    desktopImage: brandImageLargeGendai,
    mobileImage: brandImageSmallGendai,
  },
];
