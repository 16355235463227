import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.additionalColors.aggregators?.background,
    },
    container: {
      padding: theme.spacing(2),
      maxWidth: theme.spacing(75),
    },
    content: {
      padding: theme.spacing(3, 1),
      background: theme.palette.common.white,
      borderRadius: '1rem',
      alignItems: 'center',
      flexDirection: 'column',
    },
    pane: {
      overflowY: 'scroll',
    },
    trackButton: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
    receiptButton: {
      padding: theme.spacing(2, 0),
      justifyContent: 'center',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    receiptButtonHelp: {
      padding: theme.spacing(2, 0),
      justifyContent: 'center',
      textTransform: 'none',
      textDecoration: 'none',
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightMediumBold,
      fontSize: theme.typography.button.fontSize,
    },
    containerQRCode: {
      paddingBottom: theme.spacing(2),
    },
  })
);
