import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './status-box.style';

interface Props {
  title: string;
  value?: string;
  type?: 'pending' | 'warning' | 'success' | 'error';
}

export default function StatusBox({ title, value = '', type = 'success' }: Props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.statusContainer}>
      <Box id='status-box-container' className={clsx(classes.statusBox, classes[type])}>
        <Typography
          id='status-box-title'
          variant='h6'
          align='center'
          className={clsx(classes.statusTitle, classes[type])}
        >
          {title}
        </Typography>
        <Typography id='status-box-value' variant='body2' className={classes.statusText}>
          {value}
        </Typography>
      </Box>
    </Grid>
  );
}
