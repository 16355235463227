import React, { useEffect } from 'react';
import { RegisterCardInput } from '../model/register-card-input';
import { PaymentParameter } from '../../common/generated/graphql';
import { FieldFormatType } from '../../common/model/field-format-type';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import InnerInputLabel from '../../common/components/inner-input-label/inner-input-label';
import Icon from '../../common/components/icon/icon';
import { useCheckMiniapp } from '../../common/hooks/use-check-mini-app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1, 0),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

export function useGetRegisterCardInput(paymentParameters?: PaymentParameter[]): RegisterCardInput {
  const classes = useStyles();
  const MINIAPP_LIST_CHECK = ['bitz'];
  const PAYMENT_CHECKED = 'bitz_pay';
  const { isFromMiniapp, checkMiniappList } = useCheckMiniapp();

  useEffect(() => {
    checkMiniappList(MINIAPP_LIST_CHECK);
  }, [checkMiniappList, MINIAPP_LIST_CHECK]);

  return {
    cardBrands: {
      id: 'card-brand-select',
      label: <InnerInputLabel label='Escolha a bandeira do cartão *' />,
      name: 'cardBrandSelect',
      data: paymentParameters
        ?.filter((params) => !(!isFromMiniapp && params?.cardBrand?.code === PAYMENT_CHECKED))
        ?.map((param) => ({
          value: param,
          text: param?.cardBrand?.name!,
        })),
      className: classes.input,
    },
    cardNumber: {
      label: <InnerInputLabel label='Digite o número do seu cartão *' />,
      id: 'card-number',
      name: 'cardNumber',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.CARD_NUMBER,
      className: classes.input,
      InputProps: {
        startAdornment: (
          <Icon name='creditCardLined' size='large' color='dark' className={classes.icon} />
        ),
      },
    },
    cardName: {
      label: <InnerInputLabel label='Nome Impresso no Cartão *' />,
      name: 'cardName',
      id: 'card-name',
      fieldType: 'text',
      className: classes.input,
      inputProps: { maxLength: 26 },
    },
    cardExpiration: {
      label: <InnerInputLabel label='Validade (MM/AA) *' />,
      name: 'cardExpiration',
      id: 'card-expiration',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.CARD_EXPIRATION,
      className: classes.input,
    },
    birthday: {
      label: <InnerInputLabel label='Data de Nascimento (DD/MM/AAAA) *' />,
      name: 'cardBirthday',
      id: 'card-birthday',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.BIRTHDAY,
      className: classes.input,
    },
    cardCvv: {
      label: <InnerInputLabel label='Código de segurança *' />,
      name: 'cardCvv',
      id: 'card-cvv',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.NUMERICAL,
      className: classes.input,
    },
    cpf: {
      label: <InnerInputLabel label='CPF *' />,
      name: 'cpf',
      id: 'cpf',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.CPF,
      className: classes.input,
    },
    phoneNumber: {
      label: <InnerInputLabel label='Telefone *' />,
      name: 'phoneNumber',
      id: 'phone-number',
      fieldType: 'tel',
      fieldFormatType: FieldFormatType.TELEPHONE,
      className: classes.input,
    },
  };
}
