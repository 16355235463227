import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'block',
      padding: theme.spacing(0.5, 0),
    },
    button: {
      padding: 0,
    },
    text: {
      flexGrow: 1,
    },
    price: {
      textAlign: 'right',
      paddingLeft: theme.spacing(1),
    },
    priceWeight: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textWrap: {
      display: 'flex',
    },
    multiLevelChoosable: {
      padding: 0,
      marginTop: theme.spacing(2),
    },
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);
