import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useMainHeaderSpace = makeStyles((theme: Theme) => {
  const spaceDialog = (size?: number | string) => `calc(${size} + ${theme.spacing(2)}px)`;

  return createStyles({
    measures: {
      marginTop: theme.template?.header.size.main.mobile,

      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: theme.template?.header.size.main.tablet,
      },

      [theme.breakpoints.up('md')]: {
        marginTop: theme.template?.header.size.main.desktop,
      },
    },

    fullDialog: {
      marginTop: spaceDialog(theme.template?.header.size.main.mobile),
      height: `calc(100vh - (${spaceDialog(
        theme.template?.header.size.main.mobile
      )} + ${theme.spacing(2)}px))`,

      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: spaceDialog(theme.template?.header.size.main.tablet),
        height: `calc(100vh - (${spaceDialog(theme.template?.header.size.main.tablet)} * 2))`,
      },

      [theme.breakpoints.up('md')]: {
        marginTop: spaceDialog(theme.template?.header.size.main.desktop),
        height: `calc(100vh - (${spaceDialog(theme.template?.header.size.main.desktop)} * 2))`,
      },
    },

    smallDialog: {
      marginTop: spaceDialog(theme.template?.header.size.main.mobile),

      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: spaceDialog(theme.template?.header.size.main.tablet),
      },

      [theme.breakpoints.up('md')]: {
        marginTop: spaceDialog(theme.template?.header.size.main.desktop),
      },
    },
  });
});

export const useHomeHeaderSpace = makeStyles((theme: Theme) => {
  const homeSizes: any = theme.template?.header.size.home;
  const navSizes: any = theme.template?.header.size.nav;

  return createStyles({
    home: {
      marginTop: `calc(${homeSizes.mobile} + ${navSizes.mobile} + ${theme.spacing(1)}px)`,

      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: `calc(${homeSizes.tablet} + ${navSizes.tablet} + ${theme.spacing(1)}px)`,
      },

      [theme.breakpoints.up('md')]: {
        marginTop: `calc(${homeSizes.desktop} + ${navSizes.desktop} + ${theme.spacing(1)}px)`,
      },
    },
    homeWithoutNav: {
      marginTop: `calc(${homeSizes.mobile} + ${theme.spacing(1)}px)`,

      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: `calc(${homeSizes.tablet} + ${theme.spacing(1)}px)`,
      },

      [theme.breakpoints.up('md')]: {
        marginTop: `calc(${homeSizes.desktop} + ${theme.spacing(1)}px)`,
      },
    },
  });
});
