import {
  LocalCard,
  RemoteCard,
  IsExecutingPaymentCallQuery,
  IsExecutingPaymentCallDocument,
} from '../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { cloneDeep } from 'apollo-utilities';
import { encryptTicketCardNumber, encryptCvv } from './crypto';
import { getEndpoints } from './configs';
import { logError } from './log-error';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import createBenVisaValeHeader from './create-ben-visa-vale-header';

export async function executePaymentCall(
  orderId: number,
  payment: any,
  brandId: number,
  missingCardCvv: string | null | undefined,
  missingCardNumber: string | null | undefined,
  card: LocalCard | RemoteCard | null,
  cache: InMemoryCache
) {
  updateCache(true, cache);
  const updatedPaymentCall: any = cloneDeep(payment.paymentCall);

  if (updatedPaymentCall.payload.includes('[[cardNumber]]')) {
    let finalCardNumber: string | null | undefined;
    if (card?.__typename === 'LocalCard') {
      finalCardNumber = card.cardNumber;
    } else if (card?.__typename === 'RemoteCard') {
      finalCardNumber = missingCardNumber;
    } else {
      finalCardNumber = null;
    }

    if (finalCardNumber == null) {
      console.error('We dont have a valid card number');
      Sentry.captureEvent({
        message: 'Invalid card number in payment',
        level: Sentry.Severity.Error,
      });
      updateCache(false, cache);
      return;
    }
    if (updatedPaymentCall.publicKeyModulus !== '' && updatedPaymentCall.publicKeyExponent !== '') {
      // Required for Ticket vouchers only
      const encryptedCardNumber = encryptTicketCardNumber(finalCardNumber, updatedPaymentCall);
      updatedPaymentCall.payload = updatedPaymentCall.payload.replace(
        '[[cardNumber]]',
        encryptedCardNumber
      );
    } else {
      updatedPaymentCall.payload = updatedPaymentCall.payload.replace(
        '[[cardNumber]]',
        finalCardNumber
      );
    }
  }

  let finalCardCvv: string | null | undefined;
  if (card?.__typename === 'LocalCard') {
    finalCardCvv = card.cardCvv;
  } else if (card?.__typename === 'RemoteCard') {
    finalCardCvv = missingCardCvv;
  } else {
    finalCardCvv = null;
  }

  if (finalCardCvv == null) {
    updateCache(false, cache);
    console.error('We dont have a valid card cvv');
    Sentry.captureEvent({ message: 'Invalid card CVV in payment', level: Sentry.Severity.Error });
    return;
  }

  // Required for VTEX credit card only
  if (updatedPaymentCall.payload.includes('[[cvv]]')) {
    updatedPaymentCall.payload = updatedPaymentCall.payload.replace('[[cvv]]', finalCardCvv);
  }

  updatedPaymentCall.step = payment.step;
  updatedPaymentCall.orderId = orderId;
  updatedPaymentCall.requiredFields = processRequiredFields(
    updatedPaymentCall.requiredFields,
    finalCardCvv,
    brandId,
    cache
  );

  if (card?.cardBrand === 'ben_visa_vale') {
    const { headers, payload, merchantId, url, method } = updatedPaymentCall;

    updatedPaymentCall.headers = createBenVisaValeHeader(headers, payload, merchantId, url, method);
  }

  await callPaymentProxy(payment.numericalId, updatedPaymentCall);
  updateCache(false, cache);
}

function processRequiredFields(
  requiredFields: string[],
  cvv: string,
  brandId: number,
  cache: InMemoryCache
) {
  const result: any = {};

  for (const field of requiredFields) {
    switch (field) {
      case 'cvv':
        result.cvv = encryptCvv(cvv, brandId, cache);
        break;
    }
  }

  return result;
}

async function callPaymentProxy(paymentId: number, payload: any) {
  const endpoints = getEndpoints();
  try {
    const url = `${endpoints.payments}v1/proxy/${paymentId}`;
    await axios.post(url, payload);
  } catch (error) {
    logError(error, 'Error making payment call');
  }
}

function updateCache(paymentExecutionStatus: boolean, cache: InMemoryCache) {
  cache.writeQuery<IsExecutingPaymentCallQuery>({
    query: IsExecutingPaymentCallDocument,
    data: {
      isExecutingPaymentCall: paymentExecutionStatus,
    },
  });
}
