import React from 'react';
import { Box, Grid, Container } from '@material-ui/core';

import { useGetAggregatorImagesQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import useUserCoords from '../../hooks/use-user-coords';
import AggregatorCompanyGeolocatedList from '../aggregator-company-geolocated-list/aggregator-company-geolocated-list';
import AggregatorHeader from '../aggregator-header/aggregator-header';
import NoAddressAggregator from '../no-address-aggregator/no-address-aggregator';
import { useStyles } from './aggregator-company-geolocated-container.style';
import { useHomeHeaderSpace } from '../../../common/hooks/use-header-space';

interface Props {
  aggregatorId: number;
}

export default function AggregatorCompanyGeolocatedContainer(props: Props) {
  const userLocation = useUserCoords();
  const headerHomeSpace = useHomeHeaderSpace();
  const classes = useStyles();
  const { data } = useGetAggregatorImagesQuery({
    variables: { aggregatorId: props.aggregatorId },
    fetchPolicy: 'cache-only',
    onError: (err) => logError(err, 'Error executing Get Aggregator Images'),
  });

  return (
    <Box>
      <AggregatorHeader
        aggregatorName={data?.aggregator?.name}
        aggregatorImages={data?.aggregator?.images}
      />
      <Container className={`${classes.container} ${headerHomeSpace.homeWithoutNav}`}>
        <Grid container className={classes.content}>
          {userLocation ? (
            <AggregatorCompanyGeolocatedList
              userLocation={userLocation}
              aggregatorId={props.aggregatorId}
            />
          ) : (
            <NoAddressAggregator />
          )}
        </Grid>
      </Container>
    </Box>
  );
}
