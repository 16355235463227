import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconMoney(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path
          fill='#459b26'
          d='M35.862 34.117h-31.723c-2.287 0-4.138-1.901-4.138-4.249v-19.737c0-2.348 1.851-4.249 4.138-4.249h31.712c2.298 0 4.149 1.901 4.149 4.249v19.726c0 2.359-1.851 4.26-4.138 4.26z'
        ></path>
        <path
          fill='#56ad32'
          d='M35.98 26.231c0.043 0 0.085 0 0.128 0v-12.463c-0.042 0-0.085 0-0.128 0-2.117 0-3.83-1.759-3.83-3.932h-24.468c0 2.163-1.702 3.921-3.808 3.932v12.452c2.106 0.011 3.808 1.77 3.808 3.932h24.468c0-2.163 1.723-3.921 3.83-3.921z'
        ></path>
        <path
          fill='#ccffb7'
          d='M23.757 17.563c0.021 1.049-1.787 1.081-1.798 0.033-0.021-0.459-0.223-0.885-0.585-1.191-0.138-0.12-0.298-0.218-0.447-0.306v3.146c0.66 0.142 1.266 0.426 1.734 0.841 1.489 1.245 1.457 3.364 0.021 4.631-0.489 0.426-1.074 0.732-1.755 0.885v0.743c0 1.049-1.819 1.049-1.819 0v-0.743c-1.628-0.371-2.809-1.649-2.84-3.168-0.021-1.049 1.766-1.070 1.798-0.022 0.021 0.623 0.415 1.191 1.043 1.475v-3.222c-0.681-0.12-1.287-0.404-1.755-0.797-1.511-1.212-1.426-3.342 0-4.587 0.489-0.404 1.074-0.732 1.755-0.874v-0.743c0-1.070 1.819-1.070 1.819 0v0.743c0.66 0.142 1.245 0.448 1.713 0.852 0.67 0.601 1.096 1.42 1.117 2.305zM19.098 16.11c-0.17 0.087-0.33 0.197-0.468 0.317-0.691 0.601-0.819 1.671-0.074 2.283 0.16 0.12 0.33 0.229 0.532 0.306v-2.905h0.011zM20.917 23.898c0.17-0.076 0.33-0.175 0.468-0.306 0.723-0.644 0.777-1.726 0.032-2.37-0.16-0.109-0.309-0.218-0.5-0.306v2.982z'
        ></path>
        <path
          fill='#ccffb7'
          d='M10.801 19.291h1.383c0.532 0 0.957 0.437 0.957 0.983s-0.426 0.983-0.957 0.983h-1.383c-0.532 0-0.957-0.437-0.957-0.983s0.426-0.983 0.957-0.983z'
        ></path>
        <path
          fill='#ccffb7'
          d='M27.824 19.291h1.383c0.532 0 0.957 0.437 0.957 0.983s-0.426 0.983-0.957 0.983h-1.383c-0.532 0-0.957-0.437-0.957-0.983s0.426-0.983 0.957-0.983z'
        ></path>
      </>
    </SvgWrapper>
  );
}
