export const onyoOrange = '#f65a02';
export const onyoLightOrange = '#fd7e35';
export const onyoDarkOrange = '#d01e1e';
export const onyoRed = '#d01e1e';
export const onyoBrown = '#7e2f02';
export const onyoGreen = '#20c872';
export const onyoLightPurple = '#8238a5';
export const onyoPurple = '#520075';
export const onyoDarkPurple = '#240048';

export const primaryBackground = '#fafafa';
export const secondaryBackground = '#ecf2f3';
