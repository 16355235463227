import { AddressComponentInput, Maybe, AddressFieldsInput } from '../generated/graphql';

export function parseAddress(
  formattedAddress?: string | null,
  addressComponents?: Maybe<AddressComponentInput>[] | null
): AddressFieldsInput | null {
  if (!formattedAddress) {
    return null;
  }

  const fields: AddressFieldsInput = {
    street: formattedAddress,
    number: '',
    city: '',
    district: '',
    state: '',
    zipCode: '',
  };

  if (!addressComponents) {
    return fields;
  }

  for (const component of addressComponents) {
    if (!component) {
      continue;
    }

    for (const type of component.types) {
      switch (type) {
        case 'route':
        case 'street_address':
          fields.street = component.long_name;
          break;
        case 'street_number':
          fields.number = component.long_name;
          break;

        case 'sublocality_level_1':
          fields.district = component.long_name;
          break;

        case 'administrative_area_level_2':
          fields.city = component.long_name;
          break;

        case 'administrative_area_level_1':
          fields.state = component.long_name;
          break;

        case 'postal_code':
          fields.zipCode = component.long_name;
          break;
      }
    }
  }

  return fields;
}
