import { Box, Container } from '@material-ui/core';
import React from 'react';
import AggregatorHome from '../../../aggregator-home/components/aggregator-home/aggregator-home';
import BottomTab from '../../../bottom-tab/components/bottom-tab/bottom-tab';
import { OrderEdge } from '../../../common/generated/graphql';
import usePollLastOrders from '../../hooks/use-get-last-orders/use-poll-last-orders';
import { useStyles } from './home-container.style';

export default function HomeContainer() {
  const classes = useStyles();
  const { data, loading, error, isRefetching, refetch } = usePollLastOrders();
  const showBottomTab = !loading && (data.length > 0 || error || isRefetching);

  return (
    <Box className={classes.root}>
      <Box className={classes.pane} id='main-container'>
        <AggregatorHome />
      </Box>
      {showBottomTab && (
        <Container className={classes.bottomTab} id='secondary-container'>
          <BottomTab
            data={data as OrderEdge[]}
            loading={loading}
            error={error}
            isRefetching={isRefetching}
            refetch={refetch}
          />
        </Container>
      )}
    </Box>
  );
}
