import { OnlineStatus } from '../../common/generated/graphql';
import { parseImage } from '../../common/helpers/parse-image';
import { Maybe, CompanySharedEdge } from './../../common/generated/graphql';

interface Props {
  companiesCard: Maybe<CompanySharedEdge>[];
  limitShowCompanies: number;
  limitShowAvatar: number;
}

interface hiddenCompaniesParse {
  src: string;
  alt: string;
  disabled: boolean;
}

export default function useGetHiddenCompanies({
  companiesCard,
  limitShowCompanies,
  limitShowAvatar,
}: Props) {
  let hiddenNames = '';
  let numberOtherOptions = companiesCard.length - limitShowCompanies - limitShowAvatar;
  const hidden = companiesCard.slice(limitShowCompanies);

  const hiddenCompanies = hidden.map((company, index) => {
    hiddenNames += index && index < limitShowAvatar ? ', ' : '';
    hiddenNames += `${index + 1 > limitShowAvatar ? '' : company?.node?.displayName}`;
    return {
      src: parseImage(company?.node?.brand?.images, 'brand-aggregator-company-list'),
      disabled: company?.node?.onlineStatus !== OnlineStatus.Online,
      alt: company?.node?.displayName,
    } as hiddenCompaniesParse;
  });

  if (numberOtherOptions > 0) {
    hiddenNames += ` e outras ${numberOtherOptions} opções.`;
  }

  return { hiddenCompanies, hiddenNames };
}
