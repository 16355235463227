import React, { ReactElement, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';
import { OrderFieldType } from '../../generated/graphql';
import { getOnlyDigits } from '../../helpers/get-only-digits';
import { formatPhoneNumber } from '../../helpers/format-phone-number';
import { formatCPF } from '../../helpers/format-cpf';

interface Props {
  id: string;
  label: string;
  name: string;
  orderFieldType: OrderFieldType;
}

export default function OrderTypeField(props: Props): ReactElement {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  let textFieldType = 'text';
  switch (props.orderFieldType) {
    case OrderFieldType.Numeric:
      textFieldType = 'tel';
      break;
    case OrderFieldType.Telephone:
      textFieldType = 'tel';
      break;
    case OrderFieldType.Cpf:
      textFieldType = 'tel';
      break;
  }

  const handleChange = useCallback(
    (event) => {
      switch (props.orderFieldType) {
        case OrderFieldType.Text:
          setFieldValue(field.name, event.target.value);
          break;
        case OrderFieldType.Numeric:
          setFieldValue(field.name, getOnlyDigits(event.target.value));
          break;
        case OrderFieldType.Telephone:
          setFieldValue(field.name, formatPhoneNumber(event.target.value));
          break;
        case OrderFieldType.Cpf:
          setFieldValue(field.name, formatCPF(event.target.value));
          break;
        default:
          setFieldValue(field.name, event.target.value);
          break;
      }
    },
    [field.name, setFieldValue, props.orderFieldType]
  );

  return (
    <TextField
      id={props.id}
      label={props.label}
      name={props.name}
      type={textFieldType}
      value={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={meta.error && meta.touched ? true : false}
      helperText={meta.error}
      margin='dense'
      fullWidth={true}
      inputProps={{ 'aria-label': props.label }}
    />
  );
}
