import React, { useCallback, useEffect } from 'react';

import { Box, Container, Grid, useTheme } from '@material-ui/core';
import 'firebase/analytics';
import { useParams } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Category,
  Product,
  ProductCompanyEdge,
  useGetShoppingCartProductsQuery,
} from '../../../common/generated/graphql';

import { useProductsCategoriesRef } from '../../hooks/use-products-categories-ref';
import { useUpdateRepeatOrder } from '../../../common/hooks/use-update-repeat-order';
import { useGetCartCompanyFromCache } from '../../hooks/use-get-cart-company-from-cache';

import CompanyHeader from '../company-header/company-header';
import { useStyles } from './company-container.style';
import CompanyShopButton from '../company-shop-button/company-shop-button';
import CompanyNavigation from '../company-navigation/company-navigation';
import ProductListCategory from '../product-list-category/product-list-category';
import { CompanyFooter } from '../company-footer/company-footer';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { logError } from '../../../common/helpers/log-error';
import RepeatOrder from '../repeat-order/repeat-order';
import { CompanyPage } from '../../models/company-page';
import { ProductDetails } from '../../../product-details/components/product-details/product-details';

import LayoutDefault from '../layout-default/layout-default';
import LayoutDesktop from '../layout-desktop/layout-desktop';
import { logBeginCheckoutError } from '../../../analytics/helpers/log-begin-checkout';
import { logSelectReorder } from '../../../analytics/helpers/log-select-reorder';
import { mountAnalyticsItemArray } from '../../../analytics/helpers/item-utils';
import { useSelectTable } from '../../hooks/use-select-table';

interface Props {
  categories: { category: Category; products: ProductCompanyEdge[] }[];
}

export default function CompanyContainer(props: Props): React.ReactElement {
  useSelectTable();
  const headerSpace = useMainHeaderSpace();

  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme?.breakpoints?.up('md'));
  const Layout = isDesktop ? LayoutDesktop : LayoutDefault;

  const { repeatOrderCompany } = useUpdateRepeatOrder();
  let { companyId, brandId, pageType } = useParams<{
    companyId: string;
    brandId: string;
    pageType: string;
  }>();
  const companyIdParam = Number(companyId);
  const brandIdParam = Number(brandId);
  const cartCompanyLastOrder = useGetCartCompanyFromCache(companyIdParam);
  const isSimpleMenu = pageType === CompanyPage.Simple;

  const {
    handleChangeCategory,
    onClickScroll,
    categoriesRefMap,
    mountNavToScrollProps,
    tabSelected,
  } = useProductsCategoriesRef(props.categories);
  const {
    data: dataShoppingCartProducts,
    loading: loadingShoppingCartProducts,
    refetch: refetchShoppingCartProducts,
  } = useGetShoppingCartProductsQuery({
    variables: { companyId: companyIdParam },
    onError: (err) => {
      logError(err, 'Error executing Get Shopping Cart Products');
      logBeginCheckoutError({ errorCode: 41, errorMessage: err.message });
    },
  });

  useEffect(() => {
    refetchShoppingCartProducts();
  }, [refetchShoppingCartProducts]);

  const handleRepeatOrderCompany = useCallback(async () => {
    logSelectReorder({
      brand_id: brandIdParam,
      company_id: companyIdParam,
      items: mountAnalyticsItemArray(cartCompanyLastOrder?.products as Product[]),
    });

    await repeatOrderCompany(companyIdParam);
    refetchShoppingCartProducts();
  }, [
    brandIdParam,
    cartCompanyLastOrder,
    companyIdParam,
    refetchShoppingCartProducts,
    repeatOrderCompany,
  ]);

  return (
    <Box className={`${classes.page} ${!isSimpleMenu && headerSpace.measures}`}>
      <Box id='company-home-container' flexDirection='column' className={classes.root}>
        {!isSimpleMenu && <MainHeader isCompany />}
        <Container className={classes.container} classes={{ root: classes.rootContainer }}>
          <Grid container>
            <Layout>
              <CompanyHeader />
              <CompanyNavigation
                value={tabSelected}
                onChange={handleChangeCategory}
                categories={props.categories}
                onClick={onClickScroll}
                a11yProps={mountNavToScrollProps}
                isDesktop={isDesktop}
              />
            </Layout>
            <Grid item md={8}>
              <Box className={classes.products}>
                {cartCompanyLastOrder && (
                  <RepeatOrder
                    onRepeatOrder={handleRepeatOrderCompany}
                    cartCompany={cartCompanyLastOrder}
                  />
                )}
                <ProductListCategory
                  mountNavToScrollProps={mountNavToScrollProps}
                  categoriesRefMap={categoriesRefMap}
                  categories={props.categories}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <CompanyShopButton data={dataShoppingCartProducts} loading={loadingShoppingCartProducts} />
      </Box>
      <CompanyFooter />
      <ProductDetails />
    </Box>
  );
}
