import React, { useCallback, useState } from 'react';
import { useGetUserAddressQuery, SelectedOrderType } from '../../../../common/generated/graphql';
import { Box, Typography, makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import OrderTypeExtraHeader from '../order-type-extra-header/order-type-extra-header';
import GeolocationContainerDialog from '../../../../geolocation/components/geolocation-container/geolocation-container-dialog';
import { logError } from '../../../../common/helpers/log-error';

interface Props {
  orderType: SelectedOrderType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1, 0, 0, 0),
    },
    addressContainer: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    boldText: {
      fontWeight: 500,
    },
    addressBtn: {
      padding: 0,
    },
  })
);

export default function OrderTypeDeliveryAddress(props: Props) {
  const classes = useStyles();
  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const { data, loading } = useGetUserAddressQuery({
    onError: (err) => logError(err, 'Error executing Get User Address Query'),
  });

  const onDialogOpenClick = useCallback(() => {
    setLocationDialogOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setLocationDialogOpen(false);
  }, []);

  if (loading) {
    return <Box />;
  }

  return (
    <Box className={classes.root}>
      <OrderTypeExtraHeader orderType={props.orderType} />
      <Box className={classes.addressContainer}>
        {!data?.userAddress?.placeId && (
          <Box id='no-address-container'>
            <Typography variant='body1' gutterBottom={true}>
              Você ainda não escolheu o endereço de entrega
            </Typography>
            <Button
              id='choose-address-btn'
              color='primary'
              variant='contained'
              size='small'
              onClick={onDialogOpenClick}
            >
              Escolher Endereço
            </Button>
          </Box>
        )}
        {data?.userAddress?.placeId && (
          <Box id='user-address-container'>
            <Typography variant='body2' gutterBottom={true}>
              <span className={classes.boldText}>Endereço: </span>
              {data?.userAddress?.formattedAddress}
            </Typography>
            {data?.userAddress?.complement !== '' && (
              <Typography variant='body2' gutterBottom={true}>
                <span className={classes.boldText}>Complemento: </span>
                {data?.userAddress?.complement}
              </Typography>
            )}
            <Button
              id='change-address-btn'
              color='primary'
              variant='text'
              onClick={onDialogOpenClick}
              size='small'
              className={classes.addressBtn}
            >
              Mudar endereço
            </Button>
          </Box>
        )}
      </Box>
      <GeolocationContainerDialog
        open={isLocationDialogOpen}
        showCurrentLocation={false}
        handleClose={handleClose}
      />
    </Box>
  );
}
