import React from 'react';

import { List, ListSubheader, Typography } from '@material-ui/core';

import ProductListItem from '../product-list-item/product-list-item';
import { useStyles } from './product-list-category.style';
import { ProductListCategoryProps } from '../../models/product-list-category-props';

export default function ProductListCategory(props: ProductListCategoryProps): React.ReactElement {
  const classes = useStyles();

  return (
    <List subheader={<li />} className={classes.root}>
      {props.categories.map(({ category, products }) => (
        <li
          ref={props.categoriesRefMap[category.id]}
          key={'Fragment' + category.numericalId}
          {...props.mountNavToScrollProps(category.id).panel}
        >
          <List className={classes.category}>
            <ListSubheader className={classes.categoryHeader}>
              <Typography variant='caption' className={classes.title}>
                {category.name}
              </Typography>
            </ListSubheader>
            {products.map((productCompany, index) => (
              <div
                className={classes.product}
                role='tabpanel'
                key={'Product' + productCompany.node!.id!}
              >
                <ProductListItem product={productCompany} last={products.length === index + 1} />
              </div>
            ))}
          </List>
        </li>
      ))}
    </List>
  );
}
