import {
  SetUserAddressMutationVariables,
  GetUserAddressQuery,
  GetUserAddressDocument,
  UserAddress,
  UserAddressInput,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';
import { saveUserAddressToCache } from '../../browser-cache/user-address/save-user-address-to-cache';

export function userAddress(
  root: any,
  variables: SetUserAddressMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const addressQuery = context.cache.readQuery<GetUserAddressQuery>({
    query: GetUserAddressDocument,
  });

  let formattedAddress;
  if (variables.input.fields) {
    formattedAddress = `${variables.input.fields.street}, ${variables.input.fields.number}`;
  }

  let updatedAddress: any;
  if (addressQuery?.userAddress) {
    updatedAddress = createAddressFromInput(
      variables.input,
      addressQuery.userAddress.id,
      formattedAddress
    );
  } else {
    updatedAddress = createAddressFromInput(variables.input, undefined, formattedAddress);
  }

  context.cache.writeQuery<GetUserAddressQuery>({
    query: GetUserAddressDocument,
    data: { userAddress: updatedAddress },
  });

  saveUserAddressToCache(updatedAddress);

  return updatedAddress;
}

function createAddressFromInput(input: UserAddressInput, id?: string, formattedAddress?: string) {
  const address: UserAddress = {
    id: id ? id : createNewCacheKey('UserAddress'),
    __typename: 'UserAddress',
    latitude: input.latitude,
    longitude: input.longitude,
    formattedAddress: formattedAddress ?? null,
    placeId: input.placeId ?? null,
    complement: input.complement ?? null,
    fields: input.fields
      ? {
          ...input.fields,
          __typename: 'AddressFields',
        }
      : null,
  };

  return address;
}
