import { Box, Button, Typography, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useState } from 'react';
import { useGetUserAddressQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import GeolocationContainerDialog from '../../../geolocation/components/geolocation-container/geolocation-container-dialog';
import { getAppConfig } from '../../../common/helpers/configs';
import { useStyles } from './address-picker.style';

export default function AddressPicker() {
  const classes = useStyles();
  const { featureFlags } = getAppConfig();

  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const addressQuery = useGetUserAddressQuery({
    onCompleted: (result) => {
      if (!result?.userAddress && !featureFlags.isCieloMiniApp) {
        setLocationDialogOpen(true);
      }
    },
    onError: (err) => logError(err, 'Error executing Get User Address Query'),
  });

  const handleClose = useCallback(() => {
    setLocationDialogOpen(false);
  }, []);
  const onClickHandler = useCallback(() => {
    setLocationDialogOpen(true);
  }, []);

  let addressText = '';
  if (!addressQuery.data?.userAddress) {
    addressText = 'Adicione o seu endereço';
  } else if (!addressQuery.data.userAddress.placeId) {
    addressText = 'Usando a sua localização atual';
  } else {
    addressText = addressQuery.data.userAddress.formattedAddress!;
  }

  return (
    <Grid
      container
      className={`${classes.addressPicker} ${
        featureFlags.removeLogoFromTheApp && classes._brandless
      }`}
    >
      <Box className={classes.content}>
        <Typography variant='caption' className={classes.label}>
          Endereço atual
        </Typography>
        <Button
          id='address-btn'
          variant='contained'
          disableElevation={true}
          onClick={onClickHandler}
          className={classes.btn}
          endIcon={<ExpandMoreIcon />}
        >
          <span className={classes.addressText}>{addressText}</span>
        </Button>
        <GeolocationContainerDialog
          open={isLocationDialogOpen}
          showCurrentLocation={true}
          handleClose={handleClose}
        />
      </Box>
    </Grid>
  );
}
