import {
  useGetCustomerDataLazyQuery,
  usePartnerLoginMutation,
} from '../../../../generated/graphql';
import { logError } from '../../../../helpers/log-error';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import 'firebase/analytics';
import queryString from 'query-string';
import {
  logInitialization,
  logInitializationError,
} from '../../../../../analytics/helpers/log-initialization';

export default function useCustomerLogin() {
  const location = useLocation();
  const [getCustomerData] = useGetCustomerDataLazyQuery({
    onError: (err) => {
      logError(err, 'Error executing Get Order Status Lazy Query');
      logInitializationError({ errorMessage: err.message, errorCode: 33 });
    },
    onCompleted: (data) => {
      if (data?.customerData) {
        logInitialization({ userId: data?.customerData.numericalId.toString(), isLogged: 'true' });
      } else {
        logInitialization({});
      }
    },
  });
  const [partnerLogin] = usePartnerLoginMutation({
    onError: (err) => {
      logError(err, 'Error executing Partner Login Lazy Query');
      logInitializationError({ errorCode: 32, errorMessage: err.message });
    },
  });

  const login = useCallback(async () => {
    const parsed = queryString.parse(location.search);
    if (parsed.token) {
      await partnerLogin({ variables: { token: parsed.token as string } });
      getCustomerData();
    } else {
      getCustomerData();
    }
  }, [getCustomerData, location.search, partnerLogin]);

  return login;
}
