import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardText: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  listItemHighlight: {
    justifyContent: 'flex-start',
    borderRadius: '0.25rem',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  lessHighlightOffline: {
    backgroundColor: 'transparent',
  },
}));
