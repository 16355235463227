import {
  SelectWalletMutationVariables,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';

export function selectWallet(
  root: any,
  variables: SelectWalletMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
    data: {
      selectedPaymentMethod: {
        __typename: 'Wallet',
        id: createNewCacheKey('Wallet'),
        cardBrand: variables.cardBrand,
      },
    },
  });

  return true;
}
