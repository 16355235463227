import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconCieloPay(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path
          fill='#000'
          d='M19.999 31.927c-6.583 0-11.924-5.341-11.924-11.924 0-1.95 0.472-3.801 1.304-5.415l-7.328-3.416c-1.304 2.658-2.049 5.664-2.049 8.831 0 11.042 8.955 19.997 19.997 19.997 5.428 0 10.346-2.161 13.948-5.676l-5.713-5.713c-2.136 2.062-5.043 3.316-8.235 3.316z'
        ></path>
        <path
          fill='#00acf3'
          d='M20 0.001c-5.117 0-9.8 1.925-13.327 5.092l5.44 5.962c2.099-1.851 4.856-2.981 7.887-2.981 6.583 0 11.924 5.341 11.924 11.924 0 1.751-0.373 3.416-1.056 4.906l7.316 3.416c1.167-2.534 1.813-5.353 1.813-8.322 0-11.042-8.955-19.997-19.997-19.997z'
        ></path>
      </>
    </SvgWrapper>
  );
}
