import { saveCartToCache } from './save-cart-to-cache';
import { loadCartCompanyFromCache } from './load-cart-company-from-cache';
import { SHOPPING_CART_LAST_ORDER_CACHE } from '../../model/caches';

export async function repeatLastOrderFromCache(companyId: number) {
  const cartCompany = await loadCartCompanyFromCache(companyId, SHOPPING_CART_LAST_ORDER_CACHE);
  if (cartCompany) {
    await saveCartToCache({ companyId, shoppingCart: cartCompany });
  }
  return;
}
