import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'select_help';

interface LogSelectHelp {
  transaction_id: string | null;
  value: number | null;
}

export function logSelectHelp({ transaction_id, value }: LogSelectHelp) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.TRANSACTION_ID]: transaction_id,
      [ParamName.VALUE]: value,
    },
  });
}

export function logSelectHelpError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
