import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './company-header.style';
import Icon from '../../../common/components/icon/icon';
import CompanyHeaderTimeline from '../company-header-timeline/company-header-timeline';
import CompanyHeaderHightlight from '../company-header-highlight/company-header-highlight';
import CompanyHeaderPayments from '../company-header-payments/company-header-payments';
import ShareSimpleMenu from '../share-simple-menu/share-simple-menu';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import { parseCompanyAddress, parseCompanyImg } from '../../helpers/parsers';
import { Company, Address } from '../../../common/generated/graphql';
import { formatAsLocalNumber } from '../../../common/helpers/format-phone-number';
import { CompanyPage } from '../../models/company-page';

export default function CompanyHeader() {
  const companyData = useGetCompanyDataFragment();
  const classes = useStyles();
  const address = parseCompanyAddress(companyData?.address as Address);
  const companyName = companyData?.name;
  const companyImg = parseCompanyImg(companyData as Company);
  const phoneNumber = formatAsLocalNumber(companyData?.companyPhone?.number);
  const { pageType } = useParams<{
    pageType: string;
  }>();

  const isSimpleMenu = pageType === CompanyPage.Simple;

  return (
    <>
      <Box
        className={clsx(classes.root, {
          [classes.rootSimple]: isSimpleMenu,
        })}
        id='ref-company-header'
      >
        <Box className={classes.appresentation}>
          <Box>
            {companyImg ? (
              <img
                id='company-header-img'
                className={classes.img}
                src={companyImg}
                alt={`logo ${companyName}`}
              />
            ) : (
              <div id='company-header-empty-img' className={classes.emptyImg}>
                {companyName?.trim()[0]}
              </div>
            )}
          </Box>
          <Box className={classes.wrapInfo}>
            <Box>
              <Typography className={classes.title} variant='h6'>
                {companyName}
              </Typography>
            </Box>

            <Box>
              {address && (
                <Box className={classes.labelAndIcon}>
                  <Icon name='pin' size='medium' className={classes.icon} />
                  <Typography variant='caption'>{address}</Typography>
                </Box>
              )}
              {phoneNumber && (
                <Box className={classes.labelAndIcon}>
                  <Icon name='phoneNumber' size='medium' className={classes.icon} />
                  <Typography variant='caption'>{phoneNumber}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {isSimpleMenu ? (
          <ShareSimpleMenu />
        ) : (
          <>
            <CompanyHeaderTimeline />
            <CompanyHeaderPayments />
            <CompanyHeaderHightlight />
          </>
        )}
      </Box>
    </>
  );
}
