import { GetCardsQueryVariables, RemoteCard } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { getEndpoints } from '../../helpers/configs';
import { getSupportedPaymentMethods } from '../../helpers/get-supported-payment-methods';
import { handleAuthError } from '../../helpers/handle-auth-error';
import { logError } from '../../helpers/log-error';
import { filterRemoteCards } from '../../helpers/filter-remote-cards';

export default async function cards(
  root: any,
  variables: GetCardsQueryVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<RemoteCard[]> {
  const endpoints = getEndpoints();
  const url = `${endpoints.api}v1/mobile/card`;

  try {
    const result = await axios.get(url);
    const supportedPaymentMethods = await getSupportedPaymentMethods(
      context.client,
      variables.companyId
    );
    const cards = filterRemoteCards(result.data.data, supportedPaymentMethods);
    return cards;
  } catch (error) {
    handleAuthError(error, context.cache);
    logError(error, 'Error retrieving user cards.');
    throw new Error('Não foi possível obter os seus cartões.');
  }
}
