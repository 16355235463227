import { AppConfig } from '../../../common/model/config';
import { multiplanTheme } from '../themes/multiplan.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const morumbiConfig: AppConfig = {
  appName: 'morumbi',
  appTitle: 'Morumbi',
  appDescription: 'Peça aqui nos restaurantes do Shopping Morumbi.',
  aggregator: 43,
  brand: 450,
  theme: multiplanTheme,
  faviconPath: '/favicons/favicon-multiplan.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
  },
};
