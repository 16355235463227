import React from 'react';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';

import { useStyles } from './aggregators-chip-highlight.style';
import { OnlineStatus, TimeRulesFragment, TimeRule } from '../../../common/generated/graphql';
import { getStatusText } from '../../../common/helpers/get-status-text.helper';
import getHighlightStatus from '../../../common/helpers/get-highlight-status';
import { useHighLightStatusStyles } from '../../../common/hooks/use-highlight-status-style';

interface Props {
  status?: OnlineStatus | null;
  highlightText: string;
  timeRules?: TimeRule | null;
}

function AggregatorsChipHighlight({ status, timeRules, highlightText }: Props) {
  const classes = useStyles();
  const statusClasses = useHighLightStatusStyles();
  const highlight =
    status === OnlineStatus.Closed
      ? getStatusText(status, timeRules as TimeRulesFragment)
      : highlightText;

  const highlightStatus = getHighlightStatus(highlight, status as OnlineStatus);
  const isLessHighlightOffline = !highlightText && status === OnlineStatus.Offline;

  return (
    <Chip
      id='aggregators-chip-highlight'
      role='chip-highlight'
      size='small'
      label={highlight}
      className={clsx(classes.cardText, classes.listItemHighlight, statusClasses[highlightStatus], {
        [classes.lessHighlightOffline]: isLessHighlightOffline,
      })}
    />
  );
}

export default AggregatorsChipHighlight;
