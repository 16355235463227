import { Box, Typography, List } from '@material-ui/core';
import React from 'react';
import { useStyles } from './payment-selected-options.style';
import {
  RemoteCard,
  LocalCard,
  useSelectCardMutation,
  useGetCardsQuery,
  useGetLocalCardQuery,
  RemoteCardInput,
  LocalCardInput,
} from '../../../../common/generated/graphql';
import { logError } from '../../../../common/helpers/log-error';
import { useParams } from 'react-router-dom';
import NetworkError from '../../../../common/components/network-error/network-error';
import Progress from '../../../../common/components/progress/progress';
import { Maybe } from 'graphql/jsutils/Maybe';
import PaymentSelectedItem from '../payment-selected-item/payment-selected-item';
import { useLocation } from 'react-router-dom';

interface Props {
  closeDialog: () => void;
  openRemoveDialog: (id?: number) => void;
}

export default function PaymentSelectedOptions(props: Props) {
  const classes = useStyles();
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();

  const { data, error, refetch, loading } = useGetCardsQuery({
    variables: { companyId: Number(companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err) => logError(err, 'Error executing Get Cards Query'),
  });

  const { data: localData } = useGetLocalCardQuery({
    onError: (err) => logError(err, 'Error executing Get Local Card Query'),
  });

  const [selectCard] = useSelectCardMutation({
    onError: (err) => logError(err, 'Error executing Select Remote Card Mutation'),
  });

  const onClickSelectCard = (card: RemoteCard | LocalCard) => {
    if (card.__typename === 'RemoteCard') {
      selectCard({
        variables: { remoteCard: card as RemoteCardInput },
      });
    } else {
      selectCard({
        variables: { localCard: card as LocalCardInput },
      });
    }

    props.closeDialog();
  };

  if (loading) {
    return (
      <Box className={classes.progress}>
        <Progress />
      </Box>
    );
  }

  if (error) {
    return (
      <NetworkError
        errorMsg={'Erro ao carregar cartões salvos.'}
        compact={true}
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  }

  const emptyLocalOptions = !((data?.cards && data.cards?.length) || localData?.localCard);

  if (emptyLocalOptions) {
    return <span id='payment-selected-options-empty' className={classes.empty} />;
  }

  let localOptions: Maybe<Array<LocalCard | RemoteCard>> = [];

  if (data?.cards) {
    localOptions = [...localOptions, ...(data?.cards as RemoteCard[])];
  }

  if (localData?.localCard) {
    localOptions = [...localOptions, localData.localCard];
  }

  return (
    <>
      <Typography variant='body2' className={classes.title}>
        Meus cartões
      </Typography>
      <List id='payment-selected-options-list'>
        {localOptions &&
          localOptions.map((card: RemoteCard | LocalCard) => (
            <PaymentSelectedItem
              key={card.id}
              isRemote={card.__typename === 'RemoteCard'}
              card={card}
              onClickCard={onClickSelectCard}
              openRemoveDialog={props.openRemoveDialog}
            />
          ))}
      </List>
    </>
  );
}
