import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';
import { zucaBurguerTheme } from '../themes/zuca-burguer.theme';

export const zucaBurguerConfig: AppConfig = {
  appName: 'zuca-burguer',
  appTitle: 'Zuca Burguer',
  appDescription: 'Peça aqui no Zuca Burguer',
  aggregator: 813,
  brand: 822,
  theme: zucaBurguerTheme,
  faviconPath: '/favicons/favicon-zuca-burguer.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Chácara Santo Antônio
    latitude: -23.632432,
    longitude: -46.70033,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
