import { useStyles } from './complete-address-form.style';
import { FieldFormatType } from '../../../common/model/field-format-type';
import { CompleteUserAddressInput } from '../../model/complete-user-address-input';

export function useAddressFormFields(): CompleteUserAddressInput {
  const classes = useStyles();

  return {
    addressNumber: {
      id: 'address-number',
      label: 'Número',
      placeholder: 'Número da casa ou predio.',
      name: 'addressNumber',
      fieldType: 'number',
      className: classes.field,
    },
    addressComplement: {
      id: 'address-complement',
      label: 'Apartamento, bloco, etc. (opcional)',
      placeholder: 'Ap. 33, Torre A',
      name: 'addressComplement',
      fieldType: 'text',
      className: classes.field,
    },
    addressDistrict: {
      id: 'address-district',
      label: 'Bairro',
      placeholder: 'Digite seu bairro.',
      name: 'addressDistrict',
      fieldType: 'text',
      className: classes.field,
    },
    addressState: {
      id: 'address-state',
      label: 'Estado',
      placeholder: 'Selecione seu Estado.',
      name: 'addressState',
      fieldType: 'text',
      className: classes.field,
    },
    addressCity: {
      id: 'address-city',
      label: 'Cidade',
      placeholder: 'Selecione sua cidade.',
      name: 'addressCity',
      fieldType: 'text',
      className: classes.field,
    },
    addressZipCode: {
      id: 'address-zip-code',
      label: 'CEP',
      placeholder: 'xxxxx-xxx',
      name: 'addressZipCode',
      fieldType: 'text',
      fieldFormatType: FieldFormatType.CEP,
      className: classes.field,
    },
  };
}
