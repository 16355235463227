import { AppConfig } from '../../../common/model/config';
import { chefsClubTheme } from '../themes/chefs-club.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const chefsClubConfig: AppConfig = {
  appName: 'chefs-club',
  appTitle: 'ChefsClub',
  appDescription: 'Peça aqui nos restaurantes do ChefsClub.',
  aggregator: 758,
  takeOutAggregatorId: 21,
  brand: 748,
  theme: chefsClubTheme,
  faviconPath: '/favicons/favicon-chefs-club.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Ipanema
    latitude: -22.986527,
    longitude: -43.202741,
  },
  featureFlags: {
    embeddedApplication: true,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: true,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-chefs-club.png',
  },
};
