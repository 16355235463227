import { Box, Typography, ListItem, IconButton, ListItemSecondaryAction } from '@material-ui/core';
import React from 'react';
import { useStyles } from './payment-selected-item.style';
import SelectCardBrandIcon from '../../../../common/components/select-card-brand-icon/select-card-brand-icon';
import { RemoteCard, LocalCard } from '../../../../common/generated/graphql';
import { UnionCardBrand } from '../../../../common/model/card-brand';
import { formatPrintedCardNumber } from '../../../../common/helpers/format-printed-card';
import formatPaymentMethod from '../../../../common/helpers/format-payment-method';
import Icon from '../../../../common/components/icon/icon';

interface Props {
  isRemote: boolean;
  card: RemoteCard | LocalCard;
  onClickCard: (card: RemoteCard | LocalCard) => void;
  openRemoveDialog: (id?: number) => void;
}

export default function PaymentSelectedItem(props: Props) {
  const classes = useStyles();
  const pay = formatPaymentMethod(props.card);

  return (
    <ListItem id={`payment-selected-${pay?.id}-item`} button className={classes.item}>
      <Box
        className={`payment-selected-item ${classes.infoWrap}`}
        onClick={() => props.onClickCard(props.card)}
      >
        <Box className={classes.wrapIcon}>
          <SelectCardBrandIcon size='large' card={props.card.cardBrand as UnionCardBrand} />
        </Box>
        <Box className={classes.wrapText}>
          <Typography variant='body2'>{pay?.cardBrand}</Typography>
          <Typography variant='caption' className={classes.number}>
            {formatPrintedCardNumber(pay?.cardFullMasked ? pay?.cardFullMasked : pay?.cardNumber)}
          </Typography>
          <Typography variant='caption' className={classes.name}>
            {pay?.cardExpiration}
          </Typography>
        </Box>
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          className='payment-selected-remove'
          onClick={() => props.openRemoveDialog(props.isRemote ? Number(pay?.id) : undefined)}
        >
          <Icon name='trash' color='error' size='large' />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
