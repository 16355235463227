import { getOnlyDigits } from './get-only-digits';

export function getBirthdayRegex(): RegExp {
  return new RegExp('^\\d{2}/\\d{2}/\\d{4}$');
}

export function formatBirthday(value: string): string {
  let valueNumber = getOnlyDigits(value).substring(0, 8);
  if (valueNumber.length > 2) {
    valueNumber = valueNumber.substring(0, 2) + '/' + valueNumber.substring(2, 8);
  }
  if (valueNumber.length > 5) {
    valueNumber = valueNumber.substring(0, 5) + '/' + valueNumber.substring(5, 9);
  }
  return valueNumber;
}
