import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconElo(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path
          fill='#ffcb05'
          d='M16.434 8.833c1.187-0.392 2.453-0.601 3.766-0.601 5.75 0 10.55 4.043 11.647 9.418l8.15-1.645c-1.867-9.131-10.023-16.004-19.798-16.004-2.242 0-4.389 0.36-6.404 1.029l2.638 7.804z'
        ></path>
        <path
          fill='#00a4e0'
          d='M6.817 34.98l5.513-6.169c-2.458-2.157-4.009-5.307-4.009-8.817 0-3.505 1.551-6.654 4.009-8.807l-5.513-6.174c-4.178 3.667-6.816 9.021-6.816 14.981 0 5.965 2.638 11.319 6.816 14.986z'
        ></path>
        <path
          fill='#ef4123'
          d='M31.848 22.36c-1.102 5.37-5.898 9.407-11.642 9.407-1.319 0-2.59-0.209-3.772-0.606l-2.638 7.814c2.015 0.663 4.167 1.024 6.409 1.024 9.764 0 17.914-6.863 19.792-15.983l-8.15-1.656z'
        ></path>
      </>
    </SvgWrapper>
  );
}
