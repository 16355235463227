import { useState, useCallback } from 'react';
import {
  logAddAddressInfo,
  logAddAddressInfoError,
} from '../../../analytics/helpers/log-add-address-info';
import { getSharedConfig } from '../../../common/helpers/configs';
import { GeolocationData, GeolocationResult } from '../../model/geolocation';

export default function useGeolocation(
  onGeolocationSuccess?: (data: GeolocationData) => void
): GeolocationResult {
  const [geolocationData, setGeolocationData] = useState<GeolocationData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSuccess = useCallback(
    (position: Position) => {
      const data: GeolocationData = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      logAddAddressInfo({ type: 'auto', latitude: data.latitude, longitude: data.longitude });

      setGeolocationData(data);
      setLoading(false);
      if (onGeolocationSuccess) {
        onGeolocationSuccess(data);
      }
    },
    [onGeolocationSuccess]
  );

  const onError = useCallback((error: PositionError) => {
    console.warn('Error getting the user position', error);
    if (error.code === error.PERMISSION_DENIED) {
      setError(
        'Não temos permissão para acessar a sua localização :-( Você pode nos dar essa permissão ou pode adicionar o seu endereço abaixo.'
      );

      logAddAddressInfoError({ errorMessage: error.code.toString(), errorCode: 56 });
    } else {
      setError(
        'Não conseguimos obter a sua localização. Você pode tentar de novo ou adicionar o seu endereço.'
      );

      logAddAddressInfoError({ errorMessage: error.code.toString(), errorCode: 57 });
    }

    setLoading(false);
  }, []);

  const geolocation = useCallback(() => {
    if (!navigator.geolocation) {
      console.warn('No geolocation available');
      setError('A sua localização não está disponível. Você pode colocar o endereço?.');
      logAddAddressInfoError({ errorCode: 58 });
      return;
    }

    if (!loading) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: false,
        timeout: getSharedConfig().timeout,
      });
      setLoading(true);
    }
  }, [loading, onError, onSuccess]);

  return { geolocation, geolocationData, loading, error };
}
