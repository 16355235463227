import { Result, MagicLinkLoginMutationVariables } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';
import { logLoginError } from '../../../analytics/helpers/log-login';

export async function magicLinkLogin(
  root: any,
  variables: MagicLinkLoginMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  const uri = `v1/magiclink/login`;
  let success = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';

  try {
    const endpoints = getEndpoints();
    await axios.post(`${endpoints.api}${uri}`, {
      email: variables.input.email,
      validation_code: variables.input.token,
    });

    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    const err = error as AxiosError;
    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      console.warn('Error in Magic Link Create Call', err);

      const errorType = err.response.data.error;
      logLoginError({ errorCode: 80, errorMessage: errorType });
      switch (errorType) {
        case 'onyo.magic-link.invalid-email':
          errorMsg = 'E-mail invalido ou inexistente. Por favor, confira e tente de novo.';
          break;
        case 'onyo.magic-link.validation-code-invalid':
          errorMsg = 'Token invalido ou expirado. Por favor, confira e tente de novo.';
          break;
        default:
          logError(error, 'Error in MagicLink Create Call');
          break;
      }
    } else {
      logLoginError({ errorCode: 81 });
      logError(error, 'Error in MagicLink Create Call');
    }
  }

  return { __typename, success, errorMsg };
}
