import {
  Box,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';

interface Props {
  message: string;
  loading: boolean;
  refreshHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey.A400,
    },
    refreshIcon: {
      color: theme.palette.common.white,
    },
    text: {
      padding: theme.spacing(0, 2),
      color: theme.palette.common.white,
    },
    progress: {
      color: theme.palette.common.white,
      margin: theme.spacing(2),
    },
  })
);

export default function RefreshBox(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.root}>
      <Typography variant='body2' className={classes.text}>
        {props.message}
      </Typography>

      {props.loading ? (
        <CircularProgress
          id='refresh-progress'
          color='primary'
          size='16px'
          className={classes.progress}
        />
      ) : (
        <IconButton id='refresh-icon-button' onClick={props.refreshHandler}>
          <RefreshIcon className={classes.refreshIcon} />
        </IconButton>
      )}
    </Box>
  );
}
