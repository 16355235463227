import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { CompanyDemandStatus, PreparationTime } from '../../generated/graphql';
import Icon from '../icon/icon';
import { useStyles } from './company-preparation-time.styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import { UnionIconOptions } from '../icon/helpers/type-icon';

interface Props {
  preparationTime?: PreparationTime | null;
  additionalPreparationTime?: any | null;
  isDelivery?: boolean;
  variant?: Variant;
  iconName?: UnionIconOptions;
}

export default function CompanyPreparationTime({
  variant = 'body2',
  iconName = 'clock',
  preparationTime,
  additionalPreparationTime,
  isDelivery,
}: Props): ReactElement {
  const classes = useStyles();

  if (!preparationTime) {
    return <Box />;
  }

  let min = preparationTime.minTime!;
  let max = preparationTime.maxTime!;

  if (isDelivery && !!additionalPreparationTime) {
    min += additionalPreparationTime!;
    max += additionalPreparationTime!;
  }

  return (
    <Box className={classes.container}>
      <Icon name={iconName} />
      <Box>
        {preparationTime.status === CompanyDemandStatus.Blocked && (
          <Box
            id='blocked'
            display='flex'
            className={classes.root}
            alignContent='center'
            justifyContent='center'
          >
            <NotInterestedIcon className={classes.blockedIcon} fontSize='small' />
            <Typography className={classes.blockedText} variant={variant}>
              Alta Demanda
            </Typography>
          </Box>
        )}
        {preparationTime.status !== CompanyDemandStatus.Blocked && (
          <Typography id='preparation-time' variant={variant} className={classes.preparationText}>
            {min} - {max} min
          </Typography>
        )}
      </Box>
    </Box>
  );
}
