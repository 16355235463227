import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const liveloTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#e50091',
    },
    secondary: {
      main: '#ff671f',
    },
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#e50091',
    },
  },
};
