import { Box, ListItem, ListItemText, Radio } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  Product,
  ProductValidationStatus,
  useFlipChosenProductQuantityMutation,
  useSetScrollToProductMutation,
  useValidateProductMutation,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { getPositiveProductPrice } from '../../helpers/product-details-helper';
import Choosable from '../choosable/choosable';
import { useStyles } from './single-simple.style';

interface Props {
  product: Product;
  parentCacheId: string;
  menuItemCacheId: string;
}

export default function SingleSimple(props: Props) {
  const classes = useStyles();
  const ref = useRef(null);

  const [flipQuantity] = useFlipChosenProductQuantityMutation({
    variables: {
      input: {
        productCacheId: props.product.id,
        parentCacheId: props.parentCacheId,
        menuItemCacheId: props.menuItemCacheId,
      },
    },
    onError: (err) => logError(err, 'Error executing Flip Chosen Product Quantity Mutation'),
  });

  const [validateProduct] = useValidateProductMutation({
    variables: { productCacheId: props.menuItemCacheId },
    onError: (err) => logError(err, 'Error executing Validate Product Mutation'),
  });

  const [setScrollToProduct] = useSetScrollToProductMutation({
    variables: { productId: props.product.id, value: false },
  });

  useEffect(() => {
    if (props.product.scrollToProduct && ref && ref.current) {
      setScrollToProduct();
      (ref.current as any).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ref, props.product.scrollToProduct, setScrollToProduct]);

  const onClickHandler = useCallback(() => {
    flipQuantity();
    if (props.product.isValid !== ProductValidationStatus.Unknown) {
      validateProduct();
    }
  }, [flipQuantity, props.product.isValid, validateProduct]);

  return (
    <ListItem className={classes.root}>
      <div id='ref-div' ref={ref}>
        <Box
          className={classes.wrap}
          display='flex'
          alignItems='center'
          onClick={onClickHandler}
          id={`product-sinle-simple-${props.product.numericalId}`}
        >
          <Box className={classes.textWrap}>
            <ListItemText
              className={classes.text}
              primary={props.product.name}
              primaryTypographyProps={{ variant: 'body2' }}
            />
            <ListItemText
              className={classes.price}
              primary={getPositiveProductPrice(props.product)}
              primaryTypographyProps={{
                variant: 'body2',
                noWrap: true,
                align: 'right',
                className: classes.priceWeight,
              }}
            />
          </Box>

          <Radio
            edge='start'
            checked={props.product.chosenQuantity > 0}
            tabIndex={-1}
            inputProps={{ 'aria-labelledby': props.product.name! }}
            color='primary'
            size='medium'
          />
        </Box>
        {props.product.chosenQuantity > 0 &&
          props.product.subproducts &&
          props.product.subproducts.map((subproduct) => (
            <ListItem
              id={`product-sinle-simple-choosable-${subproduct?.numericalId}`}
              classes={{ gutters: classes.gutters }}
              component='div'
              className={classes.multiLevelChoosable}
              key={subproduct!.id}
            >
              <Choosable
                product={subproduct!}
                menuItemCacheId={props.menuItemCacheId}
                is2ndLevel={true}
              />
            </ListItem>
          ))}
      </div>
    </ListItem>
  );
}
