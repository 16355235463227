import 'firebase/analytics';
import firebase from 'firebase/app';
import { USER_ADDRESS_CACHE } from '../../model/caches';
import { getCachedData } from '../get-cached-data';

export async function loadUserAddressFromCache() {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return [];
  }

  const cache = await caches.open(USER_ADDRESS_CACHE);
  const addressRes = await cache.match('/user-address');
  if (addressRes?.ok) {
    const addressClone = addressRes.clone();
    const data = await getCachedData(addressClone, '/user-address', cache);
    return data;
  }
}
