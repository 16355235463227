import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Box, DialogContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from 'react';
import { useStyles } from './brand-highlights-dialog.style';
import Icon from '../icon/icon';

import { BrandHighlights } from '../../model/brand-highlights';
import { validateViewBanner } from './helpers/validate-view-banner';
import { logViewBanner } from '../../../analytics/helpers/log-view-banner';
import { logSelectBanner } from '../../../analytics/helpers/log-select-banner';

interface Props {
  data: BrandHighlights;
}

export function BrandHighlightsDialog({ data }: Props) {
  const classes = useStyles();

  const { aggregatorId, company, mobileImage, startAt, desktopImage, buttonText, endAt } = data;
  const currentDate = new Date();

  const isOpen = validateViewBanner({ data, currentDate });
  const { push } = useHistory();
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const goToHighlights = useCallback(() => {
    push(`/${aggregatorId}/highlights/${company}`);
    logSelectBanner({ banner_id: data.id });
  }, [aggregatorId, company, data.id, push]);

  useEffect(() => {
    if (isOpen) {
      logViewBanner({ banner_id: data.id });
    }
  }, [data, isOpen]);

  return (
    <Dialog open={open} onClose={handleOnClose} scroll={'paper'}>
      <DialogContent className={classes.root}>
        <Box className={classes.imgWrap}>
          <img
            srcSet={`${mobileImage} 720w, ${desktopImage}`}
            src={desktopImage}
            className={classes.img}
            alt={`highlights for brand ${company}`}
          />
        </Box>

        <IconButton id='close-button' className={classes.close} onClick={handleOnClose}>
          <Icon name='close' size='extraLarge' color='light' />
        </IconButton>

        <Button
          id='accept-button'
          className={classes.button}
          variant='contained'
          color='secondary'
          disableElevation
          onClick={goToHighlights}
          size='large'
        >
          {buttonText}
        </Button>

        <Typography data-testid='text-period' variant='caption' className={classes.superText}>
          {`Promoção válida de ${moment(startAt).format('DD/MM/YYYY')}`} <br />
          {`a ${moment(endAt).format('DD/MM/YYYY')} não cumulativa com outras promoções ou cupom.`}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
