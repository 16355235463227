import { BundleName, ParamName, Item } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsViewItem = {
  brandId: string;
  companyId: string;
  items: Array<Item>;
};

const event = 'view_item';

export function logViewItem({ brandId, companyId, items }: AnalyticsViewItem) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.ITEMS]: items,
    },
  });
}

export function logViewItemError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
