import { useTheme } from '@material-ui/core';
import { UnionColorOptions } from '../model/theme-options';

type PathType = Partial<{
  [key in UnionColorOptions]: string;
}>;

export const useGetColor = () => {
  const theme = useTheme();

  const colorOptions: PathType = {
    default: theme.palette.grey[400],
    primary: theme.palette.primary.main,
    primaryLight: theme.palette.primary.light,
    primaryLightest: theme.palette.primary.lightest,
    primaryDark: theme.palette.primary.dark,
    primaryDarkest: theme.palette.primary.darkest,
    secondary: theme.palette.secondary.main,
    secondaryLight: theme.palette.secondary.light,
    secondaryDark: theme.palette.secondary.dark,
    secondaryDarkest: theme.palette.secondary.darkest,
    secondaryLightest: theme.palette.secondary.lightest,
    success: theme.palette?.success?.main,
    successLight: theme.palette.success.light,
    successDark: theme.palette.success.dark,
    successDarkest: theme.palette.success.darkest,
    successLightest: theme.palette.success.lightest,
    warning: theme.palette?.warning?.main,
    warningLight: theme.palette.warning.light,
    warningDark: theme.palette.warning.dark,
    warningDarkest: theme.palette.warning.darkest,
    warningLightest: theme.palette.warning.lightest,
    error: theme.palette?.error?.main,
    errorLight: theme.palette.error.light,
    errorDark: theme.palette.error.dark,
    errorDarkest: theme.palette.error.darkest,
    errorLightest: theme.palette.error.lightest,
    info: theme.palette?.info?.main,
    infoLight: theme.palette.info.light,
    infoDark: theme.palette.info.dark,
    infoDarkest: theme.palette.info.darkest,
    infoLightest: theme.palette.info.lightest,
    light: theme.palette.common.white,
    dark: theme.palette.common.black,
    transparent: 'transparent',
  };

  function getColor(color?: UnionColorOptions): string {
    return colorOptions[color ?? 'default'] as string;
  }

  return {
    getColor,
  };
};
