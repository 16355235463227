import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsAddress = {
  type: 'manual' | 'auto';
  latitude: number;
  longitude: number;
};

const event = 'add_address_info';

export function logAddAddressInfo({ type, latitude, longitude }: AnalyticsAddress) {
  logAnalytics({
    event,
    [BundleName.PROPERTIES]: {
      [ParamName.TYPE]: type,
      [ParamName.LATITUDE]: latitude,
      [ParamName.LONGITUDE]: longitude,
    },
  });
}

export function logAddAddressInfoError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
