import axios from 'axios';
import { getAppConfig, getSharedConfig } from '../common/helpers/configs';
import { XBRAND } from '../common/model/default-headers';

export function initAxios() {
  const sharedConfig = getSharedConfig();
  const appConfig = getAppConfig();

  axios.defaults.headers.common[XBRAND] = appConfig.brand;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.timeout = Number(sharedConfig.timeout);
  axios.defaults.withCredentials = true;
}
