import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    paymentMethods: {
      padding: theme.spacing(1, 2),
    },
    noCardText: {
      marginBottom: theme.spacing(1),
    },
  })
);
