import { useState, useEffect } from 'react';
import { useGetUserAddressQuery } from '../../common/generated/graphql';
import { DeliveryLocation } from '../../common/model/config';
import { logError } from '../../common/helpers/log-error';

export default function useUserCoords() {
  const { data } = useGetUserAddressQuery({
    onError: (err) => logError(err, 'Error executing Get User Address Query'),
  });

  const [userCoords, setUserCoords] = useState<DeliveryLocation | null>(null);

  useEffect(() => {
    if (!data?.userAddress) {
      return;
    }

    setUserCoords({
      latitude: data.userAddress.latitude,
      longitude: data.userAddress.longitude,
      stateUf: data.userAddress.fields?.state ?? '',
      city: data.userAddress.fields?.city ?? '',
      district: data.userAddress.fields?.district ?? '',
      street: data.userAddress.fields?.street ?? '',
      addressNumber: data.userAddress.fields?.number ?? '',
      zipCode: data.userAddress.fields?.zipCode ?? '',
      complement: data.userAddress.complement ?? '',
    });
  }, [data?.userAddress?.latitude, data?.userAddress?.longitude]); // eslint-disable-line react-hooks/exhaustive-deps

  return userCoords;
}
