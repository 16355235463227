import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { getAppConfig } from '../../common/helpers/configs';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

type AnalyticsSearch = {
  brandId: string;
  companyId: string;
  type: 'item' | 'shop';
  searchTerm: string;
};

const event = 'search';

export function logSearch({ brandId, companyId, type, searchTerm }: AnalyticsSearch) {
  logAnalytics({
    event,
    [BundleName.ECOMMERCE]: {
      [ParamName.AGGREGATOR_ID]: getAppConfig().aggregator,
      [ParamName.BRAND_ID]: brandId,
      [ParamName.COMPANY_ID]: companyId,
      [ParamName.TYPE]: type,
      [ParamName.SEARCH_TERM]: searchTerm,
    },
  });
}

export function logSearchError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
