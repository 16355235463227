import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
    },
    totalContainer: {
      background: theme.palette.grey[100],
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    deliveryTax: {
      color: theme.palette.success.main,
    },
    discountValue: {
      color: theme.palette.success.main,
    },
    trackButton: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
    containerButton: {
      padding: theme.spacing(2, 0),
    },
    totalText: {
      fontWeight: theme.typography.fontWeightMediumBold,
    },
  })
);
