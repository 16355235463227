// From: https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { getAppConfig } from '../../helpers/configs';
import { useIsAuthenticatedQuery } from '../../generated/graphql';
import { logError } from '../../helpers/log-error';
import Progress from '../progress/progress';

const CustomRoute = ({ component: Component, isAuthPage, isPrivate, ...rest }: any) => {
  const lastLocation = useLastLocation();
  const appConfig = getAppConfig();

  const { data, loading } = useIsAuthenticatedQuery({
    onError: (err) => logError(err, 'Error executing is authenticated query'),
  });

  const pageAuth =
    data?.isAuthenticated &&
    isAuthPage &&
    !(
      appConfig.featureFlags.embeddedApplication ||
      appConfig.featureFlags.embededApplicationUnlogged
    );

  if (loading || !data?.authExecuted) {
    return <Progress />;
  }

  if (!data?.isAuthenticated && isPrivate) {
    return <Redirect to={`/${appConfig.aggregator}/login`} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return pageAuth ? (
          <Redirect
            to={{
              pathname: `/`,
              state: { from: lastLocation?.pathname ?? '/' },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default CustomRoute;
