import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    root: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      maxWidth: '20rem',
    },
    textWrap: {
      display: 'flex',
      position: 'relative',
      padding: theme.spacing(3, 0, 2),
      color: theme.palette.grey[600],
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(1, 0, 3),
      marginBottom: theme.spacing(6),
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(3, 0),
    },
    input: {
      margin: theme.spacing(1, 0),
    },
  })
);
