import axios, { AxiosResponse } from 'axios';
import { getEndpoints } from '../../helpers/configs';

/**
 * @function getCardBrand
 * @description take the brand of credit cards with the six first numbers.
 */
export async function getCardBrand(
  bin: number | string
): Promise<AxiosResponse<{ code: string }> | undefined> {
  const { api } = getEndpoints();

  const url = `${api}v1/mobile/card_brand?bin=${bin}`;

  return axios.get(url);
}
