import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  ContentInfo: {
    alignItems: 'center',
  },
  cardText: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    color: theme.palette.grey[700],
  },
  listItemTextDelivery: {
    color: theme.palette.success.dark,
    fontWeight: theme.typography.fontWeightMediumBold,
    marginLeft: theme.spacing(0.5),
  },
  listItemTextDeliveryValue: {
    padding: theme.spacing(0, 1),
    background: theme.palette.warning.superLightest,
    color: theme.palette.warning.darkest,
    borderRadius: '0.25rem',
    marginLeft: theme.spacing(0.25),
  },
}));
