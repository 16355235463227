import { ORDER_TYPE_CACHE } from '../../model/caches';
import 'firebase/analytics';
import firebase from 'firebase/app';

export async function removeOrderTypeFromCache() {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return;
  }

  const cache = await caches.open(ORDER_TYPE_CACHE);
  cache.delete(`/order-type`);
}
