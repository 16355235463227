import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './no-address-aggregator.style';

export default function NoAddressAggregator() {
  const classes = useStyles();

  return (
    <Box id='empty-aggregator' className={classes.root}>
      <Box className={classes.imgContainer}>
        <img className={classes.img} src='/city.png' alt='' />
      </Box>
      <Typography variant='h6' align='center' gutterBottom={true}>
        Diga para a gente onde você está?
      </Typography>
      <Typography variant='body2' align='center' gutterBottom={true}>
        Precisamos saber a sua localização para te mostrar os restaurantes mais próximos.
      </Typography>
    </Box>
  );
}
