import React, { useEffect, useRef } from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import ChooseQuantity from '../../../common/components/choose-quantity/choose-quantity';
import { Product, useSetScrollToProductMutation } from '../../../common/generated/graphql';
import { getPositiveProductPrice } from '../../helpers/product-details-helper';
import { useSimpleQuantity } from '../../hooks/use-simple-quantity';
import Choosable from '../choosable/choosable';
import { useStyles } from './quantity-simple.style';

interface Props {
  product: Product;
  parentCacheId: string;
  menuItemCacheId: string;
  acceptMore: boolean; // If true, meanst that the parent choosable is not filled and more choices can be made
}

export default function QuantitySimple(props: Props) {
  const classes = useStyles();
  const ref = useRef(null);
  const { addOne, reduceOne } = useSimpleQuantity(
    props.product,
    props.parentCacheId,
    props.menuItemCacheId
  );

  const [setScrollToProduct] = useSetScrollToProductMutation({
    variables: { productId: props.product.id, value: false },
  });

  useEffect(() => {
    if (props.product.scrollToProduct && ref && ref.current) {
      setScrollToProduct();
      (ref.current as any).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ref, props.product.scrollToProduct, setScrollToProduct]);

  return (
    <ListItem
      classes={{
        gutters: classes.gutters,
      }}
      className={classes.root}
    >
      <div id='ref-div' ref={ref}>
        <Box className={classes.wrap} id={`product-quantity-${props.product?.numericalId}`}>
          <Box className={classes.wrapText}>
            <Typography className={classes.productName} variant={'body2'}>
              {props.product.name}
              <span className={classes.priceText}>{getPositiveProductPrice(props.product)}</span>
            </Typography>
          </Box>

          <ChooseQuantity
            reduceOne={reduceOne}
            disableReduce={props.product.chosenQuantity === 0}
            addOne={addOne}
            disableAdd={!props.acceptMore}
            value={props.product.chosenQuantity}
          />
        </Box>

        {props.product.chosenQuantity > 0 &&
          props.product.subproducts &&
          props.product.subproducts.map((subproduct) => (
            <ListItem
              component='div'
              className={classes.multiLevelChoosable}
              key={subproduct!.id}
              id={`product-quantity-choosable-${subproduct?.numericalId}`}
            >
              <Choosable
                product={subproduct!}
                menuItemCacheId={props.menuItemCacheId}
                is2ndLevel={true}
              />
            </ListItem>
          ))}
      </div>
    </ListItem>
  );
}
