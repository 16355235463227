import { ProductCompanyEdge } from '../../common/generated/graphql';
import { normalizeTextCompare } from '../../common/helpers/normalize-text-compare';

interface Props {
  products: ProductCompanyEdge[];
  query: string;
  minSearch: number;
}

export function applyFilters({ products, query, minSearch }: Props) {
  if (query.length < minSearch) {
    return products;
  }

  return products?.filter((product: any) => {
    let matches = true;

    if (query) {
      const properties = ['name', 'shortDescription'];
      let containsQuery = false;

      properties.forEach((property) => {
        let productProp = normalizeTextCompare(product?.node?.product[property] ?? '');

        if (productProp.includes(normalizeTextCompare(query.toLowerCase()))) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
}
