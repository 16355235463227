import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getAppConfig } from '../../../common/helpers/configs';
import useMiniAppGeolocation from '../../hooks/use-mini-app-geolocation/use-mini-app-geolocation';
import useGeolocation from '../../hooks/use-geolocation/use-geolocation';
import { GeolocationData } from '../../model/geolocation';

interface Props {
  onLocationSuccess: (data: GeolocationData) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    progressSpinner: {
      color: theme.palette.additionalColors?.promoHighligts,
    },
    locationIcon: {
      color: theme.palette.additionalColors?.promoHighligts,
    },
    text: {
      paddingLeft: theme.spacing(2),
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
  })
);

export default function CurrentLocation(props: Props) {
  const classes = useStyles();
  const config = getAppConfig();
  const {
    geolocation,
    error: geolocationError,
    loading: geolocationLoading,
  } = useGeolocation(props.onLocationSuccess);
  const {
    geolocation: miniAppGeolocation,
    error: miniAppGeolocationError,
    loading: miniAppGeolocationLoading,
  } = useMiniAppGeolocation(props.onLocationSuccess);

  const onGeolocationClick = useCallback(() => {
    if (!config.featureFlags.isCieloMiniApp) {
      geolocation();
    } else {
      miniAppGeolocation();
    }
  }, [config.featureFlags.isCieloMiniApp, geolocation, miniAppGeolocation]);

  return (
    <Box id='current-location' className={classes.root} onClick={onGeolocationClick}>
      <Box hidden={!geolocationLoading && !miniAppGeolocationLoading}>
        <CircularProgress size='1.2rem' className={classes.progressSpinner} />
      </Box>
      <Box hidden={geolocationLoading || miniAppGeolocationLoading}>
        <FontAwesomeIcon
          icon={faMapMarkerAlt as any}
          size='lg'
          className={classes.locationIcon}
          id='spinner'
        />
      </Box>
      <Box className={classes.text}>
        <Typography>Usar minha localização atual</Typography>
        <Typography id='error-msg' variant='caption' className={classes.errorMessage}>
          {geolocationError || miniAppGeolocationError}
        </Typography>
      </Box>
    </Box>
  );
}
