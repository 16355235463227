import {
  GetAllShoppingCartsQuery,
  ShoppingCartFragment,
  GetAllShoppingCartsDocument,
} from '../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import clone from 'lodash/clone';

export function newShoppingCart(companyId: number): ShoppingCartFragment {
  return {
    id: btoa(`ShoppingCart:${companyId}`),
    __typename: 'ShoppingCart',
    orderTotal: 0,
    totalExtras: 0,
    totalProductsPrice: 0,
    products: [],
  };
}

export function addNewCartToApolloCache(shoppingCart: ShoppingCartFragment, cache: InMemoryCache) {
  const allCarts = cache.readQuery<GetAllShoppingCartsQuery>({
    query: GetAllShoppingCartsDocument,
  });
  const updatedShoppingCarts = clone(allCarts!.shoppingCarts);
  updatedShoppingCarts.push(shoppingCart);

  cache.writeQuery<GetAllShoppingCartsQuery>({
    query: GetAllShoppingCartsDocument,
    data: { shoppingCarts: updatedShoppingCarts },
  });
}
