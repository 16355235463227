import React, { useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { CompanyGroupEdge, Maybe } from '../../../common/generated/graphql';
import AggregatorFooter from '../aggregator-footer/aggregator-footer';
import CardContainer from '../card-container/card-container';
import useSizeBreakpoints from '../../../common/hooks/use-size-breakpoints';
import useGetHiddenCompanies from '../../hooks/use-get-hidden-companies';
import AggregatorsGroupItem from '../aggregators-group-item/aggregators-group-item';
import AggregatorContainerHeader from '../aggregator-container-header/aggregator-container-header';
import EventConfirmationDialog from '../event-confirmation-dialog/event-confirmation-dialog';
import { EventConfirmationModalProvider } from '../../contexts/eventConfirmationModal';

interface Props
  extends RouteComponentProps<{
    aggregatorId: string;
  }> {
  companiesGroup: Maybe<CompanyGroupEdge>;
}

export default withRouter(AggregatorsGroupsCompaniesContainerBox);
function AggregatorsGroupsCompaniesContainerBox(props: Props) {
  const theme = useTheme();
  const nameAggregator = props.companiesGroup?.node?.name as string;
  const imageUrlAggregator = props.companiesGroup?.node?.images?.[0]?.url as string;
  const companiesCard = props.companiesGroup?.node?.companies?.edges ?? [];
  const limitShowCompanies = useSizeBreakpoints({ theme });
  const isEvent = props.companiesGroup?.node?.isEvent || false;
  const limitShowAvatar = useSizeBreakpoints({
    theme,
    small: 4,
    medium: 5,
    large: 8,
  });
  const hiddenComp = useGetHiddenCompanies({ companiesCard, limitShowCompanies, limitShowAvatar });

  const handleDetails = useCallback(() => {
    props.history.push(`aggregator/${props.companiesGroup?.node?.numericalId}/details`, {
      companiesGroup: props.companiesGroup?.node,
    });
  }, [props.companiesGroup, props.history]);

  return (
    <EventConfirmationModalProvider>
      <EventConfirmationDialog companyLogo={imageUrlAggregator} companyName={nameAggregator}>
        Se você está em um evento no <strong>{nameAggregator}</strong> e deseja fazer um pedido
        confirme abaixo.
      </EventConfirmationDialog>
      <CardContainer>
        <AggregatorContainerHeader
          title={nameAggregator}
          icon='ArrowForward'
          onClickIcon={handleDetails}
          showAction={companiesCard.length > limitShowCompanies}
          address={props.companiesGroup?.node?.address!}
        />

        <AggregatorsGroupItem
          companiesCard={companiesCard.slice(0, limitShowCompanies)}
          isEvent={isEvent}
        />

        <AggregatorFooter
          companiesCard={companiesCard}
          showDetail={handleDetails}
          nameAggregator={nameAggregator}
          visibleNames={hiddenComp.hiddenNames}
          companiesHiddenAvatar={hiddenComp.hiddenCompanies}
          limitShowAvatar={limitShowAvatar}
          limitShowCompanies={limitShowCompanies}
        />
      </CardContainer>
    </EventConfirmationModalProvider>
  );
}
