import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'block',
    },
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    wrapText: {
      display: 'flex',
      paddingRight: theme.spacing(1),
    },
    priceText: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      paddingLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    productName: {
      flexGrow: 1,
      display: 'inline-flex',
      alignItems: 'center',
    },
    price: {
      textAlign: 'right',
    },
    multiLevelChoosable: {
      marginTop: theme.spacing(2),
      padding: 0,
    },
  })
);
