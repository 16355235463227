import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useClearDeprecatedCacheMutation,
  useLoadSelectedOrderTypeFromBrowserCacheMutation,
  useLoadShoppingCartsFromBrowserCacheMutation,
  useLoadUserAddressFromBrowserCacheMutation,
} from '../../../../generated/graphql';
import { getAppConfig } from '../../../../helpers/configs';
import { logError } from '../../../../helpers/log-error';

export default function useLoadFromBrowserCache() {
  const location = useLocation();

  const [loadCartsFromCache] = useLoadShoppingCartsFromBrowserCacheMutation({
    onError: (err) => logError(err, 'Error on Load Shopping Carts from Browser Mutation'),
  });
  const [loadOrderTypeFromCache] = useLoadSelectedOrderTypeFromBrowserCacheMutation({
    onError: (err) => logError(err, 'Error on Load Selected Order Type from Browser Mutation'),
  });
  const [loadUserAddressFromCache] = useLoadUserAddressFromBrowserCacheMutation({
    onError: (err) => logError(err, 'Error on User Address from Browser Mutation'),
  });
  const [clearDeprecatedBrowserCache] = useClearDeprecatedCacheMutation({
    onError: (err) => logError(err, 'Error on Clear Deprecated Cache Mutation'),
  });

  const loadFromBrowserCache = useCallback(async () => {
    await loadOrderTypeFromCache();
    await loadCartsFromCache();

    const appConfig = getAppConfig();
    if (!appConfig.featureFlags.gmapsAddress || appConfig.featureFlags.isCieloMiniApp) {
      return;
    }

    const parsed = queryString.parse(location.search);
    const latitude = Number(parsed.lat);
    const longitude = Number(parsed.long);
    if (!isNaN(latitude) && !isNaN(longitude)) {
      await loadUserAddressFromCache({ variables: { latitude, longitude } });
    } else {
      await loadUserAddressFromCache();
    }
    clearDeprecatedBrowserCache();
  }, [
    clearDeprecatedBrowserCache,
    loadCartsFromCache,
    loadOrderTypeFromCache,
    loadUserAddressFromCache,
    location.search,
  ]);

  return loadFromBrowserCache;
}
