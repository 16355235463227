import React from 'react';
import { UnionSizeOptions } from '../../model/theme-options';
import SvgWrapper from '../svg-wrapper/svg-wrapper';
import { SvgProps } from '../../model/svg-props';

interface Props extends SvgProps {
  size: UnionSizeOptions;
  className?: string;
}

export function IconAlelo(props: Props) {
  return (
    <SvgWrapper {...props}>
      <>
        <path
          fill='#007858'
          d='M26.867 6.859c-2.602-0.001-5.145 0.775-7.305 2.228-0.149 0.102-1.176 0.836-1.221 0.872-1.707 1.284-2.853 1.507-4.422 1.113-0.096-0.024-0.546-0.179-0.687-0.234-1.466-0.552-3.042-0.744-4.598-0.56s-3.044 0.739-4.34 1.619c-1.296 0.88-2.363 2.058-3.11 3.436s-1.153 2.915-1.183 4.482c-0.030 1.567 0.316 3.119 1.009 4.525s1.714 2.624 2.975 3.553c1.262 0.929 2.727 1.541 4.274 1.786s3.13 0.113 4.616-0.383c2.341-0.701 3.611-0.509 5.080 0.529 0.294 0.207 1.001 0.661 1.148 0.768 1.673 1.228 3.612 2.043 5.66 2.376s4.145 0.176 6.12-0.459 3.772-1.729 5.243-3.193c1.471-1.464 2.575-3.257 3.22-5.23s0.814-4.072 0.493-6.124c-0.321-2.051-1.124-3.998-2.342-5.679s-2.816-3.049-4.665-3.993c-1.848-0.943-3.894-1.435-5.968-1.434z'
        ></path>
        <path
          fill='#fff'
          d='M22.132 22.032c-0.374 0.368-0.861 0.599-1.382 0.654s-1.046-0.068-1.489-0.349l-0.871 1.384c0.755 0.478 1.648 0.686 2.536 0.592s1.718-0.486 2.356-1.111l-1.15-1.17zM20.562 16.393c-0.719-0.011-1.429 0.173-2.051 0.534s-1.136 0.885-1.483 1.515-0.518 1.344-0.492 2.064c0.026 0.72 0.247 1.419 0.64 2.023l7.184-3.074c-0.202-0.861-0.685-1.629-1.373-2.184s-1.541-0.864-2.424-0.879zM18.182 20.608c-0.054-0.497 0.053-0.999 0.305-1.431s0.637-0.771 1.096-0.968c0.46-0.197 0.97-0.24 1.457-0.124s0.922 0.385 1.244 0.768l-4.102 1.755zM26.616 14.605v7.664l1.328 0.552-0.627 1.507-1.317-0.546c-0.265-0.114-0.491-0.304-0.647-0.546-0.175-0.282-0.263-0.61-0.254-0.942v-7.688h1.517zM15.047 14.605v7.664l1.317 0.552-0.627 1.507-1.317-0.546c-0.256-0.114-0.474-0.301-0.627-0.537-0.176-0.282-0.265-0.609-0.256-0.942v-7.698h1.509z'
        ></path>
        <path
          fill='#c7d540'
          d='M33.151 18.254c0.276 0.133 0.523 0.32 0.727 0.549s0.361 0.496 0.462 0.785c0.101 0.29 0.144 0.596 0.126 0.903s-0.095 0.606-0.228 0.882l1.47 0.706c0.398-0.83 0.495-1.773 0.274-2.667s-0.744-1.684-1.482-2.234c-0.738-0.55-1.644-0.826-2.563-0.781s-1.794 0.409-2.474 1.028l1.099 1.207c0.344-0.315 0.775-0.52 1.236-0.587s0.933 0.005 1.353 0.209z'
        ></path>
        <path
          fill='#fff'
          d='M31.129 22.47c-0.558-0.269-0.986-0.748-1.191-1.333s-0.17-1.227 0.098-1.786l-1.469-0.708c-0.394 0.829-0.488 1.77-0.267 2.661s0.744 1.678 1.479 2.227 1.638 0.825 2.555 0.783c0.916-0.043 1.79-0.402 2.471-1.017l-1.099-1.205c-0.343 0.312-0.772 0.515-1.23 0.582s-0.927-0.003-1.346-0.203zM12.036 22.581v-2.219c0-0.782-0.232-1.547-0.666-2.197s-1.051-1.157-1.773-1.457c-0.722-0.299-1.517-0.378-2.283-0.225s-1.471 0.529-2.023 1.082c-0.553 0.553-0.929 1.258-1.081 2.025s-0.074 1.563 0.225 2.285 0.806 1.34 1.455 1.775c0.65 0.435 1.414 0.667 2.195 0.667 0.613 0 1.217-0.142 1.765-0.416l-0.636-1.488c-0.356 0.197-0.757 0.298-1.164 0.292s-0.805-0.118-1.155-0.325c-0.35-0.207-0.641-0.502-0.842-0.856s-0.307-0.754-0.306-1.162c-0.002-0.383 0.090-0.76 0.269-1.098 0.157-0.295 0.376-0.553 0.641-0.757s0.571-0.348 0.896-0.424c0.173-0.041 0.349-0.062 0.527-0.062 0.621-0.001 1.217 0.246 1.656 0.685s0.687 1.035 0.688 1.656v2.2c-0.009 0.332 0.079 0.659 0.254 0.942 0.158 0.242 0.383 0.431 0.647 0.546l0.741 0.294 0.628-1.507-0.659-0.256z'
        ></path>
      </>
    </SvgWrapper>
  );
}
