import { AppConfig } from '../../../common/model/config';
import { pizzaHutTheme } from '../themes/pizza-hut-theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const pizzaHutConfig: AppConfig = {
  appName: 'pizza-hut',
  appTitle: 'Pizza Hut',
  appDescription: 'Peça aqui na Pizza Hut.',
  aggregator: 1074,
  brand: 11,
  theme: pizzaHutTheme,
  faviconPath: '/favicons/favicon-pizza-hut.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
