import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'reset_password';

export function logResetPassword() {
  logAnalytics({
    event,
  });
}

export function logResetPasswordError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
