import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    discount: {
      color: theme.palette.success.main,
    },
  })
);
