import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const defaultTheme: ThemeOptions = {
  template: {
    header: {
      size: {
        main: {
          mobile: '5rem',
          tablet: '6.25rem',
          desktop: '5.25rem',
        },
        home: {
          mobile: '4.5rem',
          tablet: '4.5rem',
          desktop: '4.5rem',
        },
        nav: {
          mobile: '3rem',
          tablet: '3rem',
          desktop: '3rem',
        },
      },
    },
  },
  palette: {
    success: {
      darkest: '#108150',
      dark: '#22AA74',
      main: '#3ACC97',
      light: '#60DEAF',
      lightest: '#8AECC7',
      superLightest: '#E3FCF3',
    },
    info: {
      darkest: '#123C88',
      dark: '#2552B2',
      main: '#3F6AD6',
      light: '#648AE6',
      lightest: '#8CABF2',
      superLightest: '#CAD9FC',
    },
    error: {
      darkest: '#A02B19',
      dark: '#D94C31',
      main: '#F96646',
      light: '#FF8268',
      lightest: '#FFA28E',
      superLightest: '#FCD3CA',
    },
    warning: {
      darkest: '#8D730B',
      dark: '#C6A21E',
      main: '#F4CD39',
      light: '#FEDC5E',
      lightest: '#FFE887',
      superLightest: '#FCF3CA',
    },
    additionalColors: {
      pedePronto: {
        primary: '#3d0180',
        secondary: '#ff4a86',
      },
      promoHighligts: '#f65a02',
      thirdParty: {
        facebook: {
          main: '#3b5998',
          light: '#809bff',
          dark: '#283B66',
          contrastText: '#ffffff',
        },
      },
      highligts: {
        text: {
          primary: '#123C88',
          secondary: '#903055',
          ternary: '#A02B19',
        },
        background: {
          primary: '#CAD9FC',
          secondary: '#FCE3ED',
          ternary: '#FCD3CA',
        },
      },
      aggregators: {
        background: '#E5E5E5',
      },
      container: {
        background: '#F8F9FA',
        border: '#E5E5E5',
      },
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeightMediumBold: 600,
    h1: {
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: '4.5rem',
      letterSpacing: '-0.007em',
    },
    color: {
      superDarkest: '#261C3E',
      darkest: '#372C4D',
      dark: '#4B415C',
      main: '#61596C',
      light: '#8E8797',
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.5rem',
      lineHeight: '4rem',
      letterSpacing: '-0.0025em',
    },
    h3: {
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: '3rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '2rem',
      letterSpacing: '-0.05em',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.625rem',
      lineHeight: '1rem',
      textTransform: 'none',
    },
  },
};
