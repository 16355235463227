import { Box, Container } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';

import {
  useOrderCheckRequestMutation,
  useGetBrandBalanceQuery,
  useGetShoppingCartQuery,
  useGetSelectedOrderTypeQuery,
  useGetUserAddressQuery,
  useGetSelectedPaymentMethodFromCacheQuery,
  useGetCustomerDataQuery,
  useGetOrderTotalQuery,
  useGetCompanyOrderTypesQuery,
} from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import CartPayment from '../cart-payment/cart-payment';
import CartProductList from '../cart-product-list/cart-product-list';
import OrderCheckWarning from '../order-check-warning/order-check-warning';
import OrderTotal from '../order-total/order-total';
import OrderTypes from '../order-types/order-types';
import PreparationTime from '../preparation-time/preparation-time';
import SendOrderButton from '../send-order-button/send-order-button';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { CustomerInfoForm } from '../customer-info-form/customer-info-form';
import { useStyles } from './cart.style';
import { useParams } from 'react-router-dom';
import { useFormCustomer } from '../../hooks/use-form-customer';
import { customerOptions } from '../../models/customer-form';
import { logAddShippingInfoError } from '../../../analytics/helpers/log-add-shipping-info';
import { logAddPaymentInfoError } from '../../../analytics/helpers/log-add-payment-info';
import { useLogCartAnalytics } from './hooks/use-log-cart-analytics';
import { logBeginCheckoutError } from '../../../analytics/helpers/log-begin-checkout';
import { ApolloError } from '@apollo/client';

export function Cart() {
  const headerSpace = useMainHeaderSpace();
  const classes = useStyles();
  let params = useParams<{ brandId: string; companyId: string }>();

  const brandId = Number(params.brandId);
  const companyId = Number(params.companyId);

  const { data: totalQuery } = useGetOrderTotalQuery({
    variables: { companyId: Number(companyId) },
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      logAddPaymentInfoError({ errorMessage: err.message, errorCode: 9 });
    },
  });

  const [orderCheck, { loading: orderCheckLoading }] = useOrderCheckRequestMutation({
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      logError(err, 'Error executing Order Check Request Mutation');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 10 });
    },
  });

  const { loading: brandBalanceLoading } = useGetBrandBalanceQuery({
    variables: { brandId },
    fetchPolicy: 'cache-and-network', // Make sure we refetch this data everytime this component is shown.
    onError: (err) => {
      logError(err, 'Error executing Get Brand Balance Lazy Query');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 11 });
    },
  });

  const { data: shoppingCartQuery } = useGetShoppingCartQuery({
    variables: { companyId },
    onError: (err) => {
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 12 });
      throw new ApolloError(err);
    },
  });

  const { data: orderTypeQuery } = useGetSelectedOrderTypeQuery({
    onError: (err) => {
      logAddShippingInfoError({ errorMessage: err.message, errorCode: 13 });
      throw new ApolloError(err);
    },
  });

  const { data: userAddress } = useGetUserAddressQuery({
    onError: (err) => {
      logAddShippingInfoError({ errorMessage: err.message, errorCode: 14 });
      throw new ApolloError(err);
    },
  });

  const { data: selectedPaymentMethodQuery } = useGetSelectedPaymentMethodFromCacheQuery({
    onError: (err) => {
      logAddPaymentInfoError({ errorMessage: err.message, errorCode: 15 });
      throw new ApolloError(err);
    },
  });

  const { data: dataCustomer } = useGetCustomerDataQuery({
    onError: (err) => {
      logError(err, 'Error executing Get User Data Query');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 16 });
    },
  });

  const companyOrderTypes = useGetCompanyOrderTypesQuery({
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      logError(err, 'Error executing Get Company Order Types Query');
      logAddShippingInfoError({ errorCode: 23, errorMessage: err.message });
    },
  });

  const { formik, handleOnChange } = useFormCustomer(
    dataCustomer?.customerData?.cpf as customerOptions,
    dataCustomer?.customerData?.telephone as customerOptions
  );

  useLogCartAnalytics({
    brandId,
    companyId,
    totalQuery,
    orderTypeQuery,
    paymentQuery: selectedPaymentMethodQuery,
  });

  const getOrderTypes = useCallback(() => {
    const orderTypes = (companyOrderTypes.data?.company?.parameter?.orderTypes?.map(
      (item) => item?.title
    ) || []) as string[];

    return orderTypes;
  }, [companyOrderTypes.data]);

  // This effect is executed whenever the shopping cart or the order type is updated.
  useEffect(() => {
    const orderTypes = getOrderTypes();
    orderCheck({ variables: { companyId, orderTypes } });
  }, [
    companyId,
    orderCheck,
    userAddress,
    shoppingCartQuery?.shoppingCart?.id, // eslint-disable-line react-hooks/exhaustive-deps
    shoppingCartQuery?.shoppingCart?.products, // eslint-disable-line react-hooks/exhaustive-deps
    orderTypeQuery, // eslint-disable-line react-hooks/exhaustive-deps
    selectedPaymentMethodQuery,
    getOrderTypes,
  ]);

  // Manually trigger the order check
  const onOrderCheck = useCallback(() => {
    const orderTypes = getOrderTypes();

    orderCheck({ variables: { companyId, orderTypes } });
  }, [getOrderTypes, orderCheck, companyId]);

  return (
    <Box display='flex' flexDirection='column'>
      <MainHeader />
      <Container className={`${classes.root} ${headerSpace.measures}`}>
        <OrderCheckWarning orderCheck={onOrderCheck} loading={orderCheckLoading} />
        <CartProductList />
        <PreparationTime />
        <OrderTypes companyOrderTypes={companyOrderTypes} />
        <OrderTotal />
        <CartPayment />
        <CustomerInfoForm formik={formik} handleOnChange={handleOnChange} />
        <SendOrderButton
          disableButton={brandBalanceLoading || orderCheckLoading}
          customerHandleValidate={formik.validateForm}
          customerHandleOnSubmit={formik.handleSubmit}
        />
      </Container>
    </Box>
  );
}
