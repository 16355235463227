import { Box, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useGetOrderCheckPreparationTimeQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preparationTime: {
      padding: theme.spacing(1, 2),
    },
  })
);

export default function PreparationTime(): ReactElement {
  const classes = useStyles();

  const { data, loading, error } = useGetOrderCheckPreparationTimeQuery({
    onError: (err) => logError(err, 'Error executing Get Order Check Preparation Time'),
  });

  if (!data || !data.orderCheck || loading || error) {
    return <Box />;
  } else if (!data.orderCheck.minimumPreparationTime || !data.orderCheck.maximumPreparationTime) {
    return <Box />;
  }

  return (
    <Box>
      <Typography variant='h6'>Tempo Estimado</Typography>
      <Divider />
      <Box display='flex' className={classes.preparationTime}>
        <Typography variant='body1' id='prep-time'>
          Seu pedido ficará pronto entre{' '}
          <strong>
            {data.orderCheck.minimumPreparationTime} e {data.orderCheck.maximumPreparationTime}{' '}
            minutos
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
