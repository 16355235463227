import { MiniAppLoginMutationVariables, Result } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios from 'axios';
import { getEndpoints } from '../../helpers/configs';
import { logError } from '../../helpers/log-error';
import { getOnlyDigits } from '../../helpers/get-only-digits';

export async function miniAppLogin(
  root: any,
  variables: MiniAppLoginMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  let success = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';

  const uri = `v1/miniapp/login`;
  try {
    const endpoints = getEndpoints();
    await axios.post(
      `${endpoints.api}${uri}`,
      {
        data: {
          name: variables.input.name,
          document: getOnlyDigits(variables.input.document),
          email: variables.input.email,
          phone: getOnlyDigits(variables.input.phone),
        },
      },
      {
        headers: {
          authorization: `Basic ${process.env.REACT_APP_MINI_APP_AUTH}`,
        },
      }
    );

    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    logError(error, 'Error in Login Call');
  }

  return { __typename, success, errorMsg };
}
