import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      zIndex: 1,
      padding: theme.spacing(0, 1),
      boxSizing: 'border-box',

      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    active: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: theme.palette.common.white,
      left: 0,
      top: 0,
    },
    search: {
      flexGrow: 1,
    },
    searchIcon: {
      color: theme.palette.grey[500],
    },
    searchFormControl: {
      height: '100%',
      '& input, & > div': {
        height: '100%',
      },
    },
  })
);
