import { ORDER_TYPE_EVENT_ID } from './../../common/model/order-types';
import {
  STATUS_CANCELED_ID,
  STATUS_BACKEND_RECEIVED_ID,
  STATUS_PAYMENT_AUTHORIZED_ID,
  STATUS_POS_RECEIVED_ID,
  STATUS_POS_ACCEPTED_ID,
  STATUS_POS_DENIED_ID,
  STATUS_READY_ID,
  STATUS_READY_DELIVERY_ID,
  STATUS_READY_EVENT_ID,
  STATUS_DISPATCHED_ID,
  STATUS_DELIVERED_ID,
  STATUS_CONSUMED_ID,
  STATUS_RECEIVED_ID,
  STATUS_POS_IMPORTED_ID,
  STATUS_POS_IMPORT_ERROR_ID,
  STATUS_PREPARING_ID,
  STATUS_POS_ANALYSING_ID,
  STATUS_PRODUCT_UNAVAILABLE_ID,
  STATUS_CUSTOMER_ACTION_NEEDED_ID,
} from '../../common/model/order-status';
import {
  ORDER_TYPE_DELIVERY_ID,
  ORDER_TYPE_ROOM_SERVICE_ID,
  ORDER_TYPE_TABLE_ID,
} from '../../common/model/order-types';
import { StatusOrder } from './types-order';

type Status = {
  title: string;
  value: string;
  type: 'pending' | 'warning' | 'success' | 'error';
};

const statusMap: Record<StatusOrder, Status> = {
  [STATUS_CANCELED_ID]: {
    title: 'Pedido cancelado',
    value:
      'se você já tiver realizado o pagamento pelo App, não se preocupe, o reembolso será feito automaticamente',
    type: 'error',
  },
  [STATUS_BACKEND_RECEIVED_ID]: {
    title: 'Pedido em confirmação',
    value: 'Aguardando a confirmação do Estabelecimento',
    type: 'pending',
  },
  [STATUS_PAYMENT_AUTHORIZED_ID]: {
    title: 'Pedido em confirmação',
    value: 'Aguardando a confirmação do Estabelecimento',
    type: 'pending',
  },
  [STATUS_POS_RECEIVED_ID]: {
    title: 'Pedido em confirmação',
    value: 'Aguardando a confirmação do Estabelecimento',
    type: 'pending',
  },
  [STATUS_POS_ACCEPTED_ID]: {
    title: 'Pedido em andamento',
    value: 'Seu pedido já esta sendo preparado',
    type: 'success',
  },
  [STATUS_POS_DENIED_ID]: {
    title: 'Pedido cancelado',
    value:
      'se você já tiver realizado o pagamento pelo App, não se preocupe, o reembolso será feito automaticamente',
    type: 'error',
  },
  [STATUS_READY_ID]: {
    title: 'Pedido concluído',
    value: 'Basta retirar direto no local!',
    type: 'success',
  },
  [STATUS_READY_EVENT_ID]: {
    title: 'Pedido pronto',
    value:
      'Apresente este comprovante e retire seu pedido no dia do evento. Não realizamos entregas posteriores a data de compra.',
    type: 'success',
  },
  [STATUS_READY_DELIVERY_ID]: {
    title: 'Pedido concluído',
    value: 'Mas fique tranquilo (a), a gente leva até você!',
    type: 'success',
  },
  [STATUS_DISPATCHED_ID]: {
    title: 'O seu pedido está a caminho',
    value: '',
    type: 'success',
  },
  [STATUS_DELIVERED_ID]: {
    title: 'Pedido entregue',
    value: 'Agradecemos pela preferência, ótima refeição!',
    type: 'success',
  },
  [STATUS_CONSUMED_ID]: {
    title: 'Pedido entregue',
    value: 'Agradecemos pela preferência, ótima refeição!',
    type: 'success',
  },
  [STATUS_RECEIVED_ID]: {
    title: 'Pedido entregue',
    value: 'Agradecemos pela preferência, ótima refeição!',
    type: 'success',
  },
  [STATUS_POS_IMPORTED_ID]: {
    title: 'Pedido entregue',
    value: 'Agradecemos pela preferência, ótima refeição!',
    type: 'success',
  },
  [STATUS_POS_IMPORT_ERROR_ID]: {
    title: 'Pedido cancelado',
    value:
      'se você já tiver realizado o pagamento pelo App, não se preocupe, o reembolso será feito automaticamente',
    type: 'error',
  },
  [STATUS_PREPARING_ID]: {
    title: 'Pedido em andamento',
    value: 'Seu pedido já esta sendo preparado',
    type: 'success',
  },
  [STATUS_POS_ANALYSING_ID]: {
    title: 'Pedido em andamento',
    value: 'Seu pedido já esta sendo preparado',
    type: 'success',
  },
  [STATUS_PRODUCT_UNAVAILABLE_ID]: {
    title: 'Pedido cancelado',
    value:
      'se você já tiver realizado o pagamento pelo App, não se preocupe, o reembolso será feito automaticamente',
    type: 'error',
  },
  [STATUS_CUSTOMER_ACTION_NEEDED_ID]: {
    title: 'Imprevisto no pedido',
    value: 'Por favor, dirija-se ao Estabelecimento',
    type: 'warning',
  },
};

export function getStatus(status: number = 16, orderType?: number) {
  if (
    status === STATUS_READY_ID &&
    (orderType === ORDER_TYPE_ROOM_SERVICE_ID ||
      orderType === ORDER_TYPE_TABLE_ID ||
      orderType === ORDER_TYPE_DELIVERY_ID)
  ) {
    status = STATUS_READY_DELIVERY_ID;
  } else if (status === STATUS_READY_ID && orderType === ORDER_TYPE_EVENT_ID) {
    status = STATUS_READY_EVENT_ID;
  }
  return statusMap[status as StatusOrder];
}
