import 'firebase/analytics';
import firebase from 'firebase/app';
import { firebaseOnyoProduction, firebaseOnyoStaging } from './firebase/firebase-apps-configs';
import { getEndpoints } from '../common/helpers/configs';

export function initFirebase() {
  const authDomain = getEndpoints().authDomain;

  if (process.env.NODE_ENV === 'production') {
    firebase.initializeApp({
      ...firebaseOnyoProduction,
      authDomain,
    });
  } else {
    firebase.initializeApp({
      ...firebaseOnyoStaging,
      authDomain,
    });
  }
}
