import React, { useCallback } from 'react';
import { Typography, Dialog, Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './remove-payment-dialog.style';
import DefaultActions from '../../../../common/components/default-actions/default-actions';
import {
  useRemoveLocalCardMutation,
  useRemoveRemoteCardMutation,
  GetCardsDocument,
} from '../../../../common/generated/graphql';
import { logError } from '../../../../common/helpers/log-error';
import { useParams } from 'react-router-dom';
import Progress from '../../../../common/components/progress/progress';
import Icon from '../../../../common/components/icon/icon';
import { useMainHeaderSpace } from '../../../../common/hooks/use-header-space';

interface Props {
  open: boolean;
  onClose: () => void;
  cardToRemove?: number;
}

export default function RemovePaymentDialog(props: Props) {
  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();
  const { companyId } = useParams<{ companyId: string }>();
  const [loading, setLoading] = React.useState(false);

  const [removeLocalCard] = useRemoveLocalCardMutation({
    onError: (err) => logError(err, 'Error executing Remove Local Card Mutation'),
  });

  const [removeRemoteCard] = useRemoveRemoteCardMutation({
    onError: (err) => logError(err, 'Error executing Remove Local Card Mutation'),
    refetchQueries: props.cardToRemove
      ? [
          {
            query: GetCardsDocument,
            variables: { companyId: Number(companyId) },
          },
        ]
      : [],
  });

  const handleRemoveCard = useCallback(async () => {
    setLoading(true);

    if (props.cardToRemove) {
      await removeRemoteCard({
        variables: { cardId: props.cardToRemove },
      });
    }

    await removeLocalCard();
    setLoading(false);
    props.onClose();
  }, [props, removeLocalCard, removeRemoteCard]);

  return (
    <Dialog
      id='dialog-remove-card'
      onClose={props.onClose}
      aria-labelledby='modal-excluir-pagamento'
      open={props.open}
      classes={{
        container: classes.container,
        paper: `${classes.paper} ${headerSpace.smallDialog}`,
      }}
    >
      <Box className={classes.close}>
        <IconButton
          onClick={props.onClose}
          className={classes.btnIcon}
          aria-label='fechar-modal-excluir-pagamento'
          id='remove-dialog-close-button'
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.header}>
        <Box className={classes.card}>
          <Icon name='removeCreditCard' size='extraLarge' />
        </Box>
        <Typography variant='h6' className={classes.title}>
          Excluir cartão
        </Typography>
        <Typography variant='body2' className={classes.message}>
          Para realizar a exclusão do seu cartão, confirme clicando no botão abaixo.
        </Typography>
      </Box>
      <Box className={classes.events}>
        {loading ? (
          <Progress />
        ) : (
          <DefaultActions
            primary={{ label: 'Confirmar exclusão', event: handleRemoveCard }}
            secondary={{ label: 'Cancelar', event: props.onClose }}
          />
        )}
      </Box>
    </Dialog>
  );
}
