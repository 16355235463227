import {
  Box,
  createStyles,
  LinearProgress,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { green, lightGreen, yellow } from '@material-ui/core/colors';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Order, useGetCompanyImagesQuery } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { parseImage } from '../../../common/helpers/parse-image';
import { useInterval } from '../../../common/hooks/use-interval';
import {
  STATUS_BACKEND_RECEIVED_ID,
  STATUS_CANCELED_ID,
  STATUS_CONSUMED_ID,
  STATUS_CUSTOMER_ACTION_NEEDED_ID,
  STATUS_DELIVERED_ID,
  STATUS_DISPATCHED_ID,
  STATUS_PAYMENT_AUTHORIZED_ID,
  STATUS_POS_ACCEPTED_ID,
  STATUS_POS_ANALYSING_ID,
  STATUS_POS_DENIED_ID,
  STATUS_POS_IMPORTED_ID,
  STATUS_POS_IMPORT_ERROR_ID,
  STATUS_POS_RECEIVED_ID,
  STATUS_PREPARING_ID,
  STATUS_PRODUCT_UNAVAILABLE_ID,
  STATUS_READY_ID,
  STATUS_RECEIVED_ID,
} from '../../../common/model/order-status';

import { ORDER_TYPE_CURBSIDE_ID } from '../../../common/model/order-types';
import {
  getCreationTime,
  getMaxPreparationTime,
} from '../../../common/helpers/get-preparation-time';

interface Props {
  order: Order;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    companyImg: {
      width: '2rem',
      height: 'auto',
      alignSelf: 'center',
      borderRadius: '50%',
    },
    companyImgContainer: {
      paddingRight: theme.spacing(1),
    },
    orderStatusCanceled: {
      color: theme.palette.error.main,
    },
    orderStatusProcessing: {
      color: yellow[700],
    },
    orderStatusAccepted: {
      color: lightGreen[600],
    },
    orderStatusReady: {
      color: green[600],
    },
    orderStatusActionNeeded: {
      color: theme.palette.error.dark,
    },
  })
);

export default function OrderStatusItem(props: Props) {
  const classes = useStyles();

  const [orderProgress, setOrderProgress] = useState(0);
  const [delay, setDelay] = useState<number | null>(null);

  useEffect(() => {
    if (
      props.order.status === STATUS_POS_ACCEPTED_ID ||
      props.order.status === STATUS_PREPARING_ID ||
      props.order.status === STATUS_DISPATCHED_ID
    ) {
      setDelay(1000);
    } else {
      setDelay(null);
    }
  }, [props.order]);

  useInterval(() => {
    const progress = calculateOrderProgress(props.order);
    setOrderProgress(progress);
  }, delay);

  const { data, loading, error } = useGetCompanyImagesQuery({
    variables: { companyId: props.order.companyId! },
    onError: (err) => logError(err, 'Error executing Get Company Images Query'),
  });

  return (
    <ListItem divider={true}>
      {!loading && !error && data && data.company && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          className={classes.companyImgContainer}
        >
          <img
            className={classes.companyImg}
            src={parseImage(data.company.brand!.images, 'brand-aggregator-company-list')}
            alt=''
          />
        </Box>
      )}
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Typography variant='body1' color='textPrimary'>
          Meu pedido: #{props.order.displayCode}
        </Typography>
        {handleOrderStatus(props.order, classes, orderProgress)}
      </Box>
    </ListItem>
  );
}

function handleOrderStatus(
  order: Order,
  classes: any,
  orderProgress?: number
): React.ReactElement | undefined {
  switch (order.status) {
    case STATUS_CANCELED_ID:
    case STATUS_POS_DENIED_ID:
      return (
        <Typography id='status-text' variant='body2' className={classes.orderStatusCanceled}>
          Cancelado
        </Typography>
      );
    case STATUS_BACKEND_RECEIVED_ID:
    case STATUS_PAYMENT_AUTHORIZED_ID:
    case STATUS_POS_RECEIVED_ID:
      return (
        <Typography id='status-text' variant='body2' className={classes.orderStatusProcessing}>
          Enviando para a loja
        </Typography>
      );

    case STATUS_POS_ACCEPTED_ID:
    case STATUS_PREPARING_ID:
      const startTime = getCreationTime(order).format('HH:mm');
      const endTime = getMaxPreparationTime(order).format('HH:mm');
      return (
        <Box id='status-progress'>
          <LinearProgress
            id='progress'
            variant='determinate'
            color='primary'
            value={orderProgress}
          />
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Typography variant='caption' id='start-time'>
                {startTime}
              </Typography>
            </Box>
            <Box>
              <Typography variant='caption' id='end-time'>
                {endTime}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography id='status-text' variant='body2'>
              Estamos preparando o seu pedido
            </Typography>
          </Box>
        </Box>
      );

    case STATUS_READY_ID:
      if (order.orderType === ORDER_TYPE_CURBSIDE_ID) {
        return (
          <Typography id='status-text' variant='body2' className={classes.orderStatusReady}>
            Aguarde seu pedido na área de retirada
          </Typography>
        );
      }

      return (
        <Typography id='status-text' variant='body2' className={classes.orderStatusReady}>
          Venha buscar o seu pedido
        </Typography>
      );

    case STATUS_DISPATCHED_ID:
      return (
        <Box id='status-progress'>
          <LinearProgress
            id='progress'
            variant='determinate'
            color='primary'
            value={orderProgress}
          />
          <Box>
            <Typography variant='body2' id='status-text' className={classes.orderStatusReady}>
              O seu pedido está a caminho
            </Typography>
          </Box>
        </Box>
      );

    case STATUS_DELIVERED_ID:
    case STATUS_RECEIVED_ID:
    case STATUS_CONSUMED_ID:
    case STATUS_POS_IMPORTED_ID:
    case STATUS_POS_IMPORT_ERROR_ID:
    case STATUS_POS_ANALYSING_ID:
      return (
        <Typography id='status-text' variant='body2' className={classes.orderStatusReady}>
          Entregue
        </Typography>
      );

    case STATUS_PRODUCT_UNAVAILABLE_ID:
    case STATUS_CUSTOMER_ACTION_NEEDED_ID:
      return (
        <Typography id='status-text' variant='body2' className={classes.orderStatusActionNeeded}>
          Ocorreu um imprevisto, por favor compareça na loja
        </Typography>
      );
  }
}

function calculateOrderProgress(order: Order) {
  let progress = 0;
  if (
    order.creationDatetime &&
    order.preparationTimeMinimum &&
    order.preparationTimeMaximum &&
    (order.status === STATUS_POS_ACCEPTED_ID ||
      order.status === STATUS_PREPARING_ID ||
      order.status === STATUS_DISPATCHED_ID)
  ) {
    const creationTime = getCreationTime(order);
    const maxPreparationTime = getMaxPreparationTime(order);
    const currentTime = moment();

    if (currentTime.utc().isBefore(creationTime)) {
      progress = 0;
    } else if (currentTime.utc().isAfter(maxPreparationTime)) {
      progress = 100;
    } else {
      const totalDuration = moment.duration(maxPreparationTime.diff(creationTime)).asMilliseconds();
      const currentDuration = moment.duration(currentTime.diff(creationTime)).asMilliseconds();
      progress = (currentDuration / totalDuration) * 100;
    }
  }

  return progress;
}
