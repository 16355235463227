import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressPicker: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        alignItems: 'flex-start',
        textAlign: 'left',
      },
    },
    content: {
      maxWidth: '60%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    _brandless: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1, 0),
    },
    label: {
      color: theme.palette.common.white,
      fontWeight: 'normal',
      opacity: 0.6,
    },
    btn: {
      maxWidth: '100%',
      fontWeight: 'bold',
      borderRadius: '10px',
      textTransform: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      color: theme.palette.common.white,
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.6,
      },
    },
    addressText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);
