import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      background: theme.palette.common.white,
    },
    label: {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(0),
    },
    helperText: {
      marginLeft: theme.spacing(0),
    },
  })
);
