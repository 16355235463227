import React, { useCallback } from 'react';
import { Typography, Dialog, IconButton, Box } from '@material-ui/core';
import {
  useGetCompanyPaymentParamsQuery,
  CartPaymentStatus,
} from '../../../../common/generated/graphql';
import { useStyles } from './payments-methods-container.style';
import { logError } from '../../../../common/helpers/log-error';
import { useParams } from 'react-router-dom';
import PaymentOfflineOptions from '../payment-offline-options/payment-offline-options';
import PaymentOnlineOptions from '../payment-online-options/payment-online-options';
import NetworkError from '../../../../common/components/network-error/network-error';
import { useUpdateCartPayment } from '../../../../common/hooks/use-update-cart-payment';
import Icon from '../../../../common/components/icon/icon';
import { useMainHeaderSpace } from '../../../../common/hooks/use-header-space';
import { useLocation } from 'react-router-dom';

import { checkHostName } from '../../../../common/helpers/check-host-name';
import { WhiteLabelHostName } from '../../../../common/model/white-label-host-name';

interface Props {
  open: boolean;
}

export default function PaymentsMethodsContainer(props: Props) {
  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();
  const { companyId } = useParams<{ companyId: string }>();
  const [offline, setOffline] = React.useState(false);
  const { updateCartPayment } = useUpdateCartPayment();
  const location = useLocation();

  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);

  const handleSetOffline = useCallback((offline: boolean) => {
    setOffline(offline);
  }, []);

  const { data, error, refetch } = useGetCompanyPaymentParamsQuery({
    variables: { companyId: Number(companyId) },
    notifyOnNetworkStatusChange: true,
    onError: (err) => logError(err, 'Error executing Get Company Payment Methods Query'),
  });

  const handleOnClose = useCallback(async () => {
    updateCartPayment(CartPaymentStatus.CartPayment);

    handleSetOffline(false);
  }, [handleSetOffline, updateCartPayment]);

  if (error) {
    return (
      <NetworkError
        errorMsg={'Erro ao carregar os meios de pagamento'}
        compact={true}
        retry={refetch}
        error={error}
        path={location.pathname}
      />
    );
  }

  const hasOffline =
    data?.company?.parameter?.offlinePaymentParameter &&
    data.company?.parameter?.offlinePaymentParameter.length > 0;

  const dataPaymentParameter = isStreamshop
    ? data?.company?.parameter?.paymentParameter?.filter((pay) => pay?.cardBrand?.code !== 'alel0')
    : data?.company?.parameter?.paymentParameter;

  return (
    <>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='modal-metodos-de-pagamento'
        open={props.open}
        classes={{
          container: classes.container,
          paper: `${classes.paper} ${headerSpace.fullDialog}`,
          root: classes.root,
        }}
      >
        <Box className={classes.titleWrap}>
          <Typography className={classes.title} align='center' variant='h6'>
            Formas de Pagamento
          </Typography>
          <IconButton
            onClick={handleOnClose}
            className={classes.btnIcon}
            aria-label='fechar-modal-metodos-de-pagamento'
            id='payments-methods-container-close-button'
          >
            <Icon name='close' color='dark' size='large' className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <PaymentOfflineOptions
            hidden={!offline}
            offlinePayments={data?.company?.parameter?.offlinePaymentParameter}
            onClose={handleOnClose}
          />
          <PaymentOnlineOptions
            payments={dataPaymentParameter}
            setOffline={handleSetOffline}
            hasOffline={hasOffline}
            hidden={offline}
            onClose={handleOnClose}
          />
        </Box>
      </Dialog>
    </>
  );
}
