import React, { ReactNode } from 'react';
import { useStyles } from './default-actions.style';
import { Button } from '@material-ui/core';

interface ActionEvent {
  label?: string | ReactNode;
  event?: VoidFunction;
}

interface Props {
  primary?: ActionEvent;
  secondary?: ActionEvent;
}

export default function DefaultActions(props: Props) {
  const classes = useStyles();

  return (
    <>
      <Button
        id='default-actions-primary'
        data-testid='default-actions-primary'
        className={classes.primary}
        classes={{
          root: classes.root,
        }}
        size='large'
        color='secondary'
        variant='contained'
        disableElevation
        type={props.primary?.event ? 'button' : 'submit'}
        onClick={props.primary?.event}
      >
        {props.primary?.label ?? 'Confirmar'}
      </Button>

      {props.secondary && (
        <Button
          id='default-actions-secondary'
          data-testid='default-actions-secondary'
          classes={{
            root: classes.root,
            label: classes.secondaryText,
            contained: classes.secondaryContained,
          }}
          size='large'
          color='default'
          variant='contained'
          disableElevation
          onClick={props.secondary?.event}
        >
          {props.secondary?.label ?? 'Cancelar'}
        </Button>
      )}
    </>
  );
}
