import {
  GetOrderCheckFromCacheDocument,
  GetOrderCheckFromCacheQuery,
  Maybe,
  MutationsOrderCheckRequestArgs,
  OrderCheckResponse,
  OrderExtra,
  Result,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  GetSelectedOrderTypeQuery,
  GetSelectedOrderTypeDocument,
  SelectedOrderType,
  OrderItemInput,
  GetUserAddressDocument,
  GetUserAddressQuery,
  UserAddress,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';
import { createOrderProductTree } from '../../helpers/create-order-product-tree';
import { logError } from '../../helpers/log-error';
import { ORDER_TYPE_DELIVERY } from '../../model/order-types';
import { createOrderExtraFields } from '../../helpers/delivery-order-extra-fields';
import { getEndpoints, getAppConfig } from '../../helpers/configs';
import { generateCardMaskedNumber } from '../../helpers/generate-card-masked-number';
import { saveOrderTypeToCache } from '../../browser-cache/order-type/save-order-type-to-cache';

export async function orderCheckRequest(
  root: any,
  variables: MutationsOrderCheckRequestArgs,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  let success = false;
  let errorMsg = 'Erro ao verificar o seu pedido, por favor tente novamente';

  const shoppingCart = getShoppingCart(variables.companyId, context.cache, context.getCacheKey);
  const selectedOrderType = getSelectedOrderType(
    context.cache,
    (variables.orderTypes || []) as string[]
  );

  // Stop here if there are no products in the shopping cart.
  if (!shoppingCart || shoppingCart.products.length === 0) {
    updateCache(context.cache, context.getCacheKey);
    updateOrderTotal(variables.companyId, context.cache, context.getCacheKey);
    return { __typename, success: false, errorMsg: '' };
  }

  try {
    const items = createOrderProductTree(variables.companyId, context.cache, context.getCacheKey);
    const userAddressQuery = context.cache.readQuery<GetUserAddressQuery>({
      query: GetUserAddressDocument,
    });
    const orderFields = createOrderExtraFields(selectedOrderType, userAddressQuery?.userAddress);
    const payment = createPayment(context.cache);

    if (selectedOrderType) {
      let orderCheckResponse = await executeOrderCheck(
        variables.companyId,
        items!,
        selectedOrderType,
        orderFields,
        userAddressQuery?.userAddress,
        payment
      );

      if (selectedOrderType?.orderType === ORDER_TYPE_DELIVERY) {
        orderCheckResponse = await executeExtraOrderCheck(
          variables.companyId,
          items!,
          selectedOrderType,
          orderFields,
          userAddressQuery?.userAddress,
          payment,
          orderCheckResponse?.extras
        );
      }

      updateCache(context.cache, context.getCacheKey, orderCheckResponse);
      updateOrderTotal(variables.companyId, context.cache, context.getCacheKey, orderCheckResponse);
    }
    success = true;
    errorMsg = '';
  } catch (error) {
    const err = error as AxiosError;
    if (err.response && err.response?.status >= 400 && err.response?.status < 500) {
      errorMsg = getErrorMessage(err);

      const orderCheckResponse = err.response!.data;
      updateCache(context.cache, context.getCacheKey, orderCheckResponse);
      updateOrderTotal(variables.companyId, context.cache, context.getCacheKey, orderCheckResponse);
    } else {
      logError(error, 'Error in Order Check Call');
    }
  }

  return { __typename, success, errorMsg };
}

function getShoppingCart(companyId: number, cache: InMemoryCache, getCacheKey: any) {
  return cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
  });
}

function getSelectedOrderType(cache: InMemoryCache, orderTypes: string[] = []) {
  const selectedOrderTypeQuery = cache.readQuery<GetSelectedOrderTypeQuery>({
    query: GetSelectedOrderTypeDocument,
  });

  if (orderTypes.length) {
    const existsOrderInCompany = orderTypes.some(
      (type) => type === selectedOrderTypeQuery?.selectedOrderType?.orderType
    );

    if (!existsOrderInCompany) {
      cache.writeQuery<GetSelectedOrderTypeQuery>({
        query: GetSelectedOrderTypeDocument,
        data: { selectedOrderType: null },
      });

      saveOrderTypeToCache(null);
      return null;
    }
  }

  return selectedOrderTypeQuery?.selectedOrderType;
}

async function executeOrderCheck(
  companyId: number,
  items: OrderItemInput[],
  selectedOrderType?: SelectedOrderType | null,
  orderFields?: any[],
  userAddress?: UserAddress | null,
  payment?: any,
  orderExtras?: OrderExtra[]
) {
  const appConfig = getAppConfig();
  const endpoints = getEndpoints();
  const url = `${endpoints.api}v3/mobile/company/${companyId}/order/check`;
  const takeDelivery = window.localStorage.getItem('selectedDelivery');
  const deliveryStruct = JSON.parse(takeDelivery ?? '{}');

  const formattedOrderExtras =
    orderExtras?.map((extra) => ({
      key: extra.key,
      value: extra.value,
    })) ?? [];

  const extras =
    deliveryStruct && selectedOrderType?.orderType === 'delivery' && companyId === deliveryStruct.id
      ? [
          {
            value: deliveryStruct.value,
            label: deliveryStruct.label,
            optional: deliveryStruct.optional,
            key: deliveryStruct.key,
          },
        ]
      : formattedOrderExtras;

  const response = await axios.post(url, {
    orderType: selectedOrderType?.orderType
      ? selectedOrderType?.orderType
      : appConfig.defaultOrderType,
    orderFields,
    extras,
    items,
    coords: userAddress
      ? { latitude: userAddress.latitude, longitude: userAddress.longitude }
      : undefined,
    payment,
  });

  return response.data;
}

/*
 * Workaround / Gambiarra: send additional order check with the order extras that were received from the backend.
 * This is necessary, because we want the backend to validate the minimum order price and it needs the
 * order extras to do so.
 */
async function executeExtraOrderCheck(
  companyId: number,
  items: OrderItemInput[],
  selectedOrderType?: SelectedOrderType | null,
  orderFields?: any[],
  userAddress?: UserAddress | null,
  payment?: any,
  orderExtras?: OrderExtra[]
) {
  try {
    return await executeOrderCheck(
      companyId,
      items!,
      selectedOrderType,
      orderFields,
      userAddress,
      payment,
      orderExtras
    );
  } catch (error) {
    const err = error as AxiosError;
    // Ignore timeouts and 500 errors (use the information from the previous order check)
    if (err.response && err.response?.status >= 400 && err.response?.status < 500) {
      throw error;
    } else {
      console.error('Error in resending order check', error);
    }
  }
}

function updateCache(cache: InMemoryCache, getCacheKey: any, orderCheck?: OrderCheckResponse) {
  if (!orderCheck) {
    writeOrderCheckToCache(cache);
    return;
  }

  // Note: there can be only one order check, so it will always have the same id in the cache.
  const newOrderCheck: any = {
    __typename: 'OrderCheckResponse',
    id: btoa(`OrderCheckResponse:1`),
    minimumPreparationTime: orderCheck.minimumPreparationTime
      ? orderCheck.minimumPreparationTime
      : null,
    maximumPreparationTime: orderCheck.maximumPreparationTime
      ? orderCheck.maximumPreparationTime
      : null,
    warning: orderCheck.warning ? orderCheck.warning : null,
    extras: createOrderCheckExtras(orderCheck.extras),
    error: createOrderCheckErrors(orderCheck.error),
  };

  writeOrderCheckToCache(cache, newOrderCheck);
}

function writeOrderCheckToCache(cache: InMemoryCache, newOrderCheck?: OrderCheckResponse) {
  cache.writeQuery<GetOrderCheckFromCacheQuery>({
    query: GetOrderCheckFromCacheDocument,
    data: { orderCheck: newOrderCheck ? (newOrderCheck as any) : null },
  });
}

function createOrderCheckExtras(extras: Array<Maybe<OrderExtra>> | null | undefined) {
  if (!extras) {
    return null;
  }

  return extras.map((orderExtra: Maybe<OrderExtra>) => ({
    ...orderExtra!,
    __typename: 'OrderExtra',
    id: createNewCacheKey('OrderExtra'),
  }));
}

function createPayment(cache: InMemoryCache) {
  const paymentMethodQuery = cache.readQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
  });
  const payment = paymentMethodQuery?.selectedPaymentMethod;
  if (!payment) {
    return;
  }

  switch (payment.__typename) {
    case 'RemoteCard':
      return {
        card: payment.backendId,
      };

    case 'LocalCard':
      return {
        newCard: {
          cardBrand: payment.cardBrand,
          maskedNumber: generateCardMaskedNumber(payment.cardNumber),
        },
      };

    case 'Wallet':
      return {
        wallet: {
          code: payment.cardBrand,
        },
      };
  }
}

function createOrderCheckErrors(error?: any) {
  if (!error) {
    return null;
  }

  return {
    ...error,
    __typename: 'Error',
    id: createNewCacheKey('Error'),
  };
}

function updateOrderTotal(
  companyId: number,
  cache: InMemoryCache,
  getCacheKey: any,
  orderCheck?: OrderCheckResponse
) {
  const shoppingCart = getShoppingCart(companyId, cache, getCacheKey);
  if (!shoppingCart) {
    console.warn('Shopping cart for company: ' + companyId + ' not found');
    return;
  }

  let updatedShoppingCart;
  if (orderCheck?.extras?.length! > 0) {
    updatedShoppingCart = createUpdatedShoppingCartWithOrderExtras(shoppingCart, orderCheck!);
  } else {
    updatedShoppingCart = createUpdatedShoppingCartWithNoExtras(shoppingCart);
  }

  writeShoppingCartToCache(companyId, updatedShoppingCart, cache, getCacheKey);
}

function createUpdatedShoppingCartWithNoExtras(shoppingCart: ShoppingCartFragment) {
  return {
    ...shoppingCart,
    totalExtras: 0,
    orderTotal: shoppingCart.totalProductsPrice,
  };
}

function createUpdatedShoppingCartWithOrderExtras(
  shoppingCart: ShoppingCartFragment,
  orderCheck: OrderCheckResponse
) {
  let totalExtras = 0;
  for (const orderExtra of orderCheck.extras!) {
    if (orderExtra) {
      totalExtras = totalExtras + orderExtra.value;
    }
  }

  return {
    ...shoppingCart,
    totalExtras,
    orderTotal: shoppingCart.totalProductsPrice + totalExtras,
  };
}

function writeShoppingCartToCache(
  companyId: number,
  updatedShoppingCart: ShoppingCartFragment,
  cache: InMemoryCache,
  getCacheKey: any
) {
  cache.writeFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({
      id: btoa(`ShoppingCart:${companyId}`),
      __typename: 'ShoppingCart',
    }),
    data: updatedShoppingCart,
  });
}

function getErrorMessage(err: AxiosError): string {
  let errorMsg = '';

  const orderCheckResponse = err.response!.data;
  console.warn('Error in order check', orderCheckResponse);

  if (orderCheckResponse.warning && orderCheckResponse.warning !== '') {
    errorMsg = orderCheckResponse.warning;
  } else if (
    orderCheckResponse.error &&
    orderCheckResponse.error.message &&
    orderCheckResponse.error.message !== ''
  ) {
    errorMsg = orderCheckResponse.error.message;
  }

  return errorMsg;
}
