import { useCallback } from 'react';
import { logError } from '../../common/helpers/log-error';

import { repeatLastOrderFromCache } from '../../common/browser-cache/shopping-cart/repeat-last-order-from-cache';
import { useLoadShoppingCartsFromBrowserCacheMutation } from '../../common/generated/graphql';

export const useUpdateRepeatOrder = () => {
  const [loadCartsFromCache] = useLoadShoppingCartsFromBrowserCacheMutation({
    onError: (err) => logError(err, 'Error on Load Shopping Carts from Browser Mutation'),
  });

  const repeatOrderCompany = useCallback(
    async (companyId: number) => {
      await repeatLastOrderFromCache(companyId);
      await loadCartsFromCache();
    },
    [loadCartsFromCache]
  );

  return { repeatOrderCompany };
};
