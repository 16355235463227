import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import Icon from '../../../common/components/icon/icon';

import { useStyles } from './receipt-order-info.style';
import { UnionIconOptions } from '../../../common/components/icon/helpers/type-icon';

interface Props {
  startTime: string;
  endTime: string;
  orderCode: string;
  orderType: {
    label: string;
    icon: UnionIconOptions;
  };
}

export default function ReceiptOrderInfo({ startTime, endTime, orderCode, orderType }: Props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <Typography variant={'body2'} className={classes.infoSubtitle}>
          Previsão para entrega
        </Typography>
        <Grid item container>
          <Icon name='clock' size='large' color='primaryLightest' />
          <Typography
            id='receipt-order-info-time'
            variant='body1'
          >{`${startTime} - ${endTime} `}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'body2'} className={classes.infoSubtitle}>
          Número do pedido
        </Typography>
        <Grid item container alignItems='center'>
          <Icon name='flag' size='large' color='primaryLightest' />
          <Typography id='receipt-order-info-code'>{orderCode}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'body2'} className={classes.infoSubtitle}>
          Tipo de pedido
        </Typography>
        <Grid item container>
          <Icon name={orderType?.icon} size='large' color='primaryLightest' />
          <Typography gutterBottom>{orderType?.label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
