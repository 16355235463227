import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { SearchOutlined } from '@material-ui/icons';

import { BaseTextFieldProps } from '@material-ui/core';
import { useStyles } from './input-search.style';
import Icon from '../icon/icon';
import { useProductFilterQuery } from '../../generated/graphql';
import { useUpdateProductFilter } from '../../hooks/use-update-product-filter';
import { useParams } from 'react-router-dom';
import { logSearch } from '../../../analytics/helpers/log-search';

interface Props extends BaseTextFieldProps {
  placeholder?: string;
  id?: string;
  isDesktop?: boolean;
  withIcon?: boolean;
}

export default function InputSearch({ isDesktop, withIcon, ...rest }: Props) {
  const { brandId, companyId } = useParams<{ brandId: string; companyId: string }>();
  const { data } = useProductFilterQuery();
  const classes = useStyles();
  const { setProductQuery, setProductActive, clearProductQuery } = useUpdateProductFilter();

  const handleChange = useCallback(
    (e) => {
      setProductQuery(e.target.value);
      if (e.target.value.length > 3) {
        logSearch({ brandId, companyId, searchTerm: e.target.value, type: 'item' });
      }
    },
    [setProductQuery, brandId, companyId]
  );

  const handleToggleVisible = useCallback(() => {
    if (data?.productFilter.active) {
      clearProductQuery();
    } else {
      setProductActive(true);
    }
  }, [data, clearProductQuery, setProductActive]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        clearProductQuery();
      }
    },
    [clearProductQuery]
  );

  return (
    <Box className={`${classes.root} ${data?.productFilter.active && classes.active}`}>
      {!isDesktop && (
        <IconButton id='toggle-visibility' onClick={handleToggleVisible}>
          <Icon name='magnifer' size='large' id='icon-search' color='secondary' />
        </IconButton>
      )}
      {(data?.productFilter.active || isDesktop) && (
        <TextField
          variant={isDesktop ? 'outlined' : 'standard'}
          value={data?.productFilter.query}
          color='secondary'
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          className={classes.search}
          classes={{
            root: classes.searchFormControl,
          }}
          fullWidth
          autoFocus
          InputProps={{
            startAdornment: withIcon && (
              <InputAdornment id='icon-search-input' position='start'>
                <SearchOutlined id='icon-search' className={classes.searchIcon} />
              </InputAdornment>
            ),
            endAdornment: data?.productFilter.query && (
              <InputAdornment position='end'>
                <IconButton id='input-search-button-clear' onClick={clearProductQuery}>
                  <Icon name='close' size='large' id='icon-close' />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      )}
    </Box>
  );
}
