import { InMemoryCache } from '@apollo/client';
import {
  OrderItemInput,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
  ProductType,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
} from '../generated/graphql';

/**
 * Creates a product tree from the selected items in the cache.
 * This tree is meant to be sent in the Order Check and Order POST requests.
 */
export function createOrderProductTree(
  companyId: number,
  cache: InMemoryCache,
  getCacheKey: any
): OrderItemInput[] | undefined {
  const shoppingCart = cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: getCacheKey({ id: btoa(`ShoppingCart:${companyId}`), __typename: 'ShoppingCart' }),
  });

  // Stop here if there are no products in the shopping cart.
  if (!shoppingCart || shoppingCart.products.length === 0) {
    return;
  }

  const items: OrderItemInput[] = [];
  for (const product of shoppingCart.products) {
    const productTree = createTree(product.id, cache, getCacheKey);
    items.push(productTree!);
  }

  return items;
}

function createTree(
  productCacheId: string,
  cache: InMemoryCache,
  getCacheKey: any
): OrderItemInput | undefined {
  const product = cache.readFragment<ProductAndSubproductsFragment>({
    fragment: ProductAndSubproductsFragmentDoc,
    id: getCacheKey({ id: productCacheId, __typename: 'Product' }),
    fragmentName: 'productAndSubproducts',
  });

  if (!product) {
    console.warn('Product not found in cache.', productCacheId);
    return;
  }

  const orderItem: OrderItemInput = {
    isRedeem: false,
    product: product.numericalId!,
    quantity:
      product.productType === ProductType.Choosable ? '1' : product.chosenQuantity!.toString(),
    unitPrice: product.productCompanyByCompanyId!.price,
    items: new Array<OrderItemInput>(),
  };

  if (product.subproducts) {
    for (const subproduct of product.subproducts) {
      const childItem = createTree(subproduct!.id, cache, getCacheKey);
      if (childItem) {
        orderItem.items!.push(childItem);
      }
    }
  }
  return orderItem;
}
