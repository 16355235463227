import { BenVisaValeHeader, MerchantKeyMap, MerchantKeyObj } from '../model/ben-visa-vale-header';
import * as forge from 'node-forge';
import { Method } from '../model/methods';

export default function createBenVisaValeHeader(
  header: BenVisaValeHeader,
  payload: string,
  merchantKeyMapHash: string,
  url: string,
  method: string
): BenVisaValeHeader {
  const merchantKeyMap = extractMerchantKeys(merchantKeyMapHash);
  let date = '';
  let digest = '';
  let signature = '';

  if (header.Date === '[[date]]') {
    date = getUtcDate();
  }

  if (header.Digest === '[[digest]]') {
    digest = generateDigest(payload);
  }

  if (header.Signature === '[[signature]]') {
    signature = getSignature(
      header,
      url,
      method,
      merchantKeyMap.merchantKey,
      merchantKeyMap.merchantSecretKey,
      digest
    );
  }

  return {
    ...header,
    Date: date,
    Digest: digest,
    Signature: signature,
  };
}

function getUtcDate(): string {
  return new Date(Date.now()).toUTCString();
}

function generateDigest(body: string): string {
  const hash = forge.md.sha256.create();
  hash.update(body, 'utf8');

  const digestHash = hash.digest();

  return `SHA-256=${forge.util.encode64(digestHash.data)}`;
}

function getSignature(
  header: BenVisaValeHeader,
  url: string,
  method: string,
  merchantKey: string,
  secretKey: string,
  digest: string
): string {
  let signatureHeader = `keyid="${merchantKey}", algorithm="HmacSHA256"`;

  if (method === Method.GET) {
    signatureHeader += `, headers="host date (request-target) v-c-merchant-id"`;
  } else if (method === Method.POST) {
    signatureHeader += `, headers="host date (request-target) digest v-c-merchant-id"`;
  }

  return `${signatureHeader}, signature="${generateSignatureToken(
    header.Host,
    url,
    digest,
    header['v-c-merchant-id'],
    method,
    secretKey
  )}"`;
}

function generateSignatureToken(
  host: string,
  url: string,
  digest: string,
  merchandId: string,
  method: string,
  secretKey: string
): string {
  const hash = forge.hmac.create();
  hash.start('sha256', forge.util.decode64(secretKey));
  let strSignature = `host: ${host}\ndate: ${getUtcDate()}\n`;

  if (method === Method.GET) {
    strSignature += `(request-target): get ${url}\n`;
  } else if (method === Method.POST) {
    strSignature += `(request-target): post ${url}\ndigest: ${digest}'\n`;
  }

  const content = forge.util.encodeUtf8(`${strSignature}v-c-merchant-id: ${merchandId}`);

  hash.update(content);

  return forge.util.encode64(hash.digest().data);
}

function extractMerchantKeys(base64: string): MerchantKeyObj {
  const merchantKeyMap: MerchantKeyMap = JSON.parse(atob(base64));

  return {
    merchantId: merchantKeyMap.merchant_id,
    merchantKey: merchantKeyMap.merchant_key,
    merchantSecretKey: merchantKeyMap.merchant_secret_key,
  };
}
