import React from 'react';
import { Grid } from '@material-ui/core';

import { Maybe } from '../../../common/generated/graphql';
import { OrderItemTree } from '../receipt-page/receipt-page';

import ReceiptOrderSimple from '../receipt-order-simple/receipt-order-simple';

interface Props {
  items?: Maybe<OrderItemTree>[];
}

export default function ReceiptOrdemChoosable({ items }: Props) {
  return (
    <>
      {items?.map((simple, index) => (
        <Grid id={`receipt-order-choosable-${index}`} container key={String(index)}>
          <ReceiptOrderSimple items={simple?.children} />
        </Grid>
      ))}
    </>
  );
}
