import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyAddressQuery, SelectedOrderType } from '../../../../common/generated/graphql';
import { Typography, Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import OrderTypeExtraHeader from '../order-type-extra-header/order-type-extra-header';
import { logError } from '../../../../common/helpers/log-error';
import NetworkError from '../../../../common/components/network-error/network-error';
import { useLocation } from 'react-router-dom';

interface Props {
  orderType: SelectedOrderType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1, 0, 0, 0),
    },
    boldText: {
      fontWeight: 500,
    },
  })
);

export default function OrderTypeCompanyAddress(props: Props) {
  const classes = useStyles();
  const routeParams = useParams<{ aggregatorId: string; brandId: string; companyId: string }>();
  const location = useLocation();

  const { data, loading, error, refetch } = useGetCompanyAddressQuery({
    variables: { companyId: Number(routeParams.companyId) },
    onError: (err) => logError(err, 'Error executing Get Company Address Query'),
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return <Box id='no-order-type' />;
  } else if (error) {
    return (
      <Box>
        <OrderTypeExtraHeader orderType={props.orderType} />
        <NetworkError
          errorMsg='Erro ao carregar o endereço'
          compact={true}
          retry={refetch}
          error={error}
          path={location.pathname}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.root} id='company-address-root'>
      <OrderTypeExtraHeader orderType={props.orderType} />
      <Typography variant='body2'>
        <span className={classes.boldText}>Endereço: </span>
        {data?.company?.address?.street}
      </Typography>
      <Typography variant='body2'>
        <span className={classes.boldText}>Número: </span>
        {data?.company?.address?.number}
      </Typography>
      {data?.company?.address?.complement !== '' && (
        <Typography variant='body2'>
          <span className={classes.boldText}>Complemento: </span>
          {data?.company?.address?.complement}
        </Typography>
      )}
      <Typography variant='body2'>
        <span className={classes.boldText}>Cidade: </span>
        {data?.company?.address?.city}
      </Typography>
    </Box>
  );
}
