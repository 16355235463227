import { List } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';

import NetworkError from '../../../common/components/network-error/network-error';
import Progress from '../../../common/components/progress/progress';
import { OrderEdge, Order } from '../../../common/generated/graphql';
import OrderStatusItem from '../order-status-item/order-status-item';
import { useStyles } from './bottom-tab.style';
import { useLocation } from 'react-router-dom';

interface Props {
  data: OrderEdge[];
  loading: boolean;
  error: any;
  isRefetching: boolean;
  refetch: any;
}

export default function BottomTab(props: Props) {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const handleOrderClicked = useCallback(
    (order?: Order) => {
      const decodeId = atob(order?.id ?? ':');
      const [, id] = decodeId.split(':') ?? [];

      history.push(`receipt/${id}`, {
        order,
      });
    },
    [history]
  );

  if (props.loading || props.isRefetching) {
    return <Progress />;
  } else if (props.error) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os seus pedidos'
        retry={props.refetch}
        compact={true}
        error={props.error}
        path={location.pathname}
      />
    );
  }

  return (
    <List id='order-list'>
      {props.data.map((order, index) => (
        <Box
          key={String(index)}
          className={classes.containerItem}
          onClick={() => handleOrderClicked(order.node as Order)}
        >
          <OrderStatusItem key={order?.node?.id} order={order?.node! as Order} />
        </Box>
      ))}
    </List>
  );
}
