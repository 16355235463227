import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
    },
    iconPaymentImg: {
      width: '1.5rem',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 3),
    },
    itemWrapIcon: { paddingRight: theme.spacing(1), display: 'flex', alignItems: 'center' },
    itemWrapText: {
      flexGrow: 1,
    },
    itemTitle: {
      lineHeight: theme.typography.overline.lineHeight,
    },
    itemExtraText: {
      color: theme.palette.grey[600],
    },
  })
);
