import React from 'react';
import { Grid } from '@material-ui/core';

import AggregatorCompanyListItem from '../aggregator-company-list-item/aggregator-company-list-item';
import { CompanySharedEdge, Maybe } from '../../../common/generated/graphql';
import { getSharedConfig } from '../../../common/helpers/configs';

interface Props {
  companiesCard?: Maybe<CompanySharedEdge>[];
  isEvent: boolean;
}

export default function AggregatorsGroupItem(props: Props) {
  const sharedConfig = getSharedConfig();
  return (
    <Grid container>
      {props?.companiesCard?.map((company, index) => {
        return (
          <Grid
            role='group-container'
            item
            key={company?.node?.id || String(index)}
            zeroMinWidth
            {...sharedConfig.numberColumnsBreakPointsAggregator}
          >
            <AggregatorCompanyListItem company={company!.node!} isEvent={props.isEvent} />
          </Grid>
        );
      })}
    </Grid>
  );
}
