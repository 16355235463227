import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import Progress from '../../../common/components/progress/progress';
import { CompanyGroupEdge, Maybe } from '../../../common/generated/graphql';
import EmptyAggregator from '../empty-aggregator/empty-aggregator';
import { useStyles } from './aggregators-groups-companies-container-list.style';
import AggregatorsGroupsCompaniesContainerBox from '../aggregators-groups-companies-container-box/aggregators-groups-companies-container-box';
import { useLocation } from 'react-router-dom';

interface Props {
  companiesGroups: Maybe<CompanyGroupEdge>[];
  numberCompanies: number;
  hasMore: boolean;
  fetchMoreData: () => void;
  refetch: () => void;
  setTotalCompanies: (total: number) => void;
}

export default function AggregatorsGroupsCompaniesContainerList(props: Props) {
  const classes = useStyles();
  const [showEmptyAggregator, setShowEmptyAggregator] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let count = 0;

    if (!props.companiesGroups.length) {
      setShowEmptyAggregator(true);
      return;
    }

    props.companiesGroups.forEach((group) => {
      const countCompanies = group?.node?.companies?.edges?.length ?? 0;
      count += countCompanies;
    });

    if (!count) {
      setShowEmptyAggregator(true);
    }
    props.setTotalCompanies(count);
  }, [props, props.companiesGroups]);

  if (showEmptyAggregator) {
    return <EmptyAggregator retry={props.refetch} path={location.pathname} />;
  }

  return (
    <Box className={classes.root}>
      <InfiniteScroll
        scrollableTarget='main-container'
        dataLength={props.companiesGroups.length}
        next={props.fetchMoreData}
        hasMore={props.hasMore}
        loader={<Progress />}
        className={classes.infiniteScroll}
      >
        <Grid container>
          {props.companiesGroups?.map((group, index) => (
            <React.Fragment key={group?.node?.id ?? String(index)}>
              {!!group?.node?.companies?.edges.length && (
                <Grid item xs={12} className={classes.companiesGroup}>
                  <AggregatorsGroupsCompaniesContainerBox companiesGroup={group} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </InfiniteScroll>
    </Box>
  );
}
