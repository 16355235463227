import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons';
import { useStyles } from './choose-quantity.style';
import { OnClickButton } from '../../model/html-events';

export interface ChooseQuantityProps {
  value: number;
  addOne: OnClickButton | VoidFunction;
  reduceOne: OnClickButton | VoidFunction;
  disableAdd?: boolean;
  disableReduce?: boolean;
}

export default function ChooseQuantity({
  value,
  addOne,
  reduceOne,
  disableAdd = false,
  disableReduce = false,
}: ChooseQuantityProps) {
  const classes = useStyles();

  return (
    <Box aria-label='choose quantity buttons' className={classes.group}>
      <Button
        className={classes.quantityBtn}
        id='event-quantity-reduce'
        onClick={reduceOne}
        disabled={disableReduce}
      >
        <Remove fontSize='small' color='secondary' />
      </Button>
      <Typography variant='body2' className={classes.value} id='event-quantity-result'>
        {value}
      </Typography>
      <Button
        className={classes.quantityBtn}
        id='event-quantity-add'
        onClick={addOne}
        disabled={disableAdd}
      >
        <Add fontSize='small' color='secondary' />
      </Button>
    </Box>
  );
}
