import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.palette.text.secondary,
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
  })
);
