import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Product } from '../../../common/generated/graphql';
import { isProductAvailable } from '../../../common/helpers/is-product-available';
import Choosable from '../choosable/choosable';

interface Props {
  childProducts: Product[];
  menuItemCacheId: string;
}

/**
 * Renders a list of sencond level choosables (used in nested choosables)
 */
export default function ChoosablesList(props: Props) {
  const [selectedChoosable, setSelectedChoosable]: [Product | undefined, any] = useState(undefined);

  // Effect fired whenever the child choosables change.
  useEffect(() => {
    let selectedChild = props.childProducts.find(
      (child) => isProductAvailable(child) && child.chosenQuantity > 0
    );

    setSelectedChoosable(selectedChild);
  }, [props.childProducts]);

  if (selectedChoosable) {
    return (
      <Choosable
        product={selectedChoosable}
        menuItemCacheId={props.menuItemCacheId}
        is2ndLevel={true}
      />
    );
  }

  // If the user hasn't selected any product from child choosables, render all of them.
  return (
    <Box>
      {props.childProducts
        .filter((child) => isProductAvailable(child))
        .map((child) => (
          <Choosable
            key={child.id}
            product={child}
            menuItemCacheId={props.menuItemCacheId}
            is2ndLevel={true}
          />
        ))}
    </Box>
  );
}
