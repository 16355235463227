import { Endpoints } from '../../../common/model/config';

export const c3PrajaEndpoints: Endpoints = {
  api: 'https://api.c3praja.com/',
  graphql: 'https://graphql.c3praja.com/graphql',
  payments: 'https://payments.c3praja.com/',
  authDomain: 'user-auth.c3praja.com',
  enterpriseUserForm: 'https://enterprise-user.c3praja.com/',
};

export const c3PrajaStagingEndpoints: Endpoints = {
  api: 'https://api.staging.c3praja.com/',
  graphql: 'https://graphql.staging.c3praja.com/graphql',
  payments: 'https://payments.staging.c3praja.com/',
  authDomain: 'user-auth.c3praja.com',
  enterpriseUserForm: 'https://enterprise-user.staging.c3praja.com/',
};
