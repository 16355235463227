import { getOnlyDigits } from './get-only-digits';

export function getCardNumberRegex(): RegExp {
  return new RegExp('^\\d{4}\\.\\d{4}\\.\\d{4}\\.\\d{1,4}(\\.\\d{1,3})?$');
}

export function formatCardNumber(value: string): string {
  let valueNumber = getOnlyDigits(value).substring(0, 19);
  if (valueNumber.length > 4) {
    valueNumber = valueNumber.substring(0, 4) + '.' + valueNumber.substring(4, 19);
  }
  if (valueNumber.length > 9) {
    valueNumber = valueNumber.substring(0, 9) + '.' + valueNumber.substring(9, 20);
  }
  if (valueNumber.length > 14) {
    valueNumber = valueNumber.substring(0, 14) + '.' + valueNumber.substring(14, 21);
  }
  if (valueNumber.length > 19) {
    valueNumber = valueNumber.substring(0, 19) + '.' + valueNumber.substring(19, 22);
  }
  return valueNumber;
}
