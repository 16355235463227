import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, Container, Button, Divider } from '@material-ui/core';
import QRCode from 'react-qr-code';
import firebase from 'firebase';

import {
  Order,
  Maybe,
  OrderItem,
  useGetCustomerDataQuery,
  Company,
  GetCustomerDataQuery,
  GetCustomerDataDocument,
} from '../../../common/generated/graphql';
import { useStyles } from './receipt-page.style';

import StatusBox from '../status-box/status-box';
import ReceiptHeader from '../receipt-header/receipt-header';
import ReceiptPaymentMethod from '../receipt-payment-method/receipt-payment-method';
import ReceiptOrderInfo from '../receipt-order-info/receipt-order-info';
import ReceiptOrderList from '../receipt-order-list/receipt-order-list';
import ReceiptOrderValues from '../receipt-order-values/receipt-order-values';

import {
  getCreationTime,
  getMaxPreparationTime,
} from '../../../common/helpers/get-preparation-time';
import { getStatus } from '../../helpers/get-status';
import { getOrderType } from '../../helpers/get-order-type';
import { makeTree } from '../../helpers/make-tree';
import { MainHeader } from '../../../common/components/main-header/main-header';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import {
  DIGITAL_MENU,
  DIGITAL_MENU_URL,
  SEND_MESSAGE_WHATS,
} from '../../../common/model/digital-menu';
import { whatsappRedirect } from '../../helpers/whatsapp-redirect';
import Progress from '../../../common/components/progress/progress';
import NetworkError from '../../../common/components/network-error/network-error';
import { useGetCompanyDataQuery, CustomerData } from '../../../common/generated/graphql';
import { logError } from '../../../common/helpers/log-error';
import { sendText } from '../../../common/services/zapi/zapi.service';
import { checkHostName } from '../../../common/helpers/check-host-name';

import { ORDER_TYPE_EVENT_ID } from '../../../common/model/order-types';
import { useApolloClient } from '@apollo/client';
import { WhiteLabelHostName } from '../../../common/model/white-label-host-name';

interface Props {
  order: Order;
}

export interface OrderItemTree extends OrderItem {
  children?: Maybe<OrderItem>[];
}

export interface OrderSend {
  order: Order;
  status: string;
  orderId: string;
  orderItems: OrderItemTree[];
}

export default function ReceiptPage({ order }: Props) {
  const { loading, error, refetch, data } = useGetCustomerDataQuery();

  const {
    data: dataCompany,
    loading: loadingCompany,
    error: errorCompany,
    refetch: refetchCompany,
  } = useGetCompanyDataQuery({
    variables: { companyId: order.companyId! },
    onError: (err) => logError(err, 'Error executing Get Company Data Query'),
  });

  const location = useLocation();
  const client = useApolloClient();

  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();
  const history = useHistory();
  const params = useParams<{ orderId: string; aggregatorId: string }>();
  const [selectedEventItems, setSelectedEventItems] = useState<number[]>([]);
  const [userId, setUserId] = useState<number | undefined>();
  const [segmentedOrder, setSegmentedOrder] = useState<boolean>(false);

  const isFromDigitalMenu = Boolean(sessionStorage.getItem(DIGITAL_MENU));
  const sendMessage = Boolean(sessionStorage.getItem(SEND_MESSAGE_WHATS));
  const isStreamshop = checkHostName(WhiteLabelHostName.STREAM_SHOP);
  const digitalMenuUrl = sessionStorage.getItem(DIGITAL_MENU_URL);

  const lockMenu = isFromDigitalMenu || isStreamshop;

  const orderItems: OrderItemTree[] = makeTree((order?.items as OrderItem[]) ?? []);
  const startTime = getCreationTime(order).format('HH:mm');
  const endTime = getMaxPreparationTime(order).format('HH:mm');
  const dataStatus = getStatus(order.status!, order.orderType!);
  const dataOrderType = getOrderType(order.orderType!, isFromDigitalMenu);
  var database = firebase.database();

  const isEvent = order.orderType === ORDER_TYPE_EVENT_ID;
  const fbSegmentedFlag = `flags/segmentedOrder`;

  const handleDirectAggregator = useCallback(() => {
    if (lockMenu && digitalMenuUrl) {
      history.push(digitalMenuUrl ?? '/');
    } else {
      history.push(`/${params.aggregatorId}/aggregator`);
    }
  }, [digitalMenuUrl, history, lockMenu, params.aggregatorId]);

  const orderSend: OrderSend = {
    order: order,
    status: dataStatus.title,
    orderId: params?.orderId?.slice(3),
    orderItems,
  };

  if (isFromDigitalMenu && sendMessage) {
    sendText({
      company: dataCompany?.company as Company,
      user: data?.customerData as CustomerData,
      ...orderSend,
    });
    sessionStorage.removeItem(SEND_MESSAGE_WHATS);
  }

  useEffect(() => {
    client
      .query<GetCustomerDataQuery>({
        query: GetCustomerDataDocument,
      })
      .then(({ data }) => {
        setUserId(data?.customerData?.numericalId);
      });
  }, [client]);

  useEffect(() => {
    const dbRefFb = database.ref(fbSegmentedFlag);

    dbRefFb.on('value', (snapshot) => {
      setSegmentedOrder(snapshot.val());
    });
  }, [database, fbSegmentedFlag]);

  if (loading || loadingCompany) {
    return <Progress />;
  }

  if (error || errorCompany) {
    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados.'
        retry={error ? refetch : refetchCompany}
        error={error ?? errorCompany}
        path={location.pathname}
      />
    );
  }

  const redirectMounted = whatsappRedirect(
    params.orderId,
    dataCompany?.company?.name ?? '',
    data?.customerData?.name ?? '',
    data?.customerData?.email ?? '',
    551140201739
  );

  return (
    <Box className={classes.root} maxWidth='lg'>
      <Box className={classes.pane}>
        <MainHeader />
        <Container className={`${classes.container} ${headerSpace.measures}`}>
          <Grid container wrap='nowrap' className={classes.content}>
            <ReceiptHeader company={dataCompany?.company as Company} />
            <StatusBox
              title={dataStatus?.title!}
              value={dataStatus?.value}
              type={dataStatus?.type!}
            />
            <Grid className={classes.containerQRCode}>
              {segmentedOrder && isEvent && userId ? (
                selectedEventItems.length ? (
                  <QRCode
                    size={120}
                    value={`${params?.orderId}/${order.companyId}/${JSON.stringify(
                      selectedEventItems
                    )}`}
                  />
                ) : null
              ) : (
                <QRCode size={120} value={`${params?.orderId}/${order.companyId}`} />
              )}
            </Grid>

            <ReceiptOrderInfo
              startTime={startTime}
              endTime={endTime}
              orderCode={order?.displayCode ?? params?.orderId?.slice(3)}
              orderType={dataOrderType}
            />
            <Divider variant='middle' className={classes.divider} />
            <ReceiptOrderList
              order={orderItems}
              orderId={params?.orderId}
              selectedEventItems={selectedEventItems}
              setSelectedEventItems={setSelectedEventItems}
              userId={userId}
              isEvent={isEvent && segmentedOrder}
              status={order.status}
            />
            <ReceiptOrderValues orderData={order} />
            <Divider variant='middle' className={classes.divider} />
            <ReceiptPaymentMethod data={order?.payments} />
            <Grid container className={classes.receiptButton}>
              <Button
                id='receipt-page-goto-aggregator'
                data-testid='receipt-page-goto-aggregator'
                variant='contained'
                color='secondary'
                size='large'
                fullWidth
                className={classes.receiptButton}
                onClick={handleDirectAggregator}
              >
                {lockMenu ? 'Voltar para o menu' : 'Acompanhar pedido'}
              </Button>
              <a
                id='receipt-page-goto-about'
                data-testid='receipt-page-goto-about'
                href={redirectMounted}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.receiptButtonHelp}
              >
                Precisa de ajuda?
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
