import { useCallback, useEffect, useState, RefObject, useRef } from 'react';

export const useProductListHeaderRef = (
  id: string
): {
  appBarRef: RefObject<HTMLDivElement | undefined>;
  toBottomScrolling: boolean;
} => {
  const appBarRef = useRef<HTMLDivElement>();
  const mainHeaderComponent = document.getElementById('ref-main-header-component');
  const [last10Posx, setLast10Posx] = useState({
    index: 0,
    values: [0, 0, 0, 0, 0, 0],
  });

  const [toBottomScrolling, setToBottomScrolling] = useState(false);

  const hanleUpdatePosx = useCallback(
    (val: number) => {
      let new10Posx = last10Posx.values;

      new10Posx[last10Posx.index] = val;

      setLast10Posx({
        index: last10Posx.index > 5 ? 0 : last10Posx.index + 1,
        values: new10Posx,
      });
    },
    [last10Posx]
  );

  const handlerScroll = useCallback(() => {
    const clientReactScreen: ClientRect | undefined = appBarRef.current?.getBoundingClientRect();
    let limit = 0;

    if (mainHeaderComponent) {
      limit = getPageMaxScroll(mainHeaderComponent);
    }

    const limitChecked =
      last10Posx.values.every((posx) => posx - 20 <= limit) &&
      onCheckLimit(limit, clientReactScreen?.top);

    if (clientReactScreen) {
      hanleUpdatePosx(clientReactScreen.top);

      if (limitChecked && !toBottomScrolling) {
        setToBottomScrolling(true);
      } else if (!limitChecked && toBottomScrolling) {
        setToBottomScrolling(false);
      }
    }
  }, [hanleUpdatePosx, last10Posx.values, mainHeaderComponent, toBottomScrolling]);

  useEffect(() => {
    const element = document.getElementById(id);
    const clientReactScreen: ClientRect | undefined = element?.getBoundingClientRect();

    if (clientReactScreen) {
      if (clientReactScreen?.top === 0 && !toBottomScrolling) {
        setToBottomScrolling(true);
      }
    }
  }, [handlerScroll, id, toBottomScrolling]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);

    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [handlerScroll]);

  return {
    appBarRef,
    toBottomScrolling,
  };
};

function getPageMaxScroll(element: HTMLElement) {
  let scrollHeight = 0;
  let offsetHeight = 0;
  let clientHeight = 0;

  if (element.scrollHeight) {
    scrollHeight = element.scrollHeight;
  }

  if (element.offsetHeight) {
    offsetHeight = element.offsetHeight;
  }

  if (element.clientHeight) {
    clientHeight = element.clientHeight;
  }

  return Math.max(scrollHeight, offsetHeight, clientHeight);
}

type CheckLimit = {
  (height: number, top?: number): boolean;
};

const onCheckLimit: CheckLimit = (height, top = 0) => {
  return [top - 5, top, top + 5].some((posx) => posx < height);
};
