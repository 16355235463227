import { useCallback, useEffect, useState } from 'react';

export const useCustomerSession = () => {
  const KEY_STORAGE = 'customer-session';
  const [customerSession, setCustomerSession] = useState(process.env.NODE_ENV !== 'development');

  const setLogged = useCallback(() => {
    setCustomerSession(true);
    window.sessionStorage.setItem(KEY_STORAGE, JSON.stringify(true));
  }, []);

  useEffect(() => {
    const customerStatus: boolean = JSON.parse(
      window.sessionStorage.getItem(KEY_STORAGE) as string
    );

    if (customerStatus) {
      setCustomerSession(true);
    }
  }, []);

  return {
    customerSession,
    setLogged,
  };
};
