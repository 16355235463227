import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    simple: {
      cursor: 'default',
    },
    listItemText: {
      paddingRight: theme.spacing(1),
    },
    divider: {
      borderBottomWidth: 0,
      [theme.breakpoints.down('xs')]: {
        borderBottomWidth: 1,
      },
    },
    info: {
      boxSizing: 'border-box',
      paddingRight: theme.spacing(2),
    },
    title: {
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.palette.grey[800],
    },
    price: {
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.palette.grey[800],
    },
    description: {
      paddingTop: theme.spacing(2),
      color: theme.palette.grey[600],
    },
    wrapImg: {
      height: '5rem',
      width: '5rem',
      maxHeight: '5rem',
      maxWidth: '5rem',
      minHeight: '5rem',
      minWidth: '5rem',
    },
    productImg: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: 4,
    },
  })
);
