import React, { useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router';
import { Box, Button, Container, Badge, Grow, Slide } from '@material-ui/core';
import Progress from '../../../common/components/progress/progress';
import {
  useCreateNewShoppingCartMutation,
  GetShoppingCartProductsQuery,
  useIsAuthenticatedQuery,
  useGetShoppingCartQuery,
  Product,
} from '../../../common/generated/graphql';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useStyles } from './company-shop-button.style';
import { logError } from '../../../common/helpers/log-error';
import {
  logBeginCheckout,
  logBeginCheckoutError,
} from '../../../analytics/helpers/log-begin-checkout';
import { mountAnalyticsItemArray } from '../../../analytics/helpers/item-utils';
import { ApolloError } from '@apollo/client';
import { getCookie } from '../../../analytics/helpers/get-cookie';

interface Props {
  data: GetShoppingCartProductsQuery | undefined;
  loading: boolean;
}

export default function CompanyShopButton({ data, loading }: Props): React.ReactElement {
  const classes = useStyles();
  let { companyId, aggregatorId, brandId } = useParams<{
    companyId: string;
    aggregatorId: string;
    brandId: string;
  }>();

  const { pathname } = useLocation();
  const { push } = useHistory();

  const [createNewCart] = useCreateNewShoppingCartMutation({
    variables: { companyId: Number(companyId) },
    onError: (err) => {
      logBeginCheckoutError({ errorCode: 43, errorMessage: err.message });
      throw new ApolloError(err);
    },
  });

  const { data: dataAuth, loading: loadingAuth } = useIsAuthenticatedQuery({
    onError: (err) => {
      logError(err, 'Error executing is authenticated query');
      logBeginCheckoutError({ errorMessage: err.message, errorCode: 44 });
    },
  });

  const { data: shoppingCartQuery } = useGetShoppingCartQuery({
    variables: { companyId: Number(companyId) },
    onError: (err) => {
      logBeginCheckoutError({ errorCode: 45, errorMessage: err.message });
    },
  });

  const onCartClick = useCallback(() => {
    createNewCart();
    const products = shoppingCartQuery?.shoppingCart?.products ?? [];
    logBeginCheckout({
      brandId: brandId,
      companyId: companyId,
      value: Number(shoppingCartQuery?.shoppingCart?.orderTotal),
      items: mountAnalyticsItemArray(products as Product[]),
    });

    if (dataAuth?.isAuthenticated) {
      push(`/${aggregatorId}/${brandId}/${companyId}/cart`, {
        from: pathname,
      });
    } else {
      if (
        getCookie('campaign_token') ||
        window.dataLayer.find((i) => i.event === 'join_campaign')
      ) {
        push(`/${aggregatorId}/redirect-page-login`, {
          from: pathname,
        });
      } else {
        push(`/${aggregatorId}/login`, {
          from: pathname,
        });
      }
    }
  }, [
    createNewCart,
    dataAuth,
    push,
    aggregatorId,
    brandId,
    companyId,
    pathname,
    shoppingCartQuery,
  ]);

  if (loading || loadingAuth) {
    return (
      <Container>
        <Progress />
      </Container>
    );
  }

  const cartProductsNumber = data?.shoppingCartProducts?.length;

  if (!cartProductsNumber) {
    return <span className={classes.empty} id='company-shop-button-empty' />;
  }

  return (
    <Grow in={!!cartProductsNumber}>
      <Box id='company-shop-button' className={classes.buttonContainer}>
        <Container>
          <Button
            id='cart-btn'
            variant='contained'
            color='secondary'
            className={classes.button}
            onClick={onCartClick}
          >
            <div className={classes.buttonContent}>
              <Slide direction='right' in={!!cartProductsNumber}>
                <Badge badgeContent={cartProductsNumber} color='primary'>
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </Slide>
              <div className={classes.textLabel}>Meu Pedido</div>
            </div>
          </Button>
        </Container>
      </Box>
    </Grow>
  );
}
