import moment from 'moment';
import { checkHostName } from '../../../helpers/check-host-name';
import { BrandHighlights } from '../../../model/brand-highlights';

type Params = {
  data: BrandHighlights;
  currentDate: Date;
};

export const validateViewBanner = ({ data, currentDate }: Params) => {
  const inWhiteLabel =
    data.whitelabels.filter((whiteLabel) => checkHostName(whiteLabel)).length > 0;

  const isCurrentDateInBetween = moment(currentDate.toUTCString()).isBetween(
    moment(data.startAt),
    moment(data.endAt),
    null,
    '[]'
  );

  return inWhiteLabel && isCurrentDateInBetween;
};
