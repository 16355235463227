import React from 'react';
import clsx from 'clsx';
import { AppBar, Tabs, Tab, Box, Typography, Divider } from '@material-ui/core';
import { CompanyNavigationProps } from '../../models/company-navigation-props';
import InputSearch from '../../../common/components/input-search/input-search';
import { useStyles } from './company-navigation.style';
import { useProductListHeaderRef } from '../../hooks/use-product-list-header-ref';
import { useProductFilterQuery } from '../../../common/generated/graphql';
import { useGetCompanyDataFragment } from '../../../common/hooks/use-get-company-data-fragment';
import ShareSimpleMenu from '../share-simple-menu/share-simple-menu';
import { CompanyPage } from '../../models/company-page';
import { useParams } from 'react-router-dom';
import { logSelectItemListCategory } from '../../../analytics/helpers/log-select-item-list-category';

interface Params {
  pageType: string;
  aggregatorId: string;
  brandId: string;
  companyId: string;
}

export default function CompanyNavigation(props: CompanyNavigationProps): React.ReactElement {
  const companyData = useGetCompanyDataFragment();
  const { pageType, brandId, companyId } = useParams<Params>();

  const classes = useStyles();
  const appBarId = 'product-list-header-app-bar';
  const { appBarRef, toBottomScrolling } = useProductListHeaderRef(appBarId);
  const { data } = useProductFilterQuery();
  const isSimpleMenu = pageType === CompanyPage.Simple;
  const checkTabs = props.categories?.length && !(!toBottomScrolling && data?.productFilter.active);

  const orientation = props.isDesktop ? 'vertical' : 'horizontal';

  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.defaultNavigation]: !isSimpleMenu,
        [classes.rootOnVisible]: data?.productFilter.active && !toBottomScrolling,
      })}
      ref={appBarRef}
      elevation={0}
      id={appBarId}
    >
      <Box
        className={clsx({
          [classes.searchWrap]: !props.isDesktop,
          [classes.searchWrapOnScroll]: toBottomScrolling,
        })}
      >
        <Box
          id='product-list-header-scroll'
          className={clsx(
            {
              [classes.searchContainer]: props.isDesktop,
            },
            {
              [classes.searching]: !props.isDesktop,
            },
            {
              [classes.searchingOnScroll]: toBottomScrolling,
            },
            {
              [classes.searchingOnVisible]: data?.productFilter.active,
            }
          )}
        >
          {props.isDesktop && !toBottomScrolling && <Divider className={classes.divider} />}
          <InputSearch
            id='input-search-menu-scroll'
            placeholder={'Buscar no cardápio'}
            isDesktop={props.isDesktop}
            withIcon={props.isDesktop}
          />
          {props.isDesktop && toBottomScrolling && <Divider className={classes.divider} />}
          {toBottomScrolling && (
            <Box className={classes.titleWrap}>
              <Typography id='input-search-title' className={classes.title} variant='h6'>
                {companyData?.name}
              </Typography>
              {isSimpleMenu && <ShareSimpleMenu reduced />}
            </Box>
          )}
        </Box>
        {checkTabs && (
          <Tabs
            id='navigation-tab'
            orientation={orientation}
            className={clsx({
              [classes.tabsWhenSearchIsVisible]: data?.productFilter.active && toBottomScrolling,
            })}
            value={props.value}
            onChange={props.onChange}
            indicatorColor='secondary'
            textColor='secondary'
            variant='scrollable'
            aria-label='barra de navegação com rolagem lateral'
            selectionFollowsFocus={true}
            classes={{ indicator: props.isDesktop ? classes.tabIndicatorDesktop : '' }}
          >
            {props.categories &&
              props.categories.map((productCategory) => (
                <Tab
                  key={`Fragment-tab-${productCategory.category.id}`}
                  label={productCategory.category.name}
                  onClick={() => {
                    logSelectItemListCategory({
                      brand_id: Number(brandId),
                      company_id: Number(companyId),
                      item_category: productCategory.category.name as string,
                    });

                    props.onClick(productCategory.category.id);
                  }}
                  classes={
                    props.isDesktop
                      ? {
                          wrapper: classes.tabDesktop,
                          root: classes.tabDesktopRoot,
                        }
                      : {}
                  }
                  {...props.a11yProps(productCategory.category.id).tab}
                />
              ))}
          </Tabs>
        )}
      </Box>
    </AppBar>
  );
}
