import { OrderItemTree } from './../../../../receipt/components/receipt-page/receipt-page';

export const mountProducts = (orderItems: OrderItemTree, prefix = '1x '): any => {
  let res = '\xa0\xa0';
  if (orderItems.children?.length && orderItems.productType === 'CHOOSABLE') {
    res += '';
  } else {
    res += prefix + orderItems.name + '\n';
  }

  if (orderItems.children && orderItems.children.length > 0) {
    res += orderItems.children
      .map((item: any) => {
        return mountProducts(item, '    - ');
      })
      .join('');
  }
  return res;
};
