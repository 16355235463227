import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.additionalColors.aggregators?.background,
      paddingTop: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(2),
      background: theme.palette.common.white,
      borderRadius: '1rem',
    },
    pane: {
      overflowY: 'scroll',
    },
  })
);
