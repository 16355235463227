import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusContainer: {
      padding: theme.spacing(2, 0, 3, 0),
    },
    statusBox: {
      width: '100%',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
    },
    statusTitle: {
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.palette.success.darkest,
    },
    statusText: {
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
    pending: {
      background: theme.palette.info.superLightest,
      color: theme.palette.info.darkest,
    },
    warning: {
      background: theme.palette.warning.superLightest,
      color: theme.palette.warning.darkest,
    },
    success: {
      background: theme.palette.success.superLightest,
      color: theme.palette.success.darkest,
    },
    error: {
      background: theme.palette.error.superLightest,
      color: theme.palette.error.darkest,
    },
  })
);
