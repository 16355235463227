import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';
import { johnnysTasteTheme } from '../themes/johnnys-taste.theme';

export const johnnysTasteConfig: AppConfig = {
  appName: 'johnnys-taste',
  appTitle: "Johnny's Taste",
  appDescription: "Peça aqui no Johnn's Taste",
  aggregator: 916,
  brand: 920,
  theme: johnnysTasteTheme,
  faviconPath: '/favicons/favicon-johnnys-taste.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Santo Amaro
    latitude: -23.625601,
    longitude: -46.697612,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
