import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';
import { bakedPotatoTheme } from '../themes/baked-potao.theme';

export const bakedPotatoConfig: AppConfig = {
  appName: 'baked-potato',
  appTitle: 'Baked Potato',
  appDescription: 'Peça aqui na Baked Potato.',
  aggregator: 1370,
  brand: 3,
  theme: bakedPotatoTheme,
  faviconPath: '/favicons/favicon-baked-potato.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    removeLogoFromTheApp: false,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/baked-potato.png',
  },
};
