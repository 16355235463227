import { CardBrand } from '../generated/graphql';

export function fixDuplicatedPayment(cards: CardBrand[]): CardBrand[] {
  return cards.reduce((previous: CardBrand[], current) => {
    const amex = current.code?.includes('amex') || current.code?.includes('americ');
    const diners = current.code?.includes('diners');
    const hiper = current.code?.includes('hiper');
    const master = current.code?.includes('master');
    const visa = current.code?.includes('visa');
    const vr = current.code?.includes('vr');
    const ticket = current.code?.includes('ticket');
    const sodexo = current.code?.includes('sodexo');
    const alelo = current.code?.includes('alel');
    const ben = current.code?.includes('ben');
    const elo = current.code === 'elo' || current.code?.includes('_elo');
    const aura = current.code?.includes('aura');
    const discover = current.code?.includes('discover');
    const valecard = current.code?.includes('valecard');
    const cielo = current.code?.includes('cielo_pay');

    if (amex) {
      if (checkCard(previous, 'amex')) return previous;
      if (checkCard(previous, 'americ')) return previous;
    } else if (diners && checkCard(previous, 'diners')) return previous;
    else if (hiper && checkCard(previous, 'hiper')) return previous;
    else if (master && checkCard(previous, 'master')) return previous;
    else if (visa && checkCard(previous, 'visa')) return previous;
    else if (vr && checkCard(previous, 'vr')) return previous;
    else if (ticket && checkCard(previous, 'ticket')) return previous;
    else if (sodexo && checkCard(previous, 'sodexo')) return previous;
    else if (alelo && checkCard(previous, 'alel')) return previous;
    else if (ben && checkCard(previous, 'ben')) return previous;
    else if (elo && checkCard(previous, 'elo')) return previous;
    else if (aura && checkCard(previous, 'aura')) return previous;
    else if (discover && checkCard(previous, 'discover')) return previous;
    else if (valecard && checkCard(previous, 'valecard')) return previous;
    else if (cielo && checkCard(previous, 'cielo_pay', true)) return previous;

    return [...previous, current];
  }, []);
}

function checkCard(cards: CardBrand[], cardCode: string, exact?: boolean): boolean {
  return cards.some((card) => {
    return exact
      ? card.code?.includes(cardCode)
      : card.code?.includes(cardCode) || cardCode?.includes(card.code as string);
  });
}
