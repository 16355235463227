import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { getAppConfig } from '../../helpers/configs';
import { useStyles } from './terms-dialog.style';

interface Props {
  open: boolean;
  onAcceptClick?: () => void;
  hideTitle?: boolean;
  onCloseClick?: () => void;
  title?: string;
  text?: string;
}

export default function TermsDialog(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={props.open}
      onClose={props.onCloseClick}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth='lg'
      fullWidth={true}
      scroll={'paper'}
      fullScreen={fullScreen}
    >
      {!props.hideTitle && <DialogTitle>{getDialogTitle(classes)}</DialogTitle>}
      <DialogContent dividers={true}>
        <DialogContentText component='div' tabIndex={-1} className={classes.termsContainer}>
          <Box>
            <Typography id='terms-dialog-title' variant='h4' className={classes.title}>
              {props.title}
            </Typography>
          </Box>
          {props.text ? (
            <pre id='terms-dialog-text' className={classes.contentText}>
              {props.text}
            </pre>
          ) : (
            <Grid
              id='no-content-text'
              container
              justifyContent='center'
              alignItems='center'
              className={classes.noContent}
            >
              <Typography className={classes.noContentText}>
                Erro ao carregar os termos, por favor, feche a janela e tente novamente
              </Typography>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onCloseClick && (
          <Button
            id='close-button'
            className={classes.button}
            color='primary'
            variant='text'
            onClick={props.onCloseClick}
          >
            Fechar
          </Button>
        )}
        {props.onAcceptClick && (
          <Button
            id='accept-button'
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={props.onAcceptClick}
          >
            Aceitar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function getDialogTitle(classes: any) {
  const config = getAppConfig();
  const styleVariant = 'body1';

  if (config.appName === 'onyo') {
    return (
      <Box className={classes.dialogTitle}>
        <Box className={classes.logoContainer}>
          <img className={classes.logo} src='/favicons/favicon-pede-pronto.ico' alt='Pede Pronto' />
        </Box>
        <Box>
          <Typography className={classes.titleText} variant={styleVariant}>
            A nossa marca mudou. Agora somos Pede Pronto.
          </Typography>
          <Typography className={classes.titleText} variant={styleVariant}>
            Com isso, nossos termos e condições mudaram também.
          </Typography>
          <Typography className={classes.titleText} variant={styleVariant}>
            Confira abaixo e aceite para continuar usando o aplicativo.
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className={classes.dialogTitle}>
        <Box>
          <Typography className={classes.titleText} variant={styleVariant}>
            Nossos termos e condições mudaram.
          </Typography>
          <Typography className={classes.titleText} variant={styleVariant}>
            Confira abaixo e aceite para utilizar o aplicativo.
          </Typography>
        </Box>
      </Box>
    );
  }
}
