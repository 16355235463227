import { UnionCardBrand } from '../model/card-brand';
import { RemoteCard } from '../generated/graphql';

export function filterRemoteCards(
  data: Array<any>,
  supportedPaymentMethods: Set<UnionCardBrand>
): RemoteCard[] {
  let result: RemoteCard[] = [];
  if (!data) {
    return result;
  }

  for (const card of data) {
    if (card.paymentMethod !== 'offline') {
      if (supportedPaymentMethods.size === 0 && card.status === 'validated') {
        const newCard = createCard(card);
        result.push(newCard);
      } else if (supportedPaymentMethods.has(card.cardBrand) && card.status === 'validated') {
        const newCard = createCard(card);
        result.push(newCard);
      }
    }
  }

  return result;
}

function createCard(card: any) {
  return {
    ...card,
    __typename: 'RemoteCard',
    id: btoa(`RemoteCard:${card.numericalId}`),
    backendId: card.id,
  };
}
