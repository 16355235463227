import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'select_menu_options';

export function logSelectMenuOptions() {
  logAnalytics({
    event,
  });
}

export function logSelectMenuOptionsError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
