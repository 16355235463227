import React from 'react';
import * as ReactDOM from 'react-dom/client';

import 'core-js/features/promise';
import 'firebase/analytics';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Cart } from './cart/components/cart/cart';
import withErrorBoundary from './common/components/error-boundary/error-boundary';
import CustomRoute from './common/components/custom-route/custom-route';
import Progress from './common/components/progress/progress';
import Startup from './common/components/startup/startup';
import ThemeHandler from './common/components/theme-handler/theme-handler';
import { createApolloClient } from './config/apollo-client';
import { initFirebase } from './config/firebase';
import { initSentry } from './config/sentry';
import { initHotjar } from './config/hotjar';
import Countdown from './countdown/components/countdown/countdown';
import HomeContainer from './home/components/home-container/home-container';
import * as serviceWorker from './service-worker';
import { initAxios } from './config/axios';
import notFoundHandler from './common/components/not-found-handler/not-found-handler';
import { LastLocationProvider } from 'react-router-last-location';
import { globalConfig } from './config/global-config';
import PageSetup from './common/components/page-setup/page-setup';
import { getAppConfig } from './common/helpers/configs';
import TermsDialogContainer from './common/components/terms-dialog-container/terms-dialog-container';
import AggregatorDetails from './aggregator-home/components/aggregator-details/aggregator-details';
import ReceiptHome from './receipt/components/receipt/receipt';
import CompanyHome from './company-home/components/company-home/company-home';
import Notify from './common/components/notify/notify';
import { TagVersion } from './common/components/tag-version/tag-version';
import './index.scss';

globalConfig();
initSentry();
initAxios();
initFirebase();
initHotjar();

const appConfig = getAppConfig();
const apolloClient = createApolloClient();

const Welcome = React.lazy(() => import('./auth/components/welcome/welcome'));
const Validate = React.lazy(() => import('./validate/components/validate'));
const SocialLogin = React.lazy(() => import('./auth/components/social-login/social-login'));
const Login = React.lazy(() => import('./auth/components/login/login'));
const UserProfile = React.lazy(() => import('./user-profile/components/user-profile'));
const CompleteUserProfile = React.lazy(
  () => import('./complete-user-profile/components/complete-user-profile')
);
const EnterpriseUserForm = React.lazy(
  () => import('./enterprise-user/components/enterprise-user-form')
);
const ResetPassword = React.lazy(() => import('./auth/components/reset-password/reset-password'));
const RedirectPage = React.lazy(
  () => import('./auth/components/campaign-redirect-page/campaign-redirect-page')
);
const RedirectPageLogin = React.lazy(
  () => import('./auth/components/campaign-redirect-page/campaign-login')
);

const About = React.lazy(() => import('./about/components/about/about'));
const CouponsContainer = React.lazy(
  () => import('./coupons/components/coupons-container/coupons-container')
);

const Orders = React.lazy(() => import('./orders/components/list-orders/list-orders'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={apolloClient}>
    <PageSetup>
      <BrowserRouter>
        <LastLocationProvider>
          <Startup>
            <React.Suspense fallback={<Progress />}>
              <ThemeHandler>
                <TermsDialogContainer />
                <Switch>
                  <Route exact={true} path='/'>
                    <Redirect to={`/${appConfig.aggregator}/aggregator`} />
                  </Route>
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/aggregator'
                    component={withErrorBoundary(HomeContainer)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/highlights/:companyNameStartsWith'
                    component={withErrorBoundary(HomeContainer)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/aggregator/:numericalId/details'
                    component={withErrorBoundary(AggregatorDetails)}
                  />
                  <CustomRoute
                    isPrivate
                    exact={true}
                    path='/:aggregatorId/orders'
                    component={withErrorBoundary(Orders)}
                  />
                  <CustomRoute
                    isPrivate
                    exact={true}
                    path='/:aggregatorId/receipt/:orderId'
                    component={withErrorBoundary(ReceiptHome)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/:brandId/:companyId/company'
                    component={withErrorBoundary(CompanyHome)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/:brandId/:companyId/:pageType/simple-company-menu'
                    component={withErrorBoundary(CompanyHome)}
                  />
                  <CustomRoute
                    isPrivate
                    exact={true}
                    path='/:aggregatorId/:brandId/:companyId/cart/'
                    component={withErrorBoundary(Cart)}
                  />
                  <CustomRoute
                    isPrivate
                    exact={true}
                    path='/:aggregatorId/:brandId/:companyId/countdown'
                    component={withErrorBoundary(Countdown)}
                  />
                  <CustomRoute
                    isPrivate
                    exact={true}
                    path='/:aggregatorId/coupons'
                    component={withErrorBoundary(CouponsContainer)}
                  />
                  <Route exact={true} path='/welcome'>
                    <Redirect to={`/${appConfig.aggregator}/welcome`} />
                  </Route>
                  <CustomRoute
                    isAuthPage
                    exact={true}
                    path='/:aggregatorId/welcome'
                    component={withErrorBoundary(Welcome)}
                  />

                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/validate/:userEmail'
                    component={withErrorBoundary(Validate)}
                  />

                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/social-login'
                    component={withErrorBoundary(SocialLogin)}
                  />

                  <Route exact={true} path='/login'>
                    <Redirect to={`/${appConfig.aggregator}/login`} />
                  </Route>
                  <CustomRoute
                    isAuthPage
                    exact={true}
                    path='/:aggregatorId/login'
                    component={withErrorBoundary(Login)}
                  />

                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/user-profile'
                    component={withErrorBoundary(UserProfile)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/complete-user-profile'
                    component={withErrorBoundary(CompleteUserProfile)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/:aggregatorId/discount-form'
                    component={withErrorBoundary(EnterpriseUserForm)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/reset-password'
                    component={withErrorBoundary(ResetPassword)}
                  />
                  <CustomRoute
                    exact={true}
                    path='/redirect-page/:token'
                    component={withErrorBoundary(RedirectPage)}
                  />
                  <CustomRoute
                    isAuthPage
                    exact={true}
                    path='/:aggregatorId/redirect-page-login'
                    component={withErrorBoundary(RedirectPageLogin)}
                  />

                  <CustomRoute component={withErrorBoundary(About)} path='/about' />

                  <Route component={withErrorBoundary(notFoundHandler)} />
                </Switch>
                <Notify />
              </ThemeHandler>
            </React.Suspense>
            <TagVersion />
          </Startup>
        </LastLocationProvider>
      </BrowserRouter>
    </PageSetup>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
