import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    imgContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    img: {
      height: theme.spacing(16),
      width: 'auto',
    },
  })
);
