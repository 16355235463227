import { LoginMutationVariables, Result } from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { logError } from '../../helpers/log-error';
import { getEndpoints } from '../../helpers/configs';
import { logLoginError } from '../../../analytics/helpers/log-login';

export async function login(
  root: any,
  variables: LoginMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
): Promise<Result> {
  const __typename = 'Result';
  let success = false;
  let errorMsg = 'Desculpe, aconteceu um erro inesperado. Por favor, tente novamente.';

  const uri = `v1/mobile/user/login`;
  try {
    const endpoints = getEndpoints();
    await axios.post(`${endpoints.api}${uri}`, {
      email: variables.input.email,
      password: variables.input.password,
    });

    success = true;
    errorMsg = '';
  } catch (error) {
    success = false;
    const err = error as AxiosError;
    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      console.warn('Error in Login Call', err);

      const errorType = err.response.data.error;
      logLoginError({ errorMessage: errorType, errorCode: 83 });
      switch (errorType) {
        case 'onyo.user.invalid-data':
          errorMsg = 'E-mail ou senha incorretos. Por favor, confira e tente de novo.';
          break;
        case 'onyo.user.max-login-attempts':
          errorMsg =
            'Desculpe você atingiu o número máximo de tentativas de login e a sua conta foi bloqueada. Por favor vá em "esqueci minha senha" para definir uma nova.';
          break;
        default:
          logError(error, 'Error in Login Call');
          break;
      }
    } else {
      logError(error, 'Error in Login Call');
      logLoginError({ errorCode: 84 });
    }
  }

  return { __typename, success, errorMsg };
}
