import {
  GetAllShoppingCartsDocument,
  GetAllShoppingCartsQuery,
  Product,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
  ShoppingCart,
  ShoppingCartFragment,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';
import { loadCartsFromCache } from '../../browser-cache/shopping-cart/load-carts-from-cache';

export async function loadShoppingCartsFromBrowserCache(
  root: any,
  variables: any,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const shoppingCarts: ShoppingCart[] = await loadCartsFromCache(variables?.cacheName);
  if (shoppingCarts && shoppingCarts.length > 0) {
    for (const shoppingCart of shoppingCarts) {
      shoppingCart.products.map((product) =>
        loadProducts(product, context.cache, context.getCacheKey)
      );
    }

    writeShoppingCartToCache(shoppingCarts, context.cache);
  }

  return true;
}

function loadProducts(product: Product | null, cache: InMemoryCache, getCacheKey: any) {
  if (!product) {
    return;
  }

  if (product.subproducts) {
    for (const subproduct of product.subproducts) {
      loadProducts(subproduct, cache, getCacheKey);
    }
  }

  writeProductToCache(product, cache, getCacheKey);
}

function writeProductToCache(product: Product, cache: InMemoryCache, getCacheKey: any) {
  cache.writeFragment<ProductAndSubproductsFragment>({
    fragment: ProductAndSubproductsFragmentDoc,
    fragmentName: 'productAndSubproducts',
    id: getCacheKey({ id: product.id, __typename: 'Product' }),
    data: product as ProductAndSubproductsFragment,
  });
}

function writeShoppingCartToCache(shoppingCarts: ShoppingCart[], cache: InMemoryCache) {
  cache.writeQuery<GetAllShoppingCartsQuery>({
    query: GetAllShoppingCartsDocument,
    data: { shoppingCarts: shoppingCarts as ShoppingCartFragment[] },
  });
}
