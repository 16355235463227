import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.tooltip,
      padding: theme.spacing(0.5, 1.5),
      position: 'fixed',
      right: 0,
      bottom: 0,
      background: theme.palette.info.dark,
      borderRadius: '0.25rem 0 0 0',
    },
    text: {
      color: theme.palette.info.contrastText,
    },
    empty: {
      visibility: 'hidden',
      display: 'none',
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
  })
);
