import { AppConfig } from '../../../common/model/config';
import { onyoTheme } from '../themes/onyo.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const porDentroConfig: AppConfig = {
  appName: 'por-dentro',
  appTitle: 'Por Dentro',
  appDescription: 'Peça aqui nos Estúdios Globo.',
  aggregator: 31,
  brand: 426,
  theme: onyoTheme,
  faviconPath: '/favicons/por-dentro.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
