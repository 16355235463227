import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      marginTop: theme.spacing(1),
    },
    item: {
      padding: theme.spacing(1),
    },
    infiniteScroll: {
      overflow: 'hidden',
    },
  })
);
