import { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { Close as CloseIcon } from '@material-ui/icons';

import { useStyles } from './event-confirmation-dialog.style';
import { useMainHeaderSpace } from '../../../common/hooks/use-header-space';
import { EventConfirmationModalContext } from '../../contexts/eventConfirmationModal';
export interface EventConfirmationDialogProps {
  cancelLabel?: string;
  confirmLabel?: string;
  children: React.ReactNode;
  companyLogo: string;
  companyName: string;
}

export default function EventConfirmationDialog({
  cancelLabel = 'Cancelar',
  confirmLabel = 'Confirmar',
  companyLogo,
  companyName,
  children,
}: EventConfirmationDialogProps) {
  const classes = useStyles();
  const headerSpace = useMainHeaderSpace();
  const { onClose, active, onConfirm } = useContext(EventConfirmationModalContext);

  return (
    <Dialog
      id='event-confirmation-dialog'
      onClose={onClose}
      open={active}
      classes={{
        container: classes.container,
        paper: `${classes.paper} ${headerSpace.smallDialog}`,
      }}
    >
      <Box className={classes.close}>
        <IconButton
          onClick={onClose}
          className={classes.btnIcon}
          aria-label='Fechar modal'
          id='close-event-confirmation-dialog'
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>

      <img src={companyLogo} className={classes.logo} alt={`logo ${companyName}`} />

      <Typography align='center' className={classes.description}>
        {children}
      </Typography>

      <Box className={classes.buttonsContainer}>
        <Button id='button-actions-cancel' className={classes.button} onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button id='button-actions-confirm' className={classes.button} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Box>
    </Dialog>
  );
}
