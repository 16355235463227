import { AppConfig } from '../../../common/model/config';
import { maniaDeChurrascoTheme } from '../themes/mania-de-churrasco.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const maniaDeChurrascoConfig: AppConfig = {
  appName: 'maniade-de-churrasco',
  appTitle: 'Mania de Churrasco',
  appDescription: 'Peça aqui no Mania de Churrasco.',
  aggregator: 1279,
  brand: 23,
  theme: maniaDeChurrascoTheme,
  faviconPath: '/favicons/favicon-mania-de-churrasco.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
  },
};
