import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { UnionCardBrand } from '../model/card-brand';
import {
  CartPaymentStatus,
  CartPaymentFlowDocument,
  CartPaymentFlowQuery,
} from '../generated/graphql';

interface UseUpdateCartPayment {
  (): {
    updateCartPayment: (status: CartPaymentStatus, cardBrand?: UnionCardBrand) => void;
  };
}

export const useUpdateCartPayment: UseUpdateCartPayment = () => {
  const client = useApolloClient();

  const updateCartPayment = useCallback(
    (status: CartPaymentStatus, cardBrand?: UnionCardBrand) => {
      client.writeQuery<CartPaymentFlowQuery>({
        query: CartPaymentFlowDocument,
        data: {
          cartPaymentFlow: {
            __typename: 'CartPaymentFlow',
            status: status,
            cardBrand: cardBrand ?? '',
          },
        },
      });
    },
    [client]
  );

  return { updateCartPayment };
};
