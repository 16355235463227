import {
  AggregatorFilterOption,
  DeliveryAddressInput,
  GetAggregatorCompaniesQueryVariables,
  OrderTypeFilterOptionEnum,
} from '../../../../common/generated/graphql';

interface PropsQuery {
  aggregatorId: number;
  latitude: number;
  longitude: number;
  cursor?: string;
  first: number;
  maximumDistanceKilometers: number;
  isDelivery?: boolean;
  companyNameStartsWith?: string;
  deliveryAddress: DeliveryAddressInput;
}

export function getQueryVariables({
  aggregatorId,
  latitude,
  longitude,
  cursor,
  first,
  maximumDistanceKilometers,
  isDelivery,
  companyNameStartsWith,
  deliveryAddress,
}: PropsQuery) {
  const queryVariables: GetAggregatorCompaniesQueryVariables = {
    aggregatorId,
    latitude,
    longitude,
    first,
    cursor,
    companyNameStartsWith,
    deliveryAddress,
  };

  if (queryVariables.latitude && queryVariables.longitude) {
    if (isDelivery) {
      queryVariables.filterBy = AggregatorFilterOption.DeliveryArea;
      queryVariables.orderType = [OrderTypeFilterOptionEnum.Delivery];
    } else {
      queryVariables.maximumDistanceKilometers = maximumDistanceKilometers;
    }
  }

  return queryVariables;
}
