import { AppConfig } from '../../../common/model/config';
import { pedeProntoTheme } from '../themes/pede-pronto.theme';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';

export const pedeProntoConfig: AppConfig = {
  appName: 'pede-pronto',
  appTitle: 'Pede Pronto',
  appDescription: 'Peça aqui com o Pede Pronto.',
  aggregator: 820,
  takeOutAggregatorId: 21,
  brand: 4,
  theme: pedeProntoTheme,
  faviconPath: '/favicons/favicon-pede-pronto.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Avenida Paulista
    latitude: -23.5631043,
    longitude: -46.6565712,
  },
  featureFlags: {
    firebaseLoginOnly: false,
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: true,
    filterByDeliveryArea: true,
    useLocalAggregatorHeaderImg: true,
    localAggregatorImgPath: '/aggregator-headers/aggregator-header-pede-pronto.png',
  },
};
