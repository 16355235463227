import { UnionCardBrand } from '../../common/model/card-brand';

export function isDefaultCard(card: UnionCardBrand): boolean {
  return (
    card === 'ticket' ||
    card === 'offline_ticket' ||
    card === 'ben' ||
    card === 'offline_ben' ||
    card === 'aura' ||
    card === 'offline_aura' ||
    card === 'sodexo' ||
    card === 'offline_sodexo' ||
    card === 'hiper' ||
    card === 'offline_hiper'
  );
}
