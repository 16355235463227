import { BundleName, ParamName } from '../model/analytics-config';
import { logAnalytics } from './log-analytics';
import { AnalyticsErrorAux, logAnalyticsError } from './log-analytics-error';

const event = 'logout';

export function logLogout() {
  logAnalytics({
    event,
    [BundleName.PROPERTIES]: {
      [ParamName.IS_LOGGED]: 'false',
    },
  });
}

export function logLogoutError({ errorMessage, errorCode }: AnalyticsErrorAux) {
  logAnalyticsError({ event, errorMessage, errorCode });
}
