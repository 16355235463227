import { AppConfig } from '../../../common/model/config';
import { ORDER_TYPE_DELIVERY } from '../../../common/model/order-types';
import { piadinaTheme } from '../themes/piadina.theme';

export const piadinaConfig: AppConfig = {
  appName: 'piadina',
  appTitle: 'Piadina',
  appDescription: 'Peça aqui no Piadina Romagnola.',
  aggregator: 1270,
  brand: 33,
  theme: piadinaTheme,
  faviconPath: '/favicons/favicon-piadina.ico',
  defaultOrderType: ORDER_TYPE_DELIVERY,
  defaultLocation: {
    // Vila Olímpia
    latitude: -23.5668031,
    longitude: -46.7300199,
  },
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: false,
    gmapsAddress: true,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
