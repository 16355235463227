import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    imgContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    img: {
      height: '8rem',
      width: 'auto',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    suggestRestaurantButton: {
      textTransform: 'capitalize',
    },
  })
);
