import React from 'react';
import { UnionCardBrand } from '../../model/card-brand';
import { UnionSizeOptions } from '../../model/theme-options';
import {
  IconAmex,
  IconDinersClub,
  IconElo,
  IconMastercard,
  IconVisa,
  IconAlelo,
  IconMoney,
  IconCieloPay,
  IconVr,
} from '../brand-icon';
import Icon from '../icon/icon';

interface Props {
  card: UnionCardBrand;
  size: UnionSizeOptions;
  id?: string;
}

export default function SelectCardBrandIcon(props: Props) {
  let { card, ...newProps } = props;

  if (!newProps.id) {
    newProps.id = `icon-brand-${card}`;
  }

  switch (card) {
    case 'amex':
    case 'offline_americ_c':
    case 'offline_americ_d':
      return <IconAmex {...newProps} />;
    case 'diners':
    case 'offline_diners_c':
    case 'offline_diners_d':
      return <IconDinersClub {...newProps} />;
    case 'elo':
    case 'offline_elo_c':
    case 'offline_elo_d':
      return <IconElo {...newProps} />;
    case 'mastercard':
    case 'offline_master_c':
    case 'offline_master_d':
      return <IconMastercard {...newProps} />;
    case 'visa':
    case 'offline_visa_c':
    case 'offline_visa_d':
      return <IconVisa {...newProps} />;
    case 'alel0':
    case 'offline_alel0':
    case 'alelo':
    case 'offline_alelo':
      return <IconAlelo {...newProps} />;
    case 'dinheiro':
    case 'offline_dinheiro':
      return <IconMoney {...newProps} />;
    case 'cielo_pay':
    case 'offline_cielo_pay':
      return <IconCieloPay {...newProps} />;
    case 'vr':
    case 'offline_vr':
      return <IconVr {...newProps} />;
    default:
      return <Icon name='creditCardLined' {...newProps} />;
  }
}
