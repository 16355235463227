import { useState, useCallback } from 'react';

interface UseProductQuantity {
  (): {
    value: number;
    addOne: VoidFunction;
    reduceOne: VoidFunction;
    clearQuantity: VoidFunction;
  };
}

export const useProductQuantity: UseProductQuantity = () => {
  const [value, setQuantity] = useState(1);

  const addOne = useCallback(() => {
    if (value < 99) {
      setQuantity(value + 1);
    }
  }, [value, setQuantity]);

  const reduceOne = useCallback(() => {
    if (value > 1) {
      setQuantity(value - 1);
    }
  }, [value, setQuantity]);

  const clearQuantity = useCallback(() => {
    setQuantity(1);
  }, [setQuantity]);

  return {
    value,
    addOne,
    reduceOne,
    clearQuantity,
  };
};
