import { SHOPPING_CART_CACHE } from '../../model/caches';
import 'firebase/analytics';
import firebase from 'firebase/app';

export async function removeCartFromCache(companyId: number) {
  if (typeof caches === 'undefined') {
    firebase.analytics().logEvent('CacheStorageAPI_Unavailable');
    return;
  }

  const cache = await caches.open(SHOPPING_CART_CACHE);
  cache.delete(`/shopping-cart/${companyId}`);
}
