import { ThemeOptions } from '@material-ui/core/styles/createTheme';

export const pedeProntoTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#35007D',
    },
    secondary: {
      main: '#DE4A82',
    },
    tertiary: {
      main: '#E2E543',
    },
    additionalColors: {
      promoHighligts: '#3d0180',
    },
  },
};
