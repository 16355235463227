import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5, 3),
      minHeight: '2rem',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    empty: {
      display: 'none',
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
  })
);
