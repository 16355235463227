import React from 'react';
import {
  Box,
  createStyles,
  Divider,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { formatPrice } from '../../../common/helpers/format-price.helper';

interface Props {
  productCacheId: string;
  productName: string;
  subproductsDescription: string;
  price: number;
  showDivider: boolean;
  onDeleteClick: (productCacheKey: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: 'block',
    },
    descriptionText: {
      height: '2.5rem',
      overflow: 'hidden',
    },
    noWrapText: {
      whiteSpace: 'nowrap',
    },
    deleteButton: {
      cursor: 'pointer',
      fontSize: '1rem',
    },
  })
);

export default function CartProductItem(props: Props) {
  const classes = useStyles();

  return (
    <Box>
      <ListItem className={classes.listItem}>
        <Box display='flex' justifyContent='space-between' flexWrap='no-wrap'>
          <Box>
            <Typography variant='subtitle1'>{props.productName}</Typography>
          </Box>
          <Box onClick={props.onDeleteClick.bind(null, props.productCacheId)}>
            <HighlightOffOutlinedIcon className={classes.deleteButton} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' flexWrap='no-wrap'>
          <Box>
            <Typography variant='body2' className={classes.descriptionText}>
              {props.subproductsDescription}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1' className={classes.noWrapText}>
              {formatPrice(props.price!)}
            </Typography>
          </Box>
        </Box>
      </ListItem>
      {props.showDivider && <Divider id='separator-item' variant='middle' component='li' />}
    </Box>
  );
}
