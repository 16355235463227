import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      fontSize: theme.typography.body2.fontSize,
    },
    label: {
      fontWeight: theme.typography.fontWeightMediumBold,
    },
  })
);
