import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    item: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
    },
  })
);
