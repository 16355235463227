import {
  IsGmapsReadyDocument,
  IsGmapsReadyQuery,
  SetGmapsReadyMutationVariables,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export function setGmapsReady(
  root: any,
  variables: SetGmapsReadyMutationVariables,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  context.cache.writeQuery<IsGmapsReadyQuery>({
    query: IsGmapsReadyDocument,
    data: {
      isGmapsReady: variables.ready,
    },
  });

  return variables.ready;
}
