import {
  GetShoppingCartProductsQueryVariables,
  Product,
  ProductAndSubproductsFragment,
  ProductAndSubproductsFragmentDoc,
  ProductType,
  ShoppingCartFragment,
  ShoppingCartFragmentDoc,
  ShoppingCartProduct,
} from '../../generated/graphql';
import { InMemoryCache } from '@apollo/client';

export function shoppingCartProducts(
  root: any,
  variables: GetShoppingCartProductsQueryVariables,
  context: { cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const cartProducts: ShoppingCartProduct[] = [];

  const shoppingCart = context.cache.readFragment<ShoppingCartFragment>({
    fragment: ShoppingCartFragmentDoc,
    fragmentName: 'shoppingCart',
    id: context.getCacheKey({
      id: btoa(`ShoppingCart:${variables.companyId}`),
      __typename: 'ShoppingCart',
    }),
  });

  if (!shoppingCart) {
    return [];
  }

  for (const parentProduct of shoppingCart.products) {
    const subproductsDescription = formatSubproducts(
      parentProduct as any,
      true,
      '',
      context.cache,
      context.getCacheKey
    );

    const formattedProduct: ShoppingCartProduct = {
      __typename: 'ShoppingCartProduct',
      id: parentProduct.id,
      name: parentProduct.name!,
      numericalId: parentProduct.numericalId!,
      totalPrice: parentProduct.totalPrice!,
      subproductsDescription,
    };

    cartProducts.push(formattedProduct);
  }

  return cartProducts;
}

function formatSubproducts(
  parentProduct: Product,
  isRoot: boolean,
  result: string,
  cache: InMemoryCache,
  getCacheKey: any
): string {
  if (!parentProduct) {
    return result;
  }

  // Go through the product tree
  if (parentProduct.subproducts) {
    for (const subproduct of parentProduct.subproducts) {
      if (!subproduct) {
        continue;
      }

      // Note: the subproduct here is incomplete, it just have ID and __typename properties.
      // Therefore we must retrieve the whole thing from the cache.
      const cachedSubproduct = cache.readFragment<ProductAndSubproductsFragment>({
        fragment: ProductAndSubproductsFragmentDoc,
        id: getCacheKey({ __typename: 'Product', id: subproduct.id }),
        fragmentName: 'productAndSubproducts',
      });

      result = formatSubproducts(cachedSubproduct as any, false, result, cache, getCacheKey);
    }
  }

  // Ignore the names and quatitiy of choosables and the root product.
  if (!isRoot && parentProduct.productType !== ProductType.Choosable) {
    // Add semicolon termination to all subproducts except the first.
    if (result === '') {
      result = `${parentProduct.chosenQuantity}x ${parentProduct.name}`;
    } else {
      result = `${result}; ${parentProduct.chosenQuantity}x ${parentProduct.name}`;
    }
  }

  return result;
}
