import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    events: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(3, 0),
    },
  })
);
