import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
    },
    category: {
      padding: 0,
      margin: theme.spacing(1),
      marginBottom: theme.spacing(3.5),
      listStyleType: 'none',
      display: 'flex',
      flexWrap: 'wrap',

      [theme.breakpoints.down('xs')]: {
        background: theme.palette.common.white,
        boxShadow: `0px 0px 4px ${theme.palette.grey[200]}`,
        borderRadius: theme.spacing(2),
      },
    },
    categoryHeader: {
      padding: theme.spacing(0, 2),
      position: 'relative',
      flexGrow: 1,
      width: '100%',
      borderRadius: theme.spacing(2),
    },
    title: {
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightMediumBold,
      textTransform: 'uppercase',
    },
    product: {
      width: `calc(50% - ${theme.spacing(3)}px)`,
      marginRight: theme.spacing(3),

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 0,
      },

      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
        background: theme.palette.common.white,
        boxShadow: `0px 0px 4px ${theme.palette.grey[200]}`,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: theme.spacing(2),
        transition: 'box-shadow ease 0.2s',
        boxSizing: 'border-box',

        '&:hover': {
          boxShadow: `0px 0px 6px ${theme.palette.grey[300]}`,
          border: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    },
  })
);
