import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from './aggregator-tab-panel.style';

interface Props {
  value: Number;
  index: Number;
  children: React.ReactNode;
}

export default function AggregatorTabPanel(props: Props) {
  const classes = useStyles();
  return <Box className={classes.container}>{props.value === props.index && props.children}</Box>;
}
