import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Payment, Maybe } from '../../../common/generated/graphql';
import { UnionCardBrand } from '../../../common/model/card-brand';
import { useStyles } from './receipt-payment-method.style';

import { formatPrintedCardNumber } from '../../../common/helpers/format-printed-card';
import { getPaymentMethodDescription } from '../../helpers/get-payment-method-description';

import SelectCardBrandIcon from '../../../common/components/select-card-brand-icon/select-card-brand-icon';

interface Props {
  data?: Maybe<Payment>[] | null;
}

export default function ReceiptPaymentMethod({ data }: Props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item container>
        <Typography variant={'body2'}>Formas de pagamento</Typography>
      </Grid>
      {data?.map((pay, index) => (
        <Grid key={String(index)} item container xs={10} spacing={1} alignItems='center'>
          {pay?.card && (
            <>
              <Grid item>
                <SelectCardBrandIcon
                  card={pay?.card?.cardBrand?.code as UnionCardBrand}
                  size='large'
                />
              </Grid>
              <Grid item>
                <Typography
                  id='receipt-payment-method-card-description'
                  variant={'body2'}
                  className={classes.subTitle}
                >
                  {`${getPaymentMethodDescription(pay?.card?.paymentMethod)} ${
                    pay?.card?.cardBrand?.name
                  }`}
                </Typography>
                <Typography
                  id='receipt-payment-method-card-mask'
                  variant={'body2'}
                  color='textSecondary'
                >
                  {formatPrintedCardNumber(pay?.card?.maskedNumber ?? '')}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
