import { AppConfig } from '../../../common/model/config';
import { greenTable } from '../themes/green-table.theme';
import { ORDER_TYPE_TO_GO } from '../../../common/model/order-types';

export const greenTableConfig: AppConfig = {
  appName: 'green-table',
  appTitle: 'Green Table',
  appDescription: 'Peça aqui no restaurante Green Table.',
  aggregator: 1626,
  brand: 1523,
  theme: greenTable,
  faviconPath: '/favicons/favicon-greentable.ico',
  defaultOrderType: ORDER_TYPE_TO_GO,
  featureFlags: {
    embeddedApplication: false,
    getBrandThemeFromBackend: false,
    shuffleCompanyArray: true,
    gmapsAddress: false,
    showOptionsTabsAggregators: false,
    filterByDeliveryArea: false,
    useLocalAggregatorHeaderImg: false,
    removeLogoFromTheApp: true,
  },
};
