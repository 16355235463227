import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar + 100,
      background: theme.palette.common.white,
      position: 'sticky',
      boxShadow: `0px 8px 8px -4px ${theme.palette.grey[200]}`,
      top: 0,
      [theme.breakpoints.up('md')]: {
        boxShadow: 'none',
      },
    },
    defaultNavigation: {
      top: theme.template?.header.size.main.mobile,

      [theme.breakpoints.between('sm', 'md')]: {
        top: theme.template?.header.size.main.tablet,
      },

      [theme.breakpoints.up('md')]: {
        top: theme.template?.header.size.main.desktop,
      },
    },
    rootOnVisible: {
      height: '3.5rem',
    },
    searchWrap: {
      position: 'relative',
      display: 'flex',
    },
    searchWrapOnScroll: {
      flexWrap: 'wrap',
    },
    searching: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '3rem',
    },
    searchContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    searchingOnScroll: {
      flexGrow: 1,
      width: '100%',
    },
    searchingOnVisible: {
      width: '100%',
      height: '3rem',
      position: 'absolute',
      paddingBottom: theme.spacing(2),
      left: 0,
      top: 0,
    },
    tabsWhenSearchIsVisible: {
      paddingTop: '4rem',
    },
    titleWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      color: theme.palette.primary.darkest,
    },
    tabDesktop: {
      alignItems: 'flex-start',
      textTransform: 'capitalize',
      fontWeight: theme.typography.fontWeightMediumBold,
      textAlign: 'left',
    },
    tabDesktopRoot: {
      padding: theme.spacing(1, 0),
      minHeight: theme.spacing(4),
      boxSizing: 'border-box',
    },
    tabIndicatorDesktop: {
      display: 'none',
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  })
);
