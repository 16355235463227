import { useState, useEffect } from 'react';

import { loadCartCompanyFromCache } from '../../common/browser-cache/shopping-cart/load-cart-company-from-cache';
import { SHOPPING_CART_LAST_ORDER_CACHE } from '../../common/model/caches';
import { ShoppingCart } from '../../common/generated/graphql';

export function useGetCartCompanyFromCache(companyId: number) {
  const [cartCompany, setCartCompany] = useState<ShoppingCart | undefined>();

  useEffect(() => {
    (async () => {
      const cartCompany = await loadCartCompanyFromCache(companyId, SHOPPING_CART_LAST_ORDER_CACHE);
      setCartCompany(cartCompany);
    })();
  }, [companyId]);

  return cartCompany;
}
