import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Icon from '../icon/icon';
import { useStyles } from './suggest-restaurant-modal.style';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function SuggestRestaurantModal({ open, onClose }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const url = `https://pt.surveymonkey.com/r/9F2XSFQ`;

  return (
    <Dialog
      id='suggest-restaurant-dialog-container'
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth='sm'
      fullWidth={true}
      scroll={'paper'}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.container}>
        <DialogTitle className={classes.headerContainerTitle}>
          <Typography className={classes.headerTitle} id='suggest-restaurant-dialog-title'>
            Sentiu falta de algum estabelecimento no Pede Pronto?
          </Typography>
          <IconButton
            id='suggest-restaurant-dialog-action'
            edge='start'
            color='inherit'
            onClick={() => {
              onClose();
            }}
            aria-label='close'
            className={classes.buttonClose}
          >
            <Icon name='close' size='large' className={classes.buttonCloseIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContentText tabIndex={-1} className={classes.suggestRestaurantContainer}>
          <iframe
            id='suggest-restaurant-dialog-iframe'
            src={url}
            title='Sugerir restaurante'
            className={classes.suggestRestaurantIframe}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
