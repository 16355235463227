export enum FieldFormatType {
  NONE,
  NUMERICAL,
  CPF,
  CEP,
  CARD_NUMBER,
  CARD_EXPIRATION,
  BIRTHDAY,
  TELEPHONE,
}
