import { GraphQLRequest, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getAppConfig } from '../../common/helpers/configs';
import { getDeviceMetadata } from '../../common/helpers/get-device-metadata';
import { XDEVICE, XBRAND } from '../../common/model/default-headers';

export const authLink = setContext((req: GraphQLRequest, prevContext) => {
  const appConfig = getAppConfig();
  const cache: InMemoryCache = prevContext.cache;
  const deviceMetadata = getDeviceMetadata(cache);

  const headers: any = {
    [XBRAND]: appConfig.brand,
    [XDEVICE]: deviceMetadata,
  };
  return { headers };
});
