import React from 'react';
import { Typography, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import InnerInputLabel from '../../../common/components/inner-input-label/inner-input-label';
import { UserFormCustomerReturn } from '../../models/customer-form';

import { useStyles } from './customer-info-form.style';

interface Props extends UserFormCustomerReturn {}

export function CustomerInfoForm({ formik, handleOnChange }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.textWrap}>
        <Typography data-testid='customer-info-form-title' variant='body2'>
          Utilizaremos o CPF para Emissão de NF do seu pedido, telefone para enviarmos sms e
          entrarmos em contato, caso seja necessário
        </Typography>
      </Box>
      <Box className={classes.content}>
        <div>
          <TextField
            label={<InnerInputLabel label='CPF *' />}
            name='cpf'
            id='cpf'
            data-testid='customer-info-form-cpf'
            fullWidth
            className={classes.input}
            value={formik.values.cpf}
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.cpf && formik.touched.cpf)}
            helperText={formik.errors.cpf}
            variant='filled'
          />

          <TextField
            label={<InnerInputLabel label='Telefone *' />}
            name='phoneNumber'
            id='phone-number'
            data-testid='customer-info-form-phone-number'
            fullWidth
            className={classes.input}
            value={formik.values.phoneNumber}
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.phoneNumber && formik.touched.phoneNumber)}
            helperText={formik.errors.phoneNumber}
            variant='filled'
          />
        </div>
      </Box>
    </Box>
  );
}
