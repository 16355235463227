import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    primary: {
      marginBottom: theme.spacing(1),
    },
    secondaryText: {
      color: theme.palette.secondary.main,
    },
    secondaryContained: {
      background: theme.palette.common.white,
    },
  })
);
