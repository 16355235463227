import moment from 'moment';
import {
  ExceptionTimeRuleConnection,
  TimeRulesFragment,
  WeekedayTimeRuleConnection,
  TimeRule,
} from '../generated/graphql';
import { getOpeningTimes, hasTimeRuleException } from './time-rules';

export function getCompanyTimerulesMsg(timeRule?: TimeRulesFragment | TimeRule): string {
  if (!timeRule) {
    return 'Fechada hoje';
  }

  const now = moment();
  const hasException = hasTimeRuleException(
    now,
    timeRule.exceptionTimeRule as ExceptionTimeRuleConnection
  );
  if (hasException) {
    return 'Fechada hoje';
  }

  const openingTimes = getOpeningTimes(now, timeRule.weekdayTimeRule as WeekedayTimeRuleConnection);
  if (openingTimes.length === 0) {
    return 'Fechada hoje';
  }

  let result = '';
  for (const weekdayTimeRule of openingTimes) {
    const timeFrom = moment(weekdayTimeRule.timeFrom, 'HH:mm');
    const timeTo = moment(weekdayTimeRule.timeTo, 'HH:mm');

    if (now.isBefore(timeFrom)) {
      result = `Abre às ${timeFrom.format('HH:mm')}`;
      break;
    } else if (now.isAfter(timeTo)) {
      result = 'Fechada hoje';
    } else if (now.isAfter(timeFrom) && now.isBefore(timeTo)) {
      result = `Fecha às ${timeTo.format('HH:mm')}`;
    }
  }

  return result;
}
