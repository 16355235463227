import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentOffline: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    messageWrap: {
      padding: theme.spacing(0, 3, 4.5),
    },
    message: {
      color: theme.palette.grey[600],
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 3),
    },
    wrapIcon: { paddingRight: theme.spacing(1), display: 'flex', alignItems: 'center' },
    wrapLabel: {
      flexGrow: 1,
      paddingLeft: theme.spacing(2),
      display: 'flex',
    },
    radio: {
      flexDirection: 'row-reverse',
      flexGrow: 1,
      justifyContent: 'space-between',
      marginRight: 0,
    },
    label: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
    button: {
      padding: 0,
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 2),

      [theme.breakpoints.up('sm')]: {
        width: '22rem',
        alignSelf: 'center',
      },
    },
    empty: {
      display: 'none',
    },
  })
);
