import {
  MutationsAddOfflinePaymentArgs,
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheDocument,
  GetOfflinePaymentDocument,
} from '../../generated/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createNewCacheKey } from '../../helpers/create-new-cache-key';

export async function addOfflinePayment(
  root: any,
  variables: MutationsAddOfflinePaymentArgs,
  context: { client: ApolloClient<any>; cache: InMemoryCache; getCacheKey: any },
  info: any
) {
  const id = createNewCacheKey('OfflinePayment');

  const offlinePayment = {
    id,
    __typename: 'OfflinePayment',
    ...variables,
  };

  context.cache.writeQuery({ query: GetOfflinePaymentDocument, data: { offlinePayment } });

  context.cache.writeQuery<GetSelectedPaymentMethodFromCacheQuery>({
    query: GetSelectedPaymentMethodFromCacheDocument,
    data: {
      selectedPaymentMethod: offlinePayment as any,
    },
  });

  return true;
}
